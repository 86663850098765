import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

let lang_init = localStorage.getItem("charity-locale");
if (!lang_init || lang_init == "en") lang_init = false;
else if (lang_init == "he") lang_init = true;

// console.log(lang_init);
const vuetify = new Vuetify({
    // rtl: lang_init,
    rtl: false,
    theme: {
        font: { family: "Raleway" },
        themes: {
            light: {},
        },
    },
});

export default vuetify;
