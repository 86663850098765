<template>
  <!-- style="height: 500px" -->
  <div
    class="d-flex justify-center my-10 rounded-lg black ch-shadow"
    id="videoSection"
    style="height: 500px"
    v-if="
      live_stream && live_stream[0].status !== 0 &&
      ((fromPage === 'team' && live_stream[0].status >= 2) ||
        (fromPage === 'campaign' && live_stream[0].status <= 2))
    "
  >
    <iframe
      width="100%"
      height="500"
      title="Dvir Media Player"
      :src="`${live_stream[0].path}`"
      frameborder="0"
      allowfullscreen
    ></iframe>

    <!-- <iframe
      :src="`${live_stream[0].path}?autoplay=1`"
      style="width: 100%"
      title="Live Stream Video Campaign"
      frameborder="0"
      class="video"
      allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    fromPage: {
      type: String,
      default: null,
    },
  },
  computed: mapState(["live_stream"]),
};
</script>

<style scoped>
</style>
