<template>
  <div>
      <div class="pt-4 px-10 mx-3 subtitle-1">
              <p>June 2021</p>

              <p>
                At CharityLive LLC, and our affiliated companies and subsidiaries
                (collectively, “CharityLive,” “ us,” “ we,” “our”), we value your
                privacy and are dedicated to safeguarding your personal
                information. CharityLive is an all-in-one online fundraising
                response for nonprofits that provides advanced online fundraising
                tools and helps nonprofits raise more money from more donors and
                motivates their constituency. CharityLive’s Fundraising coaches
                and managers work with nonprofits to identify a compelling need
                facing their target population, the visionary solution and the
                fundraising goal required to make it happen. In this Privacy
                Policy (“Policy”), we describe the personal information we collect
                from visitors to and users of our websites (“Sites”); our online
                products and services, including application programming interface
                integrations and widgets that we offer (“Services”); and how we
                collect, use, and share personal information in the course of our
                business activities. By visiting our Sites or using any of our
                Platforms or Services, you agree that your personal information
                will be handled as described in this Policy, which is incorporated
                by reference into the CharityLive Terms of Service, available at
                https://www.charity.live/terms. It is important to note that not
                all aspects of this Policy may apply to you, depending on the
                types of interactions you have with us and the categories of
                personal information that we collect from you. Those activities
                will determine which of the following applies to you and your
                personal information. If you have questions or complaints
                regarding our privacy policy or practices, please contact us at
                Support@charity.live
              </p>
              <p>What Information do we Collect about You and Why?</p>
              <p>
                We collect information from you so that you can log onto our sites
                and use our services. These activities provide you with the
                opportunity to (1) if you are an individual consumer, donate to
                your favorite organizations and causes, create a fundraising or
                team page; and (2) if you are a client of CharityLive, set up
                peer-to-peer or crowdfunding efforts, create donation pages and
                set up registration on your own behalf or for your organization’s
                own supporters for activities (collectively “campaigns”). We also
                collect information from businesses, organizations and individuals
                who request information from CharityLive or apply to CharityLive’s
                job postings. Individuals (and agents of individuals) who provide
                us information directly or indirectly by donating to or
                participating in a campaign or by otherwise using our services in
                an individual capacity are referred to herein as “Consumers.” For
                example, consumers may enter information in order to donate to a
                campaign, or someone who has organized a team or a community as
                part of a campaign may enter your information on your behalf. In
                addition, individuals or organizations can use our Services to set
                up campaigns in order to provide Consumers the opportunity to
                donate, participate, create team fundraising pages, or otherwise
                interact with such campaigns (those who use our Services to
                provide these opportunities to Consumers are referred to as
                “clients”). Relatedly, our clients (including agents or
                representatives of our clients) may provide us personal
                information about individuals who have registered for a campaign
                being operated by our client or who the client wants us to contact
                or otherwise provide Services to on behalf of the client. When you
                participate in a client’s campaign, we provide your personal
                information to the client. This Policy does not cover our clients’
                use of your personal information outside of our Services. We are
                not responsible for the privacy practices of our clients, as such
                we encourage you to read their respective privacy statements.
                Further, you may be presented with opportunities to purchase
                products or services offered by third parties or you may elect to
                participate in contests or sweepstakes offered or sponsored by
                third parties on the Site. We are not responsible for any such
                purchase you make, or participation you undertake, and as such any
                such purchase or participation will be subject to the relevant
                third party's terms and conditions. When clients or Consumers
                contact us for support or other customer service requests, we
                maintain support tickets and other records related to the
                requests, including any information provided by such individuals
                related to such support or service requests. We may also collect
                phone call recordings related to support, customer service, and
                sales-related calls.
              </p>
              <p>
                Information We Collect Directly From You and How It May Be Shared.
                Account Information for our Sites. You may browse our Sites
                without creating an online account or providing us with your
                information, but to use certain features or to participate in
                certain campaigns, you may need to create an account or otherwise
                provide us with information. When you create an account with us or
                participate in a campaign, we may ask you to provide the
                following: Your first and last name; Your physical address for
                billing purposes; Your email address and your desired password;
                Your phone number: Your display name (this is the name other
                people will see when you post items to one of our Sites, make a
                publicly visible donation, or engage in other similar activities
                on our site), Your company name for billing and invoicing
                purposes; Other data requested by clients using custom question
                fields; The contact information of a friend, other members of your
                team, or your customers (when inviting them to visit the Site or
                when you are allowed to set up an account for them); Activity data
                such as your donation history, fundraising history, events
                attended, etc.; Your name, contact information, and financial
                information; Your organization’s name, charity number (UK) or tax
                identification number, title, 501(c)3 or other tax-exempt status,
                and other information about your organization, if you are a client
                or prospective client; Payment information, such as credit card or
                financial account numbers (if applicable).
              </p>
              <p>
                Creating a client Account. Each client that registers with the
                Sites is automatically created an account profile. This profile
                displays the client’s active campaigns. It also displays the total
                amount the client has raised per campaign and the number of donors
                who supported each campaign. Each account profile may include an
                activity feed that displays activity occurring on the Sites
                related to that client (e.g., donations made, events posted,
                etc.). These account profiles may be publicly accessible using the
                search function within the Sites or by external search engines
                (unless, where the functionality is available, the account profile
                has been made “private” by the client).
              </p>
              <p>
                Consumer Transactions. When you make a transaction through the
                Sites, we may index it for you in an individual profile specific
                to you. That information remains hidden from the public. If you
                activate your profile, then you will be able to see your profile
                when you are logged in. The information in your profile remains
                inaccessible to the public after you activate it; in order to
                prevent unauthorized access to your profile, you should not share
                your login information with other people. Donations. When you make
                a donation via the Sites, other Services, or a partner website
                that uses CharityLive’s donation processing services, you may be
                asked to share certain information with the recipient
                charity(ies), including your credit card/financial information and
                contact information. When a Consumer has asked to remain
                anonymous, this only hides your name from public activity feeds,
                not the underlying charitable beneficiary. If you make a donation
                to an individual fundraiser (i.e. via a peer-to-peer page) but do
                not donate anonymously, then the person who created the
                fundraising page will also have access to your personal
                information (except for your credit card number or financial
                information). If you make a donation through an individual
                fundraising page associated with a fundraising team, or directly
                to a team fundraising page, then the team manager will have access
                to your personal information (except for your credit card number
                or financial information). Charitable campaigns/Fundraising. If
                you are a client, you may create a variety of different types of
                pages on our Sites in connection with your campaigns (“campaign
                Pages”). Likewise, if you are a Consumer, you may create one or
                more pages on certain of our Sites in connection with your support
                of a particular charity. We may collect information from you in
                the process of your creation of these client Pages or Consumer
                Pages, as well as in connection with your creation of,
                administration of, or participation in, a campaign. Public
                campaigns. Any campaign (including all associated client Pages),
                will be publicly available unless it is set to “private” (or
                similar designation such as “Password Protected”), where that
                functionality is made available, by the client responsible for
                creating the campaign. Unless set to “private” or similar
                designation, where that functionality is made available, all such
                campaign pages created on the Sites are indexed by search engines
                and accessible to the public. Information that you post on event
                pages, campaign pages, personal fundraising pages, or other public
                pages on the Sites can be accessed by other people. You should
                exercise caution when deciding to share information on public
                pages. We cannot control who accesses shared information or how
                other parties will use that information. User Profiles, Comments,
                and Posts. Some of our Sites and Services allow users to create or
                post content such as information about the campaign, their
                fundraising team, and similar information. If you provide content
                to our Sites, we may collect and use that information as described
                in this Policy. Please note that such information may be viewed,
                collected or used by other registered users and public visitors to
                our Sites. Once posted, we cannot prevent such information from
                being used in a manner that may violate this Policy, the law, or
                your personal privacy. Surveys and Questionnaires. We may send out
                post campaign questionnaires, use automated surveys and use other
                forms of questionnaires to assess functionality, user
                satisfaction, and gather information. When you complete
                questionnaires or surveys, we collect the information you submit
                to us. We use this information for research purposes, to comply
                with legal requirements, and for other purposes as discussed in
                this Policy. This information, such as impact and financial data,
                may be shared with Partners (as defined below) to appear on their
                websites and may also be published and used by CharityLive.
                Information We Collect Automatically. We may use cookies, web
                beacons, tags, scripts, and other automated devices to collect
                information, including personally identifiable information, about
                you when you visit our Sites and receive emails from us.
                Specifically, we may collect the following information about your
                use of our Sites via these technologies: your browser type and
                operating system; web pages you view; webpage interaction,
                including cursor movement and links you click; your Internet
                Protocol (“IP”) address; your approximate geographic location;
                your interaction with the Sites; length of time you are logged in
                to our Sites; your unique ID which is given to each visitor and
                expiration of date of the ID and websites visited before or after
                our Sites. In some of our links, we use a “click-through URL”
                linked to content on the CharityLive website. When Consumers click
                one of these URLs, they pass through a separate web server before
                arriving at the destination page on our Site. We track this
                click-through data to help us determine interest in particular
                topics and measure the effectiveness of our Consumer
                communications. If you prefer not to be tracked in this way, you
                should not click text or graphic links in different online outlets
                and paid search results. We also collect non-personal data
                (including, without limitation, of the type set forth above) from
                third parties. The information we collect from third parties may
                be combined with the information we collect directly. This
                information also may be associated with your username and combined
                with other information, including personally identifiable
                information that we collect about you. This data allows our Sites
                to function properly, including, by ensuring the proper display of
                content; creating and remembering your account login details;
                interface personalization; improving our Sites; and securing our
                Sites and protecting against fraud. This data is also used to run
                statistics to avoid visitors being recorded twice, to understand
                users’ reaction to our advertising campaigns, to improve our
                offers, to provide a mechanism to share content on social media,
                to show online advertisements for products or campaigns that may
                be of interest to you (based on your previous behavior), and/or
                ads and content on social media platforms or other sites. For more
                detailed information on our use of cookies and other tracking
                technologies, please contact support@charity.live
              </p>
              <p>
                Information Collected by clients. Our clients may place cookies or
                other tracking technologies on your computer, mobile phone, or
                other device to collect information about your use of the Sites
                related to that particular client. Please refer to that party’s
                privacy policy if you have any questions about its use of your
                information. Credit Card Information. As noted above, CharityLive
                collects credit card information when you enter into certain types
                of transactions requiring payment. Credit card information
                collected by CharityLive undergoes a tokenization process
                performed by our third party service provider and is not stored by
                CharityLive directly. The information is only used to perform the
                transaction and any recurring or guaranteed minimum transactions
                to which you have agreed.
              </p>
              <p>
                How do we Use Your Information? We may use the information that we
                gather about you for the following purposes:
              </p>

              <ul>
                <li>
                  To provide our Services to you, to communicate with you about
                  your use of our Services, and for other customer service
                  purposes;
                </li>
                <li>
                  To provide information that you have requested to receive from
                  us in response to your opt-in requests;
                </li>
                <li>To provide our Services at the request of our clients;</li>
                <li>To administer campaigns;</li>
                <li>To administer your account;</li>
                <li>To send you receipts;</li>
                <li>To provide and post results of campaigns;</li>
                <li>To respond to customer service inquiries;</li>
                <li>
                  To improve our Sites and Services by providing personalized
                  experiences, location customization, personalized help, and
                  instructions;
                </li>
                <li>To provide you updates about our Sites and Services;</li>
                <li>
                  For marketing and advertising purposes; for example, we may use
                  your information to display targeted advertisements to you on
                  our Sites and to assist us in advertising our Services on third
                  party websites;
                </li>
                <li>
                  To send you email, follow-up questions about your campaign or
                  your participation in a campaign, news and newsletters,
                  promotions, and/or invitations to visit the Site;
                </li>
                <li>
                  To better understand how users access and use our Sites and
                  Services, both on an aggregated and individualized basis, and
                  for other research purposes;
                </li>
                <li>
                  When you, if a Consumer, submit your contact information to sign
                  up for or activate a CharityLive profile, you agree and consent
                  that CharityLive may send you communications relating to our
                  products, events, or recommended charities or campaigns (unless
                  such communication requires consent according to applicable law
                  and subject to your ability to opt out of such communications);
                  and
                </li>
                <li>
                  When you submit your organization’s contact information to sign
                  up for an account with CharityLive, you agree and consent that
                  CharityLive may send your organization communication relating to
                  our products, events, or other business information (unless such
                  communication requires consent according to applicable law and
                  subject to your ability to opt out of such communications).
                </li>
              </ul>

              <p>
                Clients should note that we may use information we receive or
                collect regarding Consumers (including without limitation via a
                campaign page) in accordance with the terms of this Policy,
                including in the manner set forth above. Notwithstanding the
                foregoing, in certain contexts, we collect information on behalf
                of our clients subject to contractual requirements that limit our
                ability to use and transfer your information in ways that are more
                restricted than those in this Policy (e.g., the information be
                only used as necessary to provide Services to the client and/or
                for other specified purposes). In those limited circumstances,
                your information is subject to those contractual requirements to
                the extent that they conflict with this Policy, subject to
                enforcement by the applicable client. If your information is
                collected on behalf of a third party, it will be evident at the
                time that you provide such information. This Policy does not cover
                our clients’ use of your information outside of our Services. You
                should contact that party directly to determine if your
                information is subject to such limitations on uses and to
                ascertain how the third party will make use of your information.
              </p>
              <p></p>

              <p>When and How does CharityLive Share Information it Receives?</p>
              <p>
                Third Parties. We may share your information, including personally
                identifiable information, with unaffiliated and affiliated third
                parties.
              </p>
              <p>
                Affiliates. We may disclose the information we collect from you to
                our affiliates or subsidiaries; however, if we do so, their use
                and disclosure of your information will be subject to this Policy.
              </p>
              <p>
                If you are using our Services to participate in a campaign or to
                purchase a third party product or service, CharityLive shares the
                personal information that you provide with the applicable third
                parties in order to fulfill your request. You may receive
                communications, correspondence, emails, or direct mail from these
                entities; each such party operates independently from CharityLive
                and is not subject to this Policy but is subject to its own
                privacy policy. For example, if you donate to an organization,
                that organization may reach out to you to inform you how your
                donation was used or otherwise thank you.
              </p>
              <p>
                Recent Donors Panel. The recent donors panel is a streamed display
                of actions occurring on the Sites. Unless you select the option to
                participate in a campaign anonymously, your name and the amount of
                your contribution will be publicly displayed through the relevant
                client’s recent donors panel and the recent donors panel of a
                fundraising team or community page.
              </p>
              <p>
                Making a donation "anonymous" only hides your name from public
                activity feeds, not the underlying charitable beneficiary. If you
                donate anonymously through a personal or team fundraising page,
                your personal information will be shared with the individual
                fundraiser and the team fundraising captain (except for your
                credit card number or financial information). If you join a team
                to participate in a campaign, then the team captain will also have
                access to your personal information (again, except for your credit
                card number or financial information). You consent to the
                foregoing and agree that we are not responsible for how these
                organizations or persons handle your personal information. You
                should visit their websites and/or contact them directly for their
                privacy policies and data usage practices.
              </p>
              <p>
                Supporter Communications. As part of a client's use of the
                Services, Consumers may also send emails through the Services
                ("Supporter Emails"). For example, personal fundraisers may send
                messages to their contacts through the Services in order to ask
                people to visit their fundraising pages and donate. They may also
                send emails to update donors or to thank donors for their gifts.
              </p>
              <p>
                APIs. We make certain application programming interfaces ("APIs'')
                available to clients. An API (in non-technical terms) allows a
                client to automatically retrieve certain information from our
                Sites, for use/display elsewhere (e.g., on the client’s website,
                supporting fundraising system or similar). To give an example, a
                client might use our API to retrieve the names and photos of the
                top five fundraisers for a campaign; the client could then display
                that information on a leaderboard on a different system. If you
                are fundraising for a client, your name, amount raised, and goal
                can be retrieved by the client through our API. If you create a
                team fundraising page, then your name, the team goal, and the
                amount raised by the team can also be retrieved by the client
                through our API. If you are a Consumer who donates to a campaign,
                the client who receives your donation may display your
                information, such as name and amount to the client’s own donation
                appreciation wall or similar acknowledgment (which would be hosted
                outside of CharityLive’s Services). The foregoing examples are for
                illustrative purposes only.
              </p>
              <p>
                Service Providers. We may disclose the information we collect from
                you to third party vendors, service providers, contractors, or
                agents who perform functions on our behalf. For example, these
                providers may help us administer our Sites and Applications,
                manage and administer campaigns, process credit card or other
                payments, de-duplicate data, or verify identities for fraud
                prevention purposes. These third parties have agreed to maintain
                the confidentiality, security, and integrity of your information
                and may be located wherever the Sites or Services are available.
                Where required by law, these companies agree to only use such
                information for the purposes for which they have been engaged by
                us unless you expressly permit them to use your information for
                other purposes. We now require service providers to whom we
                disclose Personal Data (as defined below) and who are not subject
                to laws based on the European Union General Data Protection
                Regulation to either subscribe to the United States / European
                Union Privacy Shield principles or agree to provide at least the
                same level of protection for Personal Data as is required by the
                relevant Privacy Shield principles, for instance by entering into
                Standard Contractual Clauses, as promulgated by the European
                Commission. If the third party does not comply with its privacy
                obligations, CharityLive will take commercially reasonable steps
                to prevent or stop the use or disclosure of Personal Data. You can
                find more information on the Privacy Shield program by visiting
                https://www.privacyshield.gov/. For purposes of this Policy,
                “Personal Data” means information that (i) is transferred from the
                EU to the United States, (ii) is recorded in any form, (iii) is
                about, or relates to, an identified or identifiable consumer,
                customer, supplier or other individual, and (iv) can be linked to
                that consumer, customer supplier, or other individual.
              </p>
              <p>
                Other Non-Affiliated Third Parties. We may disclose the
                information that we collect about you to non-affiliated third
                parties (with your consent, if consent is required by law), such
                as promotional partners and others with whom we have marketing or
                other relationships. This information may help us to determine
                what advertisements to direct to you, to place on our website, and
                where to advertise our Services. As discussed in the advertising
                section below, you have the ability to opt-out of certain uses of
                your information. You also have the ability to opt-in to receiving
                certain information from us and non-affiliated third parties.
              </p>
              <p>
                Supplemental Service Partners. By purchasing or registering for
                products or services offered by third parties as a result of your
                interaction with the Services or use of the Sites, electing to
                receive communications (such as emails) or electing to participate
                in surveys, contests, sweepstakes, or other programs offered or
                sponsored by third parties on the Site, you consent to our
                providing your personal information to those third parties, and
                you agree that we are not responsible or liable for any of their
                actions or omissions. When you provide your information in this
                manner, it will be apparent that you are providing it to a third
                party. Those third parties may use your personal information in
                accordance with their own privacy policies. You will need to
                contact those third parties to instruct them directly regarding
                your preferences for the use of your personal information by them.
                Additionally, you agree that we will have the right to use and
                disclose all such information submitted by you to such third
                parties in the same manner in which we have the right to use and
                disclose all other information submitted by you directly to us.
              </p>
              <p>
                Strategic Partners. CharityLive has integration relationships with
                third-party companies and individuals ("Partners"). These
                relationships extend the functionality of the Services and the
                Partner’s application or service ("Integrated Service") by
                allowing the Services and the Integrated Service to exchange
                Consumer data among other things and to establish linked business
                processes between the two. Consumers or clients who pay
                CharityLive for use of the Services and use an Integrated Service
                are considered customers of both CharityLive and the Partner.
                CharityLive and the Partner may share Consumer or client contact,
                account, financial and billing information, and business data with
                respect to their integrated business relationship with the
                Consumer or client. The sharing of this information allows
                CharityLive and the Partner to establish the identity of the
                shared customer and, in some cases, determine referral fees owed.
                This sharing may also allow the Partner to provide consolidated
                billing services to the shared customer and allow for the enhanced
                functionality of the Integrated Service. Those Partners may use
                your personal information in accordance with their own privacy
                policies. You will need to contact those third parties to instruct
                them directly regarding your preferences for the use of your
                personal information by them. Additionally, you agree that we will
                have the right to use and disclose all such information submitted
                by you to such third parties in the same manner in which we have
                the right to use and disclose all other information submitted by
                you directly to us.
              </p>
              <p>
                Affiliate and Referral Partners. CharityLive has referral
                relationships with third-party companies and individuals
                ("Referral Partners") who refer customers to CharityLive.
                CharityLive and a Referral Partner may share certain information
                with respect to a Consumer or client that is referred by
                CharityLive or a Referral Partner to allow CharityLive and the
                Referral Partner to establish the identity of the shared Consumer
                or client and determine referral fees owed.
              </p>
              <p>We also may share your information for the following reasons:</p>
              <ul>
                <li>
                  Business Transfers. If we are acquired by or merged with another
                  company, if substantially all of our assets are transferred to
                  another company, or as part of a bankruptcy proceeding, we may
                  transfer the information we have collected from you to the
                  acquiring company. You acknowledge and agree that any successor
                  to or acquirer of CharityLive will continue to have the right to
                  use your information in accordance with the terms of this
                  Policy.
                </li>
                <li>
                  In Response to Legal Process. We also may disclose the
                  information we collect from you in order to comply with the law,
                  a judicial proceeding, court order, or other legal process, such
                  as in response to a subpoena.
                </li>
                <li>
                  To Protect CharityLive and Others. We also may disclose the
                  information we collect from you where we believe it is necessary
                  to investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, situations involving potential
                  threats to the safety of any person, or violations of our Terms
                  of Use or this Policy.
                </li>
                <li>
                  Aggregate and Anonymized Information. We may share aggregated or
                  anonymized information about clients and Consumers with third
                  parties for marketing, advertising, research, or similar
                  purposes. For example, if we display advertisements on behalf of
                  a third party, we may share aggregated, demographic information
                  with that third party about the Consumers and/or clients to whom
                  we displayed the advertisements. In addition, we may publish
                  long-form research reports and blog posts summarizing aggregated
                  data describing behaviors of clients and Consumers on our Sites,
                  Applications, and Services. We may also publish datasets
                  containing aggregated client and/or Consumer data to support
                  replication of the published research results.
                </li>
              </ul>
              <p>
                Third Party Applications. Third party applications, such as
                Instagram, Facebook, Twitter, Pinterest, or Google buttons, may be
                available on our Sites. The owners or providers of those third
                party applications may collect personally identifiable information
                from you. Their collection of information is bound by their own
                information collection policies and practices. Your interactions
                with third party applications on our Sites may be shared with
                others within your social network. We are not responsible for the
                collection and use of your information by such third parties.
              </p>
              <p>
                Our Sites include social media features, such as the Facebook
                “comment” button and widgets such as the “share this button” or
                interactive mini-programs. These features may collect your IP
                address, which page you are visiting on our Site, and may set a
                cookie to enable the feature to function properly. Social media
                features and widgets are either hosted by a third party or hosted
                directly on our Site. Your interactions with these features are
                governed by the privacy policy of the company providing such
                features.
              </p>
              <p>
                Network Advertisers. Network advertisers are third parties that
                display advertisements based on your visits to our Sites and other
                websites you have visited. This enables us and these third parties
                to target advertisements to you for products and services in which
                you might be interested. These third party services may use
                cookies, JavaScript, and other technologies to measure the
                effectiveness of their ads and to personalize advertising content
                to you. These third party technologies are governed by each third
                party’s specific privacy policy, not this one. We may provide
                these third parties with information, including both personally
                identifiable and non-personally identifiable information, about
                your usage of our Sites and our Services.
              </p>
              <p>
                We use Local Storage, such as HTML5, to store content information
                and preferences. Third parties with whom we partner to provide
                certain features on our website or to display advertising based
                upon your web browsing activity may also use HTML5 to collect and
                store information. Various browsers may offer their own management
                tools for removing HTML5.
              </p>
              <p>
                Third Party Links. At times, our Sites and Platforms may contain
                links to other third party websites. Any access to and use of such
                linked websites is not governed by this Policy, but, is instead
                governed by the privacy policies of those third party websites,
                and we are not responsible for the information practices of such
                third party websites.
              </p>
              <p>How Secure is Information About Me?</p>
              <p>
                We have implemented and maintain appropriate technical and
                organizational security measures, policies, and procedures
                designed to reduce the risk of accidental destruction or loss, or
                unauthorized disclosure or access to such information appropriate
                to the nature of the information, concerned, including,
              </p>
              <ul>
                <li>
                  where appropriate, password protection, encryption,
                  tokenization, and secure socket layering to protect our Sites;
                </li>
                <li>
                  following strict security procedures in the storage and
                  disclosure of your personal information to prevent unauthorized
                  access to it; and
                </li>
                <li>
                  placing confidentiality requirements on our employees and
                  service providers.
                </li>
              </ul>
              <p>
                Please be aware that despite our best efforts, no data security
                measures can guarantee 100% security all of the time. Please keep
                this in mind when disclosing any of your personal information via
                the Internet.
              </p>
              <p>
                As the security of personal information depends in part on the
                security of the computer you use to communicate with us and the
                security you use to protect usernames and passwords, you should
                take steps to protect against unauthorized access to your
                password, computer, and web-enabled devices, among other things,
                by signing off after using a shared computer, inserting a password
                on your web-enabled device, choosing a password that nobody else
                knows or can easily guess, keeping your password private, and
                periodically changing your password. You should never share your
                login information with others. We are not responsible for any
                lost, stolen, or compromised passwords, or for any activity on
                your account via unauthorized password activity.
              </p>
              <p>
                We endeavor to store your personal information for only as long as
                is reasonably necessary for the purposes for which it was
                collected, as explained in this Policy. Where your information is
                no longer needed, we will dispose of it in a secure manner. In
                some circumstances, we may store your personal information for
                longer periods of time, for instance where we are required to do
                so in accordance with legal, regulatory, tax, or accounting
                requirements.
              </p>
              <p>
                In specific circumstances we may store your personal information
                for longer periods of time so that we have an accurate record of
                your dealings with us in the event of any complaints or
                challenges, or if we reasonably believe there is a prospect of
                litigation relating to your personal information or dealings.
              </p>
              <p>Can I Access and Modify my Personal Information?</p>
              <p>
                Upon request, CharityLive will provide you with information about
                whether we hold, or process on behalf of a third party, any of
                your personal information. To request this information please
                email us at support@charity.live
              </p>
              <p>
                If your personal information changes, or if you no longer desire
                our service, you may correct, update, delete/remove, or ask to
                have it removed from a public forum or testimonial by making the
                change within your account settings or by contacting us via one of
                the methods listed in this Policy. We will respond to your request
                to access, wit hin this.value=this.value.replace(/[^\d]/,'') 30
                days. Please note that copies of information ,that you have
                updated, modified, or deleted may continue to reside in our
                systems for a period of time. Also, you may not be able to remove
                your personal information from archived web pages that we no
                longer maintain, such as information related to a campaign in
                which you participated, where that information is displayed by our
                client or third party to whom the client provided your
                information. In the event we are unable to complete your access
                request, we will let you know that we are unable to do so and why.
                We may decline to process requests that are unreasonably
                repetitive, require disproportionate technical effort, jeopardize
                the privacy of others, are extremely impractical, or for which
                access is not otherwise required by local law.
              </p>
              <p>
                If your information has been shared with a third party, as
                described above, or collected on behalf of a client, then that
                third party has received their own copy of your data. If you have
                been contacted by one of these third parties, or one of our
                clients, and wish to correct or request they delete your
                information, please contact them directly.
              </p>
              <p>What Choices Do I Have Regarding My Information?</p>
              <p>
                CharityLive may send you emails from time to time, for example, to
                ask you about a campaign in which you participated. When you use
                our Services, you may have the option of signing up for news and
                offers from us or other third parties, which may include
                information about campaigns, our newsletter, or other items that
                we (or third parties) believe may be of interest to you. If, at
                any time, you would like to stop receiving the information that
                you have requested to receive from us, you may follow the opt-out
                instructions contained in any such email. Please note that it may
                take up to 10 business days for us to process opt-out requests.
              </p>
              <p>
                To opt out of products, programs, Services, or offers from our
                affiliates and trusted partners, please contact
                Support@charity.live or log into your account and update your
                subscription preferences, where that functionality is made
                available to you. If you opt out of receiving emails or promotions
                from us, we still may send you emails about your account or any
                Services you have requested or received from us, or for other
                customer services purposes, including sending you receipts. In
                addition, you may still receive emails sent by our clients through
                means other than our system. Further, if you opt out of receiving
                information related to a particular campaign, you will still
                receive CharityLive communications and communications from other
                organizations whose campaigns you have participated in or who have
                otherwise obtained your email address. As a result, as a Consumer,
                you may have to unsubscribe from multiple emails before you stop
                receiving all communications related to campaigns in which you
                have participated through our Services.
              </p>
              <p>
                As a client, by electing to stop receiving communications from us
                or through our system you will no longer receive any updates on
                your account or on campaigns in which you are participating or
                have participated, including communications regarding receipts and
                refunds. We do not recommend that you do this unless you plan to
                no longer use the Services, have not participated in a campaign
                for which you need a receipt, are not currently participating in a
                campaign, or are not currently organizing a campaign and will have
                no need to receive further communications from us or through our
                system. If you are a client, note that you may not be able to
                unsubscribe from update communications about the Services unless
                you close your account.
              </p>
              <p>
                Even after you opt out of all communications, we will retain your
                information in accordance with this Policy; however, we will no
                longer use it to contact you, unless required by law or unless you
                later provide your information to us. However, our clients who
                have received your information in accordance with this Policy may
                still use that information to contact you in accordance with their
                own privacy policies, but they may not use our system to do so.
              </p>
              <p>
                If you would like to delete your account with us, you may do so by
                emailing us at Support@charity.live and specifying which
                account(s) to delete.
              </p>
              <p>International Data Transfer</p>
              <p>
                If the Services are hosted in the United States then they are
                governed by United States law. If you are visiting the Sites or
                using the Services from outside the United States, please be aware
                that your information may be transferred to, stored, and processed
                in the United States where our servers and databases are located.
                The data protection and other laws of the United States and other
                countries might not be as comprehensive as those in your country.
              </p>
              <p>
                By using the Sites and Services, you consent to your information
                being transferred to our facilities and to the facilities of those
                third parties with whom we share it as described in this Policy.
                We will take appropriate steps to ensure that transfers of
                personal information are in accordance with applicable law, are
                carefully managed to protect your privacy rights and interests and
                limited to countries which are recognized as providing an adequate
                level of legal protection or where alternative adequate
                arrangements are in place to protect your privacy rights.
              </p>
              <p>
                In certain situations, CharityLive may be required to disclose
                personal data in response to lawful requests by public
                authorities, including to meet national security or law
                enforcement requirements.
              </p>
              <p>
                If you are located in the EUROPEAN ECONOMIC AREA (EEA), UNITED
                KINGDOM OR SWITZERLAND
              </p>
              <p>
                THIS SECTION ONLY APPLIES TO USERS OF OUR SERVICES THAT ARE
                LOCATED IN THE EUROPEAN ECONOMIC AREA, UNITED KINGDOM OR
                SWITZERLAND AT THE TIME OF DATA COLLECTION. WE MAY ASK YOU TO
                IDENTIFY WHICH COUNTRY YOU ARE LOCATED IN WHEN YOU USE SOME OF OUR
                PRODUCTS OR SERVICES, OR WE MAY RELY ON YOUR IP ADDRESS TO
                IDENTIFY YOUR COUNTRY LOCATION.
              </p>
              <p>
                CharityLive processes Personal Data as a “processor,” “joint
                controller,” and as a “controller” under the European Union’s
                General Data Protection Regulation. A “controller” is an entity
                that determines the purposes for which and the manner in which any
                personal information is processed. Any third parties that act as
                our service providers are “data processors” that handle your
                personal information in accordance with our instructions. With
                respect to your personal data that you enter or that is received
                through our websites that is not directly related to a Customer’s
                campaign, CharityLive is the controller. To the extent that
                CharityLive receives personal information from a Consumer through
                a Customer’s campaign, CharityLive and its Customer are joint data
                controllers. This means that you may choose to exercise your
                rights, including as described below, with CharityLive or the
                relevant CharityLive Customer. Please do not hesitate to contact
                us if you are unsure who the relevant CharityLive Customer is for
                a given campaign or would like their contact information at
                Support@Charity.Live
              </p>
              <p>
                If you are in the EEA, United Kingdom or Switzerland, you have the
                following rights (where applicable):
              </p>
              <ul>
                <li>
                  Access: You have the right to request a copy of the information
                  we are processing about you;
                </li>
                <li>
                  Rectification: You have the right to have incomplete or
                  inaccurate information that we process about you rectified;
                </li>
                <li>
                  Deletion: You have the right to request that we delete
                  information that we process about you, except we are not obliged
                  to do so if we need to retain such data in order to comply with
                  a legal obligation or to establish, exercise, or defend legal
                  claims;
                </li>
                <li>
                  Restriction. You have the right to restrict our processing of
                  your information where you believe such data to be inaccurate;
                  our processing is unlawful; or that we no longer need to process
                  such data for a particular purpose unless we are not able to
                  delete the data due to a legal or other obligation or because
                  you do not wish for us to delete it;
                </li>
                <li>
                  Portability. You have the right to obtain information we hold
                  about you, in a structured, electronic format, and to transmit
                  such data to another data controller, where this is (a)
                  information which you have provided to us, and (b) if we are
                  processing that data on the basis of your consent or to perform
                  a contract with you;
                </li>
                <li>
                  Objection. Where the legal basis for processing your information
                  is our legitimate interest, you have the right to object to such
                  processing on grounds relating to your particular situation. We
                  will abide by your request unless we have compelling legitimate
                  grounds for the processing which override your interests, or if
                  we need to continue to process the data for the establishment,
                  exercise, or defense of a legal claim;
                </li>
                <li>
                  Withdrawing consent: If you have consented to our processing of
                  your information, you have the right to withdraw your consent at
                  any time, free of charge. This includes where you wish to opt
                  out from marketing messages.
                </li>
              </ul>
              <p>
                You can make a request to exercise any of these rights in relation
                to your information by contacting us via support@charity.live For
                your own privacy and security, at our discretion, we may require
                you to prove your identity before providing the requested
                information. Please note that we may take up to 30 days to fulfill
                such a request. We reserve the right to charge a fee where
                permitted by law, for instance if your request is manifestly
                unfounded or excessive. We may not always be able to fully address
                your request, for example if it would impact the duty of
                confidentiality we owe to others, or if we are legally entitled to
                deal with the request in a different way.
              </p>
              <p>
                You also have the right to lodge a complaint with the local data
                protection authority if you believe that we have not complied with
                applicable data protection laws. A list of local data protection
                authorities in European countries is available here.
              </p>
              <p>
                Our legal basis for collecting and using the personal information
                described above will depend on the personal information concerned
                and the specific context in which we collect it. However, we will
                normally collect personal information from you only where we have
                your consent to do so, where we need the personal information to
                perform a contract with you, or where the processing is in our
                legitimate interests and not overridden by your data protection
                interests or fundamental rights and freedoms. In some cases, we
                may also have a legal obligation to collect personal information
                from you.
              </p>
              <p>
                If we ask you to provide personal information to comply with a
                legal requirement or to perform a contract with you, we will make
                this clear at the relevant time and advise you whether the
                provision of your personal information is mandatory or not (as
                well as of the possible consequences if you do not provide your
                personal information). Similarly, if we collect and use your
                personal information in reliance on our legitimate interests (or
                those of any third party), we will make clear to you at the
                relevant time what those legitimate interests are.
              </p>
              <p>Children Under 13 (Or 16 in the EU)</p>
              <p>
                Our Sites are for general audiences, and we do not knowingly
                market to, or solicit information, accept service or collect
                information from or about children or sell products to children
                under the age of 13 (or 16 in the EU). If you are under the age of
                13 (or 16 in the EU), you must ask your parent or guardian to use
                our Sites directly, on your behalf. Furthermore, we may restrict
                entries to any contests, sweepstakes, or promotions to those who
                are at least 18 years of age. If we become aware that any person
                submitting information to the Sites is under the age of 13 (or 16
                in the EU), we will attempt to delete the account and any related
                information as soon as possible. By using the Sites, you hereby
                represent that you are at least 13 years old (or 16 in the EU).
              </p>
              <p>Terms of Use</p>
              <p>
                Your use of our Sites or Services, as well as any dispute over
                privacy, are subject to this Policy and our Terms of Service,
                https://www.charity.live/en/terms/, including applicable
                limitations on damages and the resolution of disputes.
              </p>
              <p>Special Information for California Residents</p>
              <p>
                California Consumer Privacy Act Rights. Under the California
                Consumer Privacy Act (“CCPA”) California residents have certain
                rights regarding their personally identifiable information. If you
                would like to exercise these rights on or after January 1, 2020,
                please contact us at support@charity.live. For your own privacy
                and security, at our discretion, we may require you to prove your
                identity before providing the requested information. It may take
                us some time to respond to your request, but we will do so within
                the requirements of the CCPA.
              </p>
              <p>
                This Policy provides you disclosure regarding the personal
                information we collect from you and the purposes for doing so.
                Please see the What Information do we Collect about You and Why?
              </p>
              <ul>
                <li>
                  We do not sell your personal information as provided under the
                  CCPA.
                </li>
                <li>
                  Right to request disclosure as to personal information
                  CharityLive has collected about you:
                </li>
                <ul>
                  <li>
                    Upon a verifiable request, made through support@Charity.Live
                    we will disclose to you the items listed below, one or more of
                    which may be provided by reference to this Policy:
                  </li>
                  <li>
                    The categories of personal information it has collected about
                    you.
                  </li>
                  <li>
                    The categories of sources from which the personal information
                    was collected.
                  </li>
                  <li>
                    The business purpose behind collecting personal information.
                  </li>
                  <li>
                    The categories of third parties with whom CharityLive has
                    shared the information.
                  </li>
                  <li>
                    The specific pieces of personal information CharityLive has
                    collected about you.
                  </li>
                </ul>
                <li>
                  Right to request deletion: upon a verifiable request, made
                  through emailing support@Charity.Live, we will delete personal
                  information we have regarding you and direct our service
                  providers to delete your personal information from their
                  records, to the extent provided by the CCPA.
                </li>
                <li>
                  Right to be free from discrimination: CharityLive will not
                  discriminate against you for exercising any of your rights under
                  the CCPA. Please keep in mind that under certain circumstances,
                  we may charge you a different price or rate, or provide a
                  different level or quality of goods or services, if that
                  difference is reasonably related to the value provided to you by
                  your personal information.
                </li>
              </ul>

              <p>Changes to this Policy</p>
              <p>
                We may change this Policy from time to time, so be sure to check
                back periodically. If we make any changes to this Policy that
                materially affect our practices with regard to the personal
                information we have previously collected from you, we will
                endeavor to notify you in advance of such change, by highlighting
                the change on the Sites or by sending an email to you at the email
                address that you have registered with us. Your continued use of
                the Services after any changes or revisions to this Policy will
                indicate your agreement with the terms of the revised Policy.
              </p>
              <p>
                If you have any further questions about the CharityLive Privacy
                Policy please contact us at: support@Charity.Live
              </p>
              </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>