<template>
    <div
        :class="{
      topWithoutBanner: !banners,
      topWithBanner: banners && banners.length > 0,
    }"
    >
        <div v-if="banners && banners.length > 0" class="rounded-lg" style="">
            <div class="nabvar_web">
                <div v-if="video" class="hover-switch" @click="openDialog(video)">
                    <div v-if="upHere == false" @mouseover="upHere = true">
                        <img alt="logoPLay" class="play" src="/img/Play01_A.png"/>
                    </div>
                    <div v-if="upHere" @mouseleave="upHere = false">
                        <img alt="logoPLay" class="play" src="/img/Play01_B.png"/>
                    </div>
                </div>

                <v-carousel
                    :height="height"
                    :hide-delimiters="hideDelimiters"
                    :interval="9000"
                    :show-arrows="false"
                    class="rounded-lg"
                    cycle
                    hide-delimiter-background
                    width="100%"
                >
                    <v-carousel-item v-for="banner in banners" :key="banner.path">
                        <div style="position: relative; background-color: white">
                            <img
                                :id="banner.path"
                                :src="banner.path"
                                alt=""
                                class="shadow"
                                cover
                                width="100%"
                            />
                        </div>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div>
                <v-dialog
                    v-model="dialog"
                    content-class="videoBanner"
                    max-width="800px"
                    min-height="600px"
                    @click:outside="clickOutsideDialog()"
                >
                    <v-card id="popupVid">
                        <v-btn
                            icon
                            style="float: right; color: black"
                            @click="closeDialog(video)"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <iframe
                            v-if="
                            (video && video.includes('youtube')) ||
                            (video && video.includes('vimeo'))
                            "
                            id="myIframe"
                            :src="video +'?enablejsapi=1&loop=1&portrait=false&title=false&byline=false'"
                            :style="{ width: '100%', height: '400px' }"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="rounded-lg video"
                            frameborder="0"
                            loop
                            mozallowfullscreen
                            title="YouTube video player"
                            webkitallowfullscreen
                            allow-scripts
                        ></iframe>
                        <video v-else id="myVideo" controls width="100%">
                            <source :src="video" type="video/mp4"/>
                        </video>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  data() {
    return {
      height: '100%',
      dialog: false,
      upHere: false,
      hideDelimiters: true,
    };
  },
  computed: mapState( ['banners', 'logo', 'videos_banner', 'video'] ),
  watch: {
    banners() {
      if ( this.banners && this.banners[0] && this.banners[0].path ) {
        setTimeout( () => {
          const img = document.getElementById( this.banners[0].path );
          this.height = img.height;
        }, 2000 );
      }
    },
  },
  methods: {
    clickOutsideDialog() {
        // let iframe = document.querySelector('iframe');
        let iframe = document.getElementById('myIframe')
	    // let video = document.querySelector('video');
        let video = document.getElementById('myVideo')
        if ( iframe ) {
            let iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
        if ( video ) {
            video.pause();
        }
    },
    openDialog( video ) {
      this.dialog = true;
      if (
        video.includes( 'youtube' ) == true || video.includes( 'vimeo' ) == true
      ) {
        // const div = document.getElementById( 'popupVid' );
        // let iframe = document.getElementsByTagName( 'myIframe' )[0].contentWindow;


        // iframe.postMessage(
        //     '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        //     '*',
        // );
      }
    },
    closeDialog() {
      document.querySelectorAll( 'iframe' ).forEach( ( v ) => {
        v.src = v.src;
      } );
      document.querySelectorAll( 'video' ).forEach( ( v ) => {
        v.pause();
      } );
      this.dialog = false;
    },
    keyDownHandler( event ) {
      if ( event.code === 'Escape' ) {
        this.dialog = false;
      }
    },
    selectLang() {
            this.$i18n.locale == 'en' ?
                ( this.$i18n.locale = 'es' ) :
                ( this.$i18n.locale = 'en' );

            localStorage.setItem( 'charidy-locale', this.$i18n.locale );
    },

    openTeam() {
      this.$store.commit( 'TOGGLE_MODAL_CREATE_TEAM' );
    },
    goToAbout() {
      this.$store.commit( 'UPDATE_SECTION_PAGE', 'about' );

      document
          .getElementById( 'filterSection' )
          .scrollIntoView( {behavior: 'smooth'} );
    },
    openDonation() {
      this.$store.commit( 'TOGGLE_MODAL_DONATION' );
    },
  },
  created() {
    if ( window.innerWidth > 700 ) {
      this.hideDelimiters = false;
    }
    window.addEventListener( 'keydown', this.keyDownHandler );
  },
  mounted() {
    window.removeEventListener( 'keydown', this.keyDownHandler );
  },
};
</script>

<style scoped>
.play {
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
    cursor: pointer;
    z-index: 1;
    width: 90px;
    height: 90px;
}

.topWithBanner {
    margin-top: 100px;
}

.topWithoutBanner {
    margin-top: 60px;
}

@media (max-width: 726px) {
    .nabvar_web {
        position: relative;
        min-height: 80px;
    }

    .banner {
    }
}

@media (min-width: 726px) {
    .nabvar_web {
        position: relative;
        min-height: 170px;
    }

    .banner {
        margin-top: 40px;
    }
}

.videoBanner {
    box-shadow: none !important;
}

.nav {
    position: absolute;
    top: 0;
    right: 0;
}

.logo {
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 1000;
    height: 150px;
    width: 150px;
}

.v-btn--icon.v-size--small {
    height: 15px !important;
    width: 15px !important;
}

.shadow {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>

<style lang="scss" scoped>
$carousel-controls-size: 5px !default;
</style>

<style lang="scss" scoped>
.v-item-group > .v-size--small {
    margin-top: 35px !important;
}
</style>
