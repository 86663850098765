<template>
  <CampaignNumberResume />
</template>

<script>
import CampaignNumberResume from "../frontend/CampaignNumberResume.vue";
import { mapState } from "vuex";

export default {
  components: {
    CampaignNumberResume,
  },
  name: "Goal",
  data() {
    return {};
  },
  methods: {},
  computed: mapState(["info"]),
};
</script>

<style scoped>
.display-none {
  display: none !important;
}
#objectGoal {
  overflow: visible;
  width: 135px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
}
#raised {
  overflow: visible;
  width: 214px;
  white-space: nowrap;
  text-align: left;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  color: rgba(0, 0, 0, 1);
  margin: 0 auto;
}
</style>
