<template>
  <div v-if="!startIsBiggerThanNow">
    <div v-if="info" style="line-height: 1.4">
      <div
        v-if="info && info.donations_amount"
        id="raised"
        class="text-uppercase"
      >
        Raised <br />{{ info.principal_currency.symbol }}
        {{ info.donations_amount | dollar }}
      </div>

      <div
        class="text-center"
        v-if="
          info &&
          info.donations_amount > info.goal &&
          info.extra_goal &&
          info.extra_goal > info.goal
        "
      >
        <span class="goalstrikethrough-text text-through">
          {{ $t("goal") | uppercase }}
          {{ info.principal_currency.symbol }} {{ info.goal | dollar }}
        </span>
      </div>

      <div v-if="info && info.goal" id="objectGoal">
        <span
          v-if="
            info &&
            info.donations_amount > info.goal &&
            info.extra_goal &&
            info.extra_goal < info.goal &&
            info.third_goal &&
            info.third_goal > info.goal
          "
        >
          <span class="goalstrikethrough-text text-through">
            {{ $t("_bonus") | uppercase }}
            {{ $t("goal") | uppercase }}
          </span>
          <span
            class="goalstrikethrough-text text-through"
            style="font-style: normal; font-weight: bold"
          >
            {{ $t("goal") | uppercase }}
            {{ info.principal_currency.symbol }}
            {{ info.extra_goal | dollar }}</span
          ><br />
          <span
            class="goalstrikethrough-text"
            style="font-style: normal; font-weight: bold"
            >THIRD GOAL</span
          >
          <span
            class="goalstrikethrough-text"
            style="font-style: normal; font-weight: bold"
          >
            {{ info.principal_currency.symbol }}
            {{ info.third_goal | dollar }}
          </span>
        </span>
        <span
          v-else-if="
            info &&
            info.donations_amount > info.goal &&
            info.extra_goal &&
            info.extra_goal > info.goal
          "
        >
          <span class="goalstrikethrough-text">
            {{ $t("_bonus") | uppercase }}
            {{ $t("goal") | uppercase }}
            <span style="font-style: normal; font-weight: bold">
              {{ info.principal_currency.symbol }}
              {{ info.extra_goal | dollar }}
            </span>
          </span>
        </span>

        <span
          v-else
          class="goalstrikethrough-text"
          style="font-style: normal; font-weight: bold"
        >
          {{ $t("goal") | uppercase }}
          {{ info.principal_currency.symbol }} {{ info.goal | dollar }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      distance: 1,
      percentage: 0,
      campaignText: "Campaign not started",
      infinite: " --charity-infinite:  infinite;",
      startIsBiggerThanNow: false,
      startDate: null,
      startDateFormat: null,
      numberOfRingPortions: 72, //cantidad de
    };
  },
  computed: mapState(["donations", "info", "logo"]),
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.image_logo {
  height: 150px;
  width: 150px;
  object-fit: contain;
}

.startDay {
  text-align: center;
  font-size: 23px;
  font-family: "Roboto";
  color: #898989;
}

.coming {
  font-family: "Nunito-Black";
  font-size: 33px;
  width: 200px;
  margin: 0 auto;
  line-height: 1em;
  margin-top: 10px;
  text-align: center;
}

.raisedClockCointainter {
  max-width: 225px;
  min-width: 195px;
  width: 195px;
  height: 211px;
  margin: 0 auto;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
.outerRaised {
  position: absolute;
  width: 195px;
  height: 210px;
}

.contentRaised {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 3px;
  line-height: 1.15;
}

#percentage {
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(136, 127, 122, 1);
}

#raisedInPercent {
  white-space: nowrap;
  text-align: center;
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(198, 198, 198, 1);
}

#objectGoal {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
}
#raised {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: "Nunito-Black", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 60px;
  color: rgba(0, 0, 0, 1);
  margin: 0 auto;
}
.time-font {
  font-size: 1.1rem;
}
body {
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
}

.clock {
  width: 195px;
  height: 210px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  margin-right: 3px;
  /* background-color: pink; */
}
/* .clock:after {
  content: "";
  display: block;
  width: 62.5%;
  height: 42.5%;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 2.5%;
  left: calc(50% - 30%);
  z-index: 4;
  opacity: 0.35;
} */

.hour-indicator {
  width: 6px;
  height: 90%;
  border: 0 solid rgba(0, 0, 0, 0.12);
  border-width: 20px 0;
  position: absolute;
  z-index: 2;
  left: calc(50% - 1.5px);
  top: 5%;
  /* background-color: red; */
}
.border-top-primary {
  border-top-color: var(--charity-primary) !important;

  border-bottom-color: #e9e9e9;
}
.border-bottom-primary {
  border-top-color: var(--charity-primary) !important;

  border-bottom-color: var(--charity-primary) !important;
}

.border-bottom-secundary {
  border-top-color: #38ab42 !important;

  border-bottom-color: #38ab42 !important;
}
.border-top-secundary {
  border-top-color: #38ab42 !important;

  border-bottom-color: var(--charity-primary);
}
.hour-indicator:nth-of-type(1) {
  transform: rotate(0deg);
}

.hour-indicator:nth-of-type(2) {
  transform: rotate(5deg);
}

.hour-indicator:nth-of-type(3) {
  transform: rotate(10deg);
}
.hour-indicator:nth-of-type(4) {
  transform: rotate(15deg);
}
.hour-indicator:nth-of-type(5) {
  transform: rotate(20deg);
}
.hour-indicator:nth-of-type(6) {
  transform: rotate(25deg);
}
.hour-indicator:nth-of-type(7) {
  transform: rotate(30deg);
}
.hour-indicator:nth-of-type(8) {
  transform: rotate(35deg);
}
.hour-indicator:nth-of-type(9) {
  transform: rotate(40deg);
}
.hour-indicator:nth-of-type(10) {
  transform: rotate(45deg);
}
.hour-indicator:nth-of-type(11) {
  transform: rotate(50deg);
}
.hour-indicator:nth-of-type(12) {
  transform: rotate(55deg);
}
.hour-indicator:nth-of-type(13) {
  transform: rotate(60deg);
}
.hour-indicator:nth-of-type(14) {
  transform: rotate(65deg);
}
.hour-indicator:nth-of-type(15) {
  transform: rotate(70deg);
}
.hour-indicator:nth-of-type(16) {
  transform: rotate(75deg);
}
.hour-indicator:nth-of-type(17) {
  transform: rotate(80deg);
}
.hour-indicator:nth-of-type(18) {
  transform: rotate(85deg);
}
.hour-indicator:nth-of-type(19) {
  transform: rotate(90deg);
}
.hour-indicator:nth-of-type(20) {
  transform: rotate(95deg);
}
.hour-indicator:nth-of-type(21) {
  transform: rotate(100deg);
}
.hour-indicator:nth-of-type(22) {
  transform: rotate(105deg);
}
.hour-indicator:nth-of-type(23) {
  transform: rotate(110deg);
}
.hour-indicator:nth-of-type(24) {
  transform: rotate(115deg);
}
.hour-indicator:nth-of-type(25) {
  transform: rotate(120deg);
}
.hour-indicator:nth-of-type(26) {
  transform: rotate(125deg);
}
.hour-indicator:nth-of-type(27) {
  transform: rotate(130deg);
}

.hour-indicator:nth-of-type(28) {
  transform: rotate(135deg);
}
.hour-indicator:nth-of-type(29) {
  transform: rotate(140deg);
}
.hour-indicator:nth-of-type(30) {
  transform: rotate(145deg);
}
.hour-indicator:nth-of-type(31) {
  transform: rotate(150deg);
}
.hour-indicator:nth-of-type(32) {
  transform: rotate(155deg);
}
.hour-indicator:nth-of-type(33) {
  transform: rotate(160deg);
}
.hour-indicator:nth-of-type(34) {
  transform: rotate(165deg);
}
.hour-indicator:nth-of-type(35) {
  transform: rotate(170deg);
}
.hour-indicator:nth-of-type(36) {
  transform: rotate(175deg);
}

.seconds-wrapper {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.countdown-wrapper {
  z-index: 7 !important;
}
.seconds-wrapper {
  z-index: 6;
  /* border: 1px solid green; */
}

.second-pointer {
  border-style: solid;
  border-width: 0 4px 155px 4px;
  border-color: transparent transparent rgba(0, 0, 0, 0.12) transparent;
  animation: pointer 60s steps(60, end) var(--charity-infinite);
  transform-origin: bottom center;
  position: absolute;
  top: 20px;
  left: calc(50% - 4px);
}
.second-pointer:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: absolute;
  bottom: -164px;
  left: calc(50% - 10px);
}
.second-pointer:after {
  content: "";
  display: block;
  width: 10px;
  height: 30px;
  position: absolute;
  bottom: -164px;
  left: calc(50% - 5px);
  background: rgba(0, 0, 0, 0.12);
  /* border: 1px solid blue; */
}

@keyframes pointer {
  100% {
    transform: rotateZ(360deg);
  }
}

.text-shadow {
  text-shadow: #aaa 4px 2px 3px;
  font-size: 40px;
}
.clock-countdown {
}

.countdown-text {
  font-size: 17px;
}
.time-remaning {
  color: rgba(0, 0, 0, 0.12);
}

.goalstrikethrough-text {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 50px !important;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
}
.text-through {
  text-decoration: line-through;
}
</style>
