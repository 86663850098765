<template>
  <div
    v-if="
      info &&
      info.donations_amount >= info.goal &&
      campaignFinished == true &&
      info.is_countdown_trophy_image_hidden == 0
    "
  >
    <v-img
      lazy-src="/img/trofeo-min.png"
      width="227px"
      src="/img/trofeo-min.png"
      class="mx-auto d-block"
    >
    </v-img>
  </div>
  <div
    v-else-if="
      info &&
      info.donations_amount < info.goal &&
      campaignFinished == true &&
      info.is_countdown_welldone_image_hidden == 0
    "
  >
    <img
      class="mx-auto d-block"
      width="227px"
      loading="lazy"
      src="/img/welldone.png"
    />
  </div>
  <div
    v-else-if="startCampiagnPassed == false"
    style="height: 290px"
    class="mx-auto justify-center d-inline"
  >
    <div
      v-if="!hide_clock && info && info.is_clock_hidden == 0"
      class="d-flex justify-center"
    >
      <vue-ellipse-progress
        :is="component"
        :dot="{
          size: 20,
          backgroundColor: 'black',
          width: '2px',
        }"
        id="timer-example"
        :progress="progress"
        :determinate="determinate"
        line="butt"
        :color="colorOfTimer"
        empty-color="#b4b4b4"
        :emptyColorFill="emptyColorFill"
        thickness="9"
        emptyThickness="8"
        :size="210"
        dash="strict 12 0.95"
        line-mode="in 7"
        :legend="false"
        legendClass="legend-custom-style"
        fontSize="1.5rem"
        font-color="white"
        animation="reverse 1000"
        :loading="loading"
        :no-data="noData"
      >
        <span style="margin-top: -20px" class="d-flex" slot="legend-caption">
          <div style="line-height: 1.6" class="d-inline">
            <div>
              <p class="text-center time_left">TIME LEFT</p>
            </div>
            <v-row
              v-if="moreThanFourTeenHours"
              class="justify-center daysTimer textBig pulse"
            >
              <span style="font-weight: bold">{{ daysForCampaign }}&nbsp</span
              >{{ dayText }}
            </v-row>
            <v-row
              v-if="!moreThanFourTeenHours"
              class="justify-center"
              :class="{
                textSmall: moreThanFourTeenHours,
                textBig: !moreThanFourTeenHours,
              }"
            >
              <div>
                <div class="text-center">
                  <span id="days-clock" class="countdown-numbers pulse">{{
                    hours
                  }}</span>
                </div>
                <!--
                  <div v-if="moreThanFourTeenHours == false"  class="text-center countdown-text dayText">HOURS</div>
                -->
              </div>
              <div class="">
                <div class="text-center">
                  <span id="hours-clock" class="countdown-numbers pulse">{{
                    minutes
                  }}</span>
                </div>
                <!--
                  <div v-if="moreThanFourTeenHours == false"  class="text-center countdown-text HoursText">
                      MINS
                  </div>
                  -->
              </div>
              <div class="">
                <div class="text-center">
                  <span id="minutes-clock" class="countdown-numbers pulse">{{
                    seconds
                  }}</span>
                </div>
                <!--
                  <div v-if="moreThanFourTeenHours == false" class="text-center countdown-text HoursText">
                    SECS
                </div>
                -->
              </div>
            </v-row>
          </div>
        </span>
      </vue-ellipse-progress>
    </div>
  </div>
  <div v-else style="height: 290px" class="mx-auto justify-center d-inline">
    <div
      class="d-flex justify-center"
      v-if="!hide_clock && info && info.is_clock_hidden == 0"
    >
      <vue-ellipse-progress
        :is="component"
        id="timer-example"
        :progress="progress"
        :determinate="determinate"
        :color="colorOfTimer"
        empty-color="#324c7e"
        :emptyColorFill="emptyColorFill"
        thickness="8"
        emptyThickness="3"
        :size="210"
        dash="strict 60 0.8"
        line-mode="out "
        :legend="false"
        fontSize="1.5rem"
        font-color="white"
        animation="bounce  1000 100"
        :loading="loading"
        :no-data="noData"
      >
        <span class="d-flex" slot="legend-caption">
          <div style="line-height: 1.6" class="d-inline">
            <div>
              <p class="text-center time_left">LAUNCHING IN</p>
            </div>
            <v-row
              v-if="moreThanFourTeenHours"
              class="justify-center daysTimer textBig pulse"
            >
              <span style="font-weight: bold">{{ daysForCampaign }}&nbsp</span
              >{{ dayText }}
            </v-row>
            <v-row
              v-if="!moreThanFourTeenHours"
              class="justify-center"
              :class="{
                textSmall: moreThanFourTeenHours,
                textBig: !moreThanFourTeenHours,
              }"
            >
              <div>
                <div class="text-center">
                  <span class="countdown-numbers pulse">{{ hours }}</span>
                </div>
              </div>
              <div class="">
                <div class="text-center">
                  <span class="countdown-numbers pulse">{{ minutes }}</span>
                </div>
              </div>
              <div class="">
                <div class="text-center">
                  <span class="countdown-numbers pulse">{{ seconds }}</span>
                </div>
              </div>
            </v-row>
          </div>
        </span>
      </vue-ellipse-progress>
    </div>
  </div>

  <!-- </div>
    </div> -->
</template>

<script>
import Interval from "../../utils/interval";
import randomNumberInRange from "../../utils/randomNumberInRange";
import Motion from "tinymotion";

export default {
  components: { Motion },
  data: () => ({
    progress: 0,
    sec: 0,
    min: 0,
    hours: 0,
    seconds: 0,
    secondsTimer: 0,
    moreThanFourTeenHours: false,
    minutes: 0,
    tasksDone: 125,
    fade: false,
    colorOfTimer: "#324c7e",
    primaryColor: false,
    dayText: "day",
    motion: {
      repeat: true, // infinite animation until stopped
      rollback: true, // reverse animation until return to the first keyframe
      instantRollback: true, // instantly return to the first keyframe
      delay: 0, // delay before the start
      stepDelay: 0, // delay between keyframes
      factor: 1, // delay factor (number of steps to skip) before every step
      skip: 0, // number of steps to skip before the first flip
      duration: 600, // default duration. might be overridden by duration-{value} Tailwind class
      ease: "ease-in-out", // transition timing function
    },
    loading: false,
    colorDegrade: {
      radial: true,
      colors: [
        {
          color: "#3260FC",
          offset: "50",
          opacity: "0.15",
        },
        {
          color: "#3260FC",
          offset: "70",
          opacity: "0.15",
        },
        {
          color: "#3260FC",
          offset: "70",
          opacity: "0.1",
        },
        {
          color: "#3260FC",
          offset: "90",
          opacity: "1",
        },
        {
          color: "#3260FC",
          offset: "60",
          opacity: "1",
        },
        {
          color: "#3260FC",
          offset: "0",
          opacity: "0",
        },
      ],
    },
    distance: 1,
    daysForCampaign: 0,
    noData: false,
    determinate: false,
    hide_clock: false,
    campaignFinished: false,
    startCampiagnPassed: false,
    offsetTop: 0,
    emptyColor: {
      radial: true,
      colors: [
        {
          color: "#b4b4b4",
          offset: "90",
          opacity: "1",
        },
      ],
    },
    emptyColorFill: {
      radial: true,
      colors: [
        {
          color: "#ffffff",
          offset: "50",
          opacity: "0.2",
        },
        {
          color: "#ffffff",
          offset: "50",
          opacity: "0.15",
        },
        {
          color: "#ffffff",
          offset: "70",
          opacity: "0.15",
        },
        {
          color: "#ffffff",
          offset: "70",
          opacity: "0.1",
        },
        {
          color: "#ffffff",
          offset: "90",
          opacity: "0.1",
        },
        {
          color: "transparent",
          offset: "90",
          opacity: "0.1",
        },
        {
          color: "transparent",
          offset: "95",
          opacity: "0.1",
        },
        {
          color: "transparent",
          offset: "95",
          opacity: "0.1",
        },
      ],
    },
  }),
  computed: {
    minPrefix() {
      return this.min < 10 ? "0" : "";
    },
    secPrefix() {
      return this.sec < 10 ? "0" : "";
    },
    component() {
      return this.test ? "vue-ellipse-progress-test" : "vue-ellipse-progress";
    },
    info() {
      return this.$store.state.info;
    },
  },

  methods: {
    countDownClock() {
      if (this.info && this.info.style) {
        var style = JSON.parse(this.info.style);

        if (this.primaryColor == true) {
          this.colorOfTimer = style.pickerSecondary.hexa;
          this.primaryColor = false;
        } else {
          this.colorOfTimer = style.pickerPrimary.hexa;
          this.primaryColor = true;
        }

        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24,
          weekNew = day * 7,
          MonthNew = day * 30;
        // let birthday = "Sep 30, 2021 00:00:00",
        let date = this.info.end;
        var starDate = new Date(this.info.start.replace(/ /g, "T"));
        var countDown = starDate.getTime();
        var now = new Date();
        var finishDate = new Date(date.replace(/ /g, "T"));
        if (starDate > now) {
          // selected date is in the past
          this.startCampiagnPassed = true;
        } else {
          this.startCampiagnPassed = false;
          countDown = new Date(date.replace(/ /g, "T")).getTime();
        }

        if (finishDate < now) {
          this.campaignFinished = true;
        } else {
          this.campaignFinished = false;
        }
        let nowTime = new Date().getTime();
        var distance = countDown - nowTime;

        var hourLeft = Math.floor(distance / hour);

        if (hourLeft > 40) {
          this.moreThanFourTeenHours = true;
          this.daysForCampaign = Math.floor(distance / day);
        }
        if (this.daysForCampaign > 1) {
          this.dayText = "days";
        }

        var minuteTime = Math.floor((distance % hour) / minute);
        this.minutes = minuteTime;
        var hours = ("0" + Math.floor((distance % day) / hour)).slice(-2);

        var x = setInterval(() => {
          let now = new Date().getTime(),
            distance = countDown - now;
          if (distance <= 250 && distance > -250) {
            this.campaignFinished = true;
            location.reload();
          } else if (distance <= 250) {
            this.minutes = 0;
            this.hours = 0;
            this.seconds = 0;
            clearInterval(x);
          }

          var month = Math.floor(distance / (day * 30));
          var week = Math.floor(distance / (day * 7));
          var days = Math.floor(distance / day);

          var hours = ("0" + Math.floor(distance / hour)).slice(-2);
          var minuteTime = Math.floor((distance % hour) / minute);
          var minutes = ("0" + minuteTime).slice(-2);
          if (this.minutes < 0) this.minutes = 0;
          if (this.hours < 0) this.hours = 0;
          if (this.seconds < 0) this.seconds = 0;

          if (this.minutes <= 0 || this.hours <= 0) {
            this.hide_clock = true;
          } else {
            this.hide_clock = false;
          }
          this.minutes = minutes + ":";
          this.hours = hours + ":";
          this.seconds = ("0" + (59 - this.sec)).slice(-2);
        }, 1);
      }
    },
    getNow: function () {
      if (this.moreThanFourTeenHours) {
        if (this.sec === 60) {
          this.sec = 0;
          this.progress = (this.sec * 100) / 60;
          return;
        }
        this.sec = this.sec + 5;
        this.progress = (this.sec * 100) / 60;
      } else {
        const today = new Date();
        this.sec = today.getSeconds();
        this.progress = (this.sec * 100) / 59;
      }
    },

    countDownDays() {
      const second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24,
        weekNew = day * 7,
        MonthNew = day * 30;
      // let birthday = "Sep 30, 2021 00:00:00",
      let date = this.info.end;
      var starDate = new Date(this.info.start.replace(/ /g, "T"));

      var countDown = starDate.getTime();
      var now = new Date();
      var finishDate = new Date(date.replace(/ /g, "T"));
      if (starDate > now) {
        // selected date is in the past
        this.startCampiagnPassed = true;
      } else {
        this.startCampiagnPassed = false;
        countDown = new Date(date.replace(/ /g, "T")).getTime();
      }

      let nowTime = new Date().getTime();
      var distance = countDown - nowTime;

      var hourLeft = Math.floor(distance / hour);

      if (hourLeft > 40) {
        this.moreThanFourTeenHours = true;
        this.daysForCampaign = Math.floor(distance / day);
      } else {
        this.moreThanFourTeenHours = false;
      }
      if (this.daysForCampaign > 1) {
        this.dayText = "days";
      } else {
        this.dayText = "day";
      }

      // var minuteTime = Math.floor((distance % hour) / minute);
      // this.minutes = minuteTime;
      // var hours = ("0" + Math.floor((distance % day) / hour)).slice(-2);
    },
  },
  watch: {
    info() {
      // console.log("asd");
      // setInterval(this.countDownDays, 10000);
      this.countDownClock();
      // setInterval(this.getNow, 1000);
      // var style = JSON.parse(this.info.style);
      // this.colorOfTimer = style.pickerPrimary.hexa;
      // this.primaryColor = true;
    },
  },

  mounted() {
    setInterval(this.countDownDays, 10000);
    this.countDownClock();
    setInterval(this.getNow, 1000);
    if (this.info && this.info.style) {
      var style = JSON.parse(this.info.style);
      this.colorOfTimer = style.pickerPrimary.hexa;
      this.primaryColor = true;
    }
  },
};
</script>




<style  scoped>
*,
*:after,
*:before {
  box-sizing: border-box;
}

.textBig {
  font-size: 33px;
}
.textSmall {
  font-size: 20px;
}

.time_left {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
  margin-top: 10px;
}

.pulse {
  /* Chrome, Safari, Opera */
  -webkit-animation: pulse-keyframe 1s infinite;

  /* Internet Explorer */
  -ms-animation: pulse-keyframe 1s infinite;

  /* Standard Syntax */
  animation: pulse-keyframe 1s infinite;
}

@keyframes pulse-keyframe {
  0% {
    font-size: 33px;
    opacity: 0.5;
  }
  100% {
    font-size: 35px;
  }
}

.legend-custom-style {
  margin: 0 auto;
}

.daysTimer {
  font-family: "Nunito-Black", sans-serif;
  font-style: normal;
  color: rgba(44, 44, 44, 1);
}
.time-font {
  font-size: 1.1rem;
}

.outerClock {
  width: 227px;
  height: 227px;
  position: absolute;
}

.innerClock {
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 3px;
}
body {
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
}
#seconds {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  color: rgba(44, 44, 44, 1);
  margin: 0 auto;
}
#time_left {
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(159, 159, 159, 1);
}
.clock {
  width: 227px;
  height: 227px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  /* background-color: pink; */
}
/* .clock:after {
  content: "";
  display: block;
  width: 62.5%;
  height: 42.5%;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 2.5%;
  left: calc(50% - 30%);
  z-index: 4;
  opacity: 0.35;
} */
.countdown-numbers {
  font-family: "Nunito-Black", sans-serif;
  font-style: normal;
  font-weight: bold;

  color: rgba(44, 44, 44, 1);
}
.hour-indicator {
  width: 3px;
  height: 90%;
  border: 0 solid rgba(0, 0, 0, 0.12);
  border-width: 20px 0;
  position: absolute;
  z-index: 2;
  left: calc(50% - 1.5px);
  top: 5%;
  /* background-color: red; */
}
.hour-indicator:nth-of-type(1) {
  transform: rotate(30deg);
}
.hour-indicator:nth-of-type(2) {
  transform: rotate(60deg);
}
.hour-indicator:nth-of-type(3) {
  transform: rotate(90deg);
}
.hour-indicator:nth-of-type(4) {
  transform: rotate(120deg);
}
.hour-indicator:nth-of-type(5) {
  transform: rotate(150deg);
}
.hour-indicator:nth-of-type(6) {
  transform: rotate(180deg);
}
.seconds-wrapper {
  width: 226px;
  height: 226px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.countdown-wrapper {
  z-index: 7 !important;
}
.seconds-wrapper {
  z-index: 6;
  /* border: 1px solid green; */
}
.second-pointer {
  animation: pointer 60s steps(60, end) var(--charity-infinite);
  transform-origin: bottom center;
  position: absolute;
  left: 113px;
  bottom: 113px;
}
.second-pointer:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-color: var(--charity-secondary);
  border-radius: 50%;
  position: absolute;
  top: -75px;
  left: calc(50% - 10px);
}

@keyframes pointer {
  100% {
    transform: rotateZ(360deg);
  }
}
.text-shadow {
  text-shadow: #aaa 4px 2px 3px;
  font-size: 40px;
}
.clock-countdown {
}
.countdown-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: rgba(159, 159, 159, 1);
  line-height: 4px;
  font-weight: normal;
  font-size: 13px;
}
.time-remaning {
  color: rgba(0, 0, 0, 0.12);
}
</style>
© 2021 GitHub, Inc.
Terms
Privacy
