<template>
  <div class="mt-1 pt-1 pt-4" v-if="info">
    <div class="supporter" style="line-height: 1">
      <div>
        <span style="heigth: 25px; font-style: normal; font-weight: bold">
          {{ info.supporters | dollar }}
          {{ $t("Have_Already_Joined_") }}
          <br />
          <span style="font-size: 1.1em">
            {{ $t("of") }}

            {{ info.support_goal | dollar }}
          </span>
        </span>
      </div>
    </div>
    <div v-if="info && info.supporters_percent">
      <v-progress-linear
        :value="info.supporters_percent"
        height="22"
        class="rounded-xl mx-auto"
        style="width: 80%"
        color="success"
      >
        <strong>{{ Math.ceil(info.supporters_percent) }}%</strong>
      </v-progress-linear>
    </div>
    <!-- <div v-if="info && info.donations_amount" class="raised mt-4">
      {{ $t("_raised") }}
      {{ info.principal_currency.symbol }}
      {{ info.donations_amount | dollar }}
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      distance: 1,
      percentage: 0,
      campaignText: "Campaign not started",
      infinite: " --charity-infinite:  infinite;",
      startIsBiggerThanNow: false,
      startDate: null,
      startDateFormat: null,
      numberOfRingPortions: 72, //cantidad de
    };
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  mounted() {
    this.updatePercent();
  },
  // watch: {
  //     info() {
  //         this.updatePercent();
  //     }
  // },
  methods: {
    updatePercent() {
      if (this.info) {
        this.syncClock();
        if (this.info.supporters > this.info.support_goal) {
          var buttons = document.getElementsByClassName("hour-indicator-sup"),
            len = buttons !== null ? buttons.length : 0,
            i = 0;
          var percent = this.supporters_percent;
          this.percentage = percent;
          var percentFixed = (this.percentage - 100).toFixed();
          var percentBiger = false;
          var countsBig = 0;
          if (percentFixed >= 50) {
            percentBiger = true;
            countsBig =
              parseInt(((percentFixed - 50) / 5).toFixed()) + parseInt(1);
          }
          for (i = 0; i < 99; i++) {
            if (i <= percentFixed) {
              if (countsBig > 0) {
                buttons[i].className =
                  "hour-indicator-sup border-bottom-secundary";
                countsBig = countsBig - 1;
              } else {
                buttons[i].className =
                  "hour-indicator-sup border-top-secundary";
              }
            } else {
              if (buttons[i])
                buttons[i].className =
                  "hour-indicator-sup border-bottom-primary";
            }
          }
        } else {
          var percent = this.info.supporters_percent;

          this.percentage = percent;
          var buttons = document.getElementsByClassName("hour-indicator-sup"),
            len = buttons !== null ? buttons.length : 0;

          var percentFixed = (
            (this.numberOfRingPortions * this.info.supporters) /
            this.info.support_goal
          ).toFixed();
          this.paintRing2(percentFixed, buttons, percent);
        }
      }
    },
    paintRing2(percentFixed, buttons, percent) {
      if (buttons.length) {
        var percentBiger = false;
        var countsBig = 0;
        // console.log(percentFixed);
        if (this.percentage < 100 && percentFixed > 70) percentFixed = 70;
        if (percent >= 50) {
          percentBiger = true;
          countsBig = parseInt((percentFixed - 36).toFixed()) + parseInt(1);
        }
        for (let i = 0; i < percentFixed; i++) {
          if (countsBig > 0) {
            buttons[i].className = "hour-indicator-sup border-bottom-primary";
            countsBig = countsBig - 1;
          } else {
            buttons[i].className = "hour-indicator-sup border-top-primary";
          }
        }
      }
    },
    syncClock() {
      this.$store.dispatch("UPDATE_CAMPAIGN_DONATED_INFO", this.info.slug);
    },
  },
};
</script>

<style scoped>
.raisedClockCointainter {
  max-width: 225px;
  min-width: 195px;
  width: 195px;
  height: 211px;
  margin: 0 auto;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
.outerRaised {
  position: absolute;
  width: 195px;
  height: 210px;
}

.contentRaised {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 3px;
  line-height: 1.15;
}

#percentageSupport {
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(136, 127, 122, 1);
}

#raisedInPercentSupport {
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(198, 198, 198, 1);
}

.objectGoalSupport {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
}
.supporter {
  overflow: visible;
  text-align: center;
  font-family: "Nunito-Black", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 40px;
  margin: 0 auto;
  white-space: nowrap;
}
.raised {
  overflow: visible;
  text-align: center;
  font-family: "Nunito-Black", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 33px;
  margin: 0 auto;
  color: #616060;
}
.time-font {
  font-size: 1.1rem;
}
body {
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
}

.clock-support {
  width: 195px;
  height: 210px;
  margin: 0 auto;
  border-radius: 50%;
  position: absolute;
  margin-right: 3px;
  /* background-color: pink; */
}
/* .clock:after {
  content: "";
  display: block;
  width: 62.5%;
  height: 42.5%;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 2.5%;
  left: calc(50% - 30%);
  z-index: 4;
  opacity: 0.35;
} */

.hour-indicator-sup {
  width: 6px;
  height: 90%;
  border: 0 solid rgba(0, 0, 0, 0.12);
  border-width: 20px 0;
  position: absolute;
  z-index: 2;
  left: calc(50% - 1.5px);
  top: 5%;
  /* background-color: red; */
}
.border-top-primary {
  border-top-color: var(--charity-primary) !important;
  /* border-top-color: #000000 !important; */

  border-bottom-color: #e9e9e9;
  /* border-bottom-color: yellow; */
}
.border-bottom-primary {
  border-top-color: var(--charity-primary) !important;

  border-bottom-color: var(--charity-primary) !important;
}

.border-bottom-secundary {
  border-top-color: #38ab42 !important;

  border-bottom-color: #38ab42 !important;
}
.border-top-secundary {
  border-top-color: #38ab42 !important;

  border-bottom-color: var(--charity-primary);
}
.hour-indicator-sup:nth-of-type(1) {
  transform: rotate(0deg);
}

.hour-indicator-sup:nth-of-type(2) {
  transform: rotate(5deg);
}

.hour-indicator-sup:nth-of-type(3) {
  transform: rotate(10deg);
}
.hour-indicator-sup:nth-of-type(4) {
  transform: rotate(15deg);
}
.hour-indicator-sup:nth-of-type(5) {
  transform: rotate(20deg);
}
.hour-indicator-sup:nth-of-type(6) {
  transform: rotate(25deg);
}
.hour-indicator-sup:nth-of-type(7) {
  transform: rotate(30deg);
}
.hour-indicator-sup:nth-of-type(8) {
  transform: rotate(35deg);
}
.hour-indicator-sup:nth-of-type(9) {
  transform: rotate(40deg);
}
.hour-indicator-sup:nth-of-type(10) {
  transform: rotate(45deg);
}
.hour-indicator-sup:nth-of-type(11) {
  transform: rotate(50deg);
}
.hour-indicator-sup:nth-of-type(12) {
  transform: rotate(55deg);
}
.hour-indicator-sup:nth-of-type(13) {
  transform: rotate(60deg);
}
.hour-indicator-sup:nth-of-type(14) {
  transform: rotate(65deg);
}
.hour-indicator-sup:nth-of-type(15) {
  transform: rotate(70deg);
}
.hour-indicator-sup:nth-of-type(16) {
  transform: rotate(75deg);
}
.hour-indicator-sup:nth-of-type(17) {
  transform: rotate(80deg);
}
.hour-indicator-sup:nth-of-type(18) {
  transform: rotate(85deg);
}
.hour-indicator-sup:nth-of-type(19) {
  transform: rotate(90deg);
}
.hour-indicator-sup:nth-of-type(20) {
  transform: rotate(95deg);
}
.hour-indicator-sup:nth-of-type(21) {
  transform: rotate(100deg);
}
.hour-indicator-sup:nth-of-type(22) {
  transform: rotate(105deg);
}
.hour-indicator-sup:nth-of-type(23) {
  transform: rotate(110deg);
}
.hour-indicator-sup:nth-of-type(24) {
  transform: rotate(115deg);
}
.hour-indicator-sup:nth-of-type(25) {
  transform: rotate(120deg);
}
.hour-indicator-sup:nth-of-type(26) {
  transform: rotate(125deg);
}
.hour-indicator-sup:nth-of-type(27) {
  transform: rotate(130deg);
}

.hour-indicator-sup:nth-of-type(28) {
  transform: rotate(135deg);
}
.hour-indicator-sup:nth-of-type(29) {
  transform: rotate(140deg);
}
.hour-indicator-sup:nth-of-type(30) {
  transform: rotate(145deg);
}
.hour-indicator-sup:nth-of-type(31) {
  transform: rotate(150deg);
}
.hour-indicator-sup:nth-of-type(32) {
  transform: rotate(155deg);
}
.hour-indicator-sup:nth-of-type(33) {
  transform: rotate(160deg);
}
.hour-indicator-sup:nth-of-type(34) {
  transform: rotate(165deg);
}
.hour-indicator-sup:nth-of-type(35) {
  transform: rotate(170deg);
}
.hour-indicator-sup:nth-of-type(36) {
  transform: rotate(175deg);
}

.seconds-wrapper {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.countdown-wrapper {
  z-index: 7 !important;
}
.seconds-wrapper {
  z-index: 6;
  /* border: 1px solid green; */
}

.second-pointer {
  border-style: solid;
  border-width: 0 4px 155px 4px;
  border-color: transparent transparent rgba(0, 0, 0, 0.12) transparent;
  animation: pointer 60s steps(60, end) var(--charity-infinite);
  transform-origin: bottom center;
  position: absolute;
  top: 20px;
  left: calc(50% - 4px);
}
.second-pointer:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: absolute;
  bottom: -164px;
  left: calc(50% - 10px);
}
.second-pointer:after {
  content: "";
  display: block;
  width: 10px;
  height: 30px;
  position: absolute;
  bottom: -164px;
  left: calc(50% - 5px);
  background: rgba(0, 0, 0, 0.12);
  /* border: 1px solid blue; */
}

@keyframes pointer {
  100% {
    transform: rotateZ(360deg);
  }
}

.text-shadow {
  text-shadow: #aaa 4px 2px 3px;
  font-size: 40px;
}

.countdown-text {
  font-size: 17px;
}
.time-remaning {
  color: rgba(0, 0, 0, 0.12);
}

.goalstrikethrough {
  text-decoration: line-through;
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
  text-decoration: line-through;
}

@media (max-width: 550px) {
  .supporter {
    white-space: normal;
  }
}
</style>
