<template>
  <div>
    <div v-if="loading === true">
      <Loading :loading="loading"> </Loading>
    </div>
    <div
      style="height: 300px"
      class="d-flex flex-column align-center justify-center m-0 p-0"
      v-if="items == ''"
    >
      <div>
        <v-img
          width="120px"
          height="120px"
          contain
          src="/img/NoDonations.svg"
        ></v-img>
      </div>
      <div class="ch-font-title ch-title-color text-center">
        <p>This team has not donations yet!</p>
        <p v-if="info && info.donors > 0">
          Go to
          <a class="text-underline" :href="`/${info.slug}`">main campaign </a>
          to see the {{ info.donors }} donations
        </p>
      </div>
    </div>
    <v-row v-if="items">
      <v-col v-for="(item, index) in items" :key="index" sm="4" cols="12">
        <div class="rounded-lg border-gray ch-shadow card-donation pt-4">
          <div class="mx-6 d-flex align-center">
            <div class="pr-2">
              <v-avatar
                :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                dark
                class="white--text"
                style="font-family: 'Roboto'; color: #292929"
              >
                <template v-if="item.path">
                  <img :src="item.path" alt="" />
                </template>
                <template v-else>
                  {{ item.display_name | initWord }}
                </template>
              </v-avatar>
            </div>
            <div class="ml-2 text-line-brack">
              <div class="general-title font-weight-bold">
                {{ item.display_name }}
              </div>
              <div v-if="team" class="mt- grey--text dateOfDonation">
                <template v-if="isShowDate(item.approved_at)">
                  <div class="mt- grey--text dateOfDonation">
                    {{ hummanDate(item.approved_at) }}
                  </div>
                </template>
                with
              </div>
            </div>

            <div
              class="text-xl2 font-weight-bold text-center ml-auto"
              style="
                font-family: 'Nunito';
                font-weight: bold !important;
                color: #2c2c2c;
              "
            >
              {{ info.principal_currency.symbol }}
              {{ item.amount_public | dollar }}
            </div>
          </div>
          <div v-if="team.name" class="mx-4 mt-1 nameTeam">
            <div style="margin-left: 74px">
              {{ team.name }}
            </div>
          </div>

          <div
            v-if="info && info.is_comments_donation_hidden == 0"
            class="messages"
          >
            <div class="mx-8 my-2 text-small description-area">
              {{ item.message }}
            </div>
            <div v-if="item.response" class="response_text">
              {{ '"' + item.response + '"' }}
            </div>
          </div>
          <div class="d-flex justify-space-around align-center mx-6">
            <div
              v-if="
                info &&
                item.amount_public > 359 &&
                info.business_name == 'Keren Havshiies'
              "
              class="sub-caption text-center pink--text text--darken-2"
              style="font-family: 'Nunito'; font-weight: 600"
            >
              Nshei Keren Hashviis Member
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex justify-center my-4">
      <v-btn
        v-if="next"
        text
        dark
        large
        class="button btn-large rounded-lg ch-text-primary"
        elevation="0"
        @click="getTeamsPaginate"
      >
        {{ $t("see_more") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "../LoadingComponent.vue";
import hummanDate from "../../../utils/human-date.util";
import moment from "moment-timezone";

export default {
  components: {
    Loading,
  },
  props: {
    // team: {
    //   type: Number,
    //   default: null,
    // },
  },
  data() {
    return {
      value: 30,
      bufferValue: 120,
      interval: 0,
      items: null,
      next: null,
      loading: false,
      campaign: "",
    };
  },
  computed: mapState(["search", "info", "orderByFinder", "team"]),

  created() {
    let params = window.location.pathname.slice(1).split("/");
    this.campaign = params[0];
    axios
      .get(`/api/v1/campaign/${this.campaign}/members/${this.team.id}`)
      .then((res) => {
        this.items = res.data.data;
        this.next = res.data.next_page_url;
        this.$store.commit("UPDATE_DONORS_TEAM", res.data.total);
      });
  },
  watch: {
    team() {
      axios
        .get(
          `/api/v1/campaign/${this.campaign}/members/${this.team}?order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        });
    },
    search() {
      this.getTeamsByQuery();
    },
    orderByFinder() {
      this.getTeamsByQuery();
    },
  },
  methods: {
    getTeams() {
      const data = axios
        .get(`/api/v1/campaign/${this.campaign}/members/${this.team.id}`)
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        });
    },
    getTeamsPaginate() {
      axios
        .get(`${this.next}&query=${this.search}&order=${this.orderByFinder}`)
        .then((res) => {
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.next = res.data.next_page_url;
        });
    },
    isShowDate(from) {
      const now = moment().tz(moment.tz.guess());

      const fromDate = moment.utc(from).tz(moment.tz.guess());

      const diff = now.diff(fromDate, "days");

      return diff < 15 ? true : false;
    },
    hummanDate(from) {
      return hummanDate(from);
    },

    getTeamsByQuery() {
      const data = axios
        .get(
          `/api/v1/campaign/${this.campaign}/members/${this.team.id}?query=${this.search}&order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        });
    },
  },
};
</script>
<style scoped>
.dateOfDonation {
  font-size: 14px;
}
</style>
