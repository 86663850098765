<template>
  <div
    class="d-flex flex-wrap justify-space-between align-center contentCharity"
  >
    <div
      v-if="info && info.donations_amount && info.is_goal_hidden == 0"
      class="mx-auto"
    >
      <div class="clocks">
        <Goal />
      </div>
    </div>
    <div class="timer_card">
      <div class="ch-shadow border-gray rounded-lg py-2 px-1 cardCampaign">
        <v-container>
          <div v-if="info && info.name" id="item_text">
            <span>{{ info.name }} </span>
          </div>

          <div>
            <div class="carousel-influencer influencer-area overflow-scroll-y">
              <div
                v-if="items"
                id="carousel-items"
                class="
                  py-2
                  my-3
                  d-flex
                  carousel-items
                  flex-nowrap
                  overflow-scroll-y
                "
                :class="{ justifyCenter: items.length < 4 }"
              >
                <div
                  :id="i.id"
                  v-for="i in items"
                  :key="i.id"
                  class="rounded card_person pointer"
                  :class="{ primaryBorder: i.active }"
                >
                  <v-tooltip v-if="i.description" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        @click="setProduct(i)"
                        :class="{
                          cursorDisabled:
                            i.quantity != null && i.sold == i.quantity,
                        }"
                      >
                        <div
                          style="position: relative"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <img
                            v-if="i && i.path"
                            class="image_item"
                            :src="i.path"
                            alt="Image"
                          />
                          <div
                            v-if="
                              info &&
                              info.slug != 'YOY' &&
                              info.slug != 'yoy' &&
                              info.id != 8
                            "
                            class="raised_person"
                          >
                            <span> {{ currency }}</span>
                            <span
                              style="font-style: normal; font-weight: bold"
                              >{{
                                Math.round(i.goal * item.currency_exchange)
                              }}</span
                            >
                          </div>
                          <div v-if="i && i.name" class="text_of_raised">
                            <span v-if="i.name">{{ i.name }}</span>
                          </div>
                          <div class="grey-text mx-auto text-center">
                            <div
                              v-if="i.quantity != null && i.sold == i.quantity"
                            >
                              {{ $t("_sold") | uppercase }}
                            </div>
                            <div
                              v-else-if="i.quantity != null"
                              class="grey-text"
                            >
                              {{ i.sold }} / {{ i.quantity }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      v-if="i.description"
                      class="text-center tooltip-custom"
                    >
                      {{ i.description }}
                    </div>
                  </v-tooltip>
                  <div
                    v-else
                    @click="setProduct(i)"
                    :class="{
                      cursorDisabled:
                        i.quantity != null && i.sold == i.quantity,
                    }"
                  >
                    <img
                      v-if="i && i.path"
                      class="image_item"
                      :src="i.path"
                      alt="Image"
                    />
                    <div
                      v-if="
                        info &&
                        info.slug != 'YOY' &&
                        info.slug != 'yoy' &&
                        info.id != 8
                      "
                      class="raised_person"
                    >
                      <span> {{ currency }}</span>
                      <span
                        style="
                          font-style: normal;
                          font-weight: bold;
                          font-size: 16px;
                        "
                        >{{ Math.round(i.goal * item.currency_exchange) }}</span
                      >
                    </div>
                    <div v-if="i && i.name" class="text_of_raised">
                      <span v-if="i.name">{{ i.name }}</span>
                    </div>
                    <div class="grey-text mx-auto text-center">
                      <div v-if="i.quantity != null && i.sold == i.quantity">
                        {{ $t("_sold") | uppercase }}
                      </div>
                      <div v-else-if="i.quantity != null" class="grey-text">
                        {{ i.sold }} / {{ i.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
                <v-btn
                  v-if="items[0] && items.length > 3"
                  fab
                  small
                  class="icon-carousel icon-prev"
                  :class="{ itemWhitoutImage: items[0].path == null }"
                  @click="prev"
                >
                  <v-icon large color="white">mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  v-if="items[0] && items.length > 3"
                  fab
                  small
                  class="icon-carousel icon-next"
                  :class="{ itemWhitoutImage: items[0].path == null }"
                  @click="next"
                >
                  <v-icon color="white" large>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

          <div class="contentDonationData">
            <div id="currency">
              <v-select
                v-model="item.currency"
                :items="currencies"
                item-text="name"
                item-value="id"
                class="py-2 mt-1"
                outlined
                flat
                hide-details
              >
                <template v-slot:[`item`]="{ item }">
                  {{ item.symbol }} - {{ item.name }}
                </template>
              </v-select>
            </div>

            <div id="donation_form">
              <v-text-field
                class="py-2 mt-1"
                style="
                  font-size: 20px;
                  font-family: Nunito !important;
                  font-weight: bold;
                "
                @keypress="generateMinimuError"
                @change="validateDonation()"
                v-model="item.amount"
                type="number"
                outlined
                hide-details
                placeholder="0"
              ></v-text-field>

              <p
                style="color: #c10404; position: absolute"
                v-if="
                  info &&
                  info.minimum_donation &&
                  item.amount < info.minimum_donation * item.currency_exchange
                "
              >
                {{ minimum_donation_error }}
              </p>
            </div>
          </div>

          <div
            id="sponsors_text"
            v-if="
              info &&
              info.global_matcher &&
              info.global_matcher > 0 &&
              info.available_matcher > 0
            "
          >
            <span>{{ $t("_Generous_Donors_Will_Match_Your_Donation") }}</span>
          </div>

          <div>
            <v-row
              v-if="
                info &&
                info.global_matcher &&
                info.global_matcher > 0 &&
                info.available_matcher > 0
              "
            >
              <div class="d-flex justify-space-around w-full donationForm">
                <div style="margin-left: 50px">
                  <div class="youdonate">
                    <span>{{ $t("_You_donate") }}</span>
                  </div>
                  <div class="donate_number">
                    {{ currency }} {{ item.amount * 1 }}
                  </div>
                </div>
                <div style="margin-top: 20px">
                  <v-avatar
                    size="50"
                    style="background-color: var(--charity-primary)"
                  >
                    <span
                      v-if="
                        info &&
                        info.global_matcher > 1 &&
                        info.available_matcher > 0
                      "
                      class="white--text circleGlobalMatcher"
                      >x{{ info.global_matcher }}</span
                    >
                  </v-avatar>
                </div>
                <div style="margin-right: 50px">
                  <div class="youdonate">
                    <span>{{ $t("_We_Receive") }}</span>
                  </div>
                  <div class="donate_number">
                    {{ currency }} {{ Math.round(amount_after_match) }}
                  </div>
                  <div class="body-2">
                    {{ match_message }}
                  </div>
                </div>
              </div>
            </v-row>
          </div>
          <div>
            <v-btn
              class="
                ch-color-gradient
                nav-text
                rounded-lg
                white--text
                buttonDonate
              "
              width="100%"
              style="font-size: 22px"
              @click="makeDonation"
              elevation="0"
              height="45px"
              >{{ $t("donate") }}</v-btn
            >
          </div>
          <!-- <div>
            <v-btn
              v-if="info && (info.slug == 'nshei' || info.slug == 'NSHEI')"
              class="
                ch-color-gradient
                nav-text
                rounded-lg
                white--text
                buttonDonate
              "
              width="100%"
              style="font-size: 22px"
              elevation="0"
              height="45px"
              @click="goToShekel()"
              >{{ $t("_DONATE_IN_SHEKEL") }}</v-btn
            >
          </div> -->
        </v-container>
      </div>
      <div
        class="py-6 pb-8"
        v-if="info && info.donations_amount && info.is_goal_hidden == 1"
        id="raised"
      >
        <div>
          {{ $t("_raised") | uppercase }}
        </div>

        {{ info.principal_currency.symbol }}
        {{ info.donations_amount | dollar }}
      </div>
    </div>
    <div
      class="clocks"
      :class="{
        hidden:
          info &&
          info.is_clock_hidden == 1 &&
          info.is_countdown_trophy_image_hidden == 1 &&
          info.is_countdown_welldone_image_hidden == 1,
      }"
    >
      <CountDown />
    </div>
  </div>
</template>

<script>
import Goal from "./Goal";
import CountDown from "./CountDown.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import FireWorksComponent from "../frontend/FireWorksComponent";

export default {
  name: "timers",
  components: {
    Goal,
    CountDown,
    VueSlickCarousel,
    FireWorksComponent,
  },
  data() {
    return {
      items: null,
      goal: null,
      amount_after_match: null,
      matchers: [],
      counterPercent: 0,
      min: 0,
      max: 999999,

      currency: "USD",
      match_message: "",
      looking: false,
      campaignFinished: false,
      startCampiagnPassed: false,
      is_product_active: false,
      minimum_donation_error: "",
      item: {
        amount: 1, // poner minimum donation
        currency: "",
        // currencyNameSelected: 1,
        name: "",
        lastname: "",
        display_name: "",
        anonymus: "",
        email: "",
        message: "",
        billing_name: "",
        private_message: "",
        item: null,
        installment: 1,
        phone: "",
        country_code: "",
        team: null,
        team_full: null,
        community: "",
        wallet: "",
        teffilah_first: "",
        teffilah_second: "",
        gift_aid: null,
        terms: false,
        url: 5,
        cid: 0,
        zip_code: 0,
        house_number: 0,
        currency_exchange: 1,
        gpay_pm: "",
        token: null,
        finishedCampaign: false,
      },
    };
  },
  methods: {
    // goToShekel() {
    //   window.location.href =
    //     "https://www.matara.pro/nedarimplus/online/?mosad=7005948";
    // },
    generateMinimuError() {
      if (this.item.amount.length > 8) {
        this.item.amount = this.item.amount.slice(0, 8);
      }
      setTimeout(() => {
        const min = Math.round(
          this.info.minimum_donation * this.item.currency_exchange
        );
        this.minimum_donation_error = `Minimum Donation: ${this.currency}${min} `;
      }, 1000);
      this.item.amount = this.item.amount.replace(/[^0-9]/g, "");
      this.item.amount = this.item.amount.replace(/(\..*)\./g, "1");
    },
    validateDonation() {
      if (this.item.amount.length > 8) {
        this.item.amount = this.item.amount.slice(0, 8);
      }
      if (this.item.amount < this.min * this.item.currency_exchange) {
        this.item.amount = Math.round(this.min * this.item.currency_exchange);
      }
      if (this.item.amount > this.max) {
        this.item.amount = this.max;
      }
      this.item.amount = this.item.amount.replace(/[^0-9]/g, "");
      this.item.amount = this.item.amount.replace(/(\..*)\./g, "1");
    },

    calcMatch() {
      this.amount_after_match = 0;

      if (
        this.item.amount > 0 &&
        this.info &&
        this.info.available_matcher > 0 &&
        this.info.global_matcher > 0
      ) {
        const avail = this.info.available_matcher * this.item.currency_exchange;
        if (
          (this.item.amount / this.item.currency_exchange) *
            this.info.global_matcher -
            parseInt(this.item.amount / this.item.currency_exchange) <=
          avail
        ) {
          // aplico el matcheo si lo disponible es mayor al calculo
          this.amount_after_match = this.item.amount * this.info.global_matcher;
          this.match_message = "";
        } else {
          this.amount_after_match =
            parseInt(this.item.amount) + parseInt(avail);
          this.match_message = `up to ${this.currency} ${avail}`;
        }

        if (this.amount_after_match == 0) {
          this.amount_after_match = this.item.amount;
        }
      } else {
        this.amount_after_match = this.item.amount;
      }
    },
    desactivateProducts() {
      if (this.items) {
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].active = false;
        }
      }
    },
    setProduct(product) {
      if (product.quantity != null && product.sold == product.quantity) return;

      this.is_product_active = true;
      this.desactivateProducts();
      this.item.amount = Math.round(product.goal * this.item.currency_exchange);
      product.active = true;

      this.$store.commit("UPDATE_DONATION_ITEM", product.id);
    },
    setItemTimer() {
      this.item.amount = item.goal;
    },
    onClickOutsideCurrency() {
      this.looking = false;
    },
    // setCurrency(currency) {
    //   this.item.currency = currency.id;
    //   this.item.currencyNameSelected = currency.iso_name;
    //   this.looking = false;
    // },
    prev() {
      let a = document.getElementById("carousel-items").scrollLeft;
      a -= 320;
      document
        .getElementById("carousel-items")
        .scroll({ left: a, behavior: "smooth" });
    },

    next() {
      let a = document.getElementById("carousel-items").scrollLeft;
      a += 320;
      document
        .getElementById("carousel-items")
        .scroll({ left: a, behavior: "smooth" });
    },
    makeDonation() {
      this.$store.commit("UPDATE_AMOUNT_TO_DONATE", this.item);
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    getItems() {
      axios.get(`/api/v1/matchers/` + this.info.id).then((res) => {
        this.$store.commit("UPDATE_MATCHERS", res.data),
          this.calculatePercent();
      });
    },
    calculatePercent() {
      this.calcMatch();
    },
    updatePriceByCurrency(newVal, oldVal) {
      if (oldVal != 1) {
        const index = this.currencies.findIndex(
          (element) => element.id == oldVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount / this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      }
      if (newVal != 1) {
        const index = this.currencies.findIndex(
          (element) => element.id == newVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount * this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      } else {
        const index = this.currencies.findIndex(
          (element) => element.id == newVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount / this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      }
      this.$store.commit("UPDATE_AMOUNT_TO_DONATE", this.item);
    },
  },
  watch: {
    "item.currency": function (newVal, oldVal) {
      this.updatePriceByCurrency(newVal, oldVal);
      // this.$store.commit("UPDATE_COURRENCY_DONATE", newVal);
    },
    "item.amount": function (newVal, oldVal) {
      // this.item.amount = newVal.toString().replace(".", "");
      // if (newVal.toString().charAt(newVal.length - 1) == ".")
      //   this.item.amount.slice(0, -1);
      // console.log(newVal.toString().charAt(newVal.length - 1));
      // TODO: MATCHERS
      this.matchers = [];
      this.amount_after_match = 0;

      if (this.$store.state.donation_item != null) {
        const index = this.items.findIndex(
          (element) => element.id == this.$store.state.donation_item
        );
        if (
          index != -1 &&
          parseFloat(this.item.amount) <
            parseFloat(this.items[index].goal) *
              parseFloat(this.item.currency_exchange)
        ) {
          this.$store.commit("UPDATE_DONATION_ITEM", null);
          this.desactivateProducts();
        }
      }
      this.$store.commit("UPDATE_AMOUNT_TO_DONATE", this.item);
      //   console.log("matchers");
      this.calcMatch();
      if (this.item.amount === 0) {
        this.item.amount = null;
      }
    },
    info() {
      this.min = this.info.minimum_donation;

      const date = new Date(this.info.end);
      const starDate = new Date(this.info.start);

      let countDown = new Date(starDate).getTime();
      const now = new Date();
      if (date < now) {
        this.campaignFinished = true;
      }

      if (starDate > now) {
        // selected date is in the past
        countDown = new Date(starDate).getTime();
        this.startCampiagnPassed = true;
      } else {
        this.startCampiagnPassed = false;
        countDown = new Date(date).getTime();
      }
      this.item.amount = this.info.amount_base;
      this.calcMatch();
      this.getItems();
    },
    currency_default() {
      this.item.currency = this.currency_default.id;
      this.item.currencyNameSelected = this.currency_default.symbol;
    },
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    // is_goal_hidden() {
    //   return this.$store.state.info.is_goal_hidden;
    // },
    currencies() {
      if (this.$store.state.info && this.$store.state.info.currencies_enabled) {
        return this.$store.state.info.currencies_enabled;
      }
    },
    currency_default() {
      if (this.$store.state.info && this.$store.state.info.principal_currency) {
        return this.$store.state.info.principal_currency;
      } else return 1;
    },
  },
  async created() {
    const params = window.location.pathname.slice(1).split("/");

    const data = await axios.get(`/api/v1/campaign/${params[0]}/item`);
    this.items = data.data.map((x) => {
      // Assign new properties and return
      return Object.assign(x, {
        active: false,
      });
    });
    if (this.info && this.info.amount_base) {
      this.item.amount = this.info.amount_base;
    }

    this.$store.commit("UPDATE_ITEMS_DONATION", data.data);

    setTimeout(() => {
      this.item.currency = this.info.currency_default;
      if (this.info && this.info.slug != "chabure")
        fetch("https://icanhazip.com")
          .then((response) => response.text())
          .then((data) => {
            const ip = data;
            fetch(`https://freegeoip.app/json/${ip}`)
              .then((response) => response.json())
              .then((data) => {
                this.geoIP = data.country_code;
                if (
                  data.country_code == "AR" ||
                  data.country_code == "UK" ||
                  data.country_code == "GB"
                ) {
                  if (this.currencies) {
                    const index = this.currencies.findIndex(
                      (element) => element.id == 2
                    );

                    if (index != -1) {
                      this.item.currency = 2; // GEOIP
                    }
                  }
                }
              });
          });
    }, 2000);
  },
};
</script>

<style scoped>
.tooltip-custom {
  max-width: 200px;
}
.iconItem {
  position: absolute;
  right: 0;
}
.tooltip {
  display: inline;
  position: relative;
}
.tooltip:hover:after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #444;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: -152px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 220px;
}
.tooltip:hover:before {
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  left: 45%;
  bottom: 30px;
  position: absolute;
}
.clocks {
  position: relative;
  margin: 0 auto;
  width: 300px;
}

.hidden {
  display: none;
}

.donationForm {
  margin-top: 15px;
  margin-bottom: 15px;
}

#donation_form {
  width: 70%;
  margin-left: 10px;
}
#currency {
  width: 30%;
}
.contentDonationData {
  display: flex;
}
.buttonDonate {
  margin-top: 20px;
  font-size: 22px;
  font-family: Nunito !important;
  font-weight: bold !important;
}
.circleGlobalMatcher {
  overflow: visible;
  width: 29px;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

.itemWhitoutImage {
  bottom: 30px !important;
}
@media (max-width: 726px) {
  .contentCharity {
    margin-top: 40px;
  }
  .cardCampaign {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
@media (min-width: 726px) {
  .cardCampaign {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@media (min-width: 500px) {
  .timer_card {
    min-width: 480px;
    max-width: 480px;
    margin: 0 auto;
    width: 100vw;
  }
}
@media (max-width: 500px) {
  .timer_card {
    width: 100vw;
  }
}
#item_text {
  /* height: 34px; */
  height: auto;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  color: rgba(67, 44, 31, 1);
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  text-align: center;
  overflow: hidden;
  position: relative;
}
.card_person {
  background-color: #f6f6f8;
  width: 120px;
  min-width: 120px;
  margin-right: 3px;
  margin-left: 3px;
}
.primaryBorder {
  outline: 2px solid var(--charity-primary);
}
.image_item {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  border-color: var(--charity-primary);
  margin: 0 auto;
  margin-top: 10px;
  display: block;
}
.image_person {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  border-color: var(--charity-primary);
  margin: 0 auto;
  margin-top: 10px;
}

.raised_person {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(58, 58, 58, 1);
  margin: 0 auto;
}
.items-coarrousel-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
}

.img-container {
  width: 50px;
  height: auto;
  margin-bottom: 32px;
}

h4 {
  font-weight: bold;
}

.pink-text {
  color: #be2c83;
}

.black-text {
  color: #000;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.card-item {
  height: 185px;
  width: 220px;
  overflow: hidden;
}
.font-title-item {
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  min-height: 35px;
}
.font-amount-item {
  font-size: 32px;
  font-weight: bold;
}
.grey-text {
  color: rgba(0, 0, 0, 0.5);
}
.icon-carousel {
  background-color: rgba(0, 0, 0, 0.404) !important;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 50;
  bottom: 70px;
}
.font-xs {
  font-size: 0.8rem;
}
.poster-influ {
  width: 230px;
  height: 122px;
  border: 1px solid #cdcdcd;
}
.influencer-area {
  position: relative;
}

.icon-next {
  right: 5px;
}
.icon-prev {
  left: 5px;
}
.justifyCenter {
  justify-content: center;
}
.text_of_raised {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin-bottom: 7px;
  color: rgba(58, 58, 58, 1);
  width: 100px;
  margin: 0 auto;
  margin-bottom: 5px;
}
#sponsors_text {
  margin-top: 20px !important;
  margin: 0 auto;
  height: 24px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(175, 175, 175, 1);
}
.icon-prev {
  -moz-animation-name: left-to-right;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: left-to-right;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.icon-next {
  -moz-animation-name: right-to-left;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: right-to-left;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.youdonate {
  width: 81px;
  white-space: nowrap;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(159, 159, 159, 1);
  margin: 0 auto;
}
#donate_componet {
  width: 70%;
  margin: 0 auto;
}
.donate_number {
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgba(41, 41, 41, 1);
}
#raised {
  overflow: visible;
  white-space: nowrap;
  text-align: center;
  font-family: "Nunito-Black", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 38px;
  color: rgba(0, 0, 0, 1);
  margin: 0 auto;
}
</style>
