import Vue from "vue";

import numeral from "numeral";
Vue.use(numeral);

const dollarFilter = function(value) {
    if (!value) {
        return "0";
    }

    return numeral(value).format("(0,0)");
};

Vue.filter("uppercase", val => val && val.toUpperCase());

Vue.filter("truncate_in_break", val =>
    val && val.length > 75 ? `${val.substring(0, 75)}...` : val
);

Vue.filter("truncate_280", val =>
    val && val.length > 280 ? `${val.substring(0, 280)}...` : val
);

Vue.filter("truncate_date", val =>
    val && val.length > 10 ? `${val.substring(0, 10)}` : val
);

Vue.filter("truncate_date_with_hours", function(val) {
  var date =   val && val.length > 10 ? `${val.substring(0, 19)}` : val;
  date = date.replace('T', ' ');
  return date;
});

Vue.filter("truncate_50", val =>
    val && val.length > 50 ? `${val.substring(0, 50)}...` : val
);
Vue.filter("truncate_15", val =>
    val && val.length > 15 ? `${val.substring(0, 15)}...` : val
);
Vue.filter("truncate_20", val =>
    val && val.length > 20 ? `${val.substring(0, 20)}...` : val
);

Vue.filter("truncate_100", val =>
    val && val.length > 100 ? `${val.substring(0, 100)}...` : val
);

Vue.filter('formatoMiles', function (num) {
    if (num === null) return
    //para miles sólo con coma
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    //para miles con punto y coma
    // let val = (value/1).toFixed(2).replace('.', ',')
    // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

export { dollarFilter };

Vue.filter("initWord", function(value) {
    if (!value) return "";

    // value = value.split(' ');
    return value.charAt(0);
});


Vue.filter("initsName", function(value) {
    if (!value) return "";

     value = value.split(' ').map((n)=>n[0]).join('').toUpperCase();
    return value;
});
Vue.filter("idNameTeam", val =>
    val && val.length != null ? val.substr(0, 3).toUpperCase() : "AN"
);

// load a locale
numeral.register("locale", "ar", {
    delimiters: {
        thousands: ".",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    currency: {
        symbol: "$"
    }
});
numeral.register("locale", "us", {
    delimiters: {
        thousands: ",",
        decimal: "."
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    currency: {
        symbol: "$"
    }
});

numeral.locale("us");
