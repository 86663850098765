require( './bootstrap' );
window.Vue = require( 'vue' );
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuex from 'vuex';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import store from './plugins/store';
import i18n from './plugins/i18n';
import VueCountryCode from 'vue-country-code-select';
import VueMeta from 'vue-meta';
import VueEllipseProgress from 'vue-ellipse-progress';
import Motion from 'tinymotion';
import Utils from 'swiper/src/utils/utils';
import {dollarFilter} from './plugins/filters';

Vue.use( Utils );
Vue.use( Motion );

Vue.use( VueEllipseProgress );

Vue.use( VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
} );

Vue.use( vuetify );
Vue.use( Vuelidate );
Vue.use( Vuex );
Vue.use( VueCountryCode );

Vue.filter( 'dollar', dollarFilter );

Vue.component( 'live-campaigns', require( './views/web/LiveCampaigns.vue' ).default );
Vue.component( 'index', require( './views/web/Index.vue' ).default );
Vue.component( 'home', require( './views/web/Home.vue' ).default );
Vue.component( 'operation-room', require( './views/web/OperationRoom.vue' ).default );
Vue.component( 'team', require( './views/web/Team.vue' ).default );
Vue.component( 'site-organization', require( './views/web/SiteOrganization.vue' ).default );

const app = new Vue( {
  el: '#app',
  vuetify,
  i18n,
  icons: {
    iconfont: 'mdiSvg' || 'fa' || 'mdi', // || 'mdiSvg' || 'md' || 'fa4' || 'faSvg'
  },
  store,
} );
