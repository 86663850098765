<template>
    <div class="px-16 mt-6">
        <div>
            <div
                class="d-flex justify-center my-16 rounded-lg black ch-shadow"
                id="videoSection"
                style="height: 417px"
            >
                <template
                    v-if="video && !video.includes('youtube') && !video.includes('vimeo')"
                >
                    <video
                        class="rounded-lg video"
                        width="90%"
                        height="416px"
                        muted
                        controls
                        loop
                        :poster="poster"
                        :autoplay="false"
                    >
                        <source :src="video" type="video/mp4" />
                    </video>
                </template>
                <template v-else-if="video">
                    <iframe
                        :src="video"
                        style="width: 90%; height: 416px"
                        title="YouTube video player"
                        frameborder="0"
                        class="rounded-lg"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </template>

                <div
                    v-if="loading === true"
                    class="d-flex justify-center align-center"
                    style="height: 350px"
                >
                    <Loading :loading="loading"> </Loading>
                </div>
            </div>
        </div>
        <div>
            <v-row>
                <v-col cols="12">
                    <div class="influencer-area overflow-scroll-y">
                        <div
                            v-if="items"
                            id="carousel-influencer"
                            class="d-flex carousel-influencer flex-nowrap overflow-scroll-y"
                            :class="{ justifyCenter: items.length < 4 }"
                            style="height: 190px"
                        >
                            <div v-for="(item, index) in items" :key="index">
                                <div class="mr-4 poster-influ rounded-lg pointer elevation-1">
                                    <img
                                        v-if="item.cover"
                                        height="122px"
                                        width="230px"
                                        :src="item.cover"
                                        class="rounded-lg m-0 p-0 elevation-2"
                                        @click="toVideo(item.path, item.cover)"
                                    />

                                    <img
                                        v-else
                                        height="122px"
                                        width="230px"
                                        :src="logo[0].path"
                                        class="rounded-lg m-0 p-0 elevation-2"
                                        @click="toVideo(item.path, logo[0].path)"
                                    />
                                </div>
                                <div class="m-2 mr-6 grey--text font-xs text-center">
                                    {{ item.description | truncate_100 }}
                                </div>
                            </div>
                        </div>
                        <v-btn fab small class="icon-carousel icon-prev" @click="prev">
                            <v-icon large color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab small class="icon-carousel icon-next" @click="next">
                            <v-icon color="white" large>mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import Loading from "../frontend/LoadingComponent.vue";
import { mapState } from "vuex";

export default {
    components: {
        Loading,
    },

    data() {
        return {
            items: null,
            video: null,
            poster: null,
            loading: null,
        };
    },
    async created() {
        let params = window.location.pathname.slice(1).split("/");

        const data = await axios.get(`/api/v1/campaign/${params[0]}/influencer`);
        this.items = data.data;
        this.video = this.items[0].path;
        this.poster = this.items[0].cover;
    },
    computed: mapState(["logo"]),
    methods: {
        toVideo(url, poster) {
            this.video = null;
            this.poster = null;
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
                this.video = url;
                this.poster = poster;
            }, 1000);
        },
        prev() {
            let a = document.getElementById("carousel-influencer").scrollLeft;
            a -= 320;
            document
                .getElementById("carousel-influencer")
                .scroll({ left: a, behavior: "smooth" });
        },
        next() {
            let a = document.getElementById("carousel-influencer").scrollLeft;
            a += 320;
            document
                .getElementById("carousel-influencer")
                .scroll({ left: a, behavior: "smooth" });
        },
    },
};
</script>

<style scoped>
.influencers-text {
    color: #777;
    font-weight: 600;
    font-size: 1.1rem;
    min-height: 9px;
    text-align: center;
    white-space: nowrap;
    overflow-wrap: nowrap;
    overflow: hidden;
    margin: 5px 0;
}

.influencer-area {
    position: relative;
}
.icon-carousel {
    background-color: rgba(0, 0, 0, 0.35) !important;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    z-index: 1000;
    bottom: 100px;
    visibility: hidden;
}
.font-xs {
    font-size: 0.8rem;
}
.poster-influ {
    width: 230px;
    height: 122px;
    border: 1px solid #cdcdcd;
}
.influencer-area:hover .icon-prev {
    -moz-animation-name: left-to-right;
    -moz-animation-timing-function: ease-out;
    -moz-animation-duration: 0.4s;
    -moz-animation-iteration-count: 1;
    -moz-animation-fill-mode: forwards;

    -webkit-animation-name: left-to-right;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    visibility: visible;
}
.influencer-area:hover .icon-next {
    -moz-animation-name: right-to-left;
    -moz-animation-timing-function: ease-out;
    -moz-animation-duration: 0.4s;
    -moz-animation-iteration-count: 1;
    -moz-animation-fill-mode: forwards;

    -webkit-animation-name: right-to-left;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    visibility: visible;
}

@-moz-keyframes left-to-right {
    0% {
        left: -30px;
    }
    100% {
        left: 10px;
    }
}
@-webkit-keyframes left-to-right {
    0% {
        left: -30px;
    }
    100% {
        left: 10px;
    }
}
@-moz-keyframes right-to-left {
    0% {
        right: -30px;
    }
    100% {
        right: 10px;
    }
}
@-webkit-keyframes right-to-left {
    0% {
        right: -30px;
    }
    100% {
        right: 10px;
    }
}

.icon-next {
    right: 5px;
}

.justifyCenter {
    justify-content: center;
}
</style>
