<template>
  <div>
    <!-- <div
      style="z-index: 10001"
      v-if="teampage && info && info.slug && team && team.name"
      class="nav-fix w-full text-center success text-white"
    >
      You are in a team page for {{ team.name }}
      <a
        class="text-white"
        style="text-decoration: underline"
        :href="`/${info.slug}`"
      >
        Click here </a
      >to to Main Campaing
    </div> -->
    <div
      style="z-index: 1000"
      class="nav-shadow"
      :class="isIntersectingElement ? 'navHS' : 'navHB'"
    >
      <div class="d-flex justify-space-between navbar-top nav-w mx-auto">
        <transition name="slide-fade">
          <div class="relative">
            <div class="navbar" v-show="isIntersectingElement">
              <div class="w-30">
                <div v-if="info && info.slug == 'chabure'" class="">
                  <img class="logo_credit" src="/img/nav_a.jpeg" />
                </div>
              </div>

              <div
                style="margin: 0 auto"
                class="w-30 d-flex justify-center align-center"
              >
                <div class="nav-logo">
                  <v-img
                    :src="'/img/Charity_live.png'"
                    height="45px"
                    class="logo_charity"
                    contain
                  >
                  </v-img>
                </div>
              </div>

              <div class="w-30">
                <div class="d-flex align-center">
                  <div class="pointer mx-1">
                    <div class="lang">
                      <div
                        @click="toggleSelect = !toggleSelect"
                        class="d-flex pointer"
                      >
                        <v-img
                          width="30px"
                          height="20px"
                          contain
                          src="/img/language.png"
                        ></v-img>
                        <span>{{ $i18n.locale | uppercase }}</span>
                      </div>
                      <div class="toggle-lang" v-if="toggleSelect">
                        <div @click="selectLang('en')" class="lang-item">
                          <a>
                            <v-img
                              width="30px"
                              height="20px"
                              contain
                              src="/img/language.png"
                            ></v-img>
                            <span>EN</span>
                          </a>
                        </div>
                        <div @click="selectLang('he')" class="lang-item">
                          <a>
                            <v-img
                              width="30px"
                              height="20px"
                              contain
                              src="/img/language.png"
                            ></v-img>
                            <span>HE</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="slide-fade">
          <div class="navbar" v-show="!isIntersectingElement">
            <v-row class="d-flex align-center">
              <v-col class="py-0 hide-sm hide-xs" cols="4">
                <div
                  v-if="info && info.type_campaign != 2"
                  class="d-flex align-center nav"
                >
                  <div class="ml-2 title grey--text pl-2 font-weight-light">
                    <div
                      v-if="!campaignStartedIsBiggerThanNow"
                      class="text-center"
                    >
                      {{ $t("_raised") }}
                    </div>
                    <div
                      v-if="info && !campaignStartedIsBiggerThanNow"
                      class="ch-text-color display-1 font-weight-regular"
                    >
                      {{ info.principal_currency.symbol }}
                      {{ info.donations_amount | dollar }}
                    </div>
                    <div
                      v-else
                      class="ch-text-color headline font-weight-regular"
                    >
                      STARTING SOON
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="py-0 justify-space-around align-center nav"
              >
                <v-btn
                  v-if="info && info.type_campaign != 2"
                  class="
                    ch-color-gradient
                    nav-text
                    rounded-lg
                    font-weight-medium
                    white--text
                  "
                  width="260px"
                  elevation="0"
                  height="45px"
                  @click="makeDonation()"
                  >{{ $t("donate") }}</v-btn
                >
                <v-btn
                  v-else
                  class="
                    ch-color-gradient
                    nav-text
                    rounded-lg
                    font-weight-medium
                    white--text
                  "
                  width="260px"
                  elevation="0"
                  height="45px"
                  @click="toggleSupportModal()"
                >
                  {{ $t("SIGN_UP_HERE") }}
                </v-btn>
              </v-col>
              <v-col cols="4" class="py-0 hide-sm hide-xs">
                <div
                  v-if="
                    info && info.type_campaign != 2 && info.is_clock_hidden == 0
                  "
                  class="d-flex justify-end align-center nav"
                >
                  <div
                    class="
                      pr-2
                      mr-2
                      title
                      grey--text
                      font-weight-light
                      text-center
                    "
                  >
                    <div v-if="distance != 0">{{ statusCampaign }}</div>
                    <div
                      v-show="distance > 0"
                      class="ch-text-color font-weight-regular"
                    >
                      <span
                        id="days"
                        class="display-1 ch-text-color font-weight-regular"
                        >0</span
                      >d
                      <span
                        id="hours"
                        class="display-1 ch-text-color font-weight-regular"
                        >0</span
                      >h
                      <span
                        id="minutes"
                        class="display-1 ch-text-color font-weight-regular"
                        >0</span
                      >m
                      <span
                        id="seconds"
                        class="display-1 ch-text-color font-weight-regular"
                        >0</span
                      >s
                    </div>
                    <div v-show="distance == 0">
                      <div class="title ch-text-color font-weight-regular">
                        {{ campaignText }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    teampage: {
      default: false,
    },
    isIntersectingElement: {
      default: true,
    },
  },
  data() {
    return {
      toggleSelect: false,
      allLangs: [
        { text: "English", value: "en" },
        { text: "HE", value: "he" },
      ],
      selectedHeaders: null,
      daysElement: 0,
      hoursElement: 0,
      minutesElement: 0,
      secondsElement: 0,
      distance: 1,
      statusCampaign: this.$t("_time_left"),
      campaignText: this.$t("_campaign_not_started"),

      campaignStartedIsBiggerThanNow: false,
      moreThanThirtyTeenHours: false,
    };
  },
  watch: {
    info() {
      if (this.info.end && this.info.type_campaign == 0) this.countDownClock();

      let date = new Date(this.info.end);
      var starDate = new Date(this.info.start);

      var countDown = new Date(starDate).getTime();
    },
  },
  computed: mapState(["logo", "donations", "info", "team"]),
  methods: {
    toggleSupportModal() {
      this.$store.commit("TOGGLE_MODAL_SUPPORTER");
    },

    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION", true);
    },
    countDownClock() {
      if (
        this.info &&
        this.info.type_campaign != 2 &&
        this.info.is_clock_hidden == 0
      ) {
        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24;
        // let birthday = "Sep 30, 2021 00:00:00",
        let date = this.info.end;
        var starDate = new Date(this.info.start.replace(/ /g, "T"));
        var countDown = starDate.getTime();
        var now = new Date();
        if (starDate > now) {
          // selected date is in the past
          this.campaignStartedIsBiggerThanNow = true;
          this.statusCampaign = this.$t("_launching_in");
        } else {
          this.campaignStartedIsBiggerThanNow = false;
          countDown = new Date(date.replace(/ /g, "T")).getTime();
        }
        let nowTime = new Date().getTime();
        var distance = countDown - nowTime;
        var x = setInterval(() => {
          let now = new Date().getTime();
          let distance = countDown - now;
          document.getElementById("days").innerText = Math.floor(
            distance / day
          );
          document.getElementById("hours").innerText = Math.floor(
            (distance % day) / hour
          );
          document.getElementById("minutes").innerText = Math.floor(
            (distance % hour) / minute
          );
          document.getElementById("seconds").innerText = Math.floor(
            (distance % minute) / second
          );
          //do something later when date is reached
          if (distance < 0) {
            clearInterval(x);
            this.closeCountdown();
          }
          //seconds
        }, 0);
      }
    },
    closeCountdown() {
      this.campaignText = this.$t("_campaign_finished");
      this.distance = 0;
    },
    selectLang(lan) {
      this.$i18n.locale = lan;
      localStorage.setItem("charity-locale", this.$i18n.locale);
      // console.log(lan);
      // if (lan == "en") this.$vuetify.rtl = false;
      // else this.$vuetify.rtl = true;

      this.toggleSelect = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.lang {
  position: relative;
  right: 0;

  .toggle-lang {
    width: 150px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    right: 0px;
    top: 40px;
    transition: all 4s ease;

    box-shadow: 2px -1px 11px -2px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 2px -1px 11px -2px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 2px -1px 11px -2px rgba(0, 0, 0, 0.6);

    .lang-item {
      padding: 5px;
      width: 100%;
      position: relative;

      .v-image {
        display: inline-block;
        position: relative;
        top: 0px;
      }

      span {
        font-family: Roboto;
        font-weight: 500;
        position: relative;
        top: -5px;
      }
    }
  }
}

.nav-logo {
  max-width: 200px;
  height: 100%;
}
.nav-shadow {
  box-shadow: 0px 11px 20px -21px rgba(80, 80, 80, 0.75);
  position: fixed;
  z-index: 9999;
  background-color: white;
  width: 100%;
  top: 0px;
}
.nav-fix {
  position: fixed;
  z-index: 99999;
  background-color: white;
  width: 100%;
  top: 0px;
}
.navHS {
  height: 60px;
}
.navHB {
  height: 85px;
}
.nav-w {
  max-width: 1100px;
}
.nav {
  height: 100px;
  /* -webkit-box-shadow: 0px 2px 5px 0px rgba(80, 80, 80, 0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(80, 80, 80, 0.75); */
}
.mobile- {
  font-size: 0.5rem !important;
}
.nav-text {
  font-size: 20px !important;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s ease;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  /* transform: translateY(10px); */
  opacity: 0;
}
.navbar-top {
  position: relative;
  background-color: white;
}
.navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.pos-abs {
  direction: ltr;
  position: absolute;
  right: 0;
  top: 10% !important;
  margin-top: 20px;
}
.pos-abs-left {
  direction: ltr;
  position: absolute;
  left: 0;
  top: 2% !important;
  margin-top: 5px;
}
.logo_credit {
  height: 45px;
  max-width: 90%;
  background-position: left;
  object-fit: contain;
}
.w-30 {
  max-width: 33%;
}
</style>
