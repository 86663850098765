<template>
  <div>
    <v-snackbar v-model="snackbar" top right :timeout="5000" color="success">
      <div class="d-flex justify-space-between">
        <div>{{ $t("copied") }}</div>
        <div class="">
          <v-btn
            color="white"
            x-small
            dark
            icon
            elevation="0"
            @click="snackbar = false"
          >
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-snackbar>

    <div v-if="loading === true">
      <Loading :loading="loading"> </Loading>
    </div>

    <!-- <div v-if="communityPage"> -->
    <!-- <v-col cols="12">
        <CommunityPage />
      </v-col> -->
    <!-- </div> -->
    <div>
      <v-row v-if="items">
        <v-row v-if="items">
          <v-col
            v-for="(item, index) in items"
            :key="item.key"
            cols="12"
            md="4"
          >
            <div
              class="
                card-donation
                ch-shadow-light
                rounded-lg
                border-gray
                py-4
                px-4
                d-flex
                flex-column
                align-center
                justify-space-around
              "
            >
              <div
                class="mx-6 d-flex justify-start align-center containerWidth"
              >
                <div class="">
                  <v-avatar
                    v-if="item"
                    :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                    class="white--text mx-auto"
                    dark
                    size="50"
                  >
                    <template v-if="item.path">
                      <v-img
                        :src="item.path"
                        alt="Team Image"
                        class="bg-white rounded-circle"
                        cover
                        height="50px"
                        width="50px"
                      >
                      </v-img>
                    </template>
                    <template v-else>
                      {{ item.name | idNameTeam }}
                    </template>
                  </v-avatar>
                </div>
                <div
                  v-if="item"
                  class="
                    ml-3
                    general-title
                    font-weight-bold
                    text-truncate text-team
                  "
                >
                  {{ item.name }}
                  <!-- <div class="general-subtitle">Committee</div> -->
                </div>
              </div>
              <div class="mx-6 my-4 description-area text-small containerWidth">
                {{ item.description | truncate_280 }}
              </div>

              <div
                class="
                  mx-8
                  d-flex
                  justify-space-between
                  align-center
                  containerWidth
                "
                v-if="info && info.type_campaign == 2"
              >
                <v-row class="footerCardTeam raisedTeam">
                  <v-col cols="12" class="text-center">
                    <span v-if="item.members && item.members > 0" class="">
                      {{ item.members }}
                      Have already Joined to this community
                    </span>
                    <span v-else class="">
                      No one has joined this community
                    </span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>

      <div class="d-flex justify-center my-4">
        <v-btn
          v-if="next"
          class="button btn-large rounded-lg ch-text-primary"
          dark
          elevation="0"
          large
          text
          @click="getTeamsPaginate"
        >
          {{ $t("see_more") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import CommunityPage from "./PageComponent.vue";
import Loading from "../LoadingComponent.vue";

export default {
  components: {
    // CommunityPage,
    Loading,
  },

  data() {
    return {
      campaign: "",
      value: 30,
      bufferValue: 120,
      interval: 0,
      items: null,
      loading: true,
      next: null,
      snackbar: false,
    };
  },
  computed: mapState(["search", "communityPage", "orderByFinder", "info"]),
  watch: {
    search() {
      this.getTeamsByQuery();
    },
    orderByFinder() {
      this.getTeamsByQuery();
    },
  },

  created() {
    this.campaign = this.info.slug;
    this.getTeams();
  },

  methods: {
    copyTeamPage(slug) {
      let url = window.location.origin;
      var el = document.createElement("textarea");
      document.body.appendChild(el);
      el.value = `${url}/?community=${slug}`;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar = true;
    },

    getTeams() {
      this.loading = true;
      axios
        .get(
          `/api/v1/campaign/${this.campaign}/community/all?order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamsPaginate() {
      this.loading = true;
      axios
        .get(`${this.next}&query=${this.search}&order=${this.orderByFinder}`)
        .then((res) => {
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.next = res.data.next_page_url;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamsByQuery() {
      this.loading = true;
      axios
        .get(
          `/api/v1/campaign/${this.campaign}/community/all?query=${this.search}&order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    seeCommunity(item) {
      this.$store.commit("UPDATE_COMMUNITY", item);
      this.views = true;
    },
  },
};
</script>

<style scoped>
.containerWidth {
  width: 97%;
}

.percenteGoal {
  line-height: 0;
  text-align: end;
  margin-top: -6px;
  font-weight: bold;
  margin-bottom: 18px;
  color: var(--charity-secondary);
}

.boxView {
  padding-top: 4px;
}

.goalTeam {
  color: #9b9b9b;
  font-size: 19px;
}

.footerCardTeam {
  line-height: 1;
}

.raisedText {
  font-weight: normal;
  color: #a4a4a4;
  font-size: 14px;
  font-family: "Roboto";
}

.raisedTeam {
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  color: #2c2c2c;
}

.viewText {
  margin-right: 3px;
  margin-left: 3px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
}

.buttonView {
  border: 1px solid var(--charity-secondary) !important;
  font-size: 15px;
  cursor: pointer;
  padding: 3px;
  align-items: center;
  margin-left: 5px;
}

.buttonView:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.description-area {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  text-align: justify;
  overflow: scroll;
  overflow-y: hidden;
  min-height: 20px;
}

::-webkit-scrollbar:vertical {
  display: block;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 13px !important;
}

.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 7px;
  position: relative;
}

.progress-bar-complete {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #00bb3d 33%,
    #00b343 87%,
    #00ae4f 100%
  );
}

.progress-bar-intermediate {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f28800 33%,
    #ea6f00 87%,
    #e75900 100%
  );
}

.progress-bar-start {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f0000b 33%,
    #e50017 87%,
    #df0023 100%
  );
}

.text-team {
  white-space: inherit !important;
  line-height: 1.2;
  color: #2c2c2c;
  text-decoration: none;
}

.text-truncate {
  /* width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; */
}
</style>
