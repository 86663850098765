<template>
  <div>
    <div v-if="loading === true">
      <Loading :loading="loading"></Loading>
    </div>
    <div
      v-if="items == ''"
      class="d-flex flex-column align-center justify-center m-0 p-0"
      style="height: 300px"
    >
      <div>
        <v-img
          contain
          height="120px"
          src="img/NoDonations.svg"
          width="120px"
        ></v-img>
      </div>
      <div class="ch-font-title ch-title-color">No donations yet!</div>
    </div>

    <v-row v-if="items">
      <v-col v-for="(item, index) in items" :key="index" cols="12" sm="4">
        <div class="rounded-lg border-gray ch-shadow card-donation pt-4">
          <div class="mx-6 d-flex align-center">
            <div class="pr-2">
              <v-avatar
                :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                class="white--text"
                dark
                style="font-family: 'Roboto'; color: #292929"
              >
                <template v-if="item.path">
                  <img :src="item.path" alt="" />
                </template>
                <template v-else>
                  {{ item.display_name | initWord }}
                </template>
              </v-avatar>
            </div>
            <div class="ml-2 text-line-brack">
              <div class="general-title font-weight-bold">
                {{ item.display_name }}
              </div>
              <div class="mt- grey--text dateOfDonation">
                <template v-if="isShowDate(item.approved_at)">
                  <div class="mt- grey--text dateOfDonation">
                    {{ hummanDate(item.approved_at) }}
                  </div>
                </template>
                <span v-show="item.team_name"> with</span>
              </div>
            </div>
            <div
              class="text-xl2 font-weight-bold text-center ml-auto"
              style="
                font-family: 'Nunito';
                font-weight: bold !important;
                color: #2c2c2c;
              "
            >
              {{ info.principal_currency.symbol }}
              {{ item.amount_public | dollar }}
            </div>
          </div>
          <div v-if="item.team_name" class="mx-4 mt-1 nameTeam">
            <div style="margin-left: 74px">
              <a :href="'/' + info.slug + '/' + item.slug">
                <span class="nameTeam">
                  {{ item.team_name }}
                </span>
              </a>
            </div>
          </div>
          <div
            v-if="info && info.is_comments_donation_hidden == 0"
            class="messages"
          >
            <div class="mx-8 my-2 text-small description-area grey--text">
              {{ item.message }}
            </div>
            <template v-if="styles && styles.pickerRespondTeam">
              <div
                v-if="item.response"
                :style="`color:${styles.pickerRespondTeam.hexa};`"
              >
                {{ '"' + item.response + '"' }}
              </div>
            </template>
          </div>
          <div class="d-flex justify-space-around align-center mx-6">
            <div
              v-if="
                info &&
                item.amount_public > 359 &&
                info.business_name == 'Keren Havshiies'
              "
              class="sub-caption text-center pink--text text--darken-2"
              style="font-family: 'Nunito'; font-weight: 600"
            >
              Nshei Keren Hashviis Member
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex justify-center my-4">
      <v-btn
        v-if="next"
        :loading="loading"
        class="button btn-large rounded-lg ch-text-primary"
        dark
        elevation="0"
        large
        text
        @click="getTeamsPaginate"
      >
        {{ $t("see_more") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "../LoadingComponent.vue";
import hummanDate from "../../../utils/human-date.util";
import moment from "moment-timezone";

export default {
  props: {
    columns: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      styles: {
        pickerRespondTeam: {
          hexa: "",
        },
      },
      campaign: "",
      value: 30,
      bufferValue: 120,
      interval: 0,
      items: null,
      next: null,
      loading: false,
      latestDonorInCampaign: 0,
    };
  },
  computed: mapState([
    "search",
    "orderByFinder",
    "info",
    "lastDonor",
    "monitorDonations",
  ]),
  watch: {
    info(newVal, OldVal) {
      this.latestDonorInCampaign = newVal.lastDonation;
      this.connect();
    },
    monitorDonations() {
      this.syncDonors();
    },
    search() {
      this.getTeamsByQuery();
    },
    orderByFinder() {
      this.getTeamsByQuery();
    },
    lastDonor() {
      this.updateCards();
    },
  },
  created() {
    const params = window.location.pathname.slice(1).split("/");
    this.campaign = params[0];

    setInterval(() => {
      this.syncDonors();
    }, 60000);
    this.connect();
    this.getCampaignsColors();
  },
  methods: {
    getCampaignsColors() {
      axios
        .get("/admin/campaign/getColorBySlug/" + this.campaign)
        .then((res) => {
          if (res.data && res.data.pickerRespondTeam) {
            this.styles = res.data;
          } else {
            this.styles.pickerRespondTeam.hexa = "#9b9b9b";
          }
        });
    },
    getDonors() {
      const params = window.location.pathname.slice(1).split("/");
      axios
        .get(`/api/v2/campaign/${params[0]}/donor?order=${this.orderByFinder}`)
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
          this.loading = false;
        });
    },
    connect() {
      this.getDonors();
      if (this.info && this.info.id) {
        window.Echo.channel("donations." + this.info.id).listen(
          ".donations",
          (e) => {
            // this.$store.dispatch(
            //   "UPDATE_CAMPAIGN_DONATED_INFO",
            //   this.info.slug
            // );
            this.syncDonors();
            // this.getDonors();
          }
        );
      }
    },
    isShowDate(from) {
      const now = moment().tz(moment.tz.guess());
      const fromDate = moment.utc(from).tz(moment.tz.guess());
      const diff = now.diff(fromDate, "days");
      return diff < 15 ? true : false;
    },
    hummanDate(from) {
      return hummanDate(from);
    },
    syncDonors() {
      const data = [];
      data[0] = this.info.slug;
      data[1] = this.latestDonorInCampaign;
      if (this.latestDonorInCampaign > 0) {
        this.$store.dispatch("UPDATE_CAMPAIGN_DONORS", data);
      }
      this.$store.dispatch("UPDATE_CAMPAIGN_DONATED_INFO", this.info.slug);
    },
    updateCards() {
      if (this.lastDonor.length > 0) {
        this.latestDonorInCampaign = this.lastDonor[0].id;
        this.lastDonor.forEach((el) => {
          const index = this.items.findIndex((element) => element.id === el.id);

          if (index == -1) {
            this.items.unshift(el);
          } else {
            this.items.splice(index, 1);
            this.items.unshift(el);
          }
        });
        if (this.orderByFinder == "Highest") {
          this.items.sort(
            (a, b) => parseFloat(b.amount_public) - parseFloat(a.amount_public)
          );
        }
        if (this.orderByFinder == "Latest") {
          this.items.sort(
            (a, b) =>
              new Date(b.approved_at).getTime() -
              new Date(a.approved_at).getTime()
          );
        }
      }
    },
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    getTeams() {
      const params = window.location.pathname.slice(1).split("/");

      axios
        .get(
          `/api/v2/campaign/${this.campaign}/donor?order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        });
    },
    getTeamsPaginate() {
      this.loading = true;
      axios
        .get(`${this.next}&query=${this.search}&order=${this.orderByFinder}`)
        .then((res) => {
          if (res.data) {
            res.data.data.forEach((el) => {
              const index = this.items.findIndex(
                (element) => element.id === el.id
              );
              console.log(index);
              if (index == -1) {
                this.items.unshift(el);
              } else {
                this.items.splice(index, 1);
                this.items.unshift(el);
              }
            });
            if (this.orderByFinder == "Highest") {
              this.items.sort(
                (a, b) =>
                  parseFloat(b.amount_public) - parseFloat(a.amount_public)
              );
            }
            if (this.orderByFinder == "Latest") {
              this.items.sort(
                (a, b) =>
                  new Date(b.approved_at).getTime() -
                  new Date(a.approved_at).getTime()
              );
            }
          }
          this.next = res.data.next_page_url;
          this.loading = false;
        });
    },
    getTeamsByQuery() {
      const params = window.location.pathname.slice(1).split("/");

      axios
        .get(
          `/api/v2/campaign/${this.campaign}/donor?query=${this.search}&order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
        });
    },
    asycTesting() {
      console.log("se ejecuta a cada", moment().format("DD-MM-YYYY HH:mm:ss"));
    },
  },
};
</script>

<style scoped>
.abs-bottom {
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
}
</style>