<template>
  <v-row>
    <v-col cols="12" :md="info && info.slug == 'nshei' ? 9 : 12">
      <!-- <v-col cols="12" md="12"> -->
      <div v-if="info && info.excerpt" class="rounded info_component py-2">
        <div
          class="ql-editor info_text p-2"
          v-if="$i18n.locale == 'en'"
          v-html="info.excerpt"
        ></div>
        <div
          class="ql-editor info_text p-2"
          v-if="$i18n.locale == 'he'"
          v-html="info.excerpt_hebrew"
        ></div>
        <div v-if="readMoreActivated" class="text-center">
          <span @click="goToAbout" class="font-weight-bold pointer py-1">
            {{ $t("_read_more") }}
          </span>
        </div>
      </div>
    </v-col>
    <v-col v-if="info && info.slug == 'nshei'" cols="12" md="3">
      <div class="ch-shadow border-gray rounded-lg mt-4">
        <div class="rounded-lg">
          <v-img
            class="rounded-lg rounded-b-0"
            height="100px"
            src="/img/back_nshei.jpeg"
          ></v-img>
        </div>
        <div class="text-center px-2">
          <div class="title my-1">World Wide Event</div>
          <div>For a replay of the Worldwide Women's Event</div>
        </div>
        <div>
          <v-btn
            class="
              ch-color-gradient
              nav-text
              rounded-lg
              white--text
              buttonDonate
            "
            width="90%"
            style="font-size: 15px; margin: 10px 5%"
            elevation="0"
            height="30px"
            small
            href="https://nsheikerenhashviis.com/#event"
            target="_blank"
            >click Here</v-btn
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import "quill/dist/quill.core.css";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  name: "info",
  data() {
    return {
      readMoreActivated: false,
    };
  },
  created() {
    this.learnMore();
  },
  watch: {
    info() {
      this.learnMore();
    },
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    learnMore() {
      if (this.info && this.info.tabs) {
        this.info.tabs.forEach((element) => {
          if (element.title == "ABOUT US" || element.title == "אודות")
            this.readMoreActivated = true;
        });
      }
    },
    goToAbout() {
      document
        .getElementById("filterSection")
        .scrollIntoView({ behavior: "smooth" });

      if (this.$i18n.locale == "en")
        this.$store.commit("UPDATE_SECTION_PAGE", "ABOUT US");
      if (this.$i18n.locale == "he")
        this.$store.commit("UPDATE_SECTION_PAGE", "אודות");
    },
  },
};
</script>

<style scoped>
.lines2 {
  display: block;
  display: -webkit-box;
  /* height: 110px; */
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info_text {
  vertical-align: middle;
  width: 100%;
}

.info_component {
  background-color: #f6f6f8;
  width: 100%;
  line-height: 20px;
  margin-top: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(119, 119, 119, 1);
}
</style>
