<template>
  <div>
    <footer class="ch-color-gradient-inverse">
      <v-row style="z-index: 10001" class="container-kerem">
        <v-col
          class="
            pt-14
            footer-items
            d-flex
            justify-center justify-md-start
            align-center
          "
          cols="12"
          md="3"
        >
          <div>
            <v-img
              width="200px"
              height="250px"
              contain
              v-if="logo_footer"
              :src="logo_footer[0].path"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="footer-form mb-3 text-white d-flex justify-space-between">
            <span>{{ $t("contact_us") }}</span>
            <span>{{ $t("phone") }}: (+44) 7426 049191 </span>
          </div>
          <div class="footer-form d-flex flex-column gap-3 align-stretch">
            <div class="mb-3">
              <input
                type="text"
                class="form--mail ch-primary footer-shadow"
                :placeholder="$t('email')"
                v-model="form.email"
              />
            </div>
            <div class="mb-3">
              <textarea
                name=""
                id="contact"
                cols="30"
                rows="4"
                class="form--text-area ch-primary footer-shadow"
                :placeholder="$t('message')"
                v-model="form.message"
              ></textarea>
              <div class="mt-1 d-flex justify-end" style="width: 100%">
                <v-btn
                  color="white"
                  @click="sendForm"
                  outlined
                  dark
                  style="text-transform: capitalize"
                  class="button btn-small rounded-md ch-font-primary"
                  elevation="0"
                  :loading="loading"
                  >{{ $t("submit") }}</v-btn
                >
              </div>

              <div v-show="snackbar">
                <div
                  class="d-flex justify-space-between message"
                  :class="{ success: isSuccess, error: hasError }"
                >
                  <div>{{ text }}</div>
                  <div class="">
                    <v-btn
                      color="white"
                      x-small
                      dark
                      icon
                      elevation="0"
                      @click="snackbar = false"
                    >
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- @click.prevent="sendContact()" -->
        </v-col>
        <v-col cols="12" md="3">
          <div
            class="
              footer-menu
              d-flex
              flex-column
              justify-start
              text-white text-center-sm
              mt-4
            "
            style="height: 220px"
          >
            <div class="pointer mt-4 font-weight-bold" @click="openTeam()">
              {{ $t("create_my_team") }}
            </div>
            <div class="pointer mt-4" @click="goToAbout()">
              {{ $t("about_this") }}
            </div>
            <div
              class="pointer mt-4"
              v-show="info && info.type_campaign != 2"
              @click="openDonation()"
            >
              <!-- {{ $t("donation") }} -->
              {{ $t("donate") }}
            </div>
            <div class="mt-4" v-show="info && info.slug == 'nshei'">
              <a
                style="text-decoration: none"
                href="https://kerenhashviis.org/"
                target="_blank"
                class="white--text"
              >
                Go To Main Keren Hashviis Website</a
              >
            </div>


            <div v-if="info && info.contact_info" class="d-flex pt-4">
                <div v-for="(cont, index) in info.contact_info" :key="index">
                <div class="mr-3" v-if="cont.area === 2">
                    <v-icon
                    class="white--text"
                    @click="goToLink(cont)"
                    >
                    {{ cont.icon }}
                    </v-icon>
                </div>
                </div>
            </div>


          </div>
        </v-col>
        <v-col cols="12">
          <div class="text-center ch-title-color font-weight-bold">
            Powered by Charity Live
          </div>
        </v-col>
      </v-row>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      form: {
        email: null,
        message: null,
      },
      text: "",
      isSuccess: false,
      hasError: false,
      snackbar: false,
      loading: false,
    };
  },
  computed: mapState(["logo_footer", "info"]),

  methods: {
    goToLink(data) {
        //In case whatsapp... go open Whatsapp!
        if (data.name == 'Phone') {
            if (data.value.includes('+')) {
                data.value.replace('+','')
                window.open(`https://wa.me/${data.value}`, '_blank');
            }
            window.open(`https://wa.me/${data.value}`, '_blank');
        }
        //In case Facebook, twitter, linkdin and instagram
        if (!data.value.includes('https')) {
            //If the value doesnt includes 'https' just replacing the value with one with https included.
            window.open(data.value.replace( "www", "https://www" ), '_blank');
        } else {
            window.open(data.value, '_blank');
        }
    },
    sendForm() {
      if (
        this.form.email == "" ||
        this.form.message == "" ||
        this.form.email == null ||
        this.form.message == null
      ) {
        this.isSuccess = false;
        this.hasError = true;
        this.text = "Please, complete the form";
        this.snackbar = true;
        return;
      }
      this.loading = true;
      this.snackbar = false;
      this.isSuccess = false;
      this.hasError = false;

      let campaign = window.location.pathname.slice(1).split("/");
      axios
        .post(`/api/v1/campaign/${campaign[0]}/contact-form`, this.form)
        .then((res) => {
          this.isSuccess = true;
          this.hasError = false;
          this.text = "Thank you for your message";
          this.loading = false;
          this.snackbar = true;
          this.form.email = "";
          this.form.message = "";
          setTimeout(() => {
            this.snackbar = false;
          }, 5000);
        });
    },
    openTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
    },
    goToAbout() {
      this.$store.commit("UPDATE_SECTION_PAGE", "ABOUT");

      document
        .getElementById("filterSection")
        .scrollIntoView({ behavior: "smooth" });
    },
    openDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
  },
};
</script>

<style scoped>
footer {
  min-height: 270px;
  padding-top: 20px;
  margin-top: 50px;
}

.footer-items {
  height: 190px;
}
.footer-form {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
}
.footer-form > span {
  font-weight: 600;
}

.form--mail {
  background-color: rgba(214, 113, 139, 0.3);
  padding: 7px 10px;
  border-radius: 3px;
  border: 0px;
  /* width: 100%; */
}

.form--text-area {
  color: white;
  /* width: 100%; */
  /* background-color: #f1f1f1; */
  padding: 7px 10px;
  border-radius: 3px;
  border: 0px;
  resize: none;
}
.form--btn {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  padding: 5px 5px;
  border-radius: 5px;
  width: 30%;
  justify-self: end;
}

input,
input::placeholder,
textarea::placeholder {
  color: white;
}

.footer-menu {
  height: 100%;
}

.footer-shadow {
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.05);
}

.message {
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.error {
  color: white;
}
.success {
  color: white;
}

/* for mobile */
@media (max-width: 960px) {
  .text-center-sm {
    text-align: center;
  }
}
</style>
