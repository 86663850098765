<template>
  <div>
    <v-row v-if="info" class="mb-10">
      <v-col cols="12">
        <div class="rounded-lg ch-shadow">
          <div
            class="
              mx-6
              px-5
              py-10
              d-flex
              justify-space-between
              align-center
              about-ch
              ch-font-primary
            "
          >
            <div v-html="info.about"></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["info"]),
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
  },
};
</script>

<style scoped>
.description-area {
  min-height: 40%;
}
.about-ch {
  font-size: 1.1rem;
}
</style>
