<template>
  <div v-if="info && info.tabs" class="navBar mt-2">
    <!-- <div v-if="teampage" class="">You are in a team page</div> -->

    <div class="d-flex menu-area flex-wrap justify-center menu-area mb-8">
      <template>
        <template v-for="(tab, key) in info.tabs">
          <div
            v-if="tab.title && tab.lang == $i18n.locale"
            :key="key"
            :class="{
              'ch-color-gradient': page == tab.title,
              buttonSecondary: page != tab.title,
              buttonPrimary: page == tab.title,
            }"
            class="menu-item"
            @click="changeMenu(tab.title)"
          >
            <div class="textButton">{{ tab.title | uppercase }}</div>
          </div>
        </template>
      </template>
    </div>

    <!-- Using utilities: -->
    <v-row class="mx-1">
      <template v-if="info && info.tabs">
        <v-col v-for="(tab, key) in info.tabs" :key="key" cols="12" md="12">
          <v-row v-if="info && page == tab.title" class="mb-10">
            <v-col>
              <div class="rounded-lg ch-shadow">
                <div
                  class="
                    mx-6
                    px-5
                    py-10
                    d-flex
                    justify-space-between
                    align-center
                    about-ch
                    ch-font-primary
                  "
                >
                  <div class="tabs" v-html="tab.content"></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-col v-if="page == 'about'" cols="12" md="12">
        <TeamAboutCard />
      </v-col>
      <v-col v-if="page == 'influencers'" cols="12" md="12">
        <SectionVideoComponent></SectionVideoComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

import TeamAboutCard from "../../frontend/Team/AboutCardComponent.vue";
import SectionVideoComponent from "../../frontend/SectionVideoComponent";

export default {
  props: {
    teampage: {
      default: false,
    },
  },

  components: {
    SectionVideoComponent,
    TeamAboutCard,
  },
  data() {
    return {
      search: null,
      description: "",
      search_title: this.$t("search_donors"),
      matchers: [],
      startIsBiggerThanNow: false,
      influencers: [],
      allDonors: 0,
      teams: 0,
    };
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Charity Live",
      },
    ],
  },
  watch: {
    search() {
      this.updateSearch();
    },
    info() {
      var starDate = new Date(this.info.start.replace(/ /g, "T"));
      var countDown = starDate.getTime();
      var now = new Date();
      if (starDate > now) {
        // selected date is in the past
        this.startIsBiggerThanNow = true;
      } else {
        this.startIsBiggerThanNow = false;
      }
    },
  },

  computed: mapState([
    "info",
    "orderByFinder",
    "page",
    "team",
    "itemsDonation",
  ]),
  methods: {
    changeMenu(id) {
      this.$store.commit("UPDATE_SECTION_PAGE", id);
      if (id == "team")
        this.search_title = this.$t("search") + " " + this.$t("teams");
      else this.search_title = this.$t("search") + " " + this.$t("donors");
    },
    changeOrder(id) {
      this.$store.commit("UPDATE_ORDER_FINDER", id);
    },
  },
};
</script>

<style scoped>
.tabs {
  font-size: 1.3rem;
}

.navBar {
  padding-top: 70px;
  padding-bottom: 40px;
}

.topifIsBigger {
  margin-top: 100px;
}

.influencer-area {
  position: relative;
}

.menu-item {
  white-space: nowrap;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  height: 40px;
  margin-top: 4px;
}

@media (min-width: 700px) {
  .menu-area {
    justify-content: center;
  }
}

.justifyCenter {
  justify-content: center;
}

.btn {
  @apply font-bold py-2 px-4 rounded;
}

.btn-blue {
  @apply bg-blue-500 text-white;
}

.btn-blue:hover {
  @apply bg-blue-700;
}

.textButton {
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 18px;
  align-items: center;
  display: flex;
}

.buttonSecondary {
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  display: flex;
  z-index: 10;
}

.buttonPrimary {
  font-weight: bold;
  color: white;
  display: flex;
}

.select {
  font-weight: 800;
}

.searchbar {
  width: 50%;
}

.active {
  font-weight: 800;
  /* padding-bottom: 10px; */
  border-bottom: 3px solid var(--charity-secondary);
}

.nav-item {
  font-size: 18px;
  cursor: pointer;
}

.nav-item-search {
  font-size: 16px;
  cursor: pointer;
}

.nav-item:hover {
  font-weight: 800;
}

.item-menu {
  position: relative;
  padding-bottom: 7px;
  margin-right: 35px;
  white-space: nowrap;
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 10px solid var(--charity-secondary);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: -10px;
  left: calc(50% - 5px);
  visibility: hidden;
}

.active .triangle {
  visibility: visible;
}

.btn-title {
  font-size: 16px;
  font-weight: 800px;
}
</style>
