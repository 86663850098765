<template>
  <div>
    <div
      class="ch-shadow border-gray rounded-lg py-3 px-1"
      style="
        margin-top: 50px;
        margin-bottom: 25px;
        left: 200px;
        top: -20px;
        postion: absolute;
      "
    >
      <div style="text-align: center">
        <v-avatar
          color="ch-primary"
          size="85"
          dark
          class="roundedCircleImage white--text"
          style="font-family: 'Roboto'; color: #292929"
        >
          <img v-if="team && team.path" class="imageTeam" :src="team.path" />
          <span v-else-if="team">{{ team.name | idNameTeam }}</span>
        </v-avatar>
      </div>

      <div id="welcome" class="relative">
        <div v-if="team && team.name">
          <!-- <div style="position: absolute; top: 30px; right: -15px">
            <v-btn
              @click="goToMain()"
              class="bg-white"
              outlined
              large
              icon
              color="primary"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div> -->
          <span>Welcome To </span
          ><span style="color: rgba(0, 0, 0, 1)">{{ team.name }} </span>
          <span>Page </span>
        </div>
        <div v-if="team && team.video">
          <v-row class="justify-center">
            <v-col cols="11" md="9">
              <MainVideoCard
                class="videoClase"
                width="100%"
                height="160px"
                :video="team.video"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <p v-if="team && team.description" class="descriptionTeam">
        {{ team.description }}
      </p>
      <v-container>
        <template v-if="team && team.goal > 0">
          <div v-if="team" class="grey lighten-2 progress-bar mt-2">
            <div
              class="progress-bar-complete"
              :style="{ width: team.percent + '%' }"
            ></div>
          </div>
          <div v-if="team && team.percent" class="percent">
            {{ Math.round(team.percent) }}%
          </div>
        </template>

        <v-row v-if="team" class="footerCardTeam raisedTeam">
          <v-col>
            <p v-if="info && info.principal_currency.symbol" class="pa-0 ma-0">
              {{ info.principal_currency.symbol }} {{ team.raised | dollar }}
            </p>
            <span class="raisedText"> Raised </span>
          </v-col>
          <v-col>
            <p v-if="team.donors" class="pa-0 ma-0">{{ team.donors }}</p>
            <p v-else class="pa-0 ma-0">0</p>
            <span class="raisedText"> Donors </span>
          </v-col>
          <template v-if="team && team.goal > 0">
            <v-col>
              <p
                class="pa-0 ma-0 goalTeam"
                v-if="info && info.principal_currency.symbol"
              >
                {{ info.principal_currency.symbol }} {{ team.goal | dollar }}
              </p>
              <span class="raisedText"> Goal </span>
            </v-col>
          </template>
        </v-row>

        <v-btn
          color=""
          class="
            ch-color-gradient
            goal-text
            rounded-lg
            font-weight-medium
            white--text
            px-0
            buttonDonate
          "
          elevation="0"
          height="45px"
          @click="makeDonation()"
          >{{ $t("donate") }}</v-btn
        >
        <v-col align-items="end" class="text-end">
          <div class="my-1">Share</div>
          <a
            style="text-decoration: none"
            target="_blank"
            :href="mail_share"
            class="mx-1"
          >
            <v-icon color="info"> mdi-email-outline</v-icon>
          </a>
          <a
            style="text-decoration: none"
            target="_blank"
            :href="tw_share"
            class="mx-1"
          >
            <v-icon color="light-blue"> mdi-twitter </v-icon>
          </a>
          <a
            style="text-decoration: none"
            target="_blank"
            :href="wp_share"
            class="mx-1"
          >
            <v-icon color="success"> mdi-whatsapp</v-icon>
          </a>
          <a
            style="text-decoration: none"
            target="_blank"
            :href="fb_share"
            class="mx-1"
          >
            <v-icon color="primary"> mdi-facebook</v-icon>
          </a>
        </v-col>
      </v-container>
    </div>
    <div v-if="info" class="back">
      <a :href="'/' + info.slug"> Back to main campaign</a>
    </div>
  </div>
</template>

<script>
import MainVideoCard from "../../components/frontend/MainVideoCardComponent.vue";

export default {
  components: {
    MainVideoCard,
  },
  data() {
    return {
      snackbar: false,
      dialog: false,
      campaignFinished: "",
      currentURL: null,
      fb_share: null,
      tw_share: null,
      wp_share: null,
      mail_share: null,
    };
  },
  created() {
    this.currentURL = window.location.href;
    this.fb_share = `https://www.facebook.com/sharer/sharer.php?u=${this.currentURL}&quote=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!`;
    this.wp_share = `https://wa.me/?text=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.currentURL}`;
    this.tw_share = `https://twitter.com/intent/tweet?text=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.currentURL}`;
    this.mail_share = `mailto:?subject=I Donated!&body=Hi there, I just donated to to this important cause and invite you to join me!%0A${this.currentURL}`;
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    descriptionTeam() {
      // `this` apunta a la instancia vm
      const description =
        this.team.description.slice(0, 190) +
        (this.team.description.length > 190 ? "..." : "");
      return description;
    },
    donors() {
      return this.$store.state.donors;
    },
    donations() {
      return this.$store.state.donations;
    },
    percent_donation() {
      return this.$store.state.percent_donation;
    },
    team() {
      return this.$store.state.team;
    },
  },
  watch: {
    info() {},
  },
  methods: {
    goToMain() {
      window.location.href = `/${this.info.slug}`;
    },
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION", true);
    },
    copyTeamPage() {
      const url = window.location.href;
      const el = document.createElement("textarea");
      document.body.appendChild(el);
      el.value = `${url}`;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.percent {
  width: 95%;
  margin: 0 auto;
  font-weight: bold;
  color: var(--charity-secondary);
  margin-bottom: 12px;
  text-align: end;
}

.back {
  font-size: 18px;
  text-decoration: underline;
  color: #484848;
  font-style: italic;
  text-align: center;
}
.goalTeam {
  color: #9b9b9b;
}

.footerCardTeam {
  line-height: 1;
}

.raisedText {
  font-weight: normal;
  color: #a4a4a4;
  font-size: 14px;
  font-family: "Roboto";
}

.raisedTeam {
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  font-weight: bold;
  color: black;
}

.buttonDonate {
  height: 45px;
  font-size: 22px !important;
  font-family: Nunito !important;
  font-weight: bold !important;
  margin-top: 12px;
  width: 95%;
  margin: 0 auto;
  display: flex !important;
  margin-top: 8px;
}

.videoClase {
  margin: 0 auto;
  margin-top: 12px;
}

.card-height {
  /* --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  min-height: 280px;
}
.descriptionTeam {
  font-size: 16px;
  width: 350px;
  margin-top: 15px !important;
  text-align: center;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.imageTeam {
  object-fit: cover;
  width: 85px;
  height: 85px;
  position: absolute;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.roundedCircleImage {
  margin-top: -60px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--charity-primary);
  /* position: absolute; */
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.goal-text {
  font-size: 19px !important;
}
.goal-title {
  font-size: 22px;
}
.goal-size {
  font-size: 55px;
  line-height: 60px;
  font-weight: 500;
}
#welcome {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-top: 55px;
  color: rgba(159, 159, 159, 1);
}
/* .subtitle-goal {
    color:
} */
.donors {
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(229, 177, 103, 1);
}
#raisedOfGoal {
  text-align: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(44, 44, 44, 1);
}
.progress-bar {
  width: 95%;
  height: 10px;
  border-radius: 7px;
  position: relative;
  margin-top: 15px !important;
  margin: 0 auto;
}
.progress-bar-complete {
  height: 10px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-color: var(--charity-secondary);
}
.progressBarcomplete {
}
</style>
