import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { nullFormat } from "numeral";

Vue.use(Vuex);
// Vue.use(axios)

const store = new Vuex.Store({
    state: {
        banners: null,
        video: null,
        logo: null,
        videos_banner: null,
        live_stream: null,
        logo_footer: null,
        goal: null,
        about: null,
        info: null,
        // is_goal_hidden: null,
        message: null,
        donors: 0,
        amount_to_donate: 0,
        currency_donate: 1,
        donations: 0,
        // donation_percent: 0,
        percent_donation: 0,
        search: null,
        donors_team: 0,
        modalCreateTeam: false,
        modalCreateCommunity: false,
        modalDonation: false,
        modalSupporter: false,
        modalDonationCompleted: false,
        modalDonationError: false,
        donation_duplicated_id: "",
        modalDonationDuplicated: false,
        team: null,
        pageTeamActive: false,
        teamPage: false,
        community: null,
        pageCommunityActive: false,
        communityPage: false,
        orderByFinder: "Latest",
        page: "donors",
        donation_item: null,
        donation_team: null,
        donation_community: null,
        itemsDonation: null,
        matchers: [],
        lastDonor: [],
        lastSupporter: [],
        monitorDonations: 0,
        validate: 0,
        validateS: 0,
        whoiam: null,
    },
    mutations: {
        UPDATE_MONITOR_DONATIONS(state) {
            state.monitorDonations++;
        },
        UPDATE_CAMPAIGN_DONORS_DATA(state, payload) {
            state.lastDonor = payload.lastDonation;
        },
        UPDATE_CAMPAIGN_SUPPORTER_DATA(state, payload) {
            state.lastSupporter = payload;
        },
        UPDATE_CAMPAIGN_DONATED_INFO_DATA(state, payload) {
            state.donations = payload.donations;
            state.info.donors = payload.donors;
            state.info.donations = payload.donations;
            state.info.donations_amount = payload.donations_amount;
            state.info.percent = payload.percent;
            state.info.goal = payload.goal;
            state.info.extra_goal = payload.extra_goal;
            state.info.support_goal = payload.support_goal;
            state.info.supporters = payload.supporters;
            state.info.supporters_percent = payload.supporters_percent;
        },
        INFO(state, payload) {
            state.info = payload;
            // state.is_goal_hidden = payload.is_goal_hidden
        },
        // UPDATE_DONATION_PERCENT(state, payload) {
        //   state.donation_percent = payload
        // },
        MEDIA_BANNER(state, payload) {
            state.banners = payload;
        },
        SET_DONATION_DUPLICATED(state, payload) {
            state.donation_duplicated_id = payload;
        },
        TOGGLE_MODAL_DONATION_COMPLETE(state, payload) {
            state.modalDonationCompleted = payload;
        },
        TOGGLE_MODAL_DONATION_ERROR(state, payload) {
            state.modalDonationError = payload;
        },
        TOGGLE_MODAL_DONATION_DUPLICATED(state, payload) {
            state.modalDonationDuplicated = payload;
        },
        MEDIA_VIDEO(state, payload) {
            state.video = payload;
        },
        CHANGE_MESSAGE(state, payload) {
            state.message = payload;
        },
        MEDIA_LOGO(state, payload) {
            state.logo = payload;
        },
        INFO_EXCERPT(state, payload) {
            state.info.excerpt = payload;
        },
        INFO_DONORS(state, payload) {
            state.info.donors = payload;
        },
        INFO_GOAL(state, payload) {
            state.info.goal = payload;
        },
        MEDIA_LOGO_FOOTER(state, payload) {
            state.logo_footer = payload;
        },
        MEDIA_GOAL(state, payload) {
            state.goal = payload;
        },
        UPDATE_DONORS(state, payload) {
            state.donors = payload;
        },
        UPDATE_DONORS_TEAM(state, payload) {
            state.donors_team = payload;
        },
        UPDATE_DONATIONS(state, payload) {
            state.donations = payload;
        },
        UPDATE_SECTION_PAGE(state, payload) {
            state.page = payload;
            if (payload == "team") {
                state.orderByFinder = "Highest";
            }
            if (payload == "donors" || payload == "supporters") {
                state.orderByFinder = "Latest";
            }
        },
        UPDATE_PERCENT(state, payload) {
            while (state.percent_donation != payload) {
                state.percent_donation++;
            }
        },
        UPDATE_PERCENT_VALUE(state, payload) {
            state.percent_donation = payload;
        },
        TOGGLE_MODAL_CREATE_TEAM(state) {
            state.modalCreateTeam = !state.modalCreateTeam;
        },
        TOGGLE_MODAL_CREATE_COMMUNITY(state) {
            state.modalCreateCommunity = !state.modalCreateCommunity;
        },
        TOGGLE_MODAL_DONATION(state) {
            state.modalDonation = !state.modalDonation;
        },
        TOGGLE_MODAL_SUPPORTER(state) {
            state.modalSupporter = !state.modalSupporter;
        },
        UPDATE_SEARCH(state, payload) {
            state.search = payload;
        },
        UPDATE_ORDER_FINDER(state, payload) {
            state.orderByFinder = payload;
        },
        UPDATE_TEAM(state, payload) {
            state.team = payload;
            // payload ? state.teamPage = true : state.teamPage =  false
        },
        UPDATE_COMMUNITY(state, payload) {
            state.community = payload;
            payload
                ? (state.communityPage = true)
                : (state.communityPage = false);
        },
        UPDATE_AMOUNT_TO_DONATE(state, payload) {
            state.amount_to_donate = parseInt(payload.amount);
            state.currency_donate = payload.currency;
        },
        UPDATE_COURRENCY_DONATE(state, payload) {
            state.currency_donate = payload;
        },
        UPDATE_DONATION_ITEM(state, payload) {
            state.donation_item = payload;
        },
        UPDATE_DONATION_TEAM(state, payload) {
            state.donation_team = payload;
        },
        UPDATE_DONATION_COMMUNITY(state, payload) {
            state.donation_community = payload;
        },
        UPDATE_ITEMS_DONATION(state, payload) {
            state.itemsDonation = payload;
        },
        // PARA GUARDAR LOS MATCHERS AL TRAERLOS DE LA BASE DE DATOS EN EL CARROUSEL
        UPDATE_MATCHERS(state, matchers) {
            state.matchers = matchers;
        },
        MEDIA_BANNERS(state, payload) {
            state.videos_banner = payload;
        },
        LIVE_STREAM(state, payload) {
            state.live_stream = payload;
        },
    },
    actions: {
        async UPDATE_CAMPAIGN_DONATED_INFO({ commit }, payload) {
            axios.get(`/api/v2/campaign/${payload}/getRaised`).then((res) => {
                commit("UPDATE_CAMPAIGN_DONATED_INFO_DATA", res.data);
            });
        },
        async UPDATE_CAMPAIGN_DONORS({ commit, state }, payload) {
            state.validate++;
            const variable = state.validate;
            axios
                .get(
                    `/api/v1/campaign/${payload[0]}/getLastDonor?latest=` +
                        payload[1]
                )
                .then((res) => {
                    if (variable == state.validate) {
                        commit("UPDATE_CAMPAIGN_DONORS_DATA", res.data);
                    }
                });
        },
        async FETCH_CAMPAIGN_INFO({ commit }, payload) {
            await axios
                .get(`/api/v2/campaign/${payload}/info`)
                .then((res) => {
                    commit("INFO", res.data);
                    commit("UPDATE_DONORS", res.data.donations_amount); // solo por campaña de 1usd
                    commit("UPDATE_DONATIONS", res.data.donations_amount);
                    if (res.data["goal"] > 0 && res.data.donations > 0) {
                        commit("UPDATE_PERCENT", res.data["percent"]);
                    }
                })
                .catch((error) => {
                    commit("CHANGE_MESSAGE", error.response.data.message);
                });
        },
        async FETCH_CAMPAIGN_MEDIA({ commit }, payload) {
            const data = await axios.get(`/api/v1/campaign/${payload}/media`);
            commit("MEDIA_BANNER", data.data.banner_top);
            if (data.data.main_video) {
                commit("MEDIA_VIDEO", data.data.main_video[0].path);
            }
            commit("MEDIA_BANNERS", data.data.main_video);
            commit("MEDIA_LOGO", data.data.logo);
            commit("MEDIA_LOGO_FOOTER", data.data.logo_footer);
            commit("MEDIA_GOAL", data.data.goal_image);
            commit("LIVE_STREAM", data.data.live_stream);
        },
        async UPDATE_SEARCH({ commit }, payload) {
            if (payload[0] == "donate") window.location.href = "/nshei/donate";
            if (payload[0] == "create-my-team") {
                window.location.href = "/nshei/create-my-team";
            }
            if (!payload[1]) return;

            if (
                payload[1] == "broom-fundation" ||
                payload[1] == "achisomoch" ||
                payload[1] == "paypal" ||
                payload[1] == "stripe" ||
                payload[1] == "donation-confirmed"
            ) {
                commit("TOGGLE_MODAL_DONATION_COMPLETE", true);
            } else if (payload[1] == "create-my-team") {
                commit("TOGGLE_MODAL_CREATE_TEAM", true);
            } else if (payload[1] == "donate") {
                commit("TOGGLE_MODAL_DONATION", true);
            } else if (payload[1] == "achisomosch" && payload[3] == "S") {
                commit("TOGGLE_MODAL_DONATION_COMPLETE", true);
            } else if (
                payload[1] == "achisomosch" &&
                payload[3].charAt(0) == "D"
            ) {
                commit("SET_DONATION_DUPLICATED", payload[3].substr(1));
                commit("TOGGLE_MODAL_DONATION_DUPLICATED", true);
            } else if (
                payload[1] == "achisomosch" ||
                payload[1] == "donation-error"
            ) {
                commit("TOGGLE_MODAL_DONATION_ERROR", true);
            } else {
                axios
                    .get(`/api/v1/campaign/${payload[0]}/slug/${payload[1]}`)
                    .then((res) => {
                        // console.log(res.data);

                        if (res.data.type == "item") {
                            commit("UPDATE_DONATION_ITEM", res.data.data);
                        } else if (res.data.type == "team") {
                            commit("UPDATE_DONATION_TEAM", res.data.data);
                            if (
                                res.data.data.video != "" &&
                                res.data.data.video != null
                            ) {
                                commit("MEDIA_VIDEO", res.data.data.video);
                            }

                            // commit('INFO_EXCERPT', res.data.data.description)

                            // commit('INFO_DONORS', res.data.data.donors) comentado para probar actualización de url de teams

                            // commit('INFO_GOAL', res.data.data.goal)
                            // commit('UPDATE_PERCENT_VALUE', res.data.data.percent)
                            // console.log('temass');
                            // console.log(res.data.data);
                            commit("UPDATE_TEAM", res.data.data);
                            commit("UPDATE_SECTION_PAGE", "donors");

                            // document
                            //   .getElementById("filterSection")
                            //   .scrollIntoView({ behavior: "smooth" });
                        } else if (res.data.type == "comm") {
                            commit("UPDATE_DONATION_COMMUNITY", res.data.data);
                        } else if (res.data.message == "No slug detected") {
                            window.location.href = "/" + payload[0];
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        window.location.href = "/" + payload[0];
                    });
            }
        },
    },
});

export default store;
