<template>
  <div>
      <div class="pt-4 px-10 mx-3 subtitle-1">
            <p>
              These Terms constitute a legal and binding agreement between
              CharityLive and CharityLive End-Users.
            </p>

            <p>
              CharityLive or Crowd Raise Ltd. (“we”, “us” or “CharityLive”)
              provides a digital platform to assist non for profit companies,
              charities and organizations in structuring, advising and/or
              managing their fundraising campaigns and allowing you the end-user
              (the “Donor”) to donate to such campaigns as well as to charitable
              causes of their choice (“Charity/ies”).
            </p>
            <p>
              “User or “you” means an individual or entity end user of the
              Services. By accessing or using the CharityLive fundraising
              platform or and a subsite created for the Non for Profit company,
              organization(together, the “Site”) and any service provided by us,
              including through any mobile application that we may provide, (all
              of the foregoing, the “Services”) you agree that you have read,
              understood, accept and agree to be bound by these Terms, as well
              as our Privacy Policy, which is available at
              www.charity.live/privacy If you do not agree to these Terms or the
              Privacy Policy, do not continue to use the Services.
            </p>
            <p>
              We reserve the right, in our sole discretion, to revise, update or
              modify these Terms at any time, and you agree to be bound by such
              revisions, updates or modifications. You are responsible for
              reviewing these Terms periodically. Your continued use of the
              Services after a change, update or modification of these Terms has
              been made will constitute your acceptance of the revised Terms.
            </p>
            <p><strong>Services:</strong></p>
            <p>
              Subject to the terms and conditions hereof, during the period
              these Terms are in effect, CharityLive allows you to use the
              Services on a non-exclusive basis. CharityLive may change, modify
              or update the Services at any time without prior notice to you,
              including to remove functionality. Currently, CharityLive makes
              the Services available free-of-charge to you, but CharityLive may
              choose at any time to charge for your future use of the Services.
              In order to use the Services, you must be 18 years of age or
              older, or have the permission of your parent or guardian. You may
              not use the Services if your access to or use thereof violates any
              applicable law or regulation.
            </p>
            <p><strong>Accounts:</strong></p>
            <p>
              You may register to use CharityLive by providing the requested
              information. We may change the method of registration at our
              discretion. CharityLive reserves the right to refuse to allow any
              user to open an account for any reason at its sole discretion. You
              agree that you will supply accurate and complete information to us
              in the creation of your account and the use of the Services, and
              that you will update such information promptly after it changes.
              You shall have all responsibility for any inaccuracies in any
              information you provide to us, or in respect of your failure to
              keep such information up-to-date.
            </p>
            <p>
              You shall not share your account or login information with any
              third party, nor let any third party access your account, and are
              responsible for maintaining the confidentiality of the login
              information for your account. You are fully and solely responsible
              for the security of your computer system, mobile device and all
              activity on your account, even if such activities were not
              committed by you. We may terminate your use of the Services if you
              let someone use your account inappropriately or if you or anyone
              using your account violates these Terms. You agree to immediately
              notify us of any unauthorized use of your account. CharityLive
              will not be liable for any losses or damage arising from
              unauthorized use of the Services, and you agree to indemnify and
              hold CharityLive harmless for any improper or illegal use of the
              Services, and any charges and taxes incurred, unless you have
              notified us via email at support@Charity.Live
            </p>
            <p>Termination of Account:</p>
            <p>
              You agree that we may for any reason, in our sole discretion and
              without notice, terminate your account. Grounds for such
              termination may include (i) extended periods of inactivity, (ii)
              violation of the letter or spirit of these Terms, (iii)
              fraudulent, harassing or abusive behavior, or (iv) behavior that
              is harmful to other users, third parties, the community ethos of
              our Services or our business interests. In the event that we
              terminate your account, you may not register for the Services
              again without our express permission. If you believe that any
              action has been taken against your account in error, please
              contact us at support@Charity.Live
            </p>
            <p>
              In addition, if we believe, in our sole discretion, that a
              violation of these Terms or any illegal or inappropriate behavior
              has occurred, we may take any other corrective action we deem
              appropriate. We reserve the right to investigate suspected
              violations of these Terms or illegal and inappropriate behavior
              through the Services. We will fully cooperate with any law
              enforcement investigation or court order requesting or directing
              us to disclose the identity, behavior or activities of anyone
              believed to have violated these Terms or to have engaged in
              illegal behavior. You may request termination of your account at
              any time and for any reason by sending an email to
              support@Charity.Live
            </p>
            <p>
              Any suspension or termination of your account shall not affect
              your obligations to us under these Terms (including but not
              limited to ownership, indemnification, any representations and
              warranties made by you, and limitation of liability), which by
              their sense and context are intended to survive such suspension or
              termination.
            </p>
            <p><strong>Donations:</strong></p>
            <p>
              By means of our Services we allow the public to donate to
              campaigns (“Donors”), as well as to contribute directly to certain
              Charities. Any donation you make to a Charity through the Services
              will be subject to a Services fee. You understand and acknowledge,
              however, that CharityLive is not a charity. If you or your charity
              would like to register to be listed as a charitable organization
              on the Platform, please contact us at support@Charity.Live and we
              can help facilitate that process.
            </p>
            <p>
              While we make reasonable attempts only to list legitimate and
              worthy Non for Profit company, organization and/or Charities, we
              cannot make any representations or warranties in respect of the
              actual activities of such Non for Profit company, organization
              and/or Charities. We are not responsible for the accuracy of any
              information that the Non for Profit company, organization and/or
              Charities provide. You must conduct your own research prior to
              choosing any specific Organization and/or Charity, and we cannot
              speak to the worthiness or standards of any such Organization
              and/or Charity.
            </p>
            <p>
              The Services are an administrative platform only. CharityLive
              facilitates the donation transaction between the Non for Profit
              company, organization and/or Charities and users, but is not a
              party to any agreement between any Organization and/or Charity and
              a user. CharityLive is not a broker, agent, financial institution,
              creditor or insurer for any user. CharityLive has no control over
              the conduct of, or any information provided by, an Organization
              and/or Charity or their representatives, and CharityLive hereby
              disclaims all liability in this regard to the fullest extent
              permitted by applicable law.
            </p>
            <p>
              CharityLive does not guarantee that a specific campaign or an
              Organization and/or a Charity will obtain a certain amount of
              donations or any donations at all. We do not personally endorse
              any campaign and we make no guarantee, explicit or implied, that
              any information provided through the Services by a user is
              accurate. We expressly disclaim any liability or responsibility
              for the success of any campaign, or the outcome of any fundraising
              purpose. You, as a donor, must make the final determination as to
              the value and appropriateness of contributing to any campaign or
              Organization and/or Charity.
            </p>
            <p>
              We do not and cannot verify the information that Non for Profit
              company, organization and/or Charities supply, nor do we guarantee
              that the donations will be used in accordance with any fundraising
              or other purpose prescribed by an Organization and/or a Charity.
              We assume no responsibility to verify whether the donations are
              used in accordance with any applicable laws; such responsibility
              rests solely with the applicable Non for Profit company,
              organization and/or Charities. While we have no obligation to
              verify that the use of any funds raised is in accordance with
              applicable law and these Terms, we take possible fraudulent
              activity and the misuse of funds raised very seriously. If you
              have reason to believe that an Organization and/or Charity is not
              raising or using the funds for their stated purpose, please
              contact us at support@Charity.Live
            </p>
            <p><strong>Payments:</strong></p>
            <p>
              Payments for donations are currently made by means of some major
              credit cards, checks or ACH transfers, but CharityLive may add or
              change payment methods in its sole discretion. You must respect
              the terms and conditions of any payment methods you use.
            </p>
            <p>
              You are solely responsible for any taxes on amounts you may pay or
              obtain through the Site. CharityLive will deduct applicable
              charges and taxes from any payable amounts, as required by law.
            </p>
            <p>
              When a doner user requests to make a donation, CharityLive sends
              the user a notification of the same.
            </p>
            <ol>
              <li>
                Donors agree to provide their payment information at the time
                they pledge to a fundraising Campaign.
              </li>
              <li>
                After donation, donors receive an automatically-generated
                receipt via email to the email address provided during the
                donation process. Due SPAM filters and other internet
                connectivity issues, CharityLive does not guarantee that Donor
                will receive said receipt.
              </li>
              <li>
                Donors consent to CharityLive and its payments partners
                authorizing a hold or a charge on their payment card or other
                payment method (ACH) for the amount pledged at the time of the
                pledge and completion of the campaign.
              </li>
              <li>
                Donors agree to have sufficient funds or credit available at the
                campaign deadline to ensure that the pledge will be collectible.
              </li>
              <li>
                CharityLive does not offer refunds. A nonprofit organization is
                not required to grant a Donors’ request for a refund.
              </li>
              <li>
                Due to chargebacks and the like, CharityLive does not guarantee
                the receipt by the Charity of the full amount of funds pledged
                minus fees.
              </li>
              <li>
                CharityLive and its payments partners will remove their fees
                before transmitting proceeds of a campaign.
              </li>
              <li>
                Fees that our payments partners charge may vary. Currently the
                payment processing fee is not more than 2.9% + 30c per
                transaction for credit cards. And .8% (up to and limited to
                $5.00) for ACH transfers.
              </li>
              <li>
                CharityLive reserves the right to cancel a donation or pledge at
                any time and for any reason.
              </li>
              <li>
                CharityLive reserves the right to reject or suspend a campaign
                at any time and for any reason. CharityLive is not liable for
                any damages as a result of any of those actions. CharityLive’s
                policy is not to comment on the reasons for those actions.
              </li>
              <li>
                For all campaigns, CharityLive may provide the nonprofit of the
                specific campaign the donor’s name, email, phone number, mailing
                address and donation amount.
              </li>
              <li>
                Nonprofit companies, organizations and charities should not take
                any action in reliance on having their Campaign posted on the
                Site or having any of the money pledged until they have the
                ability to withdraw and spend the money. There may be a delay
                between the end of a successful fundraising campaign and access
                to the funds. Typically this may take up to seven working days.
                CharityLive is not liable for any damages or loss incurred
                related to any use of the Service. CharityLive is under no
                obligation to become involved in disputes between any Users, or
                between Users and any third party arising in connection with the
                use of the Service. CharityLive does not oversee the performance
                or punctuality of use of campaign funds for the purpose of the
                campaign. CharityLive does not endorse any User Submissions. You
                release CharityLive, its directors, officers, employees, agents,
                and successors in rights from claims, damages, and demands of
                every kind, known or unknown, suspected or unsuspected,
                disclosed or undisclosed, arising out of or in any way related
                to such disputes and the Service.
              </li>
            </ol>

            <p><strong>Intellectual Property:</strong></p>
            <p>
              CharityLive and its licensors own the Services, including any
              material or content made available through the Services, and all
              worldwide intellectual property rights in the foregoing. Except as
              expressly permitted herein, you may not copy, further develop,
              reproduce, republish, modify, alter download, post, broadcast,
              transmit or otherwise use any material made available in the
              Services. You will not remove, alter or conceal any copyright,
              trademark, service mark or other proprietary rights notices
              incorporated in the Services. All trademarks are trademarks or
              registered trademarks of their respective owners. Nothing in these
              Terms grants you any right to use any trademark, service mark,
              logo, or trade name of ours or any third party. You are not
              required to provide us with any feedback or suggestions regarding
              the Services. However, should you provide us with comments or
              suggestions for the modification, correction, improvement or
              enhancement of Services then you agree that no such feedback shall
              be subject to any obligation of confidentiality, and we may use or
              publish such feedback at our discretion.
            </p>
            <p><strong>Submissions:</strong></p>
            <p>
              CharityLive may allow you to post comments or reviews or other
              Submissions to the Site. “Submissions” means anything material
              that a user transmits to the Site or to another user while using
              the Site, including any text, audio or visual material. You are
              responsible for any Submissions you post or transmit. In addition,
              you represent and warrant that no Submission infringes the
              intellectual property, moral, publicity or privacy rights of any
              third party, and that you have all rights necessary to post or
              transfer such Submission. We do not pre-screen Submissions. We
              reserve the right, at our sole discretion, to remove any
              Submissions that we believe violates the letter or spirit of these
              Terms.
            </p>
            <p>
              We do not claim ownership of your Submissions. However, by sending
              any Submissions you automatically grant CharityLive a
              non-exclusive, royalty-free, perpetual license of all worldwide
              rights to use, edit, modify, reproduce, publish, display and
              distribute Submissions in any manner whatsoever, in or out of
              context, in all language, in all media, including for our
              promotional purposes.
            </p>
            <p>
              You agree that you will not: (a) solicit another person’s password
              or other personal information under false pretenses; (b)
              impersonate another user or otherwise misrepresent yourself in any
              manner (including creating multiple accounts or personas) whether
              to another user, to us, or otherwise; (c) violate the legal rights
              of others, including defaming, abuse, stalking or threatening
              users; (d) infringe the intellectual property rights, privacy
              rights, or moral rights of any third party, (e) post or transmit
              any Submission that is (or you reasonably believe or should
              reasonably believe to be) illegal, fraudulent, or unauthorized, or
              in furtherance of any illegal, counterfeiting, fraudulent,
              pirating, unauthorized, or violent activity, or that involves (or
              you reasonably believe or should reasonably believe to involve)
              any stolen, illegal, counterfeit, fraudulent, pirated, or
              unauthorized material; (f) violate any applicable law, rule or
              regulation, (g) publish falsehoods or misrepresentations; (h) post
              or transmit any Submission that is (or reasonably should be
              understood to be) libelous, defamatory, obscene, offensive
              (including material promoting or glorifying hate, violence, or
              bigotry or otherwise inappropriate to the community ethos of the
              Services; (i) post or transmit any virus, worm, Trojan horse, or
              other harmful or disruptive component; (j) circumvent, disable, or
              otherwise interfere with security-related features of the Services
              or features that prevent or restrict use or copying of any
              content; or (k) use any robot, spider, site search or retrieval
              application, or any other manual or automatic device or process to
              retrieve, index, data-mine, the Services. You may not, nor may you
              assist other parties to (a) copy, modify, or create derivative
              works of the Services, (b) reverse-engineer, disassemble, or
              attempt to derive the source code of the software used to provide
              the Services, or (c) attempt to disable or circumvent any security
              mechanism of the Services.
            </p>
            <p>
              CharityLive does not endorse any Submission or any opinion,
              recommendation, or advice expressed therein, and CharityLive
              expressly disclaims any and all liability in connection with
              Submissions. CharityLive is not responsible for the accuracy,
              usefulness, safety or intellectual property rights of or relating
              to these Submissions. Further, you understand and acknowledge that
              you may be exposed to Submissions that are inaccurate, offensive,
              indecent, or objectionable, and you hereby agree to waive, and do
              waive, any legal or equitable rights or remedies you have or may
              have against CharityLive with respect to thereto.
            </p>
            <p>No Warranties Disclaimer</p>
            <p>
              YOUR USE OF ANY ASPECT OF THE SERVICES IS AT YOUR OWN RISK.
              CharityLive CANNOT AND DOES NOT ACCEPT ANY LIABILITY IN RESPECT OF
              ANY ACTIVITIES THAT YOU MAY UNDERTAKE THROUGH USING THE SERVICES.
            </p>
            <p>
              WE MAKE NO REPRESENTATIONS OR WARRANTIES WHATSOEVER IN RESPECT OF
              THE SERVICES. INFORMATION REGARDING CHARITABLE Non for Profit
              company, organization and/or CHARITIES MAY BE PROVIDED BY THIRD
              PARTIES. WE CANNOT ACCEPT ANY LIABILITY WHATSOEVER IN RESPECT OF
              ANY CONTENT WHICH IS PROVIDED BY THIRD PARTIES. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, EVERYTHING AVAILABLE ON OR
              THROUGH THE SERVICES IS PROVIDED TO YOU “AS-IS” WITHOUT WARRANTY
              OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE DISCLAIM ANY
              IMPLIED OR STATUTORY WARRANTIES IN RESPECT OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT IN RESPECT OF
              THE SERVICES. CharityLive DOES NOT WARRANT THAT ANY DESCRIPTION
              PROVIDED THROUGH THE SERVICES REGARDING CHARITABLE Non for Profit
              company, organization AND/OR CHARITIES IS ACCURATE, COMPLETE,
              RELIABLE, CURRENT, OR ERROR-FREE.
            </p>
            <p>
              No advice or information, whether oral or written, obtained by you
              from CharityLive, shall create any warranty not expressly stated
              in these Terms. If you choose to rely on such information, you do
              so solely at your own risk. Some states or jurisdictions do not
              allow the exclusion of certain warranties. Accordingly, some of
              the above exclusions may not apply to you.
            </p>
            <p><strong>Limitation of Liability</strong></p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES,
              INCLUDING BUT NOT LIMITED TO GENERAL, INCIDENTAL, CONSEQUENTIAL,
              INDIRECT, DIRECT, SPECIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR
              RELATING TO THE SERVICES OR CONTENT. IN ANY CASE, OUR ENTIRE
              LIABILITY UNDER ANY PROVISION OF THESE TERMS SHALL NOT EXCEED
              AMOUNTS PAID BY YOU DIRECTLY TO US FOR THE USE OF THE SERVICES
              HEREUNDER. AS SUCH, IF YOU HAVE NOT MADE ANY PAYMENTS TO US IN
              ORDER TO USE THE SERVICES, WE SHALL NOT HAVE ANY LIABILITY TO YOU
              WHATSOEVER
            </p>
            <p>
              In addition to the foregoing, CharityLive assumes no
              responsibility for any error, omission, interruption, deletion,
              defect, delay in operation or transmission or communications line
              failure. CharityLive is not responsible for any problems or
              technical malfunction of any telephone or cellular phone network
              or lines, computer online systems, servers or providers, computer
              equipment, software, failure of any email due to technical
              problems or traffic congestion on the Internet or on the Site,
              including any injury or damage to users or to any person's mobile
              device or computer related to or resulting from participation or
              use of the Services. CharityLive cannot and does not warrant that
              any device shall transmit any information to us, correct or
              otherwise, or that any information submitted by you shall be
              retained and stored, correctly or at all. UNDER NO CIRCUMSTANCES
              SHALL CharityLive BE RESPONSIBLE FOR ANY LOSS OR DAMAGE, INCLUDING
              PERSONAL INJURY OR DEATH, RESULTING FROM USE OF THE SERVICES,
              WHETHER ONLINE OR OFFLINE.
            </p>
            <p><strong>Indemnification</strong></p>
            <p>
              You agree to indemnify, defend, and hold us harmless (including
              our employees, directors, officers, subcontractors and agents)
              against any and all claims, damages, losses, liabilities, costs or
              expenses (including reasonable attorneys’ fees and court costs)
              (“Claims”) that arise directly or indirectly from: (a) the breach
              of these Terms by you or anyone using your computer, mobile
              device, password or login information; (b) any claim, loss or
              damage experienced from your use or attempted use of (or inability
              to use) the Services; (c) your violation of any law or regulation;
              (d) your infringement of any right of any third party, including
              without limitation the infringement of any third party
              intellectual property right or moral right; or (e) any other
              matter for which you are responsible hereunder or under law. You
              agree that your use of the Services shall be in compliance with
              all applicable laws, regulations and guidelines.
            </p>
            <p><strong>Infringement</strong></p>
            <p>
              Under the Digital Millennium Copyright Act of 1998 (the “DMCA”),
              it is our policy to respond to copyright owners who believe
              material appearing on the Site infringes their rights under US
              copyright law. CharityLive accepts no responsibility or liability
              for any Submissions, or any material provided or posted by a user.
              If you believe that something appearing on the Site infringes your
              copyright, you may send us a notice requesting that it be removed,
              or access to it blocked. If you believe that such a notice has
              been wrongly filed against you, the DMCA lets you send us a
              counter-notice. Notices and counter-notices must meet the DMCA’s
              requirements. We suggest that you consult your legal advisor
              before filing a notice or counter-notice. Be aware that there can
              be substantial penalties for false claims. It is the policy of
              CharityLive to terminate the accounts of repeat infringers in
              appropriate circumstances. Send notices and counter-notices to
              support@Charity.Live
            </p>
            <p><strong>Miscellaneous</strong></p>
            <p>
              These Terms shall be governed by the Jewish orthodox law exclusive
              of its choice of law rules. Your conduct may also be subject to
              other local, state, and national laws. Any action to be brought in
              connection with these Terms, the Services shall be brought
              exclusively in the court of Jewish law that the directors of
              Charity Live choose based upon the location of charity, and you
              irrevocably consent to their jurisdiction. Any cause of action
              against us must be brought within one (1) year of the date such
              cause of action arose. In the event that any provision of these
              Terms is held to be unenforceable, such provision shall be
              replaced with an enforceable provision which most closely achieves
              the effect of the original provision as permitted by applicable
              law, and the remaining terms of these Terms shall remain in full
              force and effect. Nothing in these Terms creates any agency,
              employment, joint venture, or partnership relationship between you
              and us or enables you to act on our behalf. Except as may be
              expressly stated in these Terms, these Terms constitute the entire
              agreement between us and you pertaining to the subject matter
              hereof, and supersedes any other agreements, understandings or
              warranties. Nothing contained in these Terms shall be construed to
              limit the actions or remedies available to us with respect to any
              prohibited activity or conduct. Non-enforcement of any term of
              these Terms does not constitute consent or waiver, and we reserve
              the right to enforce such terms at our sole discretion. No waiver
              of any breach or default hereunder shall be deemed to be a waiver
              of any preceding or subsequent breach or default. We may assign
              our rights under these terms to any third party. You may not
              assign your rights or obligations hereunder except as we expressly
              permit in writing. Any notice to be provided to you pursuant to
              these Terms may be provided to the email address or other contact
              information you have provided to us.
            </p>
          </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>