<template>
    <div :style="`${campaign}`">
        <FireWorksComponent v-if="fireworks"></FireWorksComponent>

        <div v-if="message" class="npinfo ch-text-primary h1 text-center">
            {{ message }}
        </div>

        <div v-else class="body-base-container">
            <h1 v-if="info && info.name" class="my-2 mx-auto text-center mt-5" style="width: 50vw">
                Campaign: {{ info.name }} -- Operation Room
            </h1>
            <div
                class="d-flex justify-space-around py-2 mx-auto mt-5"
                style="width: 80vw"
            >
                <div class="clocks">
                    <ClockResume></ClockResume>
                </div>
                <div class="clocks pt-15">
                    <GoalResume></GoalResume>
                </div>
                <div class="clocks">
                    <CountDown></CountDown>
                </div>
            </div>
            <div v-if="items.length > 0" class="d-flex justify-center mx-auto px-10">
                <v-row>
                    <v-col cols="12 text-h6">
                        <strong
                            class="pb-4"
                            style="color: #a9d171; border-bottom: 3px solid #a9d171"
                        >
                            {{ sumDonors() }} DONORS
                        </strong>
                    </v-col>
                </v-row>
            </div>
            <div
                v-if="items.length > 0"
                class="d-flex justify-center mx-auto pa-10 mt-5"
            >
                <v-row>
                    <v-col lg="8" sm="12">
                        <TeamDonorsCard :columns="4"></TeamDonorsCard>
                    </v-col>
                    <v-col lg="4" sm="12">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="" style="font-size: 15px">
                                    <v-row>
                                        <v-col cols="5">

                                        </v-col>
                                        <v-col class="text-right" cols="3">
                                            <strong class="font-weight-bold text-uppercase">
                                                <span class="mr-2" style="color: grey"> Donors </span>
                                            </strong>
                                        </v-col>
                                        <v-col class="text-right" cols="4">
                                            <strong class="font-weight-bold text-uppercase">
                                                  <span class="text-uppercase" style="color: grey">
                                                    Raised
                                                  </span>
                                            </strong>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <template v-for="(item, index) in items">
                            <v-list-item
                                :key="index"
                                class="rounded-lg mb-1"
                                style="background-color: #eeeeee"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="" style="font-size: 15px">
                                        <v-row>
                                            <v-col class="text-truncate" cols="5">
                                                <strong class="font-weight-bold">
                                                    {{ item.name }}
                                                </strong>
                                            </v-col>
                                            <v-col class="text-right" cols="3">
                                                <strong class="font-weight-bold text-uppercase">
                                                    {{ item.donors }}
                                                </strong>
                                            </v-col>
                                            <v-col class="text-right" cols="4">
                                                <strong class="font-weight-bold text-uppercase">
                                                    {{ info.principal_currency.symbol }} {{ item.raised }}
                                                </strong>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div>
            <Footer/>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import Footer from '../../components/frontend/FooterComponent.vue';
import GoalResume from '../../components/frontv2/GoalResume.vue';
import ClockResume from '../../components/frontv2/ClockResume.vue';
import CountDown from '../../components/frontv2/CountDown.vue';
import TeamDonorsCard from '../../components/frontend/Team/DonorsCardComponent.vue';
import FireWorksComponent from '../../components/frontend/FireWorksComponent';

export default {
  computed: mapState( ['message', 'info', 'itemsDonation'] ),
  components: {
    TeamDonorsCard,
    GoalResume,
    ClockResume,
    FireWorksComponent,
    Footer,
    CountDown,
  },
  metaInfo() {
    return {
      title: `${ this.name } - Charity Live`,
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: `Campaign ${ this.name } - Charity.Live`,
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          name: 'description',
          content: this.description,
        },
        {
          vmid: 'description',
          property: 'description',
          content: this.description,
        },
      ],
    };
  },
  data() {
    return {
      items: [],
      fireworks: false,
      name: '',
      description: '',
      campaign: `
              --charity-primary:  #cdcdcd;
              --charity-secondary:  #fafafa;
              --charity-third:  #000000;
              --charity-color-text:  #e3e3e3;
              --charity-color-alternative:  #ccc;
              --charity-color-title:  #ccc;
            `,
    };
  },
  created() {
    const params = window.location.pathname.slice( 1 ).split( '/' );
    this.name = params[0];
    this.$store.dispatch( 'FETCH_CAMPAIGN_INFO', params[0] );
    this.getTeamsByQuery( params[0] );
    const _this = this;
    setInterval( function() {
      _this.getTeamsByQuery( params[0] );
    }, 5 * 1000 );
    // 5 seconds
  },
  methods: {
    getTeamsByQuery( campaign ) {
      axios.get( `/api/v1/campaign/${ campaign }/team/all` ).then( ( res ) => {
        this.items = res.data;
        this.sumDonors();
      } );
    },
    sumDonors() {
      let sum = 0;
      this.items.forEach( ( element ) => {
        sum += element.donors;
      } );
      return sum;
    },
  },
};
</script>
<style scoped>
@font-face {
    font-family: Nunito-Black;
    src: url("/fonts/Nunito-Black.ttf");
}

@media (max-width: 600px) {
    .information {
        margin-top: 30px;
    }
}

.body-base-container {
    background-color: white !important;
    width: 100vw;
}

.banner {
    padding-top: 125px;
    width: 95%;
}

.npinfo {
    padding-top: 340px;
    height: 70vh;
}

@media (min-width: 960px) and (max-width: 1020px) {
    .mt-mobile-nav {
        padding-top: 100px;
    }
}

@media (min-width: 600px) and (max-width: 960px) {
    .mt-mobile-nav {
        padding-top: 100px;
    }
}

@media (max-width: 600px) {
    .mt-mobile-nav {
        padding-top: 100px;
    }
}

.ch-text-primary {
    color: var(--charity-primary) !important;
}

.ch-text-secondary {
    color: var(--charity-secondary) !important;
}

.ch-text-third {
    color: var(--charity-third) !important;
}

.ch-primary {
    background-color: var(--charity-primary) !important;
}

.ch-secondary {
    background-color: var(--charity-secondary) !important;
}

.ch-third {
    background-color: var(--charity-third) !important;
}

.ch-light {
    background-color: var(--background) !important;
}

.ch-text-color {
    color: var(--charity-color-text) !important;
}

.ch-title-color {
    color: var(--charity-color-title) !important;
}

.ch-font-primary {
    font-family: var(--charity-font-primary) !important;
}

.ch-font-secondary {
    font-family: var(--charity-font-secondary) !important;
}

.ch-font-third {
    font-family: var(--charity-font-title) !important;
}

.ch-font-title {
    font-family: var(--charity-font-title) !important;
    font-weight: 600 !important;
    font-size: 22px !important;
}

.ch-color-gradient {
    background: var(--charity-primary);
    background: linear-gradient(
        90deg,
        var(--charity-primary) 0%,
        var(--charity-third) 100%
    );
}

.ch-color-gradient-inverse {
    background: var(--charity-third);
    background: linear-gradient(
        90deg,
        var(--charity-third) 0%,
        var(--charity-primary) 100%
    );
}

.ch-font-btn {
    font-family: var(--charity-font-title) !important;
    font-weight: 600 !important;
    font-size: 22px !important;
}
</style>
