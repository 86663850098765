<template>
    <div>
        <v-toolbar class="px-md-5 px-3 fixed-top" style="z-index: 9999">
            <a href="/">
                <v-avatar class="pointer" size="150" tile>
                    <v-img alt="Avatar" contain src="/img/Charity_live.png"></v-img>
                </v-avatar>
            </a>
            <v-spacer></v-spacer>
            <v-btn class="hidden-md-and-up" icon @click="drawer = !drawer">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div class="hidden-sm-and-down">
                <a class="mr-3 text-caption-index" href="/#home">
                    Home
                </a>
                <a class="mr-3 text-caption-index" href="/#live-campaigns">
                    Live Campaigns
                </a>
                <a class="mr-3 text-caption-index" href="/#journey-success">
                    The Journey to Success
                </a>
                <a class="mr-3 text-caption-index" href="/#price">
                    Price
                </a>
                <a class="mr-3 text-caption-index" href="/#about-us">
                    About Us
                </a>
                <a class="mr-3 text-caption-index" href="/#contact">
                    Contact
                </a>
            </div>
        </v-toolbar>

        <v-list v-if="drawer" class="fixed-top pt-16">
            <v-list-item-group class="py-5">
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#home"> Home </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#live-campaigns"> Live Campaigns </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#journey-success"> The Journey to Success </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#price"> Price </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#about-us"> About Us </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="drawer = false">
                    <v-list-item-content>
                        <v-list-item-title>
                            <a href="/#contact"> Contact </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <v-main class="index-scroll-bar pt-16">
            <div class="landing-top-image">
                <v-row class="pa-15">
                    <v-col cols="12 text-center pt-10">
                        <strong class="text-h4-index">
                            Campaigns
                        </strong>
                    </v-col>
                </v-row>
            </div>
            <v-row class="pa-8 justify-center" style="margin-top: -170px">
                <v-col cols="12" md="10">
                    <v-row>
                        <template v-for="(item,index) in items">
                            <v-col :key="index" cols="12" md="4">
                                <v-card
                                    :elevation="15"
                                    class="mx-auto d-flex flex-column"
                                    min-height="100%"
                                >
                                    <v-img
                                        :src="item.path"
                                        height="250"
                                    ></v-img>

                                    <v-card-title class="pb-0">
                                        <div class="text-truncate">
                                            <strong> {{ item.name || '-' }} </strong>
                                        </div>
                                    </v-card-title>

                                    <v-card-text class="text-caption">
                                        <v-row>
                                            <v-col cols="12">
                                                From
                                                <strong> {{ item.start | truncate_date }}</strong>
                                                &ensp;
                                                To
                                                <strong>{{ item.end | truncate_date }}</strong>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-text class="text-caption" style="height: 125px">
                                        <v-row>
                                            <v-col cols="12">
                                                <div
                                                    class="description"
                                                    v-html="item.excerpt"
                                                ></div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-row>
                                            <v-col class="px-5 pb-5" cols="12">
                                                <v-btn
                                                    block
                                                    class="nav-text rounded-lg font-weight-medium white--text"
                                                    color="success"
                                                    elevation="0"
                                                    height="45px"
                                                    @click="goTo(item.slug)"
                                                >
                                                    visit campaign
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-main>
    </div>
</template>

<script>
export default {
  data() {
    return {

      drawer: false,
      items: [],
    };
  },

  async created() {
    const params = window.location.hostname;
    if ( params == 'nsheikerenhashviis.org' ) window.location.href = '/nshei';
    else if ( params == 'www.nsheikerenhashviis.org' ) {
      window.location.href = '/nshei';
    }
    const data = await axios.get( `/api/v1/campaign` );
    this.items = data.data.filter( ( item ) => {
      if ( item.path === '/img/logo.png' ) {
        item.path = '/img/IndexNotImage.svg';
      }
      return true;
    } );
  },

  methods: {
    goTo( slug ) {
      window.location.href = `/${ slug }`;
    },
  },
};
</script>

<style scoped>

a {
    text-decoration: none !important;
}

a:active {
    color: red !important;
    font-weight: bold !important;
}

.text-h4-index {
    line-height: 2.5rem;
    font-size: 2.125rem !important;
    letter-spacing: 0.00735294em !important;
}

.text-caption-index {
    font-size: 0.75rem;
    letter-spacing: .03em;
}

.landing-top-image {
    background-image: url("/img/IndexHeader.png");
    background-size: cover;
    background-position: top;
    height: 350px;
}

.description {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.index-scroll-bar {
    overflow-y: hidden;
}

</style>
