<template>
  <div id="home">
    <v-toolbar class="px-md-5 px-3 fixed-top" style="z-index: 9999">
      <a href="#home">
        <v-avatar class="pointer" size="150" tile>
          <v-img alt="Avatar" contain src="/img/Charity_live.png"></v-img>
        </v-avatar>
      </a>
      <v-spacer></v-spacer>
      <v-btn class="hidden-md-and-up" icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-row class="justify-center hidden-sm-and-down">
        <v-btn
          class="px-5 text-capitalize"
          color="success"
          elevation="2"
          href="/campaigns"
        >
          Live Campaigns
        </v-btn>
      </v-row>
      <div class="hidden-sm-and-down">
        <a class="mr-3 text-caption-index" href="#home"> Home </a>
        <a class="mr-3 text-caption-index" href="#live-campaigns">
          Live Campaigns
        </a>
        <a class="mr-3 text-caption-index" href="#journey-success">
          The Journey to Success
        </a>
        <a class="mr-3 text-caption-index" href="#price"> Price </a>
        <a class="mr-3 text-caption-index" href="#about-us"> About Us </a>
        <a class="mr-3 text-caption-index" href="#contact"> Contact </a>
      </div>
    </v-toolbar>
    <v-list v-if="drawer" class="fixed-top pt-16">
      <v-list-item-group class="py-5">
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#home"> Home </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#live-campaigns"> Live Campaigns </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#journey-success"> The Journey to Success </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#price"> Price </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#about-us"> About Us </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#contact"> Contact </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-main class="index-scroll-bar pt-16">
      <div class="landing-top-image">
        <v-row class="pa-15">
          <v-col class="mt-16" md="6" sm="12">
            <div data-aos="fade-up">
              <v-row cols="12">
                <p class="title-landing-top">
                  The Power <br />
                  of Good Support
                </p>
              </v-row>
              <v-row cols="12">
                <p class="text-h6-index">
                  With the right guidance your success is very near
                </p>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-btn
                      block
                      class="px-5 text-capitalize"
                      color="success"
                      href="#contact"
                      large
                    >
                      Start Your Success Now
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="hidden-md-and-up mt-5">
                <v-btn
                  block
                  class="px-5 text-capitalize"
                  color="success"
                  href="#live-campaigns"
                  large
                >
                  Live Campaigns
                </v-btn>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="pt-md-15 px-md-15 mt-md-15">
        <v-row>
          <v-col class="pa-10" cols="12" md="6">
            <div data-aos="fade-right">
              <v-img src="/img/IndexPencilGraph.jpg"></v-img>
            </div>
          </v-col>
          <v-col class="py-md-10 px-15" cols="12" md="6">
            <div data-aos="fade-left">
              <v-row>
                <p class="text-h5-index text-bold" style="font-weight: bold">
                  Taking Your Charity Fundraising to the Next Level
                </p>
              </v-row>
              <v-row>
                With so many charities shouting for attention, your donors need
                to know why your organization is unique, vital and worthy of
                their generous support.
                <br />
                <br />
                The CharityLive experts have decades of combined experience to
                support and guide you all the way to success - and beyond.
                CharityLive support makes it easy and simple for you to
                fundraise ensuring that your charity achieves a much greater
                success than you dreamed possible. We work hard to discover your
                drive, passion and what makes you tick. Together we sharpen your
                organization profile, allowing your donors to really get to know
                you and to properly value your unique contribution to the
                nonprofit sector.
                <br />
                <br />
                CharityLive works with organizations even if you are still small
                and have not yet grown to your full potential.
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <div id="journey-success" class="pa-md-15 px-5 pt-10">
        <v-row class="pt-16 justify-center">
          <v-col cols="12" md="10">
            <div data-aos="flip-up">
              <v-card class="px-md-15 py-md-10 pa-5" elevation="15">
                <v-col cols="12">
                  <v-row class="justify-center">
                    <p
                      class="text-h5-index text-center text-bold text-uppercase"
                      style="color: green; font-weight: bold"
                    >
                      The Journey to Success
                    </p>
                  </v-row>
                  <v-row class="justify-center">
                    <p class="text-bold text-center" style="font-weight: bold">
                      The CharityLive comprehensive assessment to enable your
                      donors, fans and supporters to clearly understand your
                      unique and vital contribution.
                    </p>
                  </v-row>
                  <v-row class="justify-center mt-8">
                    <v-btn
                      class="px-5 text-capitalize"
                      color="success"
                      href="#contact"
                      large
                    >
                      Start Your Success Now
                    </v-btn>
                  </v-row>
                </v-col>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="py-10 mt-md-10">
        <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back">
          <v-row class="justify-center">
            <v-col cols="11">
              <v-row class="justify-center">
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="85"
                              transform="translate(197 866)"
                              width="90"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Ready_to_go_Let_s_start_"
                          data-name="Ready to go? Let's start!"
                          transform="translate(-197 -866)"
                        >
                          <rect
                            id="Rectángulo_847"
                            data-name="Rectángulo 847"
                            fill="#fff"
                            height="90"
                            transform="translate(197 866)"
                            width="90"
                          />
                          <g
                            id="Ready_to_go_Let_s_start_2"
                            clip-path="url(#clip-path)"
                            data-name="Ready to go? Let's start!"
                          >
                            <g id="Team" transform="translate(188.581 858.994)">
                              <g id="Grupo_2122" data-name="Grupo 2122">
                                <path
                                  id="Trazado_2230"
                                  d="M53.6,66.166a5.778,5.778,0,0,0,3.913-1.542l6.64,3.913a2.234,2.234,0,0,0,.949.237,1.709,1.709,0,0,0,1.542-.949,1.8,1.8,0,0,0-.711-2.49l-6.64-3.794a4.034,4.034,0,0,0,.119-1.186,5.877,5.877,0,0,0-4.032-5.573V47.075A1.746,1.746,0,0,0,53.6,45.3a1.824,1.824,0,0,0-1.779,1.779v7.708a5.729,5.729,0,0,0-4.032,5.455,4.034,4.034,0,0,0,.119,1.186l-6.64,3.913a1.907,1.907,0,0,0-.711,2.49,1.85,1.85,0,0,0,1.542.949,2.791,2.791,0,0,0,.949-.237l6.64-3.913A5.778,5.778,0,0,0,53.6,66.166Zm0-8.063a2.134,2.134,0,1,1-2.134,2.134A2.054,2.054,0,0,1,53.6,58.1Z"
                                  data-name="Trazado 2230"
                                  fill="#3a3a3a"
                                />
                                <path
                                  id="Trazado_2231"
                                  d="M69.368,47.787a1.746,1.746,0,0,0,1.779-1.779V41.5a5.288,5.288,0,0,0-1.779-3.913,53.8,53.8,0,0,0-8.893-5.81V27.273a4.658,4.658,0,0,0,1.3-3.32V17.431a7.692,7.692,0,0,0-7.708-7.708H52.648a7.692,7.692,0,0,0-7.708,7.708v6.522a4.868,4.868,0,0,0,1.3,3.32v4.506a53.8,53.8,0,0,0-8.893,5.81A4.9,4.9,0,0,0,35.573,41.5v4.506a1.779,1.779,0,0,0,3.557,0V41.5a1.766,1.766,0,0,1,.474-1.067,56.8,56.8,0,0,1,9.012-5.81,2.112,2.112,0,0,0,1.186-1.9V26.443a2.027,2.027,0,0,0-.83-1.542,1.078,1.078,0,0,1-.474-.949V17.431a4.124,4.124,0,0,1,4.15-4.15h1.423a4.124,4.124,0,0,1,4.15,4.15v6.522a1.464,1.464,0,0,1-.474.949,2.027,2.027,0,0,0-.83,1.542v6.285a2.112,2.112,0,0,0,1.186,1.9,48.857,48.857,0,0,1,9.012,5.81,1.156,1.156,0,0,1,.474,1.067v4.506A1.824,1.824,0,0,0,69.368,47.787Z"
                                  data-name="Trazado 2231"
                                  fill="#3a3a3a"
                                />
                                <path
                                  id="Trazado_2232"
                                  d="M98.419,87.391V82.885a5.288,5.288,0,0,0-1.779-3.913,53.8,53.8,0,0,0-8.893-5.81V68.775a4.658,4.658,0,0,0,1.3-3.32V58.933a7.692,7.692,0,0,0-7.708-7.708H79.921a7.692,7.692,0,0,0-7.708,7.708v6.522a4.868,4.868,0,0,0,1.3,3.32v4.506a53.8,53.8,0,0,0-8.893,5.81A4.9,4.9,0,0,0,62.846,83V87.51a1.779,1.779,0,1,0,3.557,0V83a1.766,1.766,0,0,1,.474-1.067,56.8,56.8,0,0,1,9.012-5.81,2.112,2.112,0,0,0,1.186-1.9V67.945a2.027,2.027,0,0,0-.83-1.542,1.078,1.078,0,0,1-.474-.949V58.933a4.124,4.124,0,0,1,4.15-4.15h1.423a4.124,4.124,0,0,1,4.15,4.15v6.522a1.464,1.464,0,0,1-.474.949,2.027,2.027,0,0,0-.83,1.542v6.285a2.112,2.112,0,0,0,1.186,1.9,48.857,48.857,0,0,1,9.012,5.81A1.156,1.156,0,0,1,94.862,83V87.51a1.746,1.746,0,0,0,1.779,1.779A1.933,1.933,0,0,0,98.419,87.391Z"
                                  data-name="Trazado 2232"
                                  fill="#3a3a3a"
                                />
                                <path
                                  id="Trazado_2233"
                                  d="M42.213,89.17a1.746,1.746,0,0,0,1.779-1.779V82.885a5.288,5.288,0,0,0-1.779-3.913,53.8,53.8,0,0,0-8.893-5.81V68.775a4.658,4.658,0,0,0,1.3-3.32V58.933a7.692,7.692,0,0,0-7.708-7.708H25.494a7.591,7.591,0,0,0-7.708,7.589v6.522a4.868,4.868,0,0,0,1.3,3.32v4.506a53.8,53.8,0,0,0-8.893,5.81,4.9,4.9,0,0,0-1.779,3.913v4.506A1.746,1.746,0,0,0,10.2,89.17a1.824,1.824,0,0,0,1.779-1.779V82.885a1.766,1.766,0,0,1,.474-1.067,56.8,56.8,0,0,1,9.012-5.81,2.112,2.112,0,0,0,1.186-1.9V67.826a2.027,2.027,0,0,0-.83-1.542,1.078,1.078,0,0,1-.474-.949V58.814a4.124,4.124,0,0,1,4.15-4.15h1.423a4.124,4.124,0,0,1,4.15,4.15v6.522a1.464,1.464,0,0,1-.474.949,2.027,2.027,0,0,0-.83,1.542v6.285a2.112,2.112,0,0,0,1.186,1.9,48.857,48.857,0,0,1,9.012,5.81,1.156,1.156,0,0,1,.474,1.067v4.506A1.746,1.746,0,0,0,42.213,89.17Z"
                                  data-name="Trazado 2233"
                                  fill="#3a3a3a"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> Ready to go? Let's start! </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    Set up a meeting so that we can understand your Charity
                    properly and can allocate the correct resources and team for
                    you.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="79"
                              transform="translate(196.824 866)"
                              width="84"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Fundraising_Plan"
                          data-name="Fundraising Plan"
                          transform="translate(-197 -866)"
                        >
                          <rect
                            id="Rectángulo_848"
                            data-name="Rectángulo 848"
                            fill="#fff"
                            height="90"
                            transform="translate(197 866)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_65"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 65"
                            transform="translate(3.176 7)"
                          >
                            <g
                              id="Fundraising_Plan-2"
                              data-name="Fundraising Plan"
                              transform="translate(197.357 866)"
                            >
                              <g
                                id="Grupo_2124"
                                data-name="Grupo 2124"
                                transform="translate(0 22.072)"
                              >
                                <g id="Grupo_2123" data-name="Grupo 2123">
                                  <path
                                    id="Trazado_2234"
                                    d="M87.086,31.86v2.513h2.622V79.828H11.8V34.373h2.622V31.86H9.288V82.342H92.221V31.86Z"
                                    data-name="Trazado 2234"
                                    fill="#3a3a3a"
                                    transform="translate(-9.288 -31.86)"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2126"
                                data-name="Grupo 2126"
                                transform="translate(3.934 62.391)"
                              >
                                <g id="Grupo_2125" data-name="Grupo 2125">
                                  <rect
                                    id="Rectángulo_830"
                                    data-name="Rectángulo 830"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="75.066"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2128"
                                data-name="Grupo 2128"
                                transform="translate(6.447 66.216)"
                              >
                                <g id="Grupo_2127" data-name="Grupo 2127">
                                  <rect
                                    id="Rectángulo_831"
                                    data-name="Rectángulo 831"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2130"
                                data-name="Grupo 2130"
                                transform="translate(10.38 66.216)"
                              >
                                <g id="Grupo_2129" data-name="Grupo 2129">
                                  <rect
                                    id="Rectángulo_832"
                                    data-name="Rectángulo 832"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2132"
                                data-name="Grupo 2132"
                                transform="translate(14.314 66.216)"
                              >
                                <g id="Grupo_2131" data-name="Grupo 2131">
                                  <rect
                                    id="Rectángulo_833"
                                    data-name="Rectángulo 833"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2134"
                                data-name="Grupo 2134"
                                transform="translate(33.654 74.083)"
                              >
                                <g id="Grupo_2133" data-name="Grupo 2133">
                                  <path
                                    id="Trazado_2235"
                                    d="M45.5,87.822v2.622h6.228v2.295h2.513V90.444h6.993V87.822Z"
                                    data-name="Trazado 2235"
                                    fill="#3a3a3a"
                                    transform="translate(-45.498 -87.822)"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2136"
                                data-name="Grupo 2136"
                                transform="translate(6.447 0)"
                              >
                                <g id="Grupo_2135" data-name="Grupo 2135">
                                  <path
                                    id="Trazado_2236"
                                    d="M16.224,8.112V54.769H45.508l5.682,5.682,5.682-5.682H86.155V8.112ZM83.642,52.147H55.888l-4.7,4.7-4.7-4.7H18.846V10.625h64.9l-.109,41.521Z"
                                    data-name="Trazado 2236"
                                    fill="#3a3a3a"
                                    transform="translate(-16.224 -8.112)"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2138"
                                data-name="Grupo 2138"
                                transform="translate(12.894 5.463)"
                              >
                                <g id="Grupo_2137" data-name="Grupo 2137">
                                  <rect
                                    id="Rectángulo_834"
                                    data-name="Rectángulo 834"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="10.49"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2140"
                                data-name="Grupo 2140"
                                transform="translate(12.893 9.178)"
                              >
                                <g id="Grupo_2139" data-name="Grupo 2139">
                                  <path
                                    id="Trazado_2237"
                                    d="M53.537,41.371l-2.622-2.622a11.493,11.493,0,0,0,2.076-6.665A11.7,11.7,0,0,0,41.3,20.391a10.234,10.234,0,0,0-4.043.765l-3.169-3.169H23.16V20.5h9.834l1.858,1.858A11.673,11.673,0,0,0,41.3,43.775a11.763,11.763,0,0,0,7.867-3.059l3.278,3.278H63.371V41.48l-9.834-.109ZM39.988,31.646v9.506a9.151,9.151,0,0,1-7.758-8.96,9.277,9.277,0,0,1,4.48-7.867l3.059,3.059,1.748-1.748-2.295-2.295a12.04,12.04,0,0,1,1.967-.219,9.224,9.224,0,0,1,5.354,1.748Zm9.069,5.245-1.639-1.639L45.669,37l1.748,1.748A9.781,9.781,0,0,1,42.5,41.043V32.52L48.51,26.4a9,9,0,0,1,1.858,5.573A7.9,7.9,0,0,1,49.057,36.891Z"
                                    data-name="Trazado 2237"
                                    fill="#3a3a3a"
                                    transform="translate(-23.16 -17.988)"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2142"
                                data-name="Grupo 2142"
                                transform="translate(42.723 36.495)"
                              >
                                <g id="Grupo_2141" data-name="Grupo 2141">
                                  <rect
                                    id="Rectángulo_835"
                                    data-name="Rectángulo 835"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="10.38"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2144"
                                data-name="Grupo 2144"
                                transform="translate(49.389 7.976)"
                              >
                                <g id="Grupo_2143" data-name="Grupo 2143">
                                  <rect
                                    id="Rectángulo_836"
                                    data-name="Rectángulo 836"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2146"
                                data-name="Grupo 2146"
                                transform="translate(54.415 7.976)"
                              >
                                <g id="Grupo_2145" data-name="Grupo 2145">
                                  <rect
                                    id="Rectángulo_837"
                                    data-name="Rectángulo 837"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="15.625"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2148"
                                data-name="Grupo 2148"
                                transform="translate(49.389 11.91)"
                              >
                                <g id="Grupo_2147" data-name="Grupo 2147">
                                  <rect
                                    id="Rectángulo_838"
                                    data-name="Rectángulo 838"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2150"
                                data-name="Grupo 2150"
                                transform="translate(54.415 11.91)"
                              >
                                <g id="Grupo_2149" data-name="Grupo 2149">
                                  <rect
                                    id="Rectángulo_839"
                                    data-name="Rectángulo 839"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="15.625"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2152"
                                data-name="Grupo 2152"
                                transform="translate(49.389 15.625)"
                              >
                                <g id="Grupo_2151" data-name="Grupo 2151">
                                  <rect
                                    id="Rectángulo_840"
                                    data-name="Rectángulo 840"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.513"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2154"
                                data-name="Grupo 2154"
                                transform="translate(54.415 15.625)"
                              >
                                <g id="Grupo_2153" data-name="Grupo 2153">
                                  <rect
                                    id="Rectángulo_841"
                                    data-name="Rectángulo 841"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="15.625"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2156"
                                data-name="Grupo 2156"
                                transform="translate(12.894 39.008)"
                              >
                                <g id="Grupo_2155" data-name="Grupo 2155">
                                  <rect
                                    id="Rectángulo_842"
                                    data-name="Rectángulo 842"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="10.49"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2158"
                                data-name="Grupo 2158"
                                transform="translate(59.55 22.29)"
                              >
                                <g id="Grupo_2157" data-name="Grupo 2157">
                                  <rect
                                    id="Rectángulo_843"
                                    data-name="Rectángulo 843"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="10.49"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2160"
                                data-name="Grupo 2160"
                                transform="translate(63.484 27.535)"
                              >
                                <g id="Grupo_2159" data-name="Grupo 2159">
                                  <rect
                                    id="Rectángulo_844"
                                    data-name="Rectángulo 844"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="6.665"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2162"
                                data-name="Grupo 2162"
                                transform="translate(65.997 32.562)"
                              >
                                <g id="Grupo_2161" data-name="Grupo 2161">
                                  <rect
                                    id="Rectángulo_845"
                                    data-name="Rectángulo 845"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="4.043"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2164"
                                data-name="Grupo 2164"
                                transform="translate(67.308 37.697)"
                              >
                                <g id="Grupo_2163" data-name="Grupo 2163">
                                  <rect
                                    id="Rectángulo_846"
                                    data-name="Rectángulo 846"
                                    fill="#3a3a3a"
                                    height="2.513"
                                    width="2.732"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> Fundraising Plan </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    The success of your campaign is dependent on using the right
                    strategy. We work together to create a clear and simple
                    fundraising plan and prepare your event to its finesse.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="85"
                              transform="translate(197 866)"
                              width="90"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Fundraising_Strategy"
                          data-name="Fundraising Strategy"
                          transform="translate(-197 -864)"
                        >
                          <rect
                            id="Rectángulo_849"
                            data-name="Rectángulo 849"
                            fill="#fff"
                            height="90"
                            transform="translate(197 864)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_66"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 66"
                          >
                            <g
                              id="XMLID_227_"
                              transform="translate(188.706 856.188)"
                            >
                              <path
                                id="XMLID_228_"
                                d="M46.732,29.683V18.153H89.293V48.694H50.9c-5.765,3.557-5.4,3.434-5.887,3.557h46a1.886,1.886,0,0,0,1.84-1.84v-34.1a1.886,1.886,0,0,0-1.84-1.84H44.892a1.886,1.886,0,0,0-1.84,1.84v8.954S46.241,26.984,46.732,29.683Z"
                                fill="#3a3a3a"
                              />
                              <path
                                id="XMLID_1158_"
                                d="M66.6,34.711l-2.944,2.33,1.227,2.821a1.741,1.741,0,0,0,1.1.981,2.051,2.051,0,0,0,1.472-.123l7.85-4.048,7.85,6.133a1.978,1.978,0,0,0,1.1.368,1.917,1.917,0,0,0,1.472-.736,1.806,1.806,0,0,0-.368-2.576l-8.831-6.746a1.664,1.664,0,0,0-1.962-.123l-7.114,3.68Z"
                                fill="#3a3a3a"
                              />
                              <path
                                id="XMLID_1159_"
                                d="M62.8,25.39a1.894,1.894,0,0,0-1.962-1.1L54.7,25.39l-2.7-4.17a1.788,1.788,0,0,0-3.066,1.84l3.312,5.274a1.825,1.825,0,0,0,1.84.859l5.519-.981,3.312-2.576Z"
                                fill="#3a3a3a"
                              />
                              <path
                                id="XMLID_1160_"
                                d="M59.365,35.693l.368.736,7.973-6.255a1.888,1.888,0,0,0,.245-2.576,1.777,1.777,0,0,0-2.576-.245l-8.1,6.378A7.947,7.947,0,0,1,59.365,35.693Z"
                                fill="#3a3a3a"
                              />
                              <path
                                id="XMLID_1161_"
                                d="M28.088,58.874a.859.859,0,1,1,1.717,0V76.046a11.066,11.066,0,0,1,5.642-1.472,10.19,10.19,0,0,1-1.472-5.4,10.674,10.674,0,0,1,3.68-7.973S37.532,59,37.532,34.466a.707.707,0,0,1,.736-.736.687.687,0,0,1,.736.613h0v11.9a3.118,3.118,0,0,0,1.717,2.821,3.216,3.216,0,0,0,3.312-.123L55.563,41.7a3.3,3.3,0,1,0-3.434-5.642l-6.746,4.416V34.343a7.7,7.7,0,0,0-7.727-7.727H34.343l-2.453,6.5L30.173,37.41l.981-4.661a1.384,1.384,0,0,0-.123-.981l-1.349-2.453,1.1-2.208a.373.373,0,0,0,0-.491c-.123-.123-.245-.245-.368-.245H27.229c-.123,0-.368.123-.368.245a.373.373,0,0,0,0,.491l1.227,2.208-1.349,2.453a1.3,1.3,0,0,0-.123.981l.859,4.661L25.88,33.117l-2.576-6.5H19.993a7.7,7.7,0,0,0-7.727,7.727v24.04A3.274,3.274,0,0,0,15.455,61.7h0a3.274,3.274,0,0,0,3.312-3.189l.123-24.04a.613.613,0,0,1,1.227,0V89.293a4.013,4.013,0,0,0,3.8,3.925V85.859a11.527,11.527,0,0,1,4.048-8.709V58.874Z"
                                fill="none"
                                stroke="#3a3a3a"
                                stroke-miterlimit="10"
                                stroke-width="2.453"
                              />
                              <circle
                                id="XMLID_1165_"
                                cx="6.746"
                                cy="6.746"
                                fill="none"
                                r="6.746"
                                stroke="#3a3a3a"
                                stroke-miterlimit="10"
                                stroke-width="2.453"
                                transform="translate(22.201 11.039)"
                              />
                              <circle
                                id="XMLID_1167_"
                                cx="6.869"
                                cy="6.869"
                                fill="none"
                                r="6.869"
                                stroke="#3a3a3a"
                                stroke-miterlimit="10"
                                stroke-width="2.453"
                                transform="translate(70.772 62.309)"
                              />
                              <path
                                id="XMLID_1168_"
                                d="M94.322,85.981a7.8,7.8,0,0,0-7.85-7.727H68.687a7.737,7.737,0,0,0-7.727,6.991,7.836,7.836,0,0,0-7.727-7.114H35.447a7.8,7.8,0,0,0-7.85,7.727v7.727h6.623V85.859a.613.613,0,0,1,1.227,0v7.727h17.54V85.859a.736.736,0,1,1,1.472,0h0v7.727H67.583v-7.6a.613.613,0,1,1,1.227,0v7.6h17.54v-7.6a.777.777,0,0,1,.613-.736.614.614,0,0,1,.736.613h0v7.6h6.623Z"
                                fill="none"
                                stroke="#3a3a3a"
                                stroke-miterlimit="10"
                                stroke-width="2.453"
                              />
                              <path
                                id="XMLID_1202_"
                                d="M44.278,62.309a6.957,6.957,0,0,0-6.869,6.5,6.853,6.853,0,0,0,2.7,5.765h0A6.82,6.82,0,0,0,51.147,68.81,6.785,6.785,0,0,0,44.278,62.309Z"
                                fill="none"
                                stroke="#3a3a3a"
                                stroke-miterlimit="10"
                                stroke-width="2.453"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> Fundraising Strategy </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    Your success is directly connected by our right strategy We
                    will make sure you understand and follow our plan.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="85"
                              transform="translate(197 866)"
                              width="90"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Campaign_setup"
                          data-name="Campaign setup"
                          transform="translate(-197 -864)"
                        >
                          <rect
                            id="Rectángulo_850"
                            data-name="Rectángulo 850"
                            fill="#fff"
                            height="90"
                            transform="translate(197 864)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_67"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 67"
                          >
                            <g
                              id="Campaign_setup-2"
                              data-name="Campaign setup"
                              transform="translate(184.378 855.884)"
                            >
                              <g id="Grupo_2165" data-name="Grupo 2165">
                                <path
                                  id="Trazado_2238"
                                  d="M97.46,46.88H91.909v-.123a3.975,3.975,0,0,0-3.948-3.948H83.89a3.975,3.975,0,0,0-3.948,3.948v.123H77.351v-2.1a1.106,1.106,0,0,0-1.11-1.11H65.385l-.37-1.727h13.94a3.844,3.844,0,0,0,3.824-3.824V13.94a3.844,3.844,0,0,0-3.824-3.824H36.393a3.844,3.844,0,0,0-3.824,3.824V38a3.844,3.844,0,0,0,3.824,3.824H50.457l-.37,1.727H39.231a1.106,1.106,0,0,0-1.11,1.11V46.88H17.765a1.106,1.106,0,0,0-1.11,1.11v6.662a1.106,1.106,0,0,0,1.11,1.11H23.44V94.006a1.106,1.106,0,0,0,1.11,1.11H30.1a1.106,1.106,0,0,0,1.11-1.11V55.762H83.89V94.006A1.106,1.106,0,0,0,85,95.116h5.552a1.106,1.106,0,0,0,1.11-1.11V55.762h5.8a1.106,1.106,0,0,0,1.11-1.11V48.113A1.021,1.021,0,0,0,97.46,46.88ZM83.89,45.029h4.071a1.764,1.764,0,0,1,1.727,1.727v.123h-7.4v-.123h0A1.662,1.662,0,0,1,83.89,45.029ZM34.913,13.94h0a1.425,1.425,0,0,1,1.48-1.48H78.955a1.425,1.425,0,0,1,1.48,1.48V34.913H34.913Zm1.48,25.537A1.425,1.425,0,0,1,34.913,38v-.74H80.435V38a1.425,1.425,0,0,1-1.48,1.48Zm26.524,4.071H52.431l.37-1.727h9.746Zm-22.7,2.344h34.79v.987H40.218ZM28.991,93.019H25.784V56.009h3.208v37.01Zm60.573,0H86.357V56.009h3.208ZM96.35,53.541H19V49.224H96.35Z"
                                  data-name="Trazado 2238"
                                  fill="#3a3a3a"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> Campaign setup </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    It's simple to use the CharityLive platform and we can
                    easily personalize your campaign page to suit your taste.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="75"
                              transform="translate(197 866.278)"
                              width="80"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="Advertising_and_PR"
                          data-name="Advertising and PR"
                          transform="translate(-196 -863)"
                        >
                          <rect
                            id="Rectángulo_851"
                            data-name="Rectángulo 851"
                            fill="#fff"
                            height="90"
                            transform="translate(196 863)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_68"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 68"
                            transform="translate(5 4.722)"
                          >
                            <g
                              id="Advertising_and_PR-2"
                              data-name="Advertising and PR"
                              transform="translate(203.323 866)"
                            >
                              <g
                                id="Grupo_2167"
                                data-name="Grupo 2167"
                                transform="translate(24.791 51.557)"
                              >
                                <g id="Grupo_2166" data-name="Grupo 2166">
                                  <path
                                    id="Trazado_2239"
                                    d="M50.8,71.19a2.149,2.149,0,0,0-2.151,2.151V79.68a2.151,2.151,0,0,0,4.3,0V73.341A2.224,2.224,0,0,0,50.8,71.19Z"
                                    data-name="Trazado 2239"
                                    fill="#3a3a3a"
                                    transform="translate(-48.648 -71.19)"
                                  />
                                </g>
                              </g>
                              <g
                                id="Grupo_2169"
                                data-name="Grupo 2169"
                                transform="translate(0 0)"
                              >
                                <g id="Grupo_2168" data-name="Grupo 2168">
                                  <path
                                    id="Trazado_2240"
                                    d="M78.6,32.03V15.276a2.106,2.106,0,0,0-2.943-1.924L42.946,28.634H22.909a2.149,2.149,0,0,0-2.151,2.151V56.029a2.149,2.149,0,0,0,2.151,2.151h1.924l5.773,28.866a2.06,2.06,0,0,0,2.038,1.7H44.191a2.149,2.149,0,0,0,2.151-2.151c0-.226-5.66-28.414-5.66-28.414h2.49l32.6,15.282c2.49.566,2.943-1.245,2.943-1.924V54.67a11.544,11.544,0,0,0,9.4-11.32A11.756,11.756,0,0,0,78.6,32.03ZM41.588,84.442H34.343l-5.32-26.376h7.245Zm.226-30.564H24.947V32.822H41.7l.113,21.056Zm32.6,14.263L46,54.783V48.218h.906a2.151,2.151,0,0,0,0-4.3H46V39.728h4.981a2.151,2.151,0,1,0,0-4.3H45.889V31.69L74.3,18.446v49.7ZM78.6,50.369V36.332a7.334,7.334,0,0,1,0,14.037Z"
                                    data-name="Trazado 2240"
                                    fill="#3a3a3a"
                                    transform="translate(-20.758 -13.188)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> Advertising and PR </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    Our experienced PR experts make sure you have a clear,
                    focused message, with great graphics. We aim for maximum
                    impact with minimal cost to you.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 92"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="85"
                              transform="translate(197 866)"
                              width="90"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="D-Day._Your_Campaign_Is_Going_Live"
                          data-name="D-Day. Your Campaign Is Going Live"
                          transform="translate(-197 -866)"
                        >
                          <rect
                            id="Rectángulo_852"
                            data-name="Rectángulo 852"
                            fill="#fff"
                            height="90"
                            transform="translate(197 868)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_69"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 69"
                          >
                            <g
                              id="D-Day"
                              transform="translate(186.606 858.109)"
                            >
                              <g id="Grupo_2171" data-name="Grupo 2171">
                                <g id="Grupo_2170" data-name="Grupo 2170">
                                  <path
                                    id="Trazado_2241"
                                    d="M89.144,71.413A2.267,2.267,0,0,0,90,71.168a1.82,1.82,0,0,0,.734-2.446L80.584,50.87a9.193,9.193,0,0,0,2.568-4.524c1.223-5.014-.245-11.739-3.913-17.486a31.377,31.377,0,0,0-2.2-3.179h0c-3.057-4.158-7.826-10.272-7.826-10.394a1.834,1.834,0,1,0-2.935,2.2s2.935,3.791,5.625,7.459H56.739c-.367-.978-.611-1.59-.611-1.59a1.783,1.783,0,0,0-3.3,1.345s.489,1.1,1.1,2.69h0c.245.734.611,1.467.856,2.2-.122.245-.245.734-.489,1.223H38.886c2.69-3.668,5.625-7.337,5.625-7.459a1.834,1.834,0,0,0-2.935-2.2c0,.122-4.769,6.114-7.826,10.394-.978,1.345-1.712,2.446-2.2,3.057-3.668,5.747-5.136,12.473-3.913,17.486A8.68,8.68,0,0,0,30.2,56.617L17.976,78.261l-4.891-2.812a1.805,1.805,0,0,0-1.712,3.179l12.84,7.337a2.267,2.267,0,0,0,.856.245,2.09,2.09,0,0,0,1.59-.856,1.721,1.721,0,0,0-.734-2.446L21.033,80.1,33.261,58.451a9.174,9.174,0,0,0,2.446.245,9.364,9.364,0,0,0,3.057-.489c4.891-1.467,9.9-6.236,12.962-12.228,1.1-2.079,3.179-7.215,4.891-11.617.978,2.446,1.957,4.524,2.568,5.747,3.057,6.114,8.071,10.761,12.962,12.228a9.364,9.364,0,0,0,3.057.489,13.474,13.474,0,0,0,2.2-.245L87.554,70.435A1.908,1.908,0,0,0,89.144,71.413Zm-40.6-27.024C45.856,49.524,41.7,53.682,37.663,54.9a5.47,5.47,0,0,1-4.158-.122,5.111,5.111,0,0,1-2.2-3.424c-.978-4.035.367-9.783,3.424-14.674q.55-.734,1.467-2.2H52.7C51.359,38.03,49.524,42.554,48.546,44.389Zm31.06.978a5.111,5.111,0,0,1-2.2,3.424,5.461,5.461,0,0,1-4.158.122C69.334,47.69,65.054,43.655,62.364,38.4c-.978-1.834-2.812-6.359-4.28-10.027H74.592q.917,1.467,1.467,2.2C79.239,35.584,80.584,41.332,79.606,45.367Z"
                                    data-name="Trazado 2241"
                                    fill="#3a3a3a"
                                  />
                                  <path
                                    id="Trazado_2242"
                                    d="M100.149,70.435A1.82,1.82,0,0,0,97.7,69.7l-12.84,7.337a1.82,1.82,0,0,0-.734,2.446,1.782,1.782,0,0,0,1.59.856,2.267,2.267,0,0,0,.856-.245l12.84-7.337A1.569,1.569,0,0,0,100.149,70.435Z"
                                    data-name="Trazado 2242"
                                    fill="#3a3a3a"
                                  />
                                  <path
                                    id="Trazado_2243"
                                    d="M74.959,36.685a1.75,1.75,0,0,0-1.223,2.2,9.018,9.018,0,0,1,.122,5.38,1.768,1.768,0,0,0,1.1,2.323,1.1,1.1,0,0,0,.611.122,1.839,1.839,0,0,0,1.712-1.223,12.869,12.869,0,0,0,0-7.459A2.016,2.016,0,0,0,74.959,36.685Z"
                                    data-name="Trazado 2243"
                                    fill="#3a3a3a"
                                  />
                                  <path
                                    id="Trazado_2244"
                                    d="M74.715,32.527c-.245-.367-.611-.978-1.1-1.59A1.749,1.749,0,0,0,70.8,33.016a14.979,14.979,0,0,1,.978,1.467,2.071,2.071,0,0,0,1.467.856,2.878,2.878,0,0,0,.978-.245A2.06,2.06,0,0,0,74.715,32.527Z"
                                    data-name="Trazado 2244"
                                    fill="#3a3a3a"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong class="text-center">
                      D-Day. <br />
                      Your Campaign Is Going Live
                    </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    You’ve planned well, and now your campaign is about to go
                    live. Our comprehensive checklist will ensure that you
                    haven’t forgotten anything, so you are prepared for success.
                  </v-row>
                </v-col>
                <v-col class="pa-10" cols="12" md="3">
                  <v-row class="justify-center">
                    <v-avatar size="80" tile>
                      <svg
                        height="300"
                        viewBox="0 0 90 90"
                        width="90"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect
                              id="Rectángulo_808"
                              data-name="Rectángulo 808"
                              fill="#b4b3b3"
                              height="71"
                              transform="translate(197.333 865.759)"
                              width="74"
                            />
                          </clipPath>
                        </defs>
                        <g
                          id="After_Care"
                          data-name="After Care"
                          transform="translate(-197 -864)"
                        >
                          <rect
                            id="Rectángulo_853"
                            data-name="Rectángulo 853"
                            fill="#fff"
                            height="90"
                            transform="translate(197 864)"
                            width="90"
                          />
                          <g
                            id="Enmascarar_grupo_70"
                            clip-path="url(#clip-path)"
                            data-name="Enmascarar grupo 70"
                            transform="translate(7.667 7.241)"
                          >
                            <g
                              id="After_Care-2"
                              data-name="After Care"
                              transform="translate(197 866.328)"
                            >
                              <g
                                id="Grupo_2174"
                                data-name="Grupo 2174"
                                transform="translate(0 0)"
                              >
                                <g id="Grupo_2173" data-name="Grupo 2173">
                                  <g id="Grupo_2172" data-name="Grupo 2172">
                                    <path
                                      id="Trazado_2245"
                                      d="M16.858,76.552h-4.1V11.494a1.581,1.581,0,0,0-1.6-1.6,1.562,1.562,0,0,0-1.5,1.6V78.154a1.581,1.581,0,0,0,1.6,1.6H82.717a1.6,1.6,0,1,0,0-3.2H69.805m-38.534,0h-4.1m17.916,0h-4.1m18.516,0h-4.1m-31.328,0h-4.1m17.916,0h-3.5m17.916,0h-4.1m18.516,0H62.6"
                                      data-name="Trazado 2245"
                                      fill="#3a3a3a"
                                      transform="translate(-9.651 -9.893)"
                                    />
                                  </g>
                                </g>
                              </g>
                              <g
                                id="Grupo_2176"
                                data-name="Grupo 2176"
                                transform="translate(9.505 10.37)"
                              >
                                <g id="Grupo_2175" data-name="Grupo 2175">
                                  <path
                                    id="Trazado_2246"
                                    d="M75.487,81.978V43.344a1.581,1.581,0,0,0-1.6-1.6H66.679a1.581,1.581,0,0,0-1.6,1.6V81.978h-4.1V55.355a1.581,1.581,0,0,0-1.6-1.6H52.867a1.581,1.581,0,0,0-1.6,1.6V81.978h-4.1V61.961a1.581,1.581,0,0,0-1.6-1.6H38.354a1.581,1.581,0,0,0-1.6,1.6V81.978h-3.9V69.167a1.581,1.581,0,0,0-1.6-1.6H24.041a1.581,1.581,0,0,0-1.6,1.6V81.978M68.281,44.845h4.1V81.978h-4.1ZM54.468,56.856h3.5V81.978h-3.5ZM40.055,63.462h4.1V81.978h-4.1ZM25.643,70.668h4.1v11.31h-4.1Z"
                                    data-name="Trazado 2246"
                                    fill="#3a3a3a"
                                    transform="translate(-21.335 -25.69)"
                                  />
                                  <path
                                    id="Trazado_2247"
                                    d="M67.853,25.234v-.2h0a1.41,1.41,0,0,0-1.3-1.4l-6.105-1.2a1.531,1.531,0,1,0-.6,3l2.3.4L21.812,52.358a1.6,1.6,0,0,0-.5,2.1,1.459,1.459,0,0,0,1.3.7,1.137,1.137,0,0,0,.8-.3L64.05,28.437l-.4,2.4a1.508,1.508,0,0,0,1.2,1.8h.3a1.5,1.5,0,0,0,1.5-1.3l1.1-5.805a.368.368,0,0,0,.1-.3Z"
                                    data-name="Trazado 2247"
                                    fill="#3a3a3a"
                                    transform="translate(-21.108 -22.393)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </v-avatar>
                  </v-row>
                  <v-row class="justify-center mt-5">
                    <strong> After Care </strong>
                  </v-row>
                  <v-row class="justify-center mt-6 text-center">
                    Your fundraising doesn’t stop at the end of your campaign;
                    in fact, that’s just the beginning. You now have many new
                    relationships with generous people who value what your
                    organization is doing. Let’s plan your future together, to
                    ensure donor retention and continued support.
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="py-10 mt-md-10">
        <v-row
          id="live-campaigns"
          class="justify-center pb-16"
          style="background-color: #f2f9fa"
        >
          <v-col class="pa-10" cols="8">
            <v-row class="justify-center">
              <p
                class="text-h4-index text-center text-bold"
                style="font-weight: bold"
              >
                We’re Good with Numbers
              </p>
            </v-row>
            <v-row class="justify-center mt-10">
              <v-col cols="12" md="3">
                <v-row class="justify-center">
                  <div data-aos="zoom-in">
                    <p
                      class="text-h3 text-bold text-center"
                      style="font-weight: bold; color: green"
                    >
                      10
                    </p>
                  </div>
                </v-row>
                <v-row class="justify-center">
                  <p class="text-center" style="color: green">
                    Years of Combined Fundraising Experience
                  </p>
                </v-row>
              </v-col>
              <v-col class="pt-10 hidden-sm-and-down">
                <v-row class="justify-center">
                  <v-avatar color="secondary" size="10"></v-avatar>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                <v-row class="justify-center">
                  <div data-aos="zoom-in">
                    <p
                      class="text-h3 text-bold text-center"
                      style="font-weight: bold; color: green"
                    >
                      120
                    </p>
                  </div>
                </v-row>
                <v-row class="justify-center">
                  <p class="text-center" style="color: green">
                    Combined successful campaigns
                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div data-aos="fade-zoom-in" data-aos-easing="ease-in-back">
          <v-row class="justify-center" style="margin-top: -80px">
            <v-col cols="11" md="10">
              <v-card class="py-10">
                <v-row class="justify-center">
                  <p class="text-h5-index text-bold">Our featured charities</p>
                  &emsp;
                  <a class="mt-2" href="/campaigns">( See full list )</a>
                </v-row>
                <v-row class="justify-center px-md-10 px-5">
                  <v-carousel height="100%" hide-delimiters>
                    <v-carousel-item
                      v-for="(item, index) in campaigns"
                      :key="index"
                      style="height: 100% !important"
                    >
                      <v-card class="px-16 hidden-sm-and-down" flat light>
                        <v-row class="px-5 pt-5">
                          <v-col class="pa-5" cols="6">
                            <div>
                              <v-img
                                :src="item.path"
                                style="height: 200px"
                              ></v-img>
                            </div>
                            <div
                              class="
                                text-caption-index
                                d-flex
                                mt-2
                                justify-center
                              "
                            >
                              <div>
                                <strong>
                                  From {{ item.start | truncate_date }}
                                </strong>
                              </div>
                              <strong> &ensp; - &ensp; </strong>
                              <div>
                                <strong>
                                  To {{ item.end | truncate_date }}
                                </strong>
                              </div>
                            </div>
                          </v-col>
                          <v-col class="pa-5 py-8" cols="6">
                            <v-row>
                              <strong class="text-h6-index">{{
                                item.name
                              }}</strong>
                            </v-row>
                            <v-row class="mt-5">
                              <div
                                class="text-caption-index description"
                                v-html="item.excerpt"
                              ></div>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                      <div class="px-2">
                        <v-card class="hidden-md-and-up" flat light>
                          <v-img :src="item.path" height="200px"></v-img>
                          <v-card-title>{{ item.name }}</v-card-title>
                          <v-card-subtitle
                            class="description pb-0"
                            v-html="item.excerpt"
                          >
                          </v-card-subtitle>
                          <v-card-actions class="justify-center">
                            <div class="text-caption-index d-flex mt-2">
                              <div>
                                <strong>
                                  From {{ item.start | truncate_date }}
                                </strong>
                              </div>
                              <strong> &ensp; - &ensp; </strong>
                              <div>
                                <strong>
                                  To {{ item.end | truncate_date }}
                                </strong>
                              </div>
                            </div>
                          </v-card-actions>
                        </v-card>
                      </div>
                    </v-carousel-item>
                  </v-carousel>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div id="about-us" class="pt-md-15 px-md-15 mt-md-15">
        <v-row>
          <v-col class="py-md-10 px-15" cols="12" md="6">
            <div data-aos="fade-left">
              <v-row>
                <p class="text-uppercase text-bold" style="font-weight: bold">
                  About
                </p>
              </v-row>
              <v-row>
                <p class="text-h5-index text-bold" style="font-weight: bold">
                  Our Experts Are the Finest
                </p>
              </v-row>
              <v-row>
                <p>
                  Fundraising today is dramatically different from what it ever
                  was before. Technical advances have made available new tools,
                  methods, and approaches that have redefined fundraising.
                </p>
                <p>
                  In recent years, with Covid affecting both travel and
                  communication, crowdfunding was a lifesaver for many
                  institutions and organizations. Today, crowdfunding is even
                  more crucial to many organizations’ survival. But with so many
                  charities shouting for attention, your donors need to know why
                  your organization is unique, vital, and worthy of their
                  generous support. The CharityLive experts have decades of
                  combined experience to support and guide you all the way to
                  success – and beyond. CharityLive support makes it simple and
                  easy for you to fundraise, ensuring that your charity achieves
                  much greater success than you dreamed possible.
                </p>
                <p>
                  We work hard to discover your drive, your passion. We know how
                  to uncover the motivations and the deep emotions that are the
                  foundation and strength of an organization, and how to
                  translate that into powerful messages that will resonate with
                  donors.
                </p>
                <p>
                  Together we sharpen your organization’s profile, allowing your
                  donors to really get to know you and to properly value your
                  unique contribution to the nonprofit sector.
                </p>
                <p>
                  After many years of highly successful fundraising, we know
                  what works and what doesn’t work; and we’ll share all that
                  valuable knowledge with you, so you can avoid the pitfalls and
                  look forward to resounding success.
                </p>
                <p>
                  <strong> CharityLive </strong>will make your fundraising event
                  the most successful one ever!
                </p>
              </v-row>
            </div>
          </v-col>
          <v-col class="px-10" cols="12" md="6">
            <div data-aos="fade-right">
              <v-img src="/img/IndexPencilGraph.jpg"></v-img>
            </div>
          </v-col>
        </v-row>
      </div>
      <div id="price" class="py-md-10 py-5 mt-10">
        <v-row
          class="justify-center"
          style="border-top: 12px solid green; background-color: #f2f9fa"
        >
          <v-col class="pa-md-10 py-10 mt-md-10" cols="10">
            <v-row class="justify-center">
              <p class="text-h4-index text-bold">Plans and prices</p>
            </v-row>
            <v-row class="justify-center mt-md-8 mt-5">
              <div data-aos="zoom-in-down">
                <v-col cols="12">
                  <v-row>
                    <v-col class="pa-5" cols="12" md="4">
                      <v-list class="py-0">
                        <v-list-item
                          class="pa-3"
                          style="
                            border-radius: 10px 10px 0 0;
                            background-color: #dbbb0a;
                          "
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center text-bold text-white"
                            >
                              Platform only
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pt-3">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform 1% Fee
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Credit Card
                              <br />
                              Standard Processing Fees
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform Setup
                              <br />
                              £500
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="my-0"></v-divider>
                        <v-list-item class="py-1">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              -
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="my-0"></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              -
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              -
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="py-1">
                          <v-list-item-content>
                            <v-btn block color="success">
                              Contact us Now
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                    </v-col>
                    <v-col class="pa-5" cols="12" md="4">
                      <v-list class="py-0">
                        <v-list-item
                          class="pa-3"
                          style="
                            border-radius: 10px 10px 0 0;
                            background-color: #4ab2cc;
                          "
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center text-bold text-white"
                            >
                              Platform only <br />
                              <strong class="text-h5-index">
                                up to £200k</strong
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pt-3">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform 0% Fee
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Credit Card
                              <br />
                              Standard Processing Fees
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform Setup
                              <br />
                              Free
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Fundraising Expert/Campaign Consultant
                              <br />
                              £3000
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Creative Team
                              <br />
                              Request Quote
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn block color="success">
                              Contact us Now
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                    </v-col>
                    <v-col class="pa-5" cols="12" md="4">
                      <v-list class="py-0">
                        <v-list-item
                          class="pa-3"
                          style="
                            border-radius: 10px 10px 0 0;
                            background-color: #b55bbb;
                          "
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center text-bold text-white"
                            >
                              Advance <br />
                              <strong class="text-h5-index">
                                up to £500k</strong
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pt-3">
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform 0% Fee
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Credit Card
                              <br />
                              Standard Processing Fees
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Platform Setup
                              <br />
                              Free
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Fundraising Expert/Campaign Consultant
                              <br />
                              £4000
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-caption-index text-center"
                            >
                              Creative Team
                              <br />
                              Request Quote
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn block color="success">
                              Contact us Now
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div id="contact" class="py-md-10 py-5 mt-md-10">
        <v-row class="justify-center">
          <v-col class="pa-10 mt-10" cols="11" md="9">
            <v-row>
              <v-col cols="12" md="6">
                <div data-aos="fade-right">
                  <v-row>
                    <p
                      class="text-uppercase text-bold"
                      style="font-weight: bold"
                    >
                      CONTACT
                    </p>
                  </v-row>
                  <v-row>
                    <p
                      class="text-h4-index text-bold"
                      style="font-weight: bold"
                    >
                      Let’s Work Together
                    </p>
                  </v-row>
                  <v-row>
                    <p>2a Highfield Avenue, London NW11 9SX</p>
                  </v-row>
                  <v-row>
                    <p>info@charity.live</p>
                  </v-row>
                  <v-row>
                    <p>Tel: 078-55022620</p>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-row v-if="successResponse" class="pt-5">
                  <v-alert style="width: 100%" type="success">
                    Thanks by contact with us.
                  </v-alert>
                </v-row>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col class="px-0" cols="6">
                      <v-text-field
                        v-model="firstname"
                        label="First name"
                      ></v-text-field>
                    </v-col>
                    <v-col class="px-0 pl-3" cols="6">
                      <v-text-field
                        v-model="lastname"
                        label="Last name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="email"
                      :rules="[(v) => !!v || 'Email is required']"
                      label="Email *"
                      required
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-textarea
                      v-model="message"
                      :rules="[(v) => !!v || 'Message is required']"
                      label="Leave us a message..."
                      required
                      rows="5"
                    >
                    </v-textarea>
                  </v-row>
                  <v-row class="mt-6">
                    <v-btn
                      :loading="loading"
                      class="px-10 text-capitalize"
                      color="success"
                      @click="sendForm"
                    >
                      Submit
                    </v-btn>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="pt-10 mt-10 map-bottom-image"></div>
      <div class="py-5 text-center">
        <strong class="text-caption-index-index"
          >© {{ year }} Charity.live
        </strong>
      </div>
    </v-main>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  mounted() {
    const params = window.location.hostname;
    if (params == "nsheikerenhashviis.org") window.location.href = "/nshei";
    else if (params == "www.nsheikerenhashviis.org") {
      window.location.href = "/nshei";
    }
  },
  created() {
    const params = window.location.hostname;
    if (params == "nsheikerenhashviis.org") window.location.href = "/nshei";
    else if (params == "www.nsheikerenhashviis.org") {
      window.location.href = "/nshei";
    }
    AOS.init({
      once: true,
    });
    this.getCampaigns();
  },

  methods: {
    async sendForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await axios
          .post(`/api/v1/contact-us`, {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            message: this.message,
          })
          .then((response) => {
            if (response.status === 201) {
              this.successResponse = true;
              this.firstname = "";
              this.lastname = "";
              this.email = "";
              this.message = "";
              this.$refs.form.resetValidation();
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    async getCampaigns() {
      await axios.get(`/api/v1/campaign`).then((response) => {
        this.campaigns = response.data.filter((item) => {
          if (item.path === "/img/logo.png") {
            item.path = "/img/IndexNotImage.svg";
          }
          return true;
        });
      });
    },
  },
  data() {
    const snackbar = {
      color: "",
      message: "",
      status: false,
    };
    return {
      snackbar,
      valid: false,
      successResponse: false,
      loading: false,
      drawer: false,
      year: new Date().getFullYear(),
      campaigns: [],
      firstname: "",
      lastname: "",
      email: "",
      message: "",
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none !important;
}

a:active {
  color: red !important;
  font-weight: bold !important;
}

.text-h5-index {
  font-size: 1.5rem !important;
  font-weight: 400;
  letter-spacing: normal !important;
}

.text-h6-index {
  font-weight: 500;
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
}

.text-h4-index {
  line-height: 2.5rem;
  font-size: 2.125rem !important;
  letter-spacing: 0.00735294em !important;
}

.text-caption-index {
  font-size: 0.75rem;
  letter-spacing: 0.03em;
}

.landing-top-image {
  background-image: url("/img/IndexHeader.png");
  background-size: cover;
  background-position: top;
  height: 550px;
}

.description {
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-landing-top {
  font-weight: bold !important;
  font-size: 8vh !important;
  line-height: 3.75rem !important;
  letter-spacing: -0.0083333333em !important;
}

.index-scroll-bar {
  overflow-y: hidden;
}

.map-bottom-image {
  background-image: url("/img/IndexMap.png");
  background-size: cover;
  background-position: top;
  height: 300px;
}
</style>
