<template>
  <div class="mx-2">
    <div class="d-flex justify-center influencer-area overflow-scroll-y">
      <div
        v-if="items"
        id="products-items"
        class="
          py-2
          px-1
          d-flex
          products-items
          flex-wrap
          overflow-scroll-y
          justifyCenter
        "
      >
        <div v-for="(item, i) in items" :key="i" class="mx-4 my-4">
          <div
            @click="setItem(item)"
            class="pointer"
            :class="{
              cursorDisabled:
                item.quantity != null && item.sold == item.quantity,
            }"
          >
            <div class="text-center ch-shadow-light item-card px-2 py-4">
              <img
                v-if="item.path"
                :src="item.path"
                alt="Image"
                class="imageOfItem"
              />
              <div class="goal_item">
                <span> {{ symbol }}</span>
                <span
                  style="font-style: normal; font-weight: bold; font-size: 22px"
                  >{{ Math.round(item.goal * excange) }}</span
                >
              </div>
              <div v-if="item && item.name" class="description_item">
                <span v-if="item.name">{{ item.name }}</span>
              </div>
              <div class="grey-text">
                <div v-if="item.quantity != null && item.sold == item.quantity">
                  SOLD
                </div>
                <div v-else-if="item.quantity != null" class="grey-text">
                  Available {{ item.quantity - item.sold }} /
                  {{ item.quantity }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
      excange: 1,
      symbol: "$",
    };
  },
  computed: mapState(["info", "currency_donate"]),
  watch: {
    currency_donate(newVal, oldVal) {
      let index = this.info.currencies_enabled.findIndex(
        (element) => element.id == newVal
      );

      this.symbol = this.info.currencies_enabled[index].symbol;
      this.excange = this.info.currencies_enabled[index].value;
    },
    info() {},
  },
  mounted() {
    this.getItems();
    this.symbol = this.info.principal_currency.symbol;
  },

  methods: {
    prev() {
      let a = document.getElementById("products-items").scrollLeft;
      a -= 320;
      document
        .getElementById("products-items")
        .scroll({ left: a, behavior: "smooth" });
    },

    next() {
      let a = document.getElementById("products-items").scrollLeft;
      a += 320;
      document
        .getElementById("products-items")
        .scroll({ left: a, behavior: "smooth" });
    },

    setItem(item) {
      if (item.quantity != null && item.sold == item.quantity) return;
      this.$store.commit("UPDATE_DONATION_ITEM", item.id);
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },

    getItems() {
      let params = window.location.pathname.slice(1).split("/");
      axios.get(`/api/v1/campaign/${params[0]}/item`).then((res) => {
        this.items = res.data;
        this.$store.commit("UPDATE_ITEMS_DONATION", res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// Estilos para el titulo del carrusel (Los estaba tomanddo de MatchersCarrouselComponent)
// para no pisar los estilos, lo he agregado aqui tambien. y asi poder utilizar el modificador scoped en matchers
// hay de definir si se va a usar una hoja de estilos globales para casos como este o no.

.productWhitoutImage {
  bottom: 40px !important;
}

.description_item {
  white-space: none;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 7px;
  color: rgba(58, 58, 58, 1);
}

.goal_item {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(58, 58, 58, 1);
  margin: 0 auto;
}

.imageOfItem {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  border-color: var(--charity-primary);
  margin: 0 auto;
  margin-top: 10px;
  display: block;
}

.items-coarrousel-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;

  .img-container {
    width: 50px;
    height: auto;
    margin-bottom: 32px;
  }

  h4 {
    font-weight: bold;
  }
}

.pink-text {
  color: #be2c83;
}

.black-text {
  color: #000;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.item-card {
  width: 220px;
  min-height: 125px;
  border-radius: 8px;
}
.font-title-item {
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  min-height: 35px;
}
.font-amount-item {
  font-size: 32px;
  font-weight: bold;
}
.grey-text {
  color: rgba(0, 0, 0, 0.5);
}
.icon-carousel {
  background-color: rgba(0, 0, 0, 0.35) !important;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  visibility: hidden;
}
.font-xs {
  font-size: 0.8rem;
}
.poster-influ {
  width: 230px;
  height: 122px;
  border: 1px solid #cdcdcd;
}
.influencer-area:hover .icon-prev {
  -moz-animation-name: left-to-right;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: left-to-right;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.influencer-area:hover .icon-next {
  -moz-animation-name: right-to-left;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: right-to-left;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
@-moz-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}
@-webkit-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}
@-moz-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}
@-webkit-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}
.icon-next {
  right: 5px;
}
.justifyCenter {
  justify-content: center;
}
</style>
