<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="modalCreateTeam"
        :retain-focus="false"
        class="description-area"
        max-width="800px"
        persistent
        style="z-index: 99999"
      >
        <v-card
          :class="{ 'text-direction': lang_init }"
          :style="styles"
          class="description-area"
        >
          <v-card-title
            :style="styles"
            class="
              px-4
              ch-font-title
              size-title
              text-white
              ch-primary
              font-weight-medium
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>
              {{ $t("create_my_team") }}
            </div>
            <div class="m-neg">
              <v-btn icon small @click="createMyTeam">
                <v-icon color="white" small>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text class="mt-4">
            {{
              $t(
                "Fill_out_the_form_below_to_create_your_own_personal_fundraising_page"
              )
            }}
            <p>
              {{
                $t(
                  "The_goal_of_becoming_a_team_is_to_invite_others_to_join_us_Thank_you_for_agreeing_to_make_an_effort"
                )
              }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <div></div>
                <v-row>
                  <!-- <v-col cols="12" md="6">
                                                        <div v-if="urlBanner" class="d-flex justify-center">
                                                          <div class="mb-4">
                                                            <img
                                                              class="rounded"
                                                              width="100%"
                                                              height="100px"
                                                              contain
                                                              v-if="urlBanner"
                                                              :src="urlBanner"
                                                            />
                                                          </div>
                                                        </div>
                                                      </v-col>-->
                  <v-col class="py-0" cols="12" md="12">
                    <div class="d-flex jstify-space-between align-center">
                      <div style="width: 48%">
                        <v-file-input
                          v-model="$v.image.$model"
                          :error-messages="imgErrors"
                          :label="$t('team_image')"
                          accept="image/*"
                          background-color="#f1f1f1"
                          color="#323232"
                          dense
                          messages="We recommend 150px x 150px images"
                          outlined
                          prepend-icon="mdi-cloud-upload-outline"
                          @blur="$v.image.$touch()"
                          @change="onFileChange"
                          @input="$v.image.$touch()"
                        >
                        </v-file-input>
                      </div>
                      <div
                        v-if="urlImage"
                        class="d-flex justify-center"
                        style="width: 55%"
                      >
                        <v-avatar class="mb-4" size="100">
                          <img
                            v-if="urlImage"
                            :src="urlImage"
                            class="rounded"
                            contain
                            width="100px"
                          />
                        </v-avatar>
                      </div>
                    </div>
                  </v-col>

                  <!-- <v-col cols="12" md="6">
                                                        <v-file-input
                                                          v-model="banner"
                                                          :label="$t('banner_image')"
                                                          outlined
                                                          dense
                                                          background-color="#F9F5F1"
                                                          color="#A4A4A4"
                                                          @change="onBannerChange"
                                                        >
                                                        </v-file-input>
                                                      </v-col> -->
                </v-row>
              </v-col>

              <v-col class="pb-0" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.name.$model"
                  :error-messages="nameErrors"
                  :label="$t('team_name')"
                  background-color="#f1f1f1"
                  colasdor="#323232"
                  dense
                  outlined
                  prepend-icon="mdi-account-group-outline"
                  type="text"
                  @blur="$v.item.name.$touch()"
                  @input="$v.item.name.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="pb-0" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.email.$model"
                  :error-messages="emailErrors"
                  :label="$t('owner_team_email')"
                  :readonly="readonly"
                  :rules="[rules.email]"
                  background-color="#f1f1f1"
                  color="#323232"
                  dense
                  outlined
                  prepend-icon="mdi-email-outline"
                  type="email"
                  @blur="$v.item.email.$touch()"
                  @input="$v.item.email.$touch()"
                >
                </v-text-field>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <div class="d-flex">
                  <div class="mr-2 mt-2">
                    <v-icon> mdi-phone</v-icon>
                  </div>
                  <div>
                    <vue-country-code
                      :preferredCountries="['us', 'gb', 'ca']"
                      style="
                        height: 40px;
                        border-color: #aaa;
                        background-color: #f1f1f1;
                      "
                      @onSelect="onSelect"
                    >
                    </vue-country-code>
                  </div>
                  <div class="pl-2" style="width: 100%">
                    <v-text-field
                      v-model="$v.item.phone.$model"
                      :error-messages="phoneErrors"
                      :label="$t('phone')"
                      background-color="#f1f1f1"
                      color="#323232"
                      dense
                      outlined
                      type="number"
                      @blur="$v.item.phone.$touch()"
                      @input="$v.item.phone.$touch()"
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-col>
              <!-- <v-col cols="12"  md="6" class="py-0">
                                            <v-text-field
                                              v-model="$v.item.owner.$model"
                                              :error-messages="ownerErrors"
                                              @input="$v.item.owner.$touch()"
                                              @blur="$v.item.owner.$touch()"
                                              :label="$t('owner_team_name')"
                                              prepend-icon="mdi-account-outline"
                                              type="text"
                                              :readonly="readonly"
                                              outlined
                                              dense
                                              background-color="#f1f1f1"
                                              color="#323232"
                                            >
                                            </v-text-field>
                                          </v-col> -->
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model="item.goal"
                  :error-messages="goalErrors"
                  :label="$t('goal')"
                  background-color="#f1f1f1"
                  color="#323232"
                  dense
                  min="0"
                  outlined
                  prepend-icon="mdi-trophy-outline"
                  type="number"
                  @blur="$v.item.goal.$touch()"
                  @input="$v.item.goal.$touch()"
                >
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12"  md="6" class="py-0">
                                            <div class="d-flex">
                                              <div style="width: 30%">
                                                <v-text-field
                                                  v-model="item.match"
                                                  :label="$t('match')"
                                                  type="number"
                                                  outlined
                                                  dense
                                                  prepend-icon="mdi-close"
                                                  background-color="#F9F5F1"
                                                  color="#A4A4A4"
                                                >
                                                </v-text-field>
                                              </div>
                                              <div style="width: 70%">
                                                <v-text-field
                                                  v-model="item.match_limit"
                                                  :label="$t('match_limit')"
                                                  type="number"
                                                  outlined
                                                  dense
                                                  :messages="$t('match_limit_donation')"
                                                  background-color="#F9F5F1"
                                                  prepend-inner-icon="mdi-currency-usd"
                                                  color="#A4A4A4"
                                                >
                                                </v-text-field>
                                              </div>
                                            </div>
                                          </v-col> -->

              <v-col class="py-0" cols="12" md="12">
                <v-textarea
                  v-model="$v.item.description.$model"
                  :error-messages="descriptionErrors"
                  :label="$t('description')"
                  background-color="#f1f1f1"
                  color="#323232"
                  counter="550"
                  dense
                  outlined
                  prepend-icon="mdi-book-open-variant"
                  @blur="$v.item.description.$touch()"
                  @input="$v.item.description.$touch()"
                >
                </v-textarea>
              </v-col>
              <v-col class="py-0" cols="12" md="12">
                <v-text-field
                  v-model="item.message_response"
                  :error-messages="message_responseErrors"
                  :label="$t('message_response')"
                  background-color="#f1f1f1"
                  color="#323232"
                  counter="500"
                  dense
                  outlined
                  prepend-icon="mdi-book-open-variant"
                  @blur="$v.item.message_response.$touch()"
                  @input="$v.item.message_response.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model="item.video"
                  :label="$t('video')"
                  :messages="$t('video_message')"
                  background-color="#f1f1f1"
                  color="#323232"
                  dense
                  outlined
                  prepend-icon="mdi-youtube"
                  type="text"
                >
                </v-text-field>
              </v-col>

              <v-col v-if="show_item" class="py-0" cols="12" md="6">
                <iframe
                  :src="item.video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  frameborder="0"
                  height="200"
                  title="YouTube video player"
                  width="100%"
                ></iframe>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-if="error_donation != ''" class="mx-6 pb-1">
            <v-alert
              border="left"
              class="mx-8"
              dense
              outlined
              style="width: 90%"
              type="error"
            >
              <div class="new-line">{{ error_donation }}</div>
            </v-alert>
          </div>
          <div class="py-0">
            <v-alert
              v-model="snackbar"
              :color="color"
              :timeout="15000"
              class="py-2 mx-5 w-100"
              style="z-index: 90002"
            >
              <div class="d-flex white--text justify-space-between">
                <span>{{ text }}</span>

                <v-btn dark elevation="0" icon small @click="snackbar = false">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-alert>

            <div
              class="
                mx-6
                my-2
                pb-6
                d-flex
                flex-column flex-md-row
                justify-center
                align-end
              "
            >
              <v-btn
                :loading="loading"
                :style="styles"
                class="
                  ch-color-gradient
                  btn-text
                  rounded-lg
                  font-weight-medium
                  white--text
                "
                elevation="0"
                height="45px"
                width="340px"
                @click="sendForm"
                >{{ $t("create_my_team") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="modalCongratulations" max-width="550" persistent>
        <v-card>
          <div class="float-right">
            <v-btn icon @click="closeCongratulations()">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-title class="teal--text darken-4 headline font-weight-bold">
            {{ $t("_Congratulations") }}
          </v-card-title>
          <v-card-text class="pt-4 subtitle-1">
            <p>
              {{ $t("_thanks_message_team_created") }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="closeCongratulations()"
              >{{ $t("_close") | uppercase }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  props: {
    styles: {
      default: null,
    },
  },

  data() {
    return {
      rules: {
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      show_item: false,
      item: {
        name: "",
        owner: "",
        country_code: "",
        phone: "",
        email: "",
        goal: 0,
        match: "",
        match_limit: "",
        slug: "",
        video: "",
        match: "",
        message_response: "",
        description: `Hi there!
Join my team and make your donation so that we reach the goal!
Thank you!`,
      },
      image: [],
      banner: [],
      communities: null,
      modalCongratulations: false,
      snackbar: true,
      readonly: false,
      loading: false,
      lang_init: false,
      dir_lang: "ltr",
      urlImage: "",
      urlBanner: "",
      url: "",
      color: "",
      text: "",
      error_donation: "",
    };
  },
  async created() {
    this.url = window.location.origin;
    // axios.get("/whoiam").then((res) => {
    //   this.item.owner = res.data.name;
    //   this.item.email = res.data.email;
    //   this.readonly = true;
    // });
    // const data = await axios.get("/api/v1/campaign/${campaign}/community/name");
    // this.communities = data.data;
  },
  mounted() {
    this.langDirection();
  },
  computed: {
    modalCreateTeam() {
      return this.$store.state.modalCreateTeam;
    },
    imgErrors() {
      const errors = [];
      if (this.image != null && this.image.size > 2642685) {
        errors.push("The image must have a maximum of 2.5mb.");
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.item.name.$dirty) return errors;
      !this.$v.item.name.maxLength &&
        errors.push("Must be a maximum of 100 characters.");
      !this.$v.item.name.required && errors.push("Required.");
      return errors;
    },
    ownerErrors() {
      const errors = [];
      if (!this.$v.item.owner.$dirty) return errors;
      !this.$v.item.owner.maxLength &&
        errors.push("Must be a maximum of 100 characters.");
      !this.$v.item.owner.required && errors.push("Required.");
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.item.slug.$dirty) return errors;
      !this.$v.item.slug.maxLength &&
        errors.push("Must be a maximum of 100 characters.");
      !this.$v.item.slug.required && errors.push("Required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.item.email.$dirty) return errors;
      !this.$v.item.email.maxLength &&
        errors.push("Must be a maximum of 190 characters.");
      !this.$v.item.email.required && errors.push("Required.");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.item.description.$dirty) return errors;
      !this.$v.item.description.maxLength &&
        errors.push("Must be a maximum of 550 characters.");
      !this.$v.item.description.required && errors.push("Required.");

      return errors;
    },
    goalErrors() {
      const errors = [];
      if (!this.$v.item.goal.$dirty) return errors;
      !this.$v.item.goal.required && errors.push("Required.");
      if (this.item.goal == 0) {
        errors.push("The team goal value must be minimum of 1");
      }

      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.item.phone.$dirty) return errors;
      !this.$v.item.phone.required && errors.push("Required.");
      return errors;
    },
    message_responseErrors() {
      const errors = [];
      if (!this.$v.item.message_response.$dirty) return errors;
      !this.$v.item.message_response.maxLength &&
        errors.push("Must be a maximum of 500 characters.");
      return errors;
    },
  },
  watch: {
    modalCreateTeam() {
      this.langDirection();
    },
    "item.name": function () {
      const replaceWith = "-";
      const searchRegExp = " ";
      this.item.slug = this.item.name.split(searchRegExp).join(replaceWith);
    },
    "item.video": function (newVal, oldVal) {
      if (
        newVal.includes("https://www.youtube.com") ||
        newVal.includes("https://youtube.com")
      ) {
        this.item.video = this.item.video.replace("watch?v=", "embed/");
        this.show_item = true;
      } else if (
        newVal.includes("https://www.vimeo.com") ||
        newVal.includes("https://vimeo.com") ||
        newVal.includes("https://player.vimeo.com")
      ) {
        this.item.video = this.item.video.replace(
          "https://vimeo.com",
          "https://player.vimeo.com/video"
        );
        this.item.video = this.item.video.replace(
          "https://www.vimeo.com",
          "https://player.vimeo.com/video"
        );
        this.show_item = true;
      } else {
        this.show_item = false;
      }
    },
  },
  methods: {
    langDirection() {
      const lang_init = localStorage.getItem("charity-locale");
      if (!lang_init || lang_init == "en") {
        this.lang_init = false;
        this.dir_lang = "ltr";
      } else if (lang_init == "he") {
        this.lang_init = true;
        this.dir_lang = "rtl";
      }
    },
    onSelect({ name, iso2, dialCode }) {
      this.item.country_code = dialCode;
    },

    onFileChange() {
      if (this.image != null) this.urlImage = URL.createObjectURL(this.image);
      else this.urlImage = "";
    },
    onBannerChange() {
      this.urlBanner = URL.createObjectURL(this.banner);
    },
    createMyTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
    },
    removeCommunity(item) {
      const index = this.item.community.indexOf(item.id);
      if (index >= 0) this.item.community.splice(index, 1);
    },
    copyTeamPage() {
      // let el = document.querySelector("#testing-code");
      // el.select();

      // document.execCommand("copy");

      const url = window.location.origin;
      const el = document.querySelector("#slug");
      el.setAttribute("type", "text");

      el.value = `${url}/?team=${this.item.slug}`;
      el.select();
      document.execCommand("copy");
      el.setAttribute("type", "hidden");
      this.text = "Copied!";
      this.color = "success";
      this.snackbar = true;
    },
    cancelEdition() {
      this.$v.$reset();
      this.item.name = "";
      this.item.owner = "";
      this.item.phone = "";
      this.item.email = "";
      this.item.goal = 1;
      this.item.description = `Hi there!
              Join my team and make your donation so that we reach the goal!
              Thank you!`;
      this.item.message_response = "";
      this.item.match = "";
      this.item.match_limit = "";
      this.item.video = "";
      this.image = [];
      this.banner = [];
      this.urlImage = "";
      this.urlBanner = "";
    },
    closeCongratulations() {
      this.modalCongratulations = false;
      this.createMyTeam();
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async sendForm() {
      this.snackbar = false;

      this.$v.item.$touch();

      let auxError = false;
      this.error_donation = "";

      if (this.image != null && this.image.size > 2642685) {
        this.error_donation += "• The Image must have a maximum of 2.5mb\n";
        auxError = true;
      }

      if (this.item.name == "") {
        this.error_donation += "• Please complete the Full Team Name\n";
        auxError = true;
      }

      if (!this.validateEmail(this.item.email)) {
        this.error_donation += "• The email is not valid\n";
        auxError = true;
      }

      if (this.item.phone == "") {
        this.error_donation += "• Please complete the Phone\n";
        auxError = true;
      }

      if (
        this.item.goal === "" ||
        this.item.goal == null ||
        this.item.goal == 0
      ) {
        this.error_donation += "• The team goal value must be minimum of 1\n";
        auxError = true;
      }

      if ((this.$v.item.$pending || this.$v.item.$error) && !auxError) {
        this.error_donation += "• Please check the form before continuing\n";
        auxError = true;
      }

      if (auxError) {
        return;
      }

      this.$v.$reset();
      this.loading = true;

      const params = new FormData();
      params.append("files", this.image);
      // params.append("banner", this.banner);
      params.append("name", this.item.name);
      // params.append("owner", this.item.owner);
      params.append("phone", this.item.phone);
      params.append("email", this.item.email);
      params.append("goal", this.item.goal);
      params.append("match", this.item.match);
      params.append("match_limit", this.item.match_limit);
      params.append("message_response", this.item.message_response);
      params.append("country_code", this.item.country_code);
      params.append("description", this.item.description);
      params.append("video", this.item.video);
      const campaign_id = window.location.pathname.slice(1).split("/");

      axios
        .post(`/campaign/${campaign_id[0]}/team`, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.cancelEdition();
          this.modalCongratulations = true;
        })
        .catch((error) => {
          // console.log(error.response);
          this.snackbar = true;
          this.color = "error";
          this.text = error.response.data.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  validations: {
    image: {},
    item: {
      name: {
        required,
        maxLength: maxLength(100),
      },
      // owner: {
      //   required,
      //   maxLength: maxLength(100),
      // },
      slug: {
        required,
        maxLength: maxLength(100),
      },
      phone: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(550),
      },
      goal: {
        required,
      },
      message_response: {
        maxLength: maxLength(500),
      },
      email: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
};
</script>

<style scoped>
.description-area {
  overflow: scroll;
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 80vh;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 3px !important;
}

::-webkit-scrollbar:vertical {
  display: block;
}

::-webkit-scrollbar-thumb {
  display: block;
  background: var(--charity-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block;
  background: var(--charity-primary);
}

.btn-text {
  font-size: 20px !important;
}

.m-neg {
  padding-top: -10px;
  padding-left: -10px;
}

.text-direction {
  direction: rtl !important;
}

.new-line {
  white-space: pre;
}
</style>
