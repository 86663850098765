<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="modalDonationCompleted" max-width="900">
        <v-card>
          <div class="float-right">
            <v-btn icon @click="closeModalDonationCompleted()"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </div>
          <div class="p-4 teal--text darken-4 headline font-weight-bold">
            Congratulations, Your Donation Has Been Approved!
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closeModalDonationCompleted()"
              >CLOSE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: mapState(["modalDonationCompleted"]),
  methods: {
    closeModalDonationCompleted() {
      let params = window.location.pathname.slice(1).split("/");
      let a = window.location;
      window.location.href = `http://${a.host}/${params[0]}`;

      this.$store.commit("TOGGLE_MODAL_DONATION_COMPLETE", false);
    },
  },
};
</script>

<style>
</style>
