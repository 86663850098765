<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="modalDonationError" max-width="900">
        <v-card>
          <div class="float-right">
            <v-btn icon @click="closemodalDonationError()"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </div>
          <v-card-title class="red--text darken-4 headline font-weight-bold">
            Error!
          </v-card-title>
          <v-card-text class="pt-4 subtitle-1">
            <p>
              There was an error processing the donation! Please try again in a
              few minutes
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closemodalDonationError()"
              >CLOSE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: mapState(["modalDonationError"]),
  methods: {
    closemodalDonationError() {
      let params = window.location.pathname.slice(1).split("/");
      let a = window.location;
      window.location.href = `http://${a.host}/${params[0]}`;
      this.$store.commit("TOGGLE_MODAL_DONATION_ERROR", false);
    },
  },
};
</script>

<style>
</style>
