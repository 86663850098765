<template>
  <div>
    <div class="bg-white toolbar">
      <div class="d-flex justify-space-between align-center px-4">
        <div class="logo">
          <v-img
            width=""
            contain
            height="60px"
            src="img/NsheiKH-LogoMobileColor.svg"
          ></v-img>
        </div>
        <div class="">
          <v-btn class="menu-responsivo" icon @click="openMenu()">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="menu-nav" v-if="menuOpen">
        <div class="menu-items">
          <div class="d-flex flex-column justify-end align-end nav">
            <!-- <div
              class="mr-10 text-kerem pointer font-weight-bold"
              @click="openTeam()"
            >
              {{ $t("create_my_team") }}
            </div> -->
            <div class="mr-10 text-kerem pointer font-weight-bold">
              <a href="https://kerenhashviis.org/" target="_blank">
                Go To Main Keren Hashviis Website
              </a>
            </div>
            <!-- <div
              class="
                mr-10
                mt-5
                blue-grey--text
                darken-3
                font-weight-bold
                pointer
              "
              @click="goToAbout()"
            >
              {{ $t("about_this") }}
            </div>
            <div
              class="
                mr-10
                mt-5
                blue-grey--text
                darken-3
                font-weight-bold
                pointer
              "
              @click="openDonation()"
            >
              {{ $t("donation") }}
            </div> -->
            <!-- <div class="mr-10 mt-5 blue-grey--text font-weight-bold darken-3">
              <v-btn
                style="text-decoration: none"
                href="/admin"
                icon
                color="blue-grey darken-3 "
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
              <v-btn
                style="text-decoration: none"
                icon
                color="blue-grey darken-3"
                @click="selectLang()"
              >
                <v-icon>mdi-earth</v-icon>
              </v-btn>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      items: [],
      menuOpen: false,
      items_buscador: [],
      ver_buscador: false,
      fav: true,
      changeCurrencyModal: false,
      menu_hogar: false,
      menu_higiene: false,
      menu_ropa: false,
      message: false,
      hints: true,
      categories: true,
    };
  },

  methods: {
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
    changeDrawer(id) {
      this.drawer = id;
    },
    selectLang() {
      this.$i18n.locale == "en"
        ? (this.$i18n.locale = "es")
        : (this.$i18n.locale = "en");

      localStorage.setItem("charidy-locale", this.$i18n.locale);
    },

    openTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
    },
    goToAbout() {
      this.$store.commit("UPDATE_SECTION_PAGE", "about");

      document
        .getElementById("filterSection")
        .scrollIntoView({ behavior: "smooth" });
    },
    openDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
  },
};
</script>

<style>
.toolbar {
  background-color: white;
  height: 70px;
  width: 100vw;
  position: fixed;
  z-index: 100;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
.menu-nav {
  width: 100vw;
  background-color: white;
}
.logo {
  margin-top: 10px;
  height: 55px;
  width: 90%;
}
</style>