<template>
  <div :style="`${campaign}`" class="scroll-bar">
    <div id="theNav" class="">
      <TheNavbar :isIntersectingElement="isIntersectingElement" />
    </div>

    <div class="body-base mx-auto">
      <TheBanner />
      <div class="information">
        <ContactInfo :styles="`${campaign}`" />
      </div>
      <div class="information">
        <InfoSite :styles="`${campaign}`" />
      </div>
      <div class="">
        <div id="filterSection" class="w-full">
          <div class="">
            <HeaderSiteComponent />
          </div>
        </div>
      </div>
      <div>
        <DonationFormCreate :styles="`${campaign}`" />
        <ModalDonationCompleted />
      </div>
      <div>
        <ModalDonationError />
        <ModalDonationDuplicated />
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import TheNavbar from "../../components/frontend/TheNavbarComponent.vue";
import HeaderSiteComponent from "../../components/frontend/WebSite/HeaderSiteComponent";
import TheBanner from "../../components/frontend/TheBannerComponent.vue";
import InfoSite from "../../components/frontend/WebSite/InfoSiteComponent.vue";
import ContactInfo from "../../components/frontend/WebSite/ContactInfoComponent.vue";
import Footer from "../../components/frontend/FooterComponent.vue";
import TheBannerMobile from "../../components/frontend/TheBannerMobileComponent.vue";
import ModalDonationCompleted from "../../components/frontend/ModalDonationCompletedComponent.vue";
import ModalDonationError from "../../components/frontend/ModalDonationErrorComponent.vue";
import ModalDonationDuplicated from "../../components/frontend/ModalDonationDuplicatedComponent.vue";
import DonationFormCreate from "../../components/frontend/Donation/FormCreateComponent.vue";
import { mapState } from "vuex";

export default {
  computed: mapState(["modalCreateTeam", "message", "info", "itemsDonation"]),

  components: {
    TheNavbar,
    HeaderSiteComponent,
    IntersectionObserver,
    TheBanner,
    ContactInfo,
    DonationFormCreate,
    ModalDonationCompleted,
    InfoSite,
    ModalDonationDuplicated,
    ModalDonationError,
    TheBannerMobile,
    Footer,
  },

  metaInfo() {
    return {
      title: `${this.name} - Charity Live`,

      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: `Campaign ${this.name} - Charity.Live`,
        },
        {
          vmid: "description",
          name: "description",
          content: this.description,
        },

        {
          name: "description",
          content: this.description,
        },

        {
          vmid: "description",
          property: "description",
          content: this.description,
        },
      ],
    };
  },

  data() {
    return {
      isIntersectingElement: true,
      fireworks: false,
      name: "",
      description: "",
      campaign: `
        --charity-primary:  #cdcdcd;
        --charity-secondary:  #fafafa;
        --charity-third:  #000000;
        --charity-color-text:  #e3e3e3;
        --charity-color-alternative:  #ccc;
        --charity-color-title:  #ccc;
      `,
    };
  },
  watch: {
    info() {
      // console.log();
      if (this.info) {
        let style = JSON.parse(this.info.style);
        this.name = this.info.name;
        this.description = this.info.excerpt;
        if (style.pickerPrimary) {
          this.campaign = `--charity-primary:  ${style.pickerPrimary.hexa};
                        --charity-secondary:  ${style.pickerSecondary.hexa};
                        --charity-third:  ${style.pickerTertiary.hexa};
                        --charity-color-text:  ${style.pickerPrimary.hexa};
                        --charity-color-alternative:  ${style.pickerSecondary.hexa};
                        --charity-color-title:  ${style.pickerPrimary.hexa};
                        `;
        }

        let endDate = this.info.end;
        var now = new Date();
        var endFireworks = new Date(this.info.end);
        endFireworks.setDate(endFireworks.getDate() + 2);
        endDate = new Date(endDate);
        if (endDate < now && endFireworks > now) {
          if (this.info.donations_amount >= this.info.goal) {
            this.fireworks = true;
          }
          // selected date is in the past
        }

        // console.log(this.campaign);
      }
    },
  },
  created() {
    let params = window.location.pathname.slice(1).split("/");

    this.$store.dispatch("FETCH_CAMPAIGN_INFO", params[1]);
    this.$store.dispatch("FETCH_CAMPAIGN_MEDIA", params[1]);
    // this.$store.dispatch("UPDATE_SEARCH", params);
  },
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    onIntersectionElement(value) {
      this.isIntersectingElement = value;
    },
  },
};
</script>
<style>
@font-face {
  font-family: Nunito-Black;
  src: url("/fonts/Nunito-Black.ttf");
}

@media (max-width: 600px) {
  .information {
    margin-top: 30px;
  }
}

.body-base {
  background-color: white !important;
  max-width: 1100px;
}

.banner {
  padding-top: 125px;
}

.npinfo {
  padding-top: 340px;
  height: 70vh;
}

@media (min-width: 960px) and (max-width: 1020px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (max-width: 600px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

.ch-text-primary {
  color: var(--charity-primary) !important;
}

.ch-text-secondary {
  color: var(--charity-secondary) !important;
}

.ch-text-third {
  color: var(--charity-third) !important;
}

.ch-primary {
  background-color: var(--charity-primary) !important;
}

.ch-secondary {
  background-color: var(--charity-secondary) !important;
}

.ch-third {
  background-color: var(--charity-third) !important;
}

.ch-light {
  background-color: var(--background) !important;
}

.ch-text-color {
  color: var(--charity-color-text) !important;
}

.ch-title-color {
  color: var(--charity-color-title) !important;
}

.ch-font-primary {
  font-family: var(--charity-font-primary) !important;
}

.ch-font-secondary {
  font-family: var(--charity-font-secondary) !important;
}

.ch-font-third {
  font-family: var(--charity-font-title) !important;
}

.ch-font-title {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.ch-color-gradient {
  background: var(--charity-primary);
  background: linear-gradient(
    90deg,
    var(--charity-primary) 0%,
    var(--charity-third) 100%
  );
}

.ch-color-gradient-inverse {
  background: var(--charity-third);
  background: linear-gradient(
    90deg,
    var(--charity-third) 0%,
    var(--charity-primary) 100%
  );
}

.ch-font-btn {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}
</style>
