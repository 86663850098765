<template>
    <div>
        <div
            v-if="(video && video.includes('youtube')) || video.includes('vimeo')"
            :style="{ width: width, height: height }"
            class="
                video
                black
                rounded-lg
                ch-shadow
            "
        >
            <template v-if="video">
                <iframe
                    v-if="video.includes('youtube') || video.includes('vimeo')"
                    :src="video"
                    :style="{ width: width, height: height }"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="rounded-lg video"
                    frameborder="0"
                    title="YouTube video player"
                ></iframe>
            </template>
        </div>
        <div v-else-if="video">
            <video id="myVideo" controls :height="height" :width="width">
                <source :src="video" type="video/mp4"/>
            </video>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    width: {
      default: '100%',
    },
    height: {
      default: '100%',
    },
    video: {
      default: null,
    },
  },
  computed: {},
};
</script>

<style>
/* .video {
  width: 99%;
  height: 400px;
} */
</style>
