<template>
  <div class="mt-6">
    <div class="d-flex justify-space-between">
      <div v-if="info && info.contact_info" class="d-flex">
        <div v-for="(cont, index) in info.contact_info" :key="index">
          <div class="mx-2">
            <v-tooltip bottom>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-btn
                  class="ch-color-gradient"
                  x-large
                  v-bind="attrs"
                  v-on="on"
                  icon
                  outlined
                  @click="goToLink(cont)"
                >
                  <v-icon large class="ch-text-secondary">{{
                    cont.icon
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{ cont.value }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div>
        <div>
          <v-btn
            class="
              ch-color-gradient
              nav-text
              rounded-lg
              white--text
              buttonDonate
            "
            min-width="300px"
            style="font-size: 22px"
            @click="makeDonation"
            elevation="0"
            height="45px"
            >{{ $t("donate") }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    goToLink(item) {
      switch (item.name) {
        case "Email":
          window.location.href = `message:${item.value}`;
          break;

        case "Phone":
          window.location.href = `tel:${item.value}`;
          break;

        case "Address":
          break;

        default:
          window.location.href = `${item.value}`;
          break;
      }
    },
  },
};
</script>

<style>
</style>