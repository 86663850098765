var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-wrap justify-space-between align-center contentCharity"},[(_vm.info && _vm.info.donations_amount && _vm.info.is_goal_hidden == 0)?_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"clocks"},[_c('Goal')],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"timer_card"},[_c('div',{staticClass:"ch-shadow border-gray rounded-lg py-2 px-1 cardCampaign"},[_c('v-container',[(_vm.info && _vm.info.name)?_c('div',{attrs:{"id":"item_text"}},[_c('span',[_vm._v(_vm._s(_vm.info.name)+" ")])]):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"carousel-influencer influencer-area overflow-scroll-y"},[(_vm.items)?_c('div',{staticClass:"\n                py-2\n                my-3\n                d-flex\n                carousel-items\n                flex-nowrap\n                overflow-scroll-y\n              ",class:{ justifyCenter: _vm.items.length < 4 },attrs:{"id":"carousel-items"}},[_vm._l((_vm.items),function(i){return _c('div',{key:i.id,staticClass:"rounded card_person pointer",class:{ primaryBorder: i.active },attrs:{"id":i.id}},[(i.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{class:{
                        cursorDisabled:
                          i.quantity != null && i.sold == i.quantity,
                      },on:{"click":function($event){return _vm.setProduct(i)}}},[_c('div',_vm._g(_vm._b({staticStyle:{"position":"relative"}},'div',attrs,false),on),[(i && i.path)?_c('img',{staticClass:"image_item",attrs:{"src":i.path,"alt":"Image"}}):_vm._e(),_vm._v(" "),(
                            _vm.info &&
                            _vm.info.slug != 'YOY' &&
                            _vm.info.slug != 'yoy' &&
                            _vm.info.id != 8
                          )?_c('div',{staticClass:"raised_person"},[_c('span',[_vm._v(" "+_vm._s(_vm.currency))]),_vm._v(" "),_c('span',{staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v(_vm._s(Math.round(i.goal * _vm.item.currency_exchange)))])]):_vm._e(),_vm._v(" "),(i && i.name)?_c('div',{staticClass:"text_of_raised"},[(i.name)?_c('span',[_vm._v(_vm._s(i.name))]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grey-text mx-auto text-center"},[(i.quantity != null && i.sold == i.quantity)?_c('div',[_vm._v("\n                            "+_vm._s(_vm._f("uppercase")(_vm.$t("_sold")))+"\n                          ")]):(i.quantity != null)?_c('div',{staticClass:"grey-text"},[_vm._v("\n                            "+_vm._s(i.sold)+" / "+_vm._s(i.quantity)+"\n                          ")]):_vm._e()])])])]}}],null,true)},[_vm._v(" "),(i.description)?_c('div',{staticClass:"text-center tooltip-custom"},[_vm._v("\n                    "+_vm._s(i.description)+"\n                  ")]):_vm._e()]):_c('div',{class:{
                    cursorDisabled:
                      i.quantity != null && i.sold == i.quantity,
                  },on:{"click":function($event){return _vm.setProduct(i)}}},[(i && i.path)?_c('img',{staticClass:"image_item",attrs:{"src":i.path,"alt":"Image"}}):_vm._e(),_vm._v(" "),(
                      _vm.info &&
                      _vm.info.slug != 'YOY' &&
                      _vm.info.slug != 'yoy' &&
                      _vm.info.id != 8
                    )?_c('div',{staticClass:"raised_person"},[_c('span',[_vm._v(" "+_vm._s(_vm.currency))]),_vm._v(" "),_c('span',{staticStyle:{"font-style":"normal","font-weight":"bold","font-size":"16px"}},[_vm._v(_vm._s(Math.round(i.goal * _vm.item.currency_exchange)))])]):_vm._e(),_vm._v(" "),(i && i.name)?_c('div',{staticClass:"text_of_raised"},[(i.name)?_c('span',[_vm._v(_vm._s(i.name))]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grey-text mx-auto text-center"},[(i.quantity != null && i.sold == i.quantity)?_c('div',[_vm._v("\n                      "+_vm._s(_vm._f("uppercase")(_vm.$t("_sold")))+"\n                    ")]):(i.quantity != null)?_c('div',{staticClass:"grey-text"},[_vm._v("\n                      "+_vm._s(i.sold)+" / "+_vm._s(i.quantity)+"\n                    ")]):_vm._e()])])],1)}),_vm._v(" "),(_vm.items[0] && _vm.items.length > 3)?_c('v-btn',{staticClass:"icon-carousel icon-prev",class:{ itemWhitoutImage: _vm.items[0].path == null },attrs:{"fab":"","small":""},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),_vm._v(" "),(_vm.items[0] && _vm.items.length > 3)?_c('v-btn',{staticClass:"icon-carousel icon-next",class:{ itemWhitoutImage: _vm.items[0].path == null },attrs:{"fab":"","small":""},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"color":"white","large":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],2):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"contentDonationData"},[_c('div',{attrs:{"id":"currency"}},[_c('v-select',{staticClass:"py-2 mt-1",attrs:{"items":_vm.currencies,"item-text":"name","item-value":"id","outlined":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.symbol)+" - "+_vm._s(item.name)+"\n              ")]}}],null,true),model:{value:(_vm.item.currency),callback:function ($$v) {_vm.$set(_vm.item, "currency", $$v)},expression:"item.currency"}})],1),_vm._v(" "),_c('div',{attrs:{"id":"donation_form"}},[_c('v-text-field',{staticClass:"py-2 mt-1",staticStyle:{"font-size":"20px","font-family":"Nunito !important","font-weight":"bold"},attrs:{"type":"number","outlined":"","hide-details":"","placeholder":"0"},on:{"keypress":_vm.generateMinimuError,"change":function($event){return _vm.validateDonation()}},model:{value:(_vm.item.amount),callback:function ($$v) {_vm.$set(_vm.item, "amount", $$v)},expression:"item.amount"}}),_vm._v(" "),(
                _vm.info &&
                _vm.info.minimum_donation &&
                _vm.item.amount < _vm.info.minimum_donation * _vm.item.currency_exchange
              )?_c('p',{staticStyle:{"color":"#c10404","position":"absolute"}},[_vm._v("\n              "+_vm._s(_vm.minimum_donation_error)+"\n            ")]):_vm._e()],1)]),_vm._v(" "),(
            _vm.info &&
            _vm.info.global_matcher &&
            _vm.info.global_matcher > 0 &&
            _vm.info.available_matcher > 0
          )?_c('div',{attrs:{"id":"sponsors_text"}},[_c('span',[_vm._v(_vm._s(_vm.$t("_Generous_Donors_Will_Match_Your_Donation")))])]):_vm._e(),_vm._v(" "),_c('div',[(
              _vm.info &&
              _vm.info.global_matcher &&
              _vm.info.global_matcher > 0 &&
              _vm.info.available_matcher > 0
            )?_c('v-row',[_c('div',{staticClass:"d-flex justify-space-around w-full donationForm"},[_c('div',{staticStyle:{"margin-left":"50px"}},[_c('div',{staticClass:"youdonate"},[_c('span',[_vm._v(_vm._s(_vm.$t("_You_donate")))])]),_vm._v(" "),_c('div',{staticClass:"donate_number"},[_vm._v("\n                  "+_vm._s(_vm.currency)+" "+_vm._s(_vm.item.amount * 1)+"\n                ")])]),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('v-avatar',{staticStyle:{"background-color":"var(--charity-primary)"},attrs:{"size":"50"}},[(
                      _vm.info &&
                      _vm.info.global_matcher > 1 &&
                      _vm.info.available_matcher > 0
                    )?_c('span',{staticClass:"white--text circleGlobalMatcher"},[_vm._v("x"+_vm._s(_vm.info.global_matcher))]):_vm._e()])],1),_vm._v(" "),_c('div',{staticStyle:{"margin-right":"50px"}},[_c('div',{staticClass:"youdonate"},[_c('span',[_vm._v(_vm._s(_vm.$t("_We_Receive")))])]),_vm._v(" "),_c('div',{staticClass:"donate_number"},[_vm._v("\n                  "+_vm._s(_vm.currency)+" "+_vm._s(Math.round(_vm.amount_after_match))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n                  "+_vm._s(_vm.match_message)+"\n                ")])])])]):_vm._e()],1),_vm._v(" "),_c('div',[_c('v-btn',{staticClass:"\n              ch-color-gradient\n              nav-text\n              rounded-lg\n              white--text\n              buttonDonate\n            ",staticStyle:{"font-size":"22px"},attrs:{"width":"100%","elevation":"0","height":"45px"},on:{"click":_vm.makeDonation}},[_vm._v(_vm._s(_vm.$t("donate")))])],1)])],1),_vm._v(" "),(_vm.info && _vm.info.donations_amount && _vm.info.is_goal_hidden == 1)?_c('div',{staticClass:"py-6 pb-8",attrs:{"id":"raised"}},[_c('div',[_vm._v("\n        "+_vm._s(_vm._f("uppercase")(_vm.$t("_raised")))+"\n      ")]),_vm._v("\n\n      "+_vm._s(_vm.info.principal_currency.symbol)+"\n      "+_vm._s(_vm._f("dollar")(_vm.info.donations_amount))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"clocks",class:{
      hidden:
        _vm.info &&
        _vm.info.is_clock_hidden == 1 &&
        _vm.info.is_countdown_trophy_image_hidden == 1 &&
        _vm.info.is_countdown_welldone_image_hidden == 1,
    }},[_c('CountDown')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }