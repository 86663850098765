<template>
  <div :style="styles" class="mt-4">
    <div
      class="heading ch-color-gradient text-white size-title p-4"
      v-if="info && info.name"
    >
      {{ info.name }}
    </div>
    <div v-if="info && info.excerpt" class="rounded info_component py-2">
      <div
        class="ql-editor info_text"
        v-if="!readMoreActivated"
        v-html="info.excerpt"
      ></div>
      <div class="text-center">
        <!-- <span @click="goToAbout" class="font-weight-bold pointer py-1">
        {{ $t("_read_more") }}
      </span> -->
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },

  props: {
    styles: {
      default: null,
    },
  },

  name: "info",
  data() {
    return {
      readMoreActivated: false,
    };
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    learnMore() {
      this.readMoreActivated = true;
    },
    goToAbout() {
      this.$store.commit(
        "UPDATE_SECTION_PAGE",
        this.info.tabs.filter((item) => item.lang == this.$i18n.locale)[0].title
      );

      document
        .getElementById("filterSection")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
@media (max-width: 700px) {
  .info_text {
    vertical-align: middle;
    width: 100%;
  }
}
@media (min-width: 700px) {
  .info_text {
    vertical-align: middle;
    width: 100%;
  }
}
.size-title {
  font-size: 22px;
}

.info_component {
  background-color: #f6f6f8;
  width: 100%;
  line-height: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(119, 119, 119, 1);
}
</style>
