<template>
  <div class="navBar mt-2" style="min-height: auto">
    <!-- <div v-if="teampage" class="">You are in a team page</div> -->

    <!-- CAROUSEL VIDEOS -->

    <div v-if="influencers && info && info.position_carousel == 1">
      <v-row class="pb-8">
        <v-col cols="12">
          <div class="influencer-area overflow-scroll-y">
            <div
              v-if="influencers"
              id="carousel-influencer"
              class="
                d-flex
                carousel-influencer
                flex-nowrap
                overflow-scroll-y
                justifyCenter
              "
            >
              <div v-for="(item, index) in influencers" :key="index">
                <div class="class2">
                  <iframe
                    :src="item.path"
                    style="width: 103%"
                    title="YouTube video player"
                    frameborder="0"
                    class="rounded-lg"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div class="m-2 mr-6 grey--text font-xs text-center">
                  {{ item.name | truncate_100 }}
                </div>
              </div>
            </div>
            <v-btn fab small class="icon-carousel-left icon-prev" @click="prev">
              <v-icon large color="white">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              class="icon-carousel-right icon-next"
              @click="next"
            >
              <v-icon color="white" large>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- <div v-if="influencers">
        <div class="scroll">
            <div v-for="(item, index) in influencers" :key="index" md="4" cols="12" class="scrolling-wrapper">
                <div class="scroll-horizontal justify-space-around">
                    <div class="class2 elevation-1">
                        <iframe
                            :src="item.path"
                            style="width: 103%;"
                            title="YouTube video player"
                            frameborder="0"
                            class="rounded-lg"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <div class="m-2 mr-6 grey--text font-xs text-center">
                        {{ item.name | truncate_100 }}
                    </div>
                </div>

            </div>
        </div>
    </div> -->

    <!-- /CAROUSEL VIDEOS -->

    <div
      v-if="info && !startIsBiggerThanNow"
      class="d-flex menu-area flex-wrap justify-center menu-area mb-8"
    >
      <div
        v-if="info.type_campaign == 2"
        :class="{
          'ch-color-gradient': page == 'supporters',
          buttonSecondary: page != 'supporters',
          buttonPrimary: page == 'supporters',
        }"
        class="menu-item"
        @click="changeMenu('supporters')"
      >
        <div class="textButton">
          {{ this.info.supporters }}
          {{ $t("Have_Joined") | uppercase }}
        </div>
      </div>
      <div
        v-if="info.type_campaign != 2"
        :class="{
          'ch-color-gradient': page == 'donors',
          buttonSecondary: page != 'donors',
          buttonPrimary: page == 'donors',
        }"
        class="menu-item"
        @click="changeMenu('donors')"
      >
        <div class="textButton">
          {{ this.info.show_number_donors ? allDonors : "" }}
          {{ $t("_DONORS") }}
        </div>
      </div>
      <div
        :class="{
          'ch-color-gradient': page == 'team',
          buttonSecondary: page != 'team',
          buttonPrimary: page == 'team',
        }"
        class="menu-item"
        @click="changeMenu('team')"
        v-if="info && info.is_teams_hidden == 0"
      >
        <div class="textButton">
          {{ this.info.show_number_teams ? teams : "" }} {{ $t("TEAMS") }}
        </div>
      </div>
      <div
        :class="{
          'ch-color-gradient': page == 'communities',
          buttonSecondary: page != 'communities',
          buttonPrimary: page == 'communities',
        }"
        class="menu-item"
        @click="changeMenu('communities')"
        v-if="info && info.type_campaign == 2"
      >
        <div class="textButton">
          {{ $t("COMMUNITIES") }}
        </div>
      </div>

      <div
        v-if="itemsDonation && itemsDonation.length > 0"
        :class="{
          'ch-color-gradient': page == 'products',
          buttonSecondary: page != 'products',
          buttonPrimary: page == 'products',
        }"
        class="menu-item"
        @click="changeMenu('products')"
      >
        <div class="textButton">{{ $t("_PRODUCTS") }}</div>
      </div>
      <div
        v-if="influencers.length > 0 && info && info.position_carousel == 0"
        :class="{
          'ch-color-gradient': page == 'influencers',
          buttonSecondary: page != 'influencers',
          buttonPrimary: page == 'influencers',
        }"
        class="menu-item"
        @click="changeMenu('influencers')"
      >
        <div class="textButton">{{ $t("_INFLUENCERS") }}</div>
      </div>
      <div
        v-if="matchers && matchers.length > 0 && info.is_match_hidden == 0"
        :class="{
          'ch-color-gradient': page == 'matchers',
          buttonSecondary: page != 'matchers',
          buttonPrimary: page == 'matchers',
        }"
        class="menu-item"
        @click="changeMenu('matchers')"
      >
        <div class="textButton">{{ $t("_MATCHERS") }}</div>
      </div>
      <template v-if="info && info.tabs">
        <template v-for="(tab, key) in info.tabs">
          <div
            v-if="tab.title && tab.lang == $i18n.locale"
            :key="key"
            :class="{
              'ch-color-gradient': page == tab.title,
              buttonSecondary: page != tab.title,
              buttonPrimary: page == tab.title,
            }"
            class="menu-item"
            @click="changeMenu(tab.title)"
          >
            <div class="textButton">{{ tab.title | uppercase }}</div>
          </div>
        </template>
      </template>
    </div>
    <div
      v-else
      class="d-flex menu-area flex-wrap justify-center menu-area mb-8"
    >
      <div
        :class="{
          'ch-color-gradient': page == 'donors',
          buttonSecondary: page != 'donors',
          buttonPrimary: page == 'donors',
        }"
        class="menu-item"
        @click="changeMenu('donors')"
        v-if="
          !startIsBiggerThanNow ||
          (startIsBiggerThanNow &&
            info &&
            info.show_donor_tab_before_campaign_starts == 1)
        "
      >
        <div class="textButton">{{ $t("_DONORS") }}</div>
      </div>
      <div
        :class="{
          'ch-color-gradient': page == 'team',
          buttonSecondary: page != 'team',
          buttonPrimary: page == 'team',
        }"
        class="menu-item"
        @click="changeMenu('team')"
        v-if="
          (!startIsBiggerThanNow && info && info.is_teams_hidden == 0) ||
          (startIsBiggerThanNow &&
            info.show_teams_tab_before_campaign_starts == 1)
        "
      >
        <div class="textButton">
          {{ this.info.show_number_teams ? teams : "" }} {{ $t("TEAMS") }}
        </div>
      </div>
      <template v-if="info && info.tabs">
        <template v-for="(tab, key) in info.tabs">
          <div
            v-if="tab.title && tab.lang == $i18n.locale"
            :key="key"
            :class="{
              'ch-color-gradient': page == tab.title,
              buttonSecondary: page != tab.title,
              buttonPrimary: page == tab.title,
            }"
            class="menu-item"
            @click="changeMenu(tab.title)"
          >
            <div class="textButton">{{ tab.title | uppercase }}</div>
          </div>
        </template>
      </template>
    </div>
    <v-row v-if="page == 'supporters'" class="mx-3 pb-4">
      <v-col cols="12" md="8">
        <div class="text-kerem-dark d-flex justify-start align-center">
          <div class="searchbar">
            <v-text-field
              v-model="search"
              :label="search_title"
              background-color="#F6F6F8"
              class="pl-0 pr-4 py-0"
              color="#323232"
              flat
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
            >
            </v-text-field>
          </div>
          <div
            :class="{ select: orderByFinder == 'Latest' }"
            class="ml-4 nav-item-search"
            @click="changeOrder('Latest')"
          >
            {{ $t("latest") }}
          </div>
          <div
            :class="{ select: orderByFinder == 'Oldest' }"
            class="ml-4 nav-item-search"
            @click="changeOrder('Oldest')"
          >
            {{ $t("Oldest") }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="page == 'donors' || page == 'team'" class="mx-3 pb-0">
      <v-col cols="12" md="12" v-if="info && info.is_donorcards_hidden == 0">
        <div
          class="text-kerem-dark d-flex justify-start align-center flex-wrap"
        >
          <div class="team-name my-1 mx-2" v-if="team && this.page == 'donors'">
            <v-text-field
              :value="`TEAM: ${team.name}`"
              readonly
              outlined
              dense
              hide-details
              class="min-w-200px"
              append-icon="mdi-close-circle-outline"
              @click:append="goBackToMainPage()"
            >
            </v-text-field>
          </div>
          <div class="searchbar">
            <v-text-field
              v-model="search"
              :label="search_title"
              background-color="#F6F6F8"
              class="pl-0 pr-4 py-0"
              color="#323232"
              flat
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              v-if="
                (!startIsBiggerThanNow && page == 'donors') ||
                (startIsBiggerThanNow &&
                  page == 'donors' &&
                  info.show_donor_tab_before_campaign_starts == 1) ||
                page == 'team'
              "
            >
            </v-text-field>
          </div>

          <div
            v-if="
              (!startIsBiggerThanNow && page != 'team') ||
              (startIsBiggerThanNow &&
                page != 'team' &&
                info.show_donor_tab_before_campaign_starts == 1)
            "
            :class="{ select: orderByFinder == 'Latest' }"
            class="ml-4 nav-item-search"
            @click="changeOrder('Latest')"
          >
            {{ $t("latest") }}
          </div>
          <div
            v-if="
              !startIsBiggerThanNow ||
              (startIsBiggerThanNow &&
                info.show_donor_tab_before_campaign_starts == 1) ||
              (startIsBiggerThanNow &&
                page == 'team' &&
                info.show_teams_tab_before_campaign_starts == 1)
            "
            :class="{ select: orderByFinder == 'Highest' }"
            class="ml-4 nav-item-search"
            @click="changeOrder('Highest')"
          >
            {{ $t("highest") }}
          </div>
          <div
            v-if="page == 'team'"
            :class="{ select: orderByFinder == 'Donnors' }"
            class="ml-4 nav-item-search"
            @click="changeOrder('Donnors')"
          >
            {{ $t("donors") }}
          </div>
          <div v-if="page == 'team'" class="mt-1 ml-auto">
            <v-btn
              class="
                ch-border-secondary
                rounded-lg
                font-weight-medium
                ch-text-secondary
                btn-title
              "
              color=""
              elevation="0"
              height="44px"
              outlined
              width="200px"
              @click="createMyTeam()"
              >{{ $t("create_my_team") }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- ProgressBar & RaisedGoal & Donate Button -->
    <v-row v-if="page == 'donors'" class="mb-0 pb-0">
      <v-col cols="12" md="8">
        <div class="pt-8">
          <div
            class="d-flex align-end justify-start justify-sm-center- flex-wrap"
            :class="team.goal > 0 ? 'justify-space-around' : null"
            v-if="info && info.principal_currency.symbol && team"
          >
            <div class="text-h5 font-weight-black">
              <span class="raisedText pl-3">Raised </span>
              {{ info.principal_currency.symbol }} {{ team.raised | dollar }}
            </div>
            <div v-if="team.goal > 0" class="text-h5 font-weight-black">
              <span class="raisedText">Goal </span>
              {{ info.principal_currency.symbol }} {{ team.goal | dollar }}
            </div>
          </div>
          <div
            class="d-flex justify-space-around px-4"
            v-if="team && team.goal > 0"
          >
            <div class="grey lighten-2 progress-bar my-4">
              <template v-if="team.percent >= 80">
                <div
                  :style="{ width: team.percent + '%' }"
                  class="progress-bar-complete d-inline-flex"
                ></div>
              </template>
              <template v-if="team.percent < 80 && team.percent >= 40">
                <div
                  :style="{ width: team.percent + '%' }"
                  class="progress-bar-intermediate d-inline-flex"
                ></div>
              </template>
              <template v-if="team.percent < 40">
                <div
                  :style="{ width: team.percent + '%' }"
                  class="progress-bar-start d-inline-flex"
                ></div>
              </template>
            </div>
            <div
              class="
                d-flex
                align-center
                text-h5
                font-weight-bold
                deep-purple--text
              "
            >
              {{ team.percent.toFixed() | dollar }}%
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center">
        <div class="" v-if="team">
          <div
            class="
              text-h5
              font-weight-black
              d-flex
              justify-center justify-md-end
              pb-2
            "
          >
            <span class="raisedText">Donors&nbsp;</span> {{ team.donors }}
          </div>
          <v-btn
            x-large
            color="success"
            dark
            class="
              ch-color-gradient
              rounded-lg
              font-weight-medium
              white--text
              text-h5
              px-16
            "
            height="45px"
            @click="makeDonation()"
          >
            DONATE
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- /ProgressBar & RaisedGoal & Donate Button -->
    <!-- Using utilities: -->
    <v-row class="mx-1">
      <v-col v-if="page == 'supporters'" cols="12" md="12">
        <template v-if="!startIsBiggerThanNow">
          <TeamSupportersCard />
        </template>
      </v-col>
      <v-col v-if="page == 'donors'" cols="12" md="12">
        <template
          v-if="
            (!startIsBiggerThanNow &&
              !team &&
              info &&
              info.is_donorcards_hidden == 0) ||
            (startIsBiggerThanNow &&
              info.show_donor_tab_before_campaign_starts == 1 &&
              info.is_donorcards_hidden == 0)
          "
        >
          <TeamDonorsCard />
        </template>
        <template v-if="!team && info && info.is_donorcards_hidden == 1">
          <div class="d-flex justify-center">
            <v-alert
              border="left"
              class="mx-8"
              dense
              outlined
              style="width: auto"
              type="info"
            >
              Donors' cards have been hidden by the organization
            </v-alert>
          </div>
        </template>
        <template v-if="team">
          <TeamMembersCard :team="team.id" />
        </template>
      </v-col>
      <v-col v-if="page == 'team'" cols="12" md="12">
        <TeamTeamsFeaturedCard />
        <TeamTeamsCard />
      </v-col>
      <v-col v-if="page == 'communities'" cols="12" md="12">
        <CommunityCommunityCard />
      </v-col>
      <template v-if="info && info.tabs">
        <v-col v-for="(tab, key) in info.tabs" :key="key" cols="12" md="12">
          <v-row v-if="info && page == tab.title" class="mb-10">
            <v-col>
              <div class="rounded-lg ch-shadow">
                <div
                  class="mx-6 px-5 py-10 align-center about-ch ch-font-primary"
                >
                  <div class="tabs ql-editor" v-html="tab.content"></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <v-col v-if="page == 'about'" cols="12" md="12">
        <TeamAboutCard />
      </v-col>
      <v-col v-if="page == 'products'" cols="12" md="12">
        <Products />
      </v-col>
      <v-col v-if="page == 'matchers'" cols="12" md="12" class="mt-0 pt-0">
        <matchers></matchers>
      </v-col>
      <v-col v-if="page == 'influencers'" cols="12" md="12">
        <SectionVideoComponent></SectionVideoComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";

import influencers from "./influencers";
import TeamSupportersCard from "../frontend/Team/SuppertersCardComponent.vue";
import TeamDonorsCard from "../frontend/Team/DonorsCardComponent.vue";
import TeamMembersCard from "../frontend/Team/MembersCardComponent.vue";
import CommunityCommunityCard from "../frontend/Community/CommunityCardComponent.vue";
import TeamTeamsFeaturedCard from "../frontend/Team/TeamsFeaturedCardComponent.vue";
import TeamTeamsCard from "../frontend/Team/TeamsCardComponent.vue";
import TeamAboutCard from "../frontend/Team/AboutCardComponent.vue";
import Products from "./Products";
import Matchers from "../frontv2/Matchers";
import SectionVideoComponent from "../frontend/SectionVideoComponent";

export default {
  props: {
    teampage: {
      default: false,
    },
  },

  components: {
    influencers,
    Matchers,
    Products,
    TeamDonorsCard,
    TeamMembersCard,
    CommunityCommunityCard,
    TeamTeamsFeaturedCard,
    TeamTeamsCard,
    SectionVideoComponent,
    TeamAboutCard,
    VueEditor,
    TeamSupportersCard,
  },
  data() {
    return {
      activeTab: null,
      search: null,
      description: "",
      search_title: this.$t("search_donors"),
      matchers: [],
      startIsBiggerThanNow: false,
      influencers: [],
      allDonors: 0,
      teams: 0,
      video: null,
      poster: null,
    };
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Charity Live",
      },
    ],
  },
  watch: {
    search() {
      this.updateSearch();
    },
    donors_team() {
      let params = window.location.pathname.slice(1).split("/");
      if (params.length == 2) {
        this.allDonors = this.donors_team;
      }
    },
    "info.donors": function () {
      this.getAllDonors();
    },

    info() {
      var starDate = new Date(this.info.start.replace(/ /g, "T"));
      var countDown = starDate.getTime();
      var now = new Date();
      let params = window.location.pathname.slice(1).split("/");
      if (params.length == 1) {
        this.allDonors = this.info.donors;
      }
      this.getTeams();

      if (starDate > now) {
        // selected date is in the past
        this.startIsBiggerThanNow = true;
      } else {
        this.startIsBiggerThanNow = false;
      }
      if (this.info.type_campaign == 2) {
        this.changeMenu("supporters");
      }
    },
  },

  computed: mapState([
    "donors_team",
    "info",
    "orderByFinder",
    "page",
    "team",
    "itemsDonation",
    "logo",
  ]),
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION", true);
    },
    goBackToMainPage() {
      window.location.href = `/${this.info.slug}`;
    },
    toVideo(url, poster) {
      this.video = null;
      this.poster = null;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.video = url;
        this.poster = poster;
      }, 1000);
    },
    prev() {
      let a = document.getElementById("carousel-influencer").scrollLeft;
      a -= 320;
      document
        .getElementById("carousel-influencer")
        .scroll({ left: a, behavior: "smooth" });
    },
    next() {
      let a = document.getElementById("carousel-influencer").scrollLeft;
      a += 320;
      document
        .getElementById("carousel-influencer")
        .scroll({ left: a, behavior: "smooth" });
    },
    getItems() {
      let campaign = window.location.pathname.slice(1).split("/");

      axios.get(`/api/v1/campaign/${campaign[0]}/matcher`).then((res) => {
        this.$store.commit("UPDATE_MATCHERS", res.data);
        this.matchers = res.data;
      });
    },
    getAllDonors() {
      let params = window.location.pathname.slice(1).split("/");

      if (params.length == 2) {
        this.allDonors = this.donors_team;
      } else {
        if (this.info && this.info.donors) this.allDonors = this.info.donors;
      }
    },
    getTeams() {
      if (this.info && this.info.slug)
        axios.get(`/api/v1/campaign/${this.info.slug}/team/all`).then((res) => {
          this.teams = res.data.length;
        });
    },
    updateSearch() {
      this.$store.commit("UPDATE_SEARCH", this.search);
    },
    createMyTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
    },
    changeMenu(id) {
      this.activeTab = id;
      this.$store.commit("UPDATE_SECTION_PAGE", id);
      this.search = null;
      if (id == "team")
        this.search_title = this.$t("search") + " " + this.$t("teams");
      else if (id == "supporters")
        this.search_title = this.$t("search") + " " + this.$t("supporter");
      else this.search_title = this.$t("search") + " " + this.$t("donors");
    },
    changeOrder(id) {
      this.$store.commit("UPDATE_ORDER_FINDER", id);
    },
  },
  created() {
    let params = window.location.pathname.slice(1).split("/");
    if (params.length == 2) {
      this.allDonors = this.donors_team;
    } else {
      if (this.info && this.info.donors) this.allDonors = this.info.donors;
    }

    axios.get(`/api/v1/campaign/${params[0]}/influencer`).then((res) => {
      this.influencers = res.data;
    });
    // this.video = this.influencers[0].path;
    // this.poster = this.influencers[0].cover;
  },
  mounted() {
    this.getItems();
    this.getTeams();
  },
};
</script>

<style scoped>
.tabs {
  font-size: 1.3rem;
}

.navBar {
  padding-top: 25px;
  padding-bottom: 40px;
}

.topifIsBigger {
  margin-top: 100px;
}

.influencer-area {
  position: relative;
}

.menu-item {
  white-space: nowrap;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  height: 40px;
  margin-top: 4px;
}

@media (min-width: 700px) {
  .menu-area {
    justify-content: center;
  }
}

.justifyCenter {
  justify-content: center;
}

/* .btn {
  @apply font-bold py-2 px-4 rounded;
} */

/* .btn-blue {
  @apply bg-blue-500 text-white;
} */

/* .btn-blue:hover {
  @apply bg-blue-700;
} */

.textButton {
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Roboto;
  font-size: 18px;
  align-items: center;
  display: flex;
}

.buttonSecondary {
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  display: flex;
  z-index: 10;
}

.buttonPrimary {
  font-weight: bold;
  color: white;
  display: flex;
}

.select {
  font-weight: 800;
}

.searchbar {
  max-width: 300px;
  width: 50%;
  min-width: 200px;
}

.team-name {
  width: 30%;
  min-width: 300px;
  margin-right: 10px;
}

.active {
  font-weight: 800;
  /* padding-bottom: 10px; */
  border-bottom: 3px solid var(--charity-secondary);
}

.nav-item {
  font-size: 18px;
  cursor: pointer;
}

.nav-item-search {
  font-size: 16px;
  cursor: pointer;
}

.nav-item:hover {
  font-weight: 800;
}

.item-menu {
  position: relative;
  padding-bottom: 7px;
  margin-right: 35px;
  white-space: nowrap;
  text-align: center;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 10px solid var(--charity-secondary);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: -10px;
  left: calc(50% - 5px);
  visibility: hidden;
}

.active .triangle {
  visibility: visible;
}

.btn-title {
  font-size: 16px;
  font-weight: 800px;
}

.scroll {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
}

.scrolling-wrapper {
  display: inline-block !important;
}

.scroll-horizontal {
  display: inline-block !important;
}

.class2 {
  width: 283px;
  padding-right: 25px;
  background-color: white;
  justify-content: space-around;
  /* display: inline-block; */
}

.icon-carousel {
  background-color: rgba(0, 0, 0, 0.35) !important;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  /* visibility: hidden; */
}
.icon-carousel-left {
  background: linear-gradient(
    90deg,
    var(--charity-primary) 0%,
    var(--charity-third) 100%
  );
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  left: 0;
  visibility: hidden;
}
.icon-carousel-right {
  background: linear-gradient(
    90deg,
    var(--charity-primary) 0%,
    var(--charity-third) 100%
  );
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  right: 0;
  visibility: hidden;
}

.influencer-area:hover .icon-prev {
  animation-name: left-to-right;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  -webkit-animation-name: left-to-right;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.influencer-area:hover .icon-next {
  animation-name: right-to-left;
  animation-timing-function: ease-out;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  -webkit-animation-name: right-to-left;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.raisedText {
  font-weight: normal;
  color: #a4a4a4;
  font-size: 20px;
  font-family: "Roboto";
}
.progress-bar {
  width: 90%;
  height: 10px;
  border-radius: 10px;
  position: relative;
}
.progress-bar-complete {
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #00bb3d 33%,
    #00b343 87%,
    #00ae4f 100%
  );
}
.progress-bar-intermediate {
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f28800 33%,
    #ea6f00 87%,
    #e75900 100%
  );
}
.progress-bar-start {
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f0000b 33%,
    #e50017 87%,
    #df0023 100%
  );
}
.min-w-200px {
  min-width: 200px;
}
@media (max-width: 600px) {
  .justify-sm-center- {
    justify-content: center !important;
  }
}
</style>
