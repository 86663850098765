import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../language/en.json";
import es from "../language/es.json";
import he from "../language/he.json";

Vue.use(VueI18n);

const messages = {
    en: en,
    es: es,
    he: he,
};

let lang_init = localStorage.getItem("charity-locale");
if (!lang_init) lang_init = "en";

const i18n = new VueI18n({
    messages,
    locale: lang_init,
});

export default i18n;
