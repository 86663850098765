<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color="color"
      :timeout="15000"
      right
      style="z-index: 10001"
      top
    >
      <span>{{ text }} </span>

      <div class="d-flex justify-space-between">
        <v-btn dark elevation="0" icon small @click="snackbar = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog
        v-model="modalDonation"
        :fullscreen="fullscreen"
        :retain-focus="false"
        class="description-area"
        max-width="900px"
        persistent
        style="z-index: 99999"
      >
        <v-card
          v-if="loading_form"
          :style="styles"
          class="relative description-area"
        >
          <div
            :style="styles"
            class="
              px-6
              py-5
              ch-font-title
              size-title
              text-white
              ch-primary
              font-weight-medium
              d-flex
              align-center
              justify-space-between
            "
          >
            <div>
              {{ $t("title_donation_form") }}
            </div>
            <div>
              <v-btn class="m-neg" icon small @click="makeDonation">
                <v-icon class="font-weight-bold" color="white" small
                  >mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex justify-center" style="margin: 200px 50px">
            <v-progress-circular
              :size="50"
              color="ch-primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>

        <v-card
          v-else
          :class="{ 'text-direction': lang_init }"
          :disabled="loading_form"
          :loading="loading_form"
          :style="styles"
          class="relative description-area"
        >
          <div
            :style="styles"
            class="
              px-6
              py-5
              ch-font-title
              size-title
              text-white
              ch-primary
              font-weight-medium
              d-flex
              align-center
              justify-space-between
              sticky-toolbar
            "
            style="z-index: 9999"
          >
            <div>
              {{ $t("title_donation_form") }}
            </div>
            <div>
              <v-btn class="m-neg" icon small @click="makeDonation">
                <v-icon class="font-weight-bold" color="white" small>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>

          <!-- <div class="my-4 mx-8 pb-0">
                                <span class="font-weight-bold subtitle-1 text-blue">
                                  {{ $t("Would_you_like_to_donate_a_product?") }}</span
                                >
                              </div> -->
          <div class="relative">
            <div
              v-if="itemsDonation"
              class="carousel-influencer bg-white influencer-area"
              style="overflow-y: hidden"
            >
              <div
                id="carousel-items-form"
                :class="{ justifyCenter: itemsDonation.length < 4 }"
                class="py-2 my-3 d-flex carousel-items-form flex-nowrap"
                style="overflow-y: hidden"
              >
                <div
                  v-for="(it, i) in itemsDonation"
                  :key="i"
                  class="mx-4 pointer"
                >
                  <div
                    :class="{
                      cursorDisabled:
                        it.quantity != null && it.sold == it.quantity,
                    }"
                    @click="changeIdSelected(it)"
                  >
                    <div
                      class="text-center ch-shadow-light item-card px-2 py-4"
                      style="position: relative"
                    >
                      <img
                        v-if="it.path"
                        :src="it.path"
                        alt="Image"
                        class="imageOfItem"
                      />
                      <div class="goal_item">
                        <span> {{ currency }}</span>
                        <span
                          style="
                            font-style: normal;
                            font-weight: bold;
                            font-size: 22px;
                          "
                          >{{
                            Math.round(it.goal * item.currency_exchange)
                          }}</span
                        >
                      </div>
                      <div v-if="item && it.name" class="description_item">
                        <span v-if="it.name">{{ it.name }}</span>
                      </div>
                      <div class="grey-text">
                        <div
                          v-if="it.quantity != null && it.sold == it.quantity"
                        >
                          {{ $t("_sold") | uppercase }}
                        </div>
                        <div v-else-if="it.quantity != null" class="grey-text">
                          {{ $t("_available") }} {{ it.quantity - it.sold }} /
                          {{ it.quantity }}
                        </div>
                      </div>
                      <div v-if="it.id === idItemSelected" class="icon-item">
                        <v-icon color="success" x-large>
                          mdi-check-circle
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <v-btn
                v-if="
                  itemsDonation && itemsDonation[0] && itemsDonation.length > 3
                "
                :class="{ productWhitoutImage: itemsDonation[0].path == null }"
                class="icon-carousel icon-prev"
                fab
                small
                @click="prev"
              >
                <v-icon color="white" large>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  itemsDonation && itemsDonation[0] && itemsDonation.length > 3
                "
                :class="{ productWhitoutImage: itemsDonation[0].path == null }"
                class="icon-carousel icon-next"
                fab
                small
                @click="next"
              >
                <v-icon color="white" large>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
          <!--if team is select -->
          <div
            :style="styles"
            class="mx-8 my-5 pb-2 px-2 pt-2"
            style="min-height: 220px"
          >
            <v-row class="">
              <v-col
                class="rounded-lg"
                cols="12"
                md="6"
                style="
                  min-height: 220px;
                  background-color: #f1f1f1;
                  border: 1px solid rgb(170, 170, 170);
                "
              >
                <div class="bg-white rounded card-amount mx-4">
                  <div
                    class="
                      text-center
                      ch-text-primary
                      font-weight-bold
                      text-uppercase
                      ch-amount-title
                      pr-4
                    "
                  >
                    {{ $t("Your_amount") }}
                  </div>
                  <div
                    class="
                      d-flex
                      justify-space-around
                      align-end
                      inputs-amount-area
                      mx-2
                    "
                  >
                    <div class="pb-1 select-iso_name">
                      <v-select
                        v-model="item.currency"
                        :items="currencies"
                        background-color="#F1F1F1"
                        color="#F1F1F1"
                        dense
                        hide-details
                        item-text="name"
                        item-value="id"
                        outlined
                      >
                        <template v-slot:[`item`]="{ item }">
                          {{ item.symbol }} - {{ item.name }}
                        </template>
                      </v-select>
                    </div>
                    <div class="input-amount">
                      <input
                        v-model="item.amount"
                        :readonly="!distributeInTeamsActive"
                        max="8"
                        oninput="this.value = this.value.replace(/[^0-9]/g, '');
                                this.value = this.value.replace(/(\..*)\./g, '$1');
                                if (this.value.length > 8) this.value = this.value.slice(0, 8);"
                        placeholder="0"
                        type="tel"
                        @change="validateDonation()"
                        @keypress="generateMinimuError"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      item.amount < min * item.currency_exchange &&
                      minimum_donation_error != ''
                    "
                    class=""
                    style="position: absolute; top: 32px; right: 10px"
                  >
                    <div
                      class="mx-6 error white--text rounded border px-8 py-2"
                    >
                      <div>
                        {{ minimum_donation_error }}

                        <!-- Minimum Donation
                                                                        {{ currency }}
                                                                        {{ Math.round(min * item.currency_exchange) }} -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mx-4 my-2">
                  <select
                    v-model="item.installment"
                    background-color="#F1F1F1"
                    class="select-installment white"
                    color="#FEF0F0"
                    v-if="info && info.installments_quantity"
                  >
                    <template v-for="i in info.installments_quantity">
                      <option :key="i" :value="i">
                        {{ $t("_In") }} {{ i }} {{ $t("_installments") }}
                        {{ currency }}
                        {{ (item.amount / i).toFixed(2) }}
                      </option>
                    </template>
                  </select>
                  <div class="amount-installment body-1">
                    {{ $t("_Total_amount") }}: {{ currency }} {{ item.amount }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div
                  class="d-flex flex-column justify-center align-center"
                  style="height: 100%"
                >
                  <div
                    v-if="info"
                    class="text-center ch-title-amount-recive ch-font-third"
                  >
                    {{ info.business_name }}
                  </div>
                  <div class="text-center ch-title-amount-recive ch-font-third">
                    {{ $t("will_recive") }}
                  </div>
                  <!-- TODO: MATCHERS -->
                  <div class="text-center ch-text-primary display-1">
                    <span class="font-weight-bold">
                      <div
                        v-if="
                          info &&
                          info.available_matcher > 0 &&
                          info.global_matcher > 0
                        "
                      >
                        <div class="match d-flex flex-column mb-2 mx-auto">
                          <div>
                            x
                            {{ info.global_matcher }}
                          </div>
                          <div class="tinitext">{{ $t("_by_match") }}</div>
                        </div>
                        <div class="body-2">
                          {{ match_message }}
                        </div>
                      </div>
                      <div class="mt-4">
                        {{ currency_default.symbol }}
                        {{ amount_after_match }}
                      </div>
                    </span>
                  </div>

                  <div
                    v-if="item.currency != currency_default.id"
                    class="ch-text-primary"
                  >
                    ( {{ currency_default.symbol }} 1= {{ currency }}
                    {{ item.currency_exchange }})
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div class="mx-6 py-0 px-0 form-donation">
            <v-row class="">
              <v-col v-show="teams.length" class="pb-0 pt-1" cols="12">
                <span>
                  {{
                    $t(
                      "I_would_like_to_donate_to_the_following_Team_or_Community"
                    ) + (item.team.length > 1 ? "s" : "")
                  }}
                  ({{ $t("optional") }})
                </span>
              </v-col>

              <v-col v-show="teams.length" class="py-0" cols="12" md="12">
                <p class="text-right">
                  {{ $t("_Teams_Selected") }}: {{ item.team.length }}
                </p>
                <div>
                  <v-combobox
                    v-model="item.team"
                    :items="teams"
                    :label="$t('select_team')"
                    :menu-props="{
                      maxHeight: 304,
                      closeOnContentClick: true,
                    }"
                    background-color="#F1F1F1"
                    chips
                    class="relative"
                    color="#FEF0F0"
                    deletable-chips
                    dense
                    hide-details
                    item-text="public_name"
                    item-value="id"
                    multiple
                    outlined
                    @change="validateSelectedTeam"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-btn
                              class="float-right"
                              color="red"
                              outlined
                              x-small
                            >
                              Close
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>
                        <i style="font-size: 0.9em">({{ item.slug }})</i>
                        <strong>{{ item.name }}</strong>
                      </span>
                    </template>
                  </v-combobox>
                </div>
              </v-col>
              <v-col cols="12">
                <div>
                  <!-- <div v-if="item.team_full != null"> -->
                  <div
                    v-if="item.team.length > 0"
                    class="mx-8 font-weight-bold"
                  >
                    {{
                      $t("You_are_donating_to_the_following_Team") +
                      (item.team.length > 1 ? "s" : "")
                    }}
                  </div>
                  <div class="d-flex justify-end">
                    <v-alert
                      v-show="messageAmount && item.team.length > 0"
                      border="left"
                      class="mx-8"
                      dense
                      outlined
                      style="width: 90%"
                      type="warning"
                    >
                      {{ messageAmountText }}
                      <!-- <v-btn icon color="warning" @click="recalcAmount">
                                              <v-icon small>mdi-refresh</v-icon>
                                            </v-btn> -->
                    </v-alert>
                  </div>
                  <div class="d-flex justify-end mx-8 mb-2">
                    <div>
                      <div
                        v-if="item.team.length > 1"
                        class="d-flex justify-end mx-8 align-center"
                      >
                        <v-checkbox
                          v-model="distributeInTeamsActive"
                          class="mt-0 pt-0 mr-0"
                          color="#323232"
                          dense
                          hide-details
                        >
                        </v-checkbox>
                        <div>{{ $t("_Distribute_amount_equally") }}</div>
                      </div>
                      <div
                        v-if="item.team.length > 0"
                        class="d-flex justify-end mx-8 align-center"
                      >
                        <v-checkbox
                          v-model="repeatMessageActive"
                          class="mt-0 pt-0 mr-0"
                          color="#323232"
                          dense
                          hide-details
                        >
                        </v-checkbox>
                        <div>
                          {{ $t("_Apply_the_same_message_in_all_teams") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-for="(t, i) in item.team">
                    <v-card
                      :key="t.id"
                      class="
                        rounded-lg
                        pt-2
                        mx-8
                        mt-3
                        border-ch-primary
                        my-1
                        py-1
                      "
                      elevation="0"
                    >
                      <div
                        class="close-team-area pointer"
                        @click="removeTeam(t.team)"
                      >
                        <v-icon color="white">mdi-close</v-icon>
                      </div>

                      <div class="mx-4">
                        <v-row>
                          <v-col class="d-flex justify-start" cols="12" md="6">
                            <div class="mr-4 rounded-circle">
                              <v-avatar
                                class="pointer white--text mx-auto"
                                color="ch-primary"
                                dark
                                size="35"
                              >
                                <span v-if="t && t.path">
                                  <v-img
                                    :src="t.path"
                                    alt="team"
                                    class="rounded-circle"
                                    contain
                                    height="35px"
                                    width="35px"
                                  ></v-img>
                                </span>
                                <span v-else class="display-1">
                                  {{ t.name | idNameTeam }}
                                </span>
                              </v-avatar>
                            </div>
                            <div class="mt-2">
                              <span class="font-weight-bold">
                                {{ t.name }}
                              </span>
                              <v-avatar
                                v-if="t && t.value > 0 && t.available > 0"
                                class="value-matcher ml-4"
                                color="ch-third"
                                size="30"
                              >
                                <span
                                  class="font-weight-bold text-white caption"
                                >
                                  x{{ t.value }}
                                </span>
                              </v-avatar>
                              <v-text-field
                                v-model="t.amount"
                                :label="`Amount in ${currency}`"
                                :readonly="distributeInTeamsActive"
                                class="mt-2"
                                dense
                                hide-details
                                outlined
                                type="number"
                              ></v-text-field>
                              <!-- :prepend-inner-icon="currency" -->
                            </div>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-textarea
                              v-model="t.message"
                              :readonly="i != 0 && repeatMessageActive == true"
                              dense
                              hide-details
                              label="Message"
                              maxlength="255"
                              outlined
                              rows="3"
                              type="text"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </div>
                      <v-btn
                        absolute
                        fab
                        right
                        style="right: -20px"
                        top
                        x-small
                        @click="removeTeamFromSelectedList(t)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card>
                  </template>
                </div>
              </v-col>
              <v-col
                v-if="communities && communities.length > 0"
                class="py-0"
                cols="12"
              >
                <v-combobox
                  v-model="community"
                  :items="communities"
                  :label="$t('communities')"
                  clearable
                  dense
                  filled
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-combobox>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.name.$model"
                  :error-messages="nameErrors"
                  :label="$t('First_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.name.$touch()"
                  @input="$v.item.name.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.surname.$model"
                  :error-messages="surnameErrors"
                  :label="$t('Last_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.surname.$touch()"
                  @input="$v.item.surname.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-0 pb-2" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.display_name.$model"
                  :error-messages="displayNameErrors"
                  :label="$t('name_to_be_displayed')"
                  :readonly="item.anonymus"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  hide-details
                  outlined
                  type="text"
                  @blur="$v.item.display_name.$touch()"
                  @input="$v.item.display_name.$touch()"
                >
                </v-text-field>
                <div class="d-flex align-center justify-start mb-1">
                  <div class="mr-0">
                    <v-checkbox
                      v-model="item.anonymus"
                      class="mt-0 pt-0 mr-0"
                      color="#323232"
                      dense
                      hide-details
                    >
                    </v-checkbox>
                  </div>
                  <div
                    class="pointer"
                    style="font-size: 1.05rem"
                    @click="item.anonymus = !item.anonymus"
                  >
                    {{ $t("donate_anonymously") }}
                  </div>
                </div>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model="item.billing_name"
                  :label="$t('billing_name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model.trim="$v.item.email.$model"
                  :error-messages="emailErrors"
                  :label="$t('email')"
                  :rules="[]"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.email.$touch()"
                  @input="$v.item.email.$touch()"
                >
                  <!-- :rules="[$rules.email($v.item.email.$model)]" -->
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <div class="d-flex">
                  <div>
                    <vue-country-code
                      :preferredCountries="['gb', 'us', 'ca']"
                      style="
                        height: 40px;
                        border-color: #aaa;
                        background-color: #f1f1f1;
                      "
                      @onSelect="onSelect"
                    >
                    </vue-country-code>
                  </div>
                  <div class="pl-2" style="width: 100%">
                    <v-text-field
                      v-model="item.phone"
                      :label="$t('phone')"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      type="number"
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col v-show="item.team.length == 0" class="py-0" cols="12">
                <v-textarea
                  v-model="$v.item.message.$model"
                  :error-messages="messageErrors"
                  :label="$t('message_or_dedication')"
                  background-color="#F1F1F1"
                  color="#323232"
                  counter="255"
                  dense
                  maxlength="255"
                  outlined
                  rows="3"
                  @blur="$v.item.message.$touch()"
                  @input="$v.item.message.$touch()"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="show_tefila" class="py-0">
              <v-col class="py-1" cols="12">
                <div style="border-bottom: 1px solid #cdcdcd; font-size: 16px">
                  {{
                    $t(
                      "The_next_field_is_for_the_farmers-s_wife_to_pray_for_you"
                    )
                  }}
                </div>
              </v-col>
              <v-col class="py-1" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.teffilah_first.$model"
                  :error-messages="teffilah_firstErrors"
                  :label="$t('Your_Full_Jewish_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  counter="60"
                  dense
                  dir="rtl"
                  outlined
                  type="text"
                  @blur="$v.item.teffilah_first.$touch()"
                  @input="$v.item.teffilah_first.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-1" cols="12" md="6">
                <v-text-field
                  v-model="$v.item.teffilah_second.$model"
                  :error-messages="teffilahErrors"
                  :label="$t('Your_Teffilah')"
                  background-color="#F1F1F1"
                  color="#323232"
                  counter="60"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.teffilah.$touch()"
                  @input="$v.item.teffilah.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-card-title class="font-weight-bold subtitle-1 text-blue py-0"
            >{{ $t("Payment_method") }}
          </v-card-title>
          <div class="pb-1">
            <div class="d-flex justify-center flex-wrap mx-6">
              <div v-for="wallet in wallets" :key="wallet.id">
                <div
                  :class="{ active: wallet.default }"
                  :style="styles"
                  class="
                    d-flex
                    payment-card
                    flex-column
                    align-center
                    my-2
                    mr-2
                    justify-center
                    rounded
                  "
                  @click="selectPaymentBtn(wallet)"
                >
                  <div class="title-payment">
                    {{ wallet.name }}
                  </div>
                  <v-img
                    v-if="wallet.default"
                    :src="`${wallet.path_activo}`"
                    contain
                    width="65"
                  ></v-img>
                  <v-img v-else :src="wallet.path" contain width="65"></v-img>
                </div>
              </div>
            </div>
          </div>
          <div ref="wallets-data">
            <div
              v-if="wallet == 'stripe'"
              :key="wallet"
              class="mx-auto px-0 pb-1"
            >
              <div class="mx-6">
                <div class="">{{ $t("Credit_card") }}</div>
                <div>
                  <stripe-element-card
                    ref="elementRef"
                    :elementStyle="elementStyle"
                    :pk="publishableKey"
                    @error="loading = false"
                    @token="tokenCreated"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="
                item.currency == 2 &&
                wallet != 'achisomoch' &&
                wallet != 'broomvoucher' &&
                wallet != 'asser_bishvil' &&
                wallet != 'kol_yom' &&
                wallet != 'mh_trust' &&
                wallet != 'three_pillars' &&
                wallet != 'zoreya' &&
                info.id != 8 &&
                info.slug != 'YOY' &&
                info.slug != 'yoy'
              "
              class="pb-1 px-0 mt-3"
            >
              <div class="mx-6 py-1">
                <v-row class="d-flex justify-start">
                  <div class="mx-4" style="border-top: 1px solid #cdcdcd">
                    <div class="d-flex align-center justify-start">
                      <v-checkbox
                        id="gift_aid"
                        v-model="item.gift_aid"
                        class="mt-1 pt-0 mr-0"
                        color="#323232"
                        dense
                        hide-details
                      >
                      </v-checkbox>
                      <label class="mb-0 pt-1 font-weight-black" for="gift_aid">
                        Are you eligible for Gift Aid? Your donation will be 25%
                        more. Your effective (pre-matched) amount donated is
                        {{ currency }} {{ item.amount }} .
                      </label>
                    </div>
                    <p v-if="item.gift_aid == true">
                      Please treat this donation as a GIFT AID DONATION. I
                      confirm that I am a UK taxpayer and understand that if I
                      pay less Income Tax and/or Capital Gains Tax in the
                      current tax year than the amount of Gift Aid claimed on
                      all my donations it is my responsibility to pay any
                      difference.
                    </p>
                  </div>
                  <v-col class="py-1" cols="12" md="4">
                    <v-text-field
                      v-if="item.gift_aid == true"
                      v-model="$v.item.house_number.$model"
                      :error-messages="house_numberErrors"
                      :label="$t('house_number')"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      type="text"
                      @blur="$v.item.house_number.$touch()"
                      @input="$v.item.house_number.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="py-1" cols="12" md="4">
                    <v-text-field
                      v-if="item.gift_aid == true"
                      v-model="$v.item.zip_code.$model"
                      :error-messages="zip_codeErrors"
                      :label="$t('zip_code')"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      @blur="$v.item.zip_code.$touch()"
                      @input="$v.item.zip_code.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div v-if="wallet == 'einza'" class="pb-1 px-0 mt-3">
              <div class="mx-6 py-1">
                <v-row class="d-flex justify-start">
                  <v-col class="py-1" cols="12" md="6">
                    <v-text-field
                      v-model="$v.item.house_number.$model"
                      :error-messages="house_numberErrors"
                      label="Addresse*"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      type="text"
                      @blur="$v.item.house_number.$touch()"
                      @input="$v.item.house_number.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="py-1" cols="12" md="4">
                    <v-text-field
                      v-model="$v.item.zip_code.$model"
                      :error-messages="zip_codeErrors"
                      label="PLZ*"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      @blur="$v.item.zip_code.$touch()"
                      @input="$v.item.zip_code.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              v-if="
                wallet == 'achisomoch' ||
                wallet == 'broomvoucher' ||
                wallet == 'asser_bishvil' ||
                wallet == 'kol_yom' ||
                wallet == 'mh_trust' ||
                wallet == 'three_pillars' ||
                wallet == 'zoreya'
              "
              class="mx-6 mt-1"
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="item.cid"
                    :label="$t('your_customer_id')"
                    background-color="#F1F1F1"
                    color="#323232"
                    dense
                    outlined
                    type="text"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-if="wallet == 'ojc'" class="mx-6 mt-1">
              <v-row>
                <v-col cols="9" md="9">
                  <v-text-field
                    v-model="item.card_number"
                    background-color="#F1F1F1"
                    color="#323232"
                    dense
                    label="Your Card Number"
                    outlined
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    v-model="item.exp_date"
                    background-color="#F1F1F1"
                    color="#323232"
                    dense
                    label="Expiration Date"
                    outlined
                    type="number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-if="this.wallet == 'nadarim'">
              <div class="mx-6 my-4 py-0">
                <iframe
                  id="NedarimFrame"
                  ref="NedarimFrame"
                  class="nedarimFrame"
                  src="https://matara.pro/nedarimplus/iframe?language=en"
                ></iframe>
              </div>
              <v-row class="mx-6 my-4 py-0">
                <v-col class="py-1" cols="12" md="8">
                  <v-text-field
                    v-model="$v.item.house_number.$model"
                    :error-messages="house_numberErrors"
                    :label="$t('house_number')"
                    background-color="#F1F1F1"
                    color="#323232"
                    dense
                    outlined
                    type="text"
                    @blur="$v.item.house_number.$touch()"
                    @input="$v.item.house_number.$touch()"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="py-1" cols="12" md="4">
                  <v-text-field
                    v-model="item.city"
                    :label="$t('City *')"
                    background-color="#F1F1F1"
                    color="#323232"
                    dense
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div
              v-if="wallet == 'paypal'"
              class="w-full mx-auto d-flex justify-end"
            >
              <div class="mx-6 bank-area rounded-lg border px-8 py-2">
                <div>
                  You will be redirected to PayPal checkout after clicking to
                  complete the donation
                </div>
              </div>
            </div>
            <div v-if="wallet == 'pledge'">
              <v-col class="py-0" cols="12">
                <v-textarea
                  v-model="$v.item.async_payment_message.$model"
                  :error-messages="async_payment_messageErrors"
                  :label="$t('async_payment_message')"
                  :placeholder="$t('async_payment_message_placeholder')"
                  background-color="#F1F1F1"
                  color="#323232"
                  counter="255"
                  dense
                  maxlength="255"
                  outlined
                  rows="3"
                  @blur="$v.item.async_payment_message.$touch()"
                  @input="$v.item.async_payment_message.$touch()"
                >
                </v-textarea>
              </v-col>
            </div>
            <div class="my-2">
              <div
                v-if="information != null && information != ''"
                :style="styles"
                class="w-65 bank-area mx-auto rounded-lg px-8 py-4"
              >
                <div v-html="information"></div>
              </div>

              <div>
                <div
                  v-if="
                    item.amount < min * item.currency_exchange &&
                    minimum_donation_error != ''
                  "
                >
                  <div class="w-full mx-auto d-flex justify-center">
                    <div
                      class="mx-6 error white--text rounded-lg border px-8 py-2"
                    >
                      <div>
                        {{ minimum_donation_error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="error_donation != ''" class="mx-6 pb-1">
              <v-alert
                border="left"
                class="mx-8"
                dense
                outlined
                style="width: 90%"
                type="error"
              >
                <div class="new-line">{{ error_donation }}</div>
              </v-alert>
            </div>
            <div
              v-show="messageAmountText && item.team.length > 0"
              class="mx-6 pb-1"
            >
              <v-alert
                v-show="messageAmount && item.team.length > 0"
                border="left"
                class="mx-8"
                dense
                outlined
                style="width: 90%"
                type="warning"
              >
                {{ messageAmountText }}
                <!-- <v-btn icon color="warning" @click="recalcAmount">
                                                        <v-icon small>mdi-refresh</v-icon>
                                                      </v-btn> -->
              </v-alert>
            </div>
            <div v-show="snackbar" class="mx-6 my-2 pt-4 pb-6">
              <v-alert
                v-show="snackbar"
                border="left"
                class="mx-8"
                dense
                outlined
                style="width: 90%"
                type="success"
              >
                {{ text }}
                <!-- <v-btn icon color="warning" @click="recalcAmount">
                                                        <v-icon small>mdi-refresh</v-icon>
                                                      </v-btn> -->
              </v-alert>
            </div>

            <div class="w-full px-0 py-1 mt-1">
              <div class="d-flex justify-center align-center">
                <v-checkbox
                  v-model="item.terms"
                  :error-messages="termsErrors"
                  class="mt-0 pt-0 mr-0"
                  color="#323232"
                  dense
                  hide-details
                >
                </v-checkbox>
                <div>
                  {{ $t("I_agree_with") }}
                  <span class="blue--text pointer" @click="modalTerms = true"
                    >{{ $t("terms_and_conditions") }}
                  </span>
                  <span v-if="this.wallet == 'achisomoch'"
                    >and that my information will be shared with AAC
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="this.wallet != 'gpay' && !tranzila_url"
              class="
                mx-6
                my-2
                pt-4
                pb-6
                d-flex
                flex-column flex-md-row
                justify-center
              "
            >
              <v-btn
                :loading="loading"
                :style="styles"
                class="
                  ch-color-gradient
                  btn-text
                  rounded-lg
                  font-weight-medium
                  white--text
                "
                elevation="0"
                height="45px"
                width="300px"
                @click="sendForm()"
              >
                {{ $t("complete_donation") }}
              </v-btn>
              <!-- {{ min }} -->
            </div>
            <div v-if="tranzila_url" class="mx-6 mt-0">
              <v-row>
                <v-col cols="12" md="12">
                  <div class="d-flex justify-center">
                    <div>
                      <v-skeleton-loader
                        v-if="tranzila_url_loading"
                        loading
                        type="image, actions"
                        v-bind="attrs"
                        width="370px"
                      ></v-skeleton-loader>
                    </div>
                    <div>
                      <iframe
                        v-show="!tranzila_url_loading"
                        :src="tranzila_url"
                        frameborder="0"
                        height="470px"
                        width="375px"
                      ></iframe>
                      <!-- <iframe
                                                                  src="https://direct.tranzila.com/0Gu7W0U/iframenew.php?sum=1&currency=1&cred_type=1"
                                                                  width="370px"
                                                                  height="455px"
                                                                  frameborder="0"
                                                                ></iframe> -->
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalCongratulations" max-width="700" persistent>
        <v-card>
          <div class="float-right">
            <v-btn icon @click="closeCongratulations()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="p-4 teal--text darken-4 headline font-weight-bold">
            {{ congratulationsTitle }}
          </div>
          <div class="pt-4 subtitle-1 px-4">
            <p>
              {{ congratulationsText }}
            </p>
          </div>
          <div class="px-4 py-2 d-flex justify-space-between align-end">
            <div>
              <div class="my-1">Share</div>
              <div>
                <a
                  :href="mail_share"
                  class="mx-1"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-icon color="info"> mdi-email-outline</v-icon>
                </a>
                <!-- <a
                                                  style="text-decoration: none"
                                                  target="_blank"
                                                  :href="tw_share"
                                                  class="mx-1"
                                                >
                                                  <v-icon color="light-blue"> mdi-twitter </v-icon></a
                                                > -->
                <a
                  :href="wp_share"
                  class="mx-1"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-icon color="success"> mdi-whatsapp</v-icon>
                </a>
              </div>
            </div>
            <div class="d-flex justify-end items-end">
              <v-btn
                v-if="!website"
                class="button"
                color="red lighten-2"
                dark
                elevation="0"
                small
                @click="createMyTeam"
                >{{ $t("create_my_team") }}
              </v-btn>

              <v-btn color="green darken-1" text @click="closeCongratulations()"
                >{{ $t("_close") | uppercase }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalTerms" max-width="90%" scrollable>
        <v-card>
          <v-card-title class="headline font-weight-bold">
            Terms of Service &nbsp;
            <strong>(“Terms”)</strong>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalTerms = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="custom-scrollbar">
            <TermsOfService></TermsOfService>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="openPrivacy()"
              >SEE PRIVACY
            </v-btn>
            <v-btn color="green darken-1" text @click="modalTerms = false"
              >CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalPrivacy" max-width="90%" scrollable>
        <v-card>
          <v-card-title class="headline font-weight-bold">
            Charity Live Privacy Policy
            <v-spacer></v-spacer>
            <v-btn icon @click="modalPrivacy = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="custom-scrollbar">
            <TermsOfPrivacy></TermsOfPrivacy>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="openTerms()"
              >SEE TERMS
            </v-btn>

            <v-btn color="green darken-1" text @click="modalPrivacy = false"
              >CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { StripeCheckout, StripeElementCard } from "@vue-stripe/vue-stripe";
import TermsOfService from "./TermsOfService.vue";
import TermsOfPrivacy from "./TermsOfPrivacy";

export default {
  components: {
    StripeCheckout,
    StripeElementCard,
    TermsOfService,
    TermsOfPrivacy,
  },
  props: {
    styles: {
      default: null,
    },
  },
  data() {
    return {
      rules: {
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      lang_init: false,
      dir_lang: "ltr",
      publishableKey: "",
      sessionId: "session-id", // session id from backend
      token: null,
      carouselItem: 0,
      modalCongratulations: false,
      modalTerms: false,
      minimum_donation_error: "",
      modalPrivacy: false,
      tranzila_url: null,
      tranzila_url_loading: false,
      modalCondition: false,
      vouchers: [
        { id: 1, name: "mhmanchester" },
        { id: 2, name: "asserbishvil" },
        { id: 3, name: "threepillars" },
        { id: 4, name: "zoreyatzedokos" },
        { id: 5, name: "broomfoundation" },
        { id: 6, name: "kolyomtrust" },
      ],
      seletected: [],
      titleOptions: ["Mr.", "Ms.", "Reb", "R’", "Rabbi"],
      item: {
        amount: 0,
        currency: "",
        name: "",
        surname: "",
        display_name: "",
        anonymus: false,
        email: "",
        message: "",
        billing_name: "",
        private_message: "",
        item: null,
        installment: 1,
        phone: "",
        country_code: "",
        team: [],
        team_full: null,
        community_id: null,
        wallet: "",
        async_payment_message: null,
        teffilah_first: "",
        teffilah_second: "",
        gift_aid: null,
        terms: false,
        url: 5,
        cid: "",
        zip_code: 0,
        house_number: "",
        city: "",
        currency_exchange: 1,
        card_number: "",
        exp_date: "",
        form_id: "2.2.4",
        pay_status: [],
        gpay_pm: "",
        token: null,
        title: null,
        surname: "",
      },
      currency: "USD",
      wallets_full: [],
      wallets: [],
      wallet: null,
      messageAmount: null,
      url_share: null,
      fb_share: null,
      tw_share: null,
      wp_share: null,
      mail_share: null,
      messageAmountText: null,
      idItemSelected: 0,
      image: [],
      // currencies: [],
      communities: [],
      community: [],
      teams: [],
      team: [],
      snackbar: false,
      loading: false,
      installment: 1,
      information: "",
      campaign: "",
      extra_data_payment: 0,
      extra_data_1_payment: 0,
      min: 0,
      max: 999999,
      element: "",
      card: "",
      stripe_var: "",
      fullscreen: true,
      repeatMessageActive: false,
      distributeInTeamsActive: true,
      urlImage: "",
      error_donation: "",
      color: "",
      text: "",
      congratulationsTitle: "",
      congratulationsText: "",
      exchange_shekel: [],
      // TODO: MATCHERS
      matchers: [],
      match_message: "",
      amount_after_match: 0,
      match: {
        value: 0,
        available: 0,
      },
      geoIP: null,
      elementStyle: {
        base: {
          borderColor: "#000",
          color: "#000",
          fontWeight: "400",
          fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#000",
          },
          "::placeholder": {
            color: "#333",
          },
        },
        invalid: {
          iconColor: "red",
          color: "red",
        },
      },
      base_match: 0,
      secret_stripe: "",
      message_donation: "",
      gp_stripe: null,
      gp_paymentRequest: null,
      loading_form: false,
      website: false,
    };
  },
  async created() {
    this.loading_form = true;
    if (window.addEventListener) {
      window.addEventListener("message", this.readPostResponse, false);
    } else {
      window.attachEvent("onmessage", this.readPostResponse);
    }
    this.url_share = window.location.href; // ${this.url_share}
    // this.fb_share = `https://www.facebook.com/sharer/sharer.php?u=${this.url_share}&quote=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!`;
    this.wp_share = `https://wa.me/?text=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.url_share}`;
    // this.tw_share = `https://twitter.com/intent/tweet?text=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.url_share}`;
    this.mail_share = `mailto:?subject=I Donated!&body=Hi there, I just donated to to this important cause and invite you to join me!%0A${this.url_share}`;

    const params = window.location.pathname.slice(1).split("/");
    if (params[0] == "website") {
      this.campaign = params[1];
      this.website = true;
    } else this.campaign = params[0];
    axios.get(`/api/v1/campaign/${this.campaign}/wallet/stripe`).then((res) => {
      this.publishableKey = res.data;
    });
    axios
      .get(`/api/v1/campaign/${this.campaign}/community/list`)
      .then((res) => {
        this.communities = res.data;
        // this.selectIdCommunityByStore();
      });
    axios.get(`/api/v1/campaign/${this.campaign}/team/list`).then((res) => {
      this.teams = res.data;
      this.selectIdTeamByStore();
    });
    axios.get(`/api/v1/exchange/ils`).then((res) => {
      this.exchange_shekel = res.data;
    });
    axios.get(`/api/v1/campaign/${this.campaign}/wallet`).then((res) => {
      this.wallets_full = res.data;
      this.selectPayment(res.data[0].id);
      this.updateWalletByCurrency(this.item.currency);
    });
    axios.get(`/api/v1/campaign/${this.campaign}/matcher`).then((res) => {
      this.match.value = res.data.value;
      this.match.available = res.data.available;
      this.base_match = res.data.value;
    });
    if (window.innerWidth > 900) this.fullscreen = false;
    // hrdcoding GBP
    setTimeout(() => {
      if (this.info && this.info.slug != "chabure")
        fetch("https://icanhazip.com")
          .then((response) => response.text())
          .then((data) => {
            const ip = data;
            fetch(`https://freegeoip.app/json/${ip}`)
              .then((response) => response.json())
              .then((data) => {
                this.geoIP = data.country_code;
                if (
                  data.country_code == "AR" ||
                  data.country_code == "UK" ||
                  data.country_code == "GB"
                ) {
                  if (this.currencies) {
                    const index = this.currencies.findIndex(
                      (element) => element.id == 2
                    );
                    if (index != -1) {
                      this.item.currency = 2; // GEOIP
                    }
                  }
                }
                if (data.country_code == "AR" || data.country_code == "IL") {
                  const lang_init = localStorage.getItem("charity-locale");
                  if (!lang_init) {
                    this.$i18n.locale = "he";
                    localStorage.setItem("charity-locale", this.$i18n.locale);
                  }
                }
              });
          });
    }, 1000);
    // setTimeout(() => {
    //   this.createGooglePayStripe();
    // }, 3000);
    setTimeout(() => {
      this.item.amount =
        this.info && this.info.amount_base !== null
          ? this.info.amount_base
          : null;
    }, 500);
    setTimeout(() => {
      this.calcMatch();
    }, 1500);

    setTimeout(() => {
      this.loading_form = false;
    }, 5000);
  },
  mounted() {
    this.selectIdTeamByStore();
  },
  computed: {
    show_tefila() {
      // This piece of code: If Campaign == Nshei and item.amount >= 365 is true (Show). Otherwise false (Dont show).
      // For other campaigns, just if the show_tefillah in DB is 1 (show), otherwise (0 - dont show).
      if (this.$store.state.info.show_tefila == 1) {
        if (this.$store.state.info.name == "Nshei") {
          if (this.item.amount * (1 / this.item.currency_exchange) >= 365) {
            return true;
          }
          return;
        }
        return true;
      } else {
        return false;
      }
    },
    amount_to_donate() {
      return this.$store.state.amount_to_donate;
    },
    currency_donate() {
      return this.$store.state.currency_donate;
    },
    currencies() {
      if (this.$store.state.info && this.$store.state.info.currencies_enabled) {
        return this.$store.state.info.currencies_enabled;
      }
    },
    currency_default() {
      if (this.$store.state.info && this.$store.state.info.principal_currency) {
        return this.$store.state.info.principal_currency;
      } else return 1;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.item.name.$dirty) return errors;
      !this.$v.item.name.required && errors.push("Required.");
      return errors;
    },
    info() {
      return this.$store.state.info;
    },
    displayNameErrors() {
      const errors = [];
      if (!this.$v.item.display_name.$dirty) return errors;
      !this.$v.item.display_name.maxLength &&
        errors.push("Max. 60 characters.");
      return errors;
    },
    teffilahErrors() {
      const errors = [];
      if (!this.$v.item.teffilah_second.$dirty) return errors;
      !this.$v.item.teffilah_second.maxLength &&
        errors.push("Max. 60 characters.");
      return errors;
    },
    teffilah_firstErrors() {
      const errors = [];
      if (!this.$v.item.teffilah_first.$dirty) return errors;
      !this.$v.item.teffilah_first.maxLength &&
        errors.push("Max. 60 characters.");
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.item.surname.$dirty) return errors;
      !this.$v.item.surname.required && errors.push("Required.");
      return errors;
    },
    // phoneErrors() {
    //   const errors = [];
    //   if (!this.$v.item.phone.$dirty) return errors;
    //   !this.$v.item.phone.required && errors.push("Required.");
    //   return errors;
    // },
    zip_codeErrors() {
      const errors = [];
      if (!this.$v.item.zip_code.$dirty) return errors;
      // !this.$v.item.zip_code.required && errors.push("Required.");
      !this.$v.item.zip_code.maxLength && errors.push("Max. 10 characters.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.item.message.$dirty) return errors;
      !this.$v.item.message.maxLength && errors.push("Max. 255 characters.");
      return errors;
    },
    async_payment_messageErrors() {
      const errors = [];
      if (!this.$v.item.async_payment_message.$dirty) return errors;
      !this.$v.item.async_payment_message.required &&
        errors.push("Required if pledge method is selected");
      return errors;
    },
    house_numberErrors() {
      const errors = [];
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.item.amount.$dirty) return errors;
      !this.$v.item.amount.required && errors.push("Required.");
      return errors;
    },
    termsErrors() {
      const errors = [];
      if (!this.$v.item.terms.$dirty) return errors;
      !this.$v.item.terms.required && errors.push("Required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.item.email.$dirty) return errors;
      !this.$v.item.email.required && errors.push("Required.");
      return errors;
    },
    itemsDonation() {
      return this.$store.state.itemsDonation;
    },
    donation_item() {
      return this.$store.state.donation_item;
    },
    donation_team() {
      return this.$store.state.donation_team;
    },
    donation_community() {
      return this.$store.state.donation_community;
    },
    modalDonation() {
      return this.$store.state.modalDonation;
    },
  },
  watch: {
    currency_default() {
      this.item.currency = this.currency_default.id;
    },
    amount_to_donate() {
      setTimeout(() => {
        this.item.amount = this.amount_to_donate;
      }, 1000);
    },
    currency_donate() {
      this.item.currency = this.currency_donate;
    },

    info() {
      this.min = this.info.minimum_donation;
    },
    modalDonation() {
      if (window.innerWidth > 900) this.fullscreen = false;
      this.langDirection();
    },
    itemsDonation(newVal, OldVal) {
      //  this.selectIdItem();
    },
    donation_item(newVal, OldVal) {
      if (newVal != null) {
        this.changeIdSelected(
          this.$store.state.itemsDonation.find((x) => x.id === newVal)
        );
      } else {
        this.cancelIdSelected();
      }
    },
    donation_team() {
      this.selectIdTeamByStore();
    },
    donation_community() {
      this.selectIdCommunityByStore();
    },
    carouselItem() {
      this.updatePriceByItem();
    },
    "item.currency": function (newVal, oldVal) {
      this.updatePriceByCurrency(newVal, oldVal);
      setTimeout(() => {
        this.updateWalletByCurrency(newVal);
        this.distributeInTeams();
      }, 200);
    },
    "item.anonymus": function (newVal, oldVal) {
      if (this.item.anonymus == true) this.item.display_name = "Anonymous";
      else this.item.display_name = `${this.item.name} ${this.item.surname}`;
    },
    "item.gift_aid": function (newVal, oldVal) {
      if (this.item.gift_aid == false) {
        this.item.zip_code = "";
        this.item.house_number = "";
      }
    },
    "item.amount": function (newVal, oldVal) {
      this.item.installment = 1;
      // TODO: MATCHERS
      this.matchers = [];
      this.amount_after_match = 0;
      // aplicar match
      this.calcMatch();

      if (this.item.item != null) {
        const index = this.itemsDonation.findIndex(
          (element) => element.id == this.item.item
        );
        if (
          index != -1 &&
          parseFloat(this.item.amount) <
            parseFloat(this.itemsDonation[index].goal) *
              parseFloat(this.item.currency_exchange)
        ) {
          this.cancelIdSelected();
        }
      }
      this.distributeInTeams();
      if (this.item.amount == 0 || isNaN(this.item.amount)) {
        this.item.amount = null;
      }
    },
    "item.team": {
      handler: function (oldItem, newItem) {
        this.repeatMessage();
        if (this.distributeInTeamsActive == true) {
          this.distributeInTeams();
        } else {
          this.teamAmount2();
        }
        if (this.item.team.length == 0) {
          this.distributeInTeamsActive = true;
          if (this.item.amount == 0) {
            this.item.amount = Math.round(
              this.min * this.item.currency_exchange
            );
          }
        }
      },
      deep: true,
    },

    "item.name": function (newVal, oldVal) {
      if (this.item.anonymus == false) {
        this.item.display_name = `${this.item.name} ${this.item.surname}`;
      }
      this.item.billing_name = `${this.item.name} ${this.item.surname}`;
    },
    "item.title": function (newVal, oldVal) {
      if (this.item.anonymus == false) {
        this.item.display_name = `${this.item.name} ${this.item.surname}`;
      }
      this.item.billing_name = `${this.item.name} ${this.item.surname}`;
    },
    "item.surname": function (newVal, oldVal) {
      if (this.item.anonymus == false) {
        this.item.display_name = `${this.item.name} ${this.item.surname}`;
      }
      this.item.billing_name = `${this.item.name} ${this.item.surname}`;
    },
    repeatMessageActive() {
      if (this.repeatMessageActive == true) this.repeatMessage();
    },
    distributeInTeamsActive() {
      this.distributeInTeams();
    },
  },
  methods: {
    langDirection() {
      const lang_init = localStorage.getItem("charity-locale");
      if (!lang_init || lang_init == "en") {
        this.lang_init = false;
        this.dir_lang = "ltr";
      } else if (lang_init == "he") {
        this.lang_init = true;
        this.dir_lang = "rtl";
      }
    },

    generateMinimuError() {
      setTimeout(() => {
        const min = Math.round(
          this.info.minimum_donation * this.item.currency_exchange
        );
        this.minimum_donation_error = `Minimum Donation: ${this.currency}${min} `;
      }, 1000);
    },
    recalcAmount() {
      let amount = 0;
      this.item.team.forEach((element) => {
        if (element.amount != "") {
          amount = parseInt(amount) + parseInt(element.amount);
        }
      });
      this.item.amount = Math.round(amount);
    },
    distributeInTeams() {
      if (this.item.amount > 0 && this.item.team.length) {
        if (this.distributeInTeamsActive == true) {
          if (this.warningDeletedTeam || this.messageAmount) {
            this.messageWarningDeletedTeam = `Has been deleted the team with amount zero`;
            this.messageAmountText = `Your total donation amount has change`;
          }
          const part =
            Math.round((this.item.amount / this.item.team.length) * 100) / 100;

          this.item.team.forEach((element) => {
            element.amount = part;
          });
          //   this.messageAmount = false;
          //   this.warningDeletedTeam = false;
        } else {
          //   this.teamAmount();
        }
      }
      if (this.item.amount == 0) {
        this.item.amount = null;
      }
    },
    repeatMessageAction() {
      this.repeatMessageActive = !this.repeatMessageActive;
    },
    repeatMessage() {
      if (this.item.team.length > 0 && this.repeatMessageActive == true) {
        this.item.team.forEach((element) => {
          element.message = this.item.team[0].message;
        });
      }
    },
    prev() {
      let a = document.getElementById("carousel-items-form").scrollLeft;
      a -= 320;
      document
        .getElementById("carousel-items-form")
        .scroll({ left: a, behavior: "smooth" });
    },
    next() {
      let a = document.getElementById("carousel-items-form").scrollLeft;
      a += 320;
      console.log(a);
      document
        .getElementById("carousel-items-form")
        .scroll({ left: a, behavior: "smooth" });
    },
    calcMatch() {
      this.amount_after_match = 0;

      if (
        this.item.amount > 0 &&
        this.info &&
        this.info.available_matcher > 0 &&
        this.info.global_matcher > 0
      ) {
        if (
          (this.item.amount / this.item.currency_exchange) *
            this.info.global_matcher -
            parseInt(this.item.amount / this.item.currency_exchange) <=
          this.info.available_matcher
        ) {
          // aplico el matcheo si lo disponible es mayor al calculo
          this.amount_after_match = Math.round(
            (this.item.amount * this.info.global_matcher) /
              this.item.currency_exchange
          );
          this.match_message = "";
        } else {
          this.amount_after_match = Math.round(
            this.item.amount / this.item.currency_exchange +
              parseInt(this.info.available_matcher)
          );
          this.match_message = `up to ${this.currency_default.symbol} ${this.info.available_matcher}`;
        }

        if (this.amount_after_match == 0) {
          this.amount_after_match = Math.round(
            this.item.amount / this.item.currency_exchange
          );
        }
      } else {
        this.amount_after_match = Math.round(
          this.item.amount / this.item.currency_exchange
        );
      }
    },
    changeIdSelected(item) {
      if (item.quantity != null && item.sold == item.quantity) return;

      if (this.idItemSelected == item.id) {
        this.idItemSelected = 0;
        this.item.item = null;
      } else {
        this.item.amount = Math.round(item.goal * this.item.currency_exchange);
        this.idItemSelected = item.id;
        this.item.item = item.id;
      }
    },
    cancelIdSelected() {
      this.idItemSelected = 0;
      this.item.item = null;
    },
    createGooglePayStripe() {
      this.gp_stripe = Stripe(this.publishableKey, {
        apiVersion: "2020-08-27",
      });
      this.gp_paymentRequest = this.gp_stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Donation Charity Live",
          amount: Math.round(this.item.amount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      const elements = this.gp_stripe.elements();
      const prButton = elements.create("paymentRequestButton", {
        paymentRequest: this.gp_paymentRequest,
      });
      // Check the availability of the Payment Request API,
      // then mount the PaymentRequestButton
      this.gp_paymentRequest.canMakePayment().then(function (result) {
        if (result) {
          prButton.mount("#payment-request-button");
        } else {
          document.getElementById("payment-request-button").style.display =
            "none";
          console.log(
            "Google Pay support not found. Check the pre-requisites above and ensure you are testing in a supported browser."
          );
          document.getElementById(
            "payment-request-button-error"
          ).style.display = "inline";
        }
      });
      this.gp_paymentRequest.on("paymentmethod", async (e) => {
        // Make a call to the server to create a new
        // payment intent and store its client_secret.
        this.item.gpay_pm = e.paymentMethod.id;
        this.$v.item.$touch();
        if (this.$v.item.$pending || this.$v.item.$error) {
          e.complete("fail");
          this.error_donation = "Please check the form before continuing";
          return;
        }
        if (this.item.terms == false) {
          e.complete("fail");
          this.error_donation =
            "Please accept the terms and conditions before continuing";
          return;
        }
        axios
          .post(`/api/v1/campaign/${this.campaign}/donation`, this.item)
          .then((res) => {
            this.color = "success";
            this.text = res.data.message;
            if (res.data.url == "succeeded" || res.data.url == "active") {
              this.modalCongratulations = true;
            }
            this.error_donation = `There was an error: ${res.data.url}`;
            e.complete("success");
          })
          .catch((error) => {
            this.error_donation = `There was an error: ${error.response.data.message}`;
            e.complete("fail");
          });
      });
    },
    openPrivacy() {
      this.modalTerms = false;
      this.modalPrivacy = true;
    },
    openTerms() {
      this.modalTerms = true;
      this.modalPrivacy = false;
    },
    up() {
      let a = document.getElementById("card").scrollLeft;
      a -= 120;
      document.getElementById("card").scroll({ top: a, behavior: "smooth" });
    },
    down() {
      let a = document.getElementById("card").scrollLeft;
      a += 120;
      document.getElementById("card").scroll({ bottom: a, behavior: "smooth" });
    },
    noDot(i) {
      // let charCode = evt.which;
      if (i.charCode > 31 && (i.charCode < 48 || i.charCode > 57)) {
        return true;
      }
      return true;
    },
    onSelect({ name, iso2, dialCode }) {
      this.item.country_code = dialCode;
    },
    async submit() {
      await this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.loading = true;
      this.item.token = token.id;
      if (this.item.token == "" || this.item.token == null) {
        this.error_donation = "Please check the form before continuing";
        this.loading = false;
      } else {
        this.fetch();
      }
    },
    closeCongratulations() {
      this.modalCongratulations = false;
      this.makeDonation();
    },
    validateDonation() {
      if (this.item.amount < this.min * this.item.currency_exchange) {
        this.item.amount = Math.round(this.min * this.item.currency_exchange);
      }
      if (this.item.amount > this.max) {
        this.item.amount = this.max;
      }
      // this.partDonation();
    },
    teamAmount2() {
      if (this.item.team.length < 0) return;
      let amount = 0;
      this.item.team.forEach((element) => {
        if (element.amount != "") {
          amount = parseFloat(amount) + parseFloat(element.amount);
        }
      });
      if (amount != this.item.amount) {
        this.messageAmount = true;
        const diff = parseFloat(this.item.amount) - parseFloat(amount);
        this.messageAmountText = `Your total donation amount increased to ${this.currency}${amount} `;
        this.item.amount = Math.round(amount);
      } else {
        this.messageAmount = false;
        const diff = 0;
        this.messageAmountText = ``;
      }
    },
    updateWalletByCurrency(newVal) {
      this.item.wallet = null;
      this.wallets = [];
      const stripe = 0;
      if (this.info && this.info.slug != "nshei") {
        this.wallets_full.forEach((element) => {
          if (element.currency_id == newVal) this.wallets.push(element);
          else if (element.int_name == "stripe") this.wallets.push(element);
          else if (element.int_name == "pledge") this.wallets.push(element);
        });
      } else {
        this.wallets_full.forEach((element) => {
          if (element.currency_id == newVal) this.wallets.push(element);
        });
      }
      this.selectPayment(this.wallets[0]);
    },
    updatePriceByCurrency(newVal, oldVal) {
      if (oldVal != this.currency_default.id) {
        const index = this.currencies.findIndex(
          (element) => element.id == oldVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount / this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      }
      if (newVal != this.currency_default.id) {
        const index = this.currencies.findIndex(
          (element) => element.id == newVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount * this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      } else {
        const index = this.currencies.findIndex(
          (element) => element.id == newVal
        );
        if (index != -1) {
          this.item.amount = Math.round(
            this.item.amount * this.currencies[index].value
          );
          this.currency = this.currencies[index].symbol;
          this.item.currency_exchange = this.currencies[index].value;
        }
      }
    },
    selectIdTeamByStore() {
      if (
        this.donation_team === null ||
        this.donation_team === undefined ||
        this.donation_team === ""
      ) {
        return;
      }
      this.item.team = [];
      this.item.team.push(this.donation_team);
      const index = this.teams.findIndex(
        (element) => element.id == this.item.team
      );
      if (index != -1) {
        this.item.team_full = this.teams[index];
      }
    },
    selectIdCommunityByStore() {
      if (
        this.donation_community === null ||
        this.donation_community === undefined ||
        this.donation_community === ""
      ) {
        return;
      }
      const index = this.communities.findIndex(
        (element) => element.slug == this.donation_community
      );
      if (index != -1) {
        this.item.community_id = this.communities[index].id;
        this.community = this.communities[index];
      }
    },
    selectIdItem() {
      if (this.itemsDonation == null) return;
      if (!this.donation_item) return;
      this.updatePriceByItem();
    },
    reloadComponent() {
      this.$refs.elementRef.unmount();
    },
    selectPaymentBtn(wallet) {
      const el = this.$refs["wallets-data"];
      if (el) el.scrollIntoView({ behavior: "smooth" });
      this.selectPayment(wallet);
    },
    async selectPayment(wallet) {
      this.wallet = null;
      if (wallet) {
        if (wallet.int_name == "stripe" && this.$refs.elementRef != undefined) {
          await this.reloadComponent();
        }
        this.wallets.forEach((element) => {
          if (element.id != wallet.id) element.default = false;
          else element.default = true;
        });
        this.item.wallet = wallet.id;
        this.item.gift_aid = false;
        this.information = wallet.information;
        this.extra_data_payment = wallet.extra_data;
        this.extra_data_1_payment = wallet.extra_data_1;
        this.publishableKey = wallet.extra_data;
        this.wallet = wallet.int_name;
        this.error_donation = "";
      }
      // if (wallet.int_name == "gpay") {
      //   setTimeout(() => {
      //     this.testingGPay();
      //   }, 3000);
      // }
    },
    updatePriceByItem() {
      if (this.itemsDonation == null) return;
      if (
        this.carouselItem === null ||
        this.carouselItem === undefined ||
        this.carouselItem === ""
      ) {
        this.item.amount = this.info.amount_base * this.item.currency_exchange;
        this.item.item = null;
      } else {
        this.item.amount =
          this.itemsDonation[this.carouselItem].goal *
          this.item.currency_exchange;
        this.item.item = this.itemsDonation[this.carouselItem].id;
      }
    },
    removeTeamFromSelectedList(team) {
      this.item.team = this.item.team.filter((item) => {
        return team.id !== item.id;
      });
    },
    removeTeam(data) {
      this.item.team = null;
      this.item.team_full = null;
    },
    makeDonation() {
      this.$v.$reset();
      this.item.name = "";
      this.item.surname = "";
      this.item.display_name = "";
      this.item.email = "";
      this.item.phone = "";
      this.item.anonymus = false;
      this.item.message = "";
      this.item.private_message = "";
      this.item.community_id = null;
      this.item.teffilah_first = "";
      this.item.teffilah_second = "";
      this.item.gift_aid = null;
      this.item.terms = false;
      this.item.url = 5;
      this.item.cid = "";
      this.item.card_number = "";
      this.item.exp_date = "";
      this.item.zip_code = "";
      this.item.house_number = "";
      this.error_donation = "";
      this.item.token = null;
      this.$store.commit("TOGGLE_MODAL_DONATION");
      if (this.wallet == "stripe") this.$refs.elementRef.clear();
    },
    createMyTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
      this.closeCongratulations();
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async sendForm() {
      this.$v.item.$touch();

      let auxError = false;
      this.error_donation = "";

      if (
        this.item.amount <
        Math.round(this.info.minimum_donation * this.item.currency_exchange)
      ) {
        const min = this.info.minimum_donation * this.item.currency_exchange;
        this.error_donation += `• Minimum donation for this campaign is ${this.currency}${min}\n`;
        auxError = true;
      }

      // Little validation if some team have zero amount, then some warning shows to the user and the process is stopped.
      if (this.item.team.filter((element) => element.amount == 0).length > 0) {
        this.error_donation +=
          "• The amount of the teams must be greater than zero\n";
        auxError = true;
      }

      if (this.item.name === "") {
        this.error_donation += "• Please complete your first name\n";
        auxError = true;
      }

      if (this.item.surname === "") {
        this.error_donation += "• Please complete your last name\n";
        auxError = true;
      }

      if (!this.validateEmail(this.item.email)) {
        this.error_donation += "• The email is not valid\n";
        auxError = true;
      }
      if (
        this.item.gift_aid === true &&
        (this.item.zip_code === "" || this.item.house_number === "")
      ) {
        this.error_donation += "• Please complete your address and zip code\n";
        auxError = true;
      }

      if (
        this.wallet === "einza" &&
        (this.item.zip_code === "" || this.item.house_number === "")
      ) {
        this.error_donation += "• Please complete your Addresse and PLZ\n";
        auxError = true;
      }

      if (this.item.wallet == null) {
        this.error_donation += "• Please select a Payment Method\n";
        auxError = true;
      }
      if (this.wallet === "pledge" && this.item.phone === "") {
        this.error_donation += "• Please complete your phone\n";
        auxError = true;
      }

      if (
        (this.wallet === "pledge" || this.wallet === "offline") &&
        this.item.async_payment_message === ""
      ) {
        this.error_donation +=
          "• Please write in the pledge message textbox how are you going to make the payment";
        auxError = true;
      }

      if (this.item.terms === false) {
        this.error_donation +=
          "• Please accept the terms and conditions before continuing\n";
        auxError = true;
      }

      if ((this.$v.item.$pending || this.$v.item.$error) && !auxError) {
        this.error_donation += "• Please check the form before continuing\n";
        auxError = true;
      }
      if (
        (this.wallet === "achisomoch" ||
          this.wallet === "broomvoucher" ||
          this.wallet === "asser_bishvil" ||
          this.wallet === "kol_yom" ||
          this.wallet === "mh_trust" ||
          this.wallet === "three_pillars" ||
          this.wallet === "zoreya") &&
        this.item.cid === ""
      ) {
        this.error_donation += "• Please complete your Account Number\n";
        auxError = true;
      }
      if (
        this.wallet === "ojc" &&
        (this.item.card_number == "" || this.item.exp_date == "")
      ) {
        this.error_donation +=
          "• Please complete your Card Number and Expiration Date\n";
        auxError = true;
      }

      if (
        this.wallet === "nadarim" &&
        (this.item.city === "" || this.item.house_number === "")
      ) {
        this.error_donation += "• Please complete your street and city\n";
        auxError = true;
      }

      if (auxError) {
        return;
      }

      this.$v.$reset();
      this.loading = true;
      if (this.wallet == "stripe") this.sessionId = await this.submit();
      else if (this.wallet === "nadarim") {
        const iframeWin = this.$refs.NedarimFrame.contentWindow;
        let amount = this.item.amount;
        let currency = 1;
        if (this.item.currency == 1) {
          currency = 2;
        }
        if (this.item.currency != 1 && this.item.currency != 5) {
          const index = this.exchange_shekel.findIndex(
            (element) => element.id == this.item.currency
          );
          if (index != -1) {
            amount =
              Math.round(
                (this.item.amount / this.exchange_shekel[index].value) * 100
              ) / 100;
          } else {
            amount = 0;
          }
          // console.log("index " + index);
          // console.log("exchange " + this.exchange_shekel[index].value);
          console.log("amount in shekel " + amount);
        }
        const params = {
          extra_data_payment: this.extra_data_payment,
          extra_data_1_payment: this.extra_data_1_payment,
          name: this.item.name,
          surname: this.item.surname,
          phone: this.item.phone,
          email: this.item.email,
          street: this.item.house_number,
          city: this.item.city,
          amount: amount,
          installment: this.item.installment,
          info_name: this.info.name,
          currencies: currency,
        };
        iframeWin.postMessage(
          {
            Name: "FinishTransaction2",
            Value: {
              Mosad: params.extra_data_payment,
              ApiValid: params.extra_data_1_payment,
              PaymentType: "Ragil",
              Currency: params.currencies,

              Zeout: "",
              FirstName: params.name,
              surname: params.surname,
              Street: params.street,
              City: params.city,
              Phone: params.phone,
              Mail: params.email,

              Amount: params.amount,
              Tashlumim: params.installment,

              Groupe: "",
              Comment: `Campaign ${params.info_name}`,

              Param1: "פרמטר 1",
              Param2: "",
              ForceUpdateMatching: "1", // מיועד לקמפיין אם מעוניינים שהמידע יידחף ליעד, למרות שזה לא נהוג באייפרם

              CallBack: "",
              Tokef: "", // אם אתם מנהלים את התוקף בדף שלכם (מיועד למי שרוצה להפריד בין חודש לשנה ורוצה לעצב מותאם אישית)
            },
          },
          "*"
        );
      } else {
        this.fetch();
      }
    },
    readPostResponse(event) {
      if (event.data.Name === "TransactionResponse") {
        this.item.pay_status = event.data.Value;
        this.fetch();
      }
    },
    testingGPay() {
      this.createGooglePayStripe();
    },
    fetch() {
      // Erase/clean the content of async_payment_message if the payment method selected differs from "pledge".
      if (this.wallet !== "pledge") {
        this.item.async_payment_message = null;
      }

      if (this.community !== null) this.item.community_id = this.community.id;
      axios
        .post(`/api/v2/campaign/${this.campaign}/donation`, this.item)
        .then((res) => {
          switch (res.data.action) {
            case "redirect":
              this.color = "success";
              this.text = res.data.message;
              this.error_donation = "";

              this.snackbar = true;
              setTimeout(() => {
                this.snackbar = false;
              }, 4000);

              window.location.href = res.data.url;
              break;
            case "iframe":
              this.tranzila_url = res.data.url;
              this.tranzila_url_loading = true;
              setTimeout(() => {
                this.tranzila_url_loading = false;
              }, 1500);
              break;
            case "pledge":
              this.congratulationsTitle =
                "Thank You! We will review your message and contact you for further details!";
              this.congratulationsText = "";
              this.error_donation = "";

              this.modalCongratulations = true;
              break;
            case "stripe":
              this.color = "success";
              this.text = res.data.message;
              if (res.data.url == "succeeded" || res.data.url == "active") {
                this.congratulationsTitle =
                  "Congratulations, Your Donation Has Been Approved!";
                this.congratulationsText = "";
                this.modalCongratulations = true;
                this.error_donation = "";
              } else {
                this.color = "danger";
                this.text = res.data.message;
                this.error_donation = `There was an error: ${this.text}`;
              }
              this.$refs.elementRef.clear();
              break;
            case "nadarim":
              this.color = "success";
              this.text = res.data.message;
              if (res.data.url == "succeeded" || res.data.url == "active") {
                this.congratulationsTitle =
                  "Congratulations, Your Donation Has Been Approved!";
                this.congratulationsText = "";
                this.modalCongratulations = true;
                this.error_donation = "";
              } else {
                this.error_donation = `There was an error: ${res.data.url}`;
              }
              break;
            case "ojc":
              this.color = "success";
              this.text = res.data.message;
              if (res.data.url == "succeeded" || res.data.url == "active") {
                this.congratulationsTitle =
                  "Congratulations, Your Donation Has Been Approved!";
                this.congratulationsText = "";
                this.modalCongratulations = true;
                this.error_donation = "";
              } else {
                this.error_donation = `There was an error: ${res.data.message}`;
              }
              break;
            default:
              break;
          }
          if (this.error_donation == "") {
            this.item.title = null;
            this.item.name = "";
            this.item.surname = "";
            this.item.display_name = "";
            this.item.email = "";
            this.item.phone = "";
            this.item.anonymus = false;
            this.item.message = "";
            this.item.private_message = "";
            this.item.team = [];
            this.item.community_id = null;
            this.item.teffilah_first = "";
            this.item.teffilah_second = "";
            this.item.gift_aid = null;
            this.item.terms = false;
            this.item.url = 5;
            this.item.cid = "";
            this.item.card_number = "";
            this.item.exp_date = "";
            (this.item.async_payment_message = null), (this.item.zip_code = "");
            this.item.house_number = "";
            this.item.token = null;
          }
          this.$store.dispatch("UPDATE_CAMPAIGN_DONATED_INFO", this.campaign);
        })
        .catch((error) => {
          this.color = "error";
          this.text = `There was an error: ${error.response.data.message}`;
          this.error_donation = "";

          if (Array.isArray(error.response.data.message)) {
            this.error_donation = "There were some errors:\n";
            const auxArr = error.response.data.message;
            for (let i = 0; i < auxArr.length; i++) {
              this.error_donation += `• ${auxArr[i]}\n`;
            }
          } else {
            this.error_donation = `There was an error: ${error.response.data.message}`;
          }
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("UPDATE_MONITOR_DONATIONS");
          setTimeout(() => {
            this.error_donation = "";
          }, 10000);
        });
    },
    validateSelectedTeam(value) {
      this.item.team = value.filter((item) => typeof item !== "string");
    },
  },
  validations: {
    item: {
      cid: {},
      zip_code: {
        maxLength: maxLength(10),
      },
      house_number: {},
      name: {
        required,
      },
      display_name: {
        maxLength: maxLength(60),
      },
      message: {
        maxLength: maxLength(255),
      },
      async_payment_message: {
        required: requiredIf(function () {
          if (this.wallet === "pledge" || this.wallet == "offline") {
            return true;
          }
          // return this.wallet == "pledge" ;
        }),
      },
      teffilah_first: {
        maxLength: maxLength(60),
      },
      teffilah_second: {
        maxLength: maxLength(60),
      },
      surname: {
        required,
      },
      // phone: {
      //   required,
      // },
      email: {
        required,
      },
      terms: {
        required,
      },
      wallet: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-scroll-y {
  overflow-y: hidden;
}

.productWhitoutImage {
  bottom: 40px !important;
}

.match_adder {
  font-size: 14px;
  line-height: 32px;
}

.size-title > div {
  height: 26px;
}

.card-amount {
  height: 70%;
  border-radius: 5px;
}

.inputs-amount-area {
  height: 75%;
  padding-bottom: 1%;
}

.payment-card {
  width: 185px;
  height: 100px;
  cursor: pointer;
  border: 1px solid #a4a4a4;
  color: #777;
}

.active {
  cursor: pointer;
  background-color: #f1f1f1;
  color: var(--charity-primary);
}

.bank-area {
  background-color: #f1f1f1;
  border: 1px solid #b3b2b2;
  color: var(--charity-primary);
}

/* .form-donation {
  width: 95%;
} */
.input-amount {
  width: 70%;
}

.input-amount > input {
  width: 100%;
  text-align: right;
  font-size: 50px;
  height: 55px;
  color: var(--charity-color-title);
  font-family: var(--charity-font-primary) !important;
}

.input-amount > input:focus {
  outline: none;
}

.select-iso_name {
  width: 100px !important;
  margin-bottom: 2px;
}

.select-installment {
  width: 100%;
  background-color: var(--background);
  /* color: var(--charity-color-title); */
  font-family: var(--charity-font-primary) !important;
  border-radius: 5px;
  padding: 10px;
}

.bordered-item {
  border: 1px solid #a4a4a4;
  margin: auto;
  padding: 4px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  box-sizing: border-box;
}

.ch-amount-title {
  font-family: var(--charity-font-title) !important;
  font-size: 20px;
}

.ch-title-amount-recive {
  font-family: var(--charity-font-title) !important;
  font-size: 40px;
  font-weight: 400;
  /* height: 45px; */
}

/* .amount {
  color: #432c1f;
  text-align: left;
  font-weight: 600;
  font-size: 1rem;
  margin: 3px 0 0 0;
} */
.amount-descrip {
  color: #777;
  text-align: left;
  font-weight: 800;
  font-size: 0.9rem;
  margin: 10px 0 3px 0;
}

.sold {
  text-align: left;
  font-weight: 400;
  font-size: 0.8rem;
  margin: 0 13px;
}

.button-amount {
  color: white;
  font-weight: 600;
  font-size: 10px;
}

.carrouse-kerem-items {
  padding: 13px;
}

.v-card--link:before {
  background: none;
}

.card-item {
  position: relative;
}

.icon-item {
  position: absolute;
  right: 3px;
  bottom: 6px;
}

.close-team-area {
  position: absolute;
  top: 10px;
  right: 10px;
}

.title-payment {
  font-size: 1rem;
  font-weight: 600;
  padding-top: 2px;
  width: 175px;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tinitext {
  font-size: 10px;
  height: 12px;
  margin: 0px;
  line-height: 0px;
}

.match {
  padding: 5px;
  width: 67px;
  height: 67px;
  border: 3px solid var(--charity-color-title);
  background-color: #f1f1f1;
  border-radius: 50%;
}

.amount-installment {
  text-align: right;
}

.description-area {
  overflow: scroll;
  word-wrap: break-word;
  overflow-wrap: break-word;
  height: 80vh;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 3px !important;
}

::-webkit-scrollbar:vertical {
  display: block;
}

.btn-text {
  font-size: 20px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  display: block;
  background: var(--charity-primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block;
  background: var(--charity-primary);
}

.custom-scrollbar {
  /* Handle */
  &::-webkit-scrollbar-thumb {
    display: block;
    background: rgba(0, 0, 0, 0.15);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    display: block;
    background: rgba(0, 0, 0, 0.15);
  }
}

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

.StripeElement {
  border: 1px solid #a4a4a4 !important;
  background-color: var(--background) !important;
}

.item-card {
  width: 220px;
  min-height: 120px;
  border-radius: 8px;
}

.goal_item {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(58, 58, 58, 1);
  margin: 0 auto;
}

.border-ch-primary {
  border: 1px solid var(--charity-primary);
}

.imageOfItem {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  border-color: var(--charity-primary);
  margin: 0 auto;
  margin-top: 10px;
  display: block;
}

.items-coarrousel-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;

  .img-container {
    width: 50px;
    height: auto;
    margin-bottom: 32px;
  }

  h4 {
    font-weight: bold;
  }
}

.pink-text {
  color: #be2c83;
}

.black-text {
  color: #000;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.item-card {
  width: 220px;
  border-radius: 8px;
}

.font-title-item {
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  min-height: 35px;
}

.font-amount-item {
  font-size: 32px;
  font-weight: bold;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.icon-carousel {
  background-color: rgba(0, 0, 0, 0.35) !important;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  visibility: visible;
}

.font-xs {
  font-size: 0.8rem;
}

.poster-influ {
  width: 230px;
  height: 122px;
  border: 1px solid #cdcdcd;
}

@-moz-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}

@-webkit-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}

@-moz-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}

@-webkit-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}

.icon-next {
  right: 5px;
}

.justifyCenter {
  justify-content: center;
}

.influencer-area {
  position: relative;
}

.icon-prev {
  left: 5px;
}

.nedarimFrame {
  width: 100%;
  box-sizing: border-box;
  height: 350px;
  border: 4px solid indianred;
  padding: 15px;
  margin-top: 10px;
}

.m-neg {
  margin-top: -10px;
  margin-left: -10px;
}

.text-direction {
  direction: rtl !important;
}

.new-line {
  white-space: pre;
}
</style>
