<template>
  <div>
    <v-snackbar v-model="snackbar" top right :timeout="5000" color="success">
      <div class="d-flex justify-space-between">
        <div>{{ $t("copied") }}</div>
        <div class="">
          <v-btn
            color="white"
            x-small
            dark
            icon
            elevation="0"
            @click="snackbar = false"
          >
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-snackbar>

    <img
      v-show="team.bpath"
      :src="team.bpath"
      width="100%"
      height="100%"
      contain
      elevation="2"
      class="elevation-2 banner-team rounded-xl p-0"
    />
    <v-card v-if="team" elevation="4" class="rounded-xl mt-7">
      <v-btn icon small fab absolute right @click="closeTeamPage">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
      <v-row>
        <v-col cols="12">
          <v-row class="mx-4 mt-2 d-flex justify-space-between align-center">
            <v-col col="12" md="6" class="align-self-start d-flex">
              <div>
                <v-avatar
                  color="ch-primary"
                  dark
                  class="white--text avatar-size"
                >
                  <template v-if="team.path">
                    <v-img
                      :src="team.path"
                      alt="Team Image"
                      height="120px"
                      width="120px"
                      class="bg-white rounded-circle"
                      cover
                    >
                    </v-img>
                  </template>
                  <template v-else>
                    <v-img
                      src="img/team_default.png"
                      alt="Team Image"
                      height="120px"
                      width="120px"
                      class="bg-white rounded-circle"
                      cover
                    >
                    </v-img>
                  </template>
                </v-avatar>
              </div>
              <div class="ml-2 my-5">
                <div class="general-title font-weight-bold">
                  {{ team.name }}
                </div>
                <!-- <div class="general-subtitle">Committee</div> -->
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mx-4 d-flex justify-end">
                <div
                  class="
                    text-kerem-dark text-xl4
                    font-weight-medium
                    text-center
                  "
                >
                  <span class="ch-text-third">
                    {{ team.donors }}
                  </span>
                  <div class="text-small">{{ $t("donors") }}</div>
                </div>
                <!-- <div>
                  <div class="text-xl4 text-right text-kerem ch-text-third">
                    {{ team.raised }}/{{ team.goal }}
                  </div>
                  <div class="text-small">
                    {{ $t("raised_of") }}
                  </div>
                </div> -->
              </div>
              <div class="mt-6 text-small">
                <v-progress-linear
                  :value="team.percent"
                  height="17"
                  class="rounded-xl"
                  color="ch-primary"
                ></v-progress-linear>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="mx-4 d-flex justify-end">
                <v-btn
                  color=""
                  icon
                  dark
                  class="mr-4 ch-text-primary"
                  @click="copyTeamPage(team.slug)"
                  ><v-icon>mdi-share-variant</v-icon></v-btn
                >
                <v-btn
                  color="ch-secondary"
                  dark
                  elevation="0"
                  medium
                  class="button btn-small rounded-pill"
                  @click="donate(team)"
                  >{{ $t("donate_to_this_team") }}</v-btn
                >
              </div>
            </v-col>
          </v-row>

          <div class="my-6 mx-auto w-11-12">
            <div class="d-flex flex-md-row flex-column justify-space-around">
              <div class="d-flex justify-center" v-if="team.video">
                <div class="">
                  <iframe
                    width="310"
                    height="180"
                    :src="team.video"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <!-- <div class="w-1-2-md-lg-xl" v-if="team.description"> -->
              <div class="" v-if="team.description">
                <div class="mt-sm-4 text-small description-area body-2 px-4">
                  {{ team.description }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-4">
      <v-col cols="12" md="12">
        <TeamMembersCard :team="team.id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import TeamMembersCard from "./MembersCardComponent.vue";

export default {
  metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.team.description,
        },
      ],
    };
  },

  components: {
    TeamMembersCard,
  },
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    team() {
      return this.$store.state.team;
    },
    pageTeamActive() {
      return this.$store.state.pageTeamActive;
    },
    colOffset() {
      return this.$store.state.team.description && this.$store.state.team.video
        ? 2
        : 0;
    },
    colVideo() {
      return this.$store.state.team.description ? 4 : 12;
    },
    colDescription() {
      return this.$store.state.team.video ? 6 : 12;
    },
  },
  methods: {
    closeTeamPage() {
      this.$store.commit("UPDATE_TEAM", null);
    },
    donate(team) {
      this.$store.commit("UPDATE_DONATION_TEAM", team);
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    copyTeamPage(slug) {
      let url = window.location.origin;
      var el = document.createElement("textarea");
      document.body.appendChild(el);
      el.value = `${url}/${slug}`;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.avatar-size {
  height: 120px !important;
  width: 120px !important;
}
.description-area {
  height: 180px;
  overflow: scroll;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.banner-team {
  max-height: 120px;
}
::-webkit-scrollbar:vertical {
  display: block;
}
.general-title {
  font-size: 1.3rem;
}
::-webkit-scrollbar {
  width: 7px !important;
  height: 13px !important;
}
</style>
