// import moment from "moment"
import * as moment from "moment-timezone";
import humanize from "humanize-duration";

export default (from) => {
    if (!from) return null;

    const now = moment().tz(moment.tz.guess());

    const fromDate = moment.utc(from).tz(moment.tz.guess());

    const diffSeconds = now.diff(fromDate, "seconds");
    const diffMins = now.diff(fromDate, "minutes");
    const diffHours = now.diff(fromDate, "hours");
    const diffDays = now.diff(fromDate, "days");

    if (diffSeconds > 0 && diffSeconds < 60) {
        const pluralizeSecondStr = diffSeconds === 1 ? `second` : `seconds`;

        return `${diffSeconds} ${pluralizeSecondStr} ago`;
    } else if (diffMins > 0 && diffMins < 60) {
        const pluralizeMinStr = diffMins === 1 ? `minute` : `minutes`;

        return `${diffMins} ${pluralizeMinStr} ago`;
    } else if (diffHours > 0 && diffHours < 24) {
        const pluralizeHourStr = diffHours === 1 ? `hour` : `hours`;

        return `${diffHours} ${pluralizeHourStr} ago`;
    } else if (diffDays > 0 && diffDays < 29) {
        const pluralizeDayStr = diffHours === 1 ? `day` : `days`;

        return `${diffDays} ${pluralizeDayStr} ago`;
    } else {
        const humanizeString = humanize(diffSeconds, {
            round: true,
            units: ["d", "h", "m", "s"],
        });

        return `a seconds ago`;
    }
};
