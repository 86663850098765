<template>
  <div :style="`${campaign}`" class="scroll-bar">
    <FireWorksComponent v-if="fireworks"></FireWorksComponent>

    <div id="theNav" class="">
      <TheNavbar :isIntersectingElement="isIntersectingElement" />
    </div>

    <div v-if="message" class="npinfo ch-text-primary h1 text-center">
      {{ message }}
    </div>

    <div v-else class="body-base mx-auto">
      <IntersectionObserver
        sentinal-name="sentinal-name"
        @on-intersection-element="onIntersectionElement"
      >
        <TheBanner />

        <!-- Timers when type campaign classic (type = 0) or when supportCampaign (type = 2) -->
        <!-- Using V-SHOW because with V-IF the values inside timers are not showing. -->
        <div v-if="info && info.type_campaign == 0">
          <Timers />
        </div>
        <div v-else-if="info && info.type_campaign == 2">
          <TimersSupporterPage />
        </div>
      </IntersectionObserver>

      <!-- LIVE STREAM VIDEO COMPONENT -->
      <div id="LiveStreamVideoComponent">
        <LiveStreamVideoComponent
          :fromPage="`campaign`"
        ></LiveStreamVideoComponent>
      </div>
      <!-- /LIVE STREAM VIDEO COMPONENT -->

      <div class="information">
        <Info />
      </div>
      <div class="">
        <div id="filterSection" class="w-full">
          <div class="">
            <NewHeaderComponent />
          </div>
        </div>
      </div>
      <div>
        <DonationFormCreate :styles="`${campaign}`" />
        <ModalSupporter :styles="`${campaign}`" />
        <ModalDonationCompleted />
        <div v-show="modalCreateTeam">
          <TeamFormCreate :styles="`${campaign}`" />
        </div>
        <div v-show="modalCreateCommunity">
          <CommunityFormCreate :styles="`${campaign}`" />
        </div>
      </div>
      <div>
        <ModalDonationError />
        <ModalDonationDuplicated />
        <!-- <div v-show="modalCreateTeam">
          <TeamFormCreate :styles="`${campaign}`" />
        </div> -->
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import TheNavbar from "../../components/frontend/TheNavbarComponent.vue";
import NewHeaderComponent from "../../components/frontv2/NewHeaderComponent";
import TheBanner from "../../components/frontend/TheBannerComponent.vue";
import Footer from "../../components/frontend/FooterComponent.vue";
import TheBannerMobile from "../../components/frontend/TheBannerMobileComponent.vue";
import ModalDonationCompleted from "../../components/frontend/ModalDonationCompletedComponent.vue";
import ModalDonationError from "../../components/frontend/ModalDonationErrorComponent.vue";
import ModalDonationDuplicated from "../../components/frontend/ModalDonationDuplicatedComponent.vue";
import DonationFormCreate from "../../components/frontend/Donation/FormCreateComponent.vue";
import ModalSupporter from "../../components/frontend/Donation/ModalSupporter.vue";
import TeamFormCreate from "../../components/frontend/Team/FormCreateComponent.vue";
import CommunityFormCreate from "../../components/frontend/Team/FormCreateCommunityComponent.vue";
import IntersectionObserver from "../../components/frontend/IntersectionObserverComponent.vue";
import LiveStreamVideoComponent from "../../components/frontv2/LiveStreamVideoComponent.vue";
import { mapState } from "vuex";

import Timers from "../../components/frontv2/Timers";
import TimersSupporterPage from "../../components/frontv2/TimersSupporterPage.vue";
import Info from "../../components/frontv2/Info";
import FireWorksComponent from "../../components/frontend/FireWorksComponent";

export default {
  computed: mapState([
    "modalCreateTeam",
    "message",
    "info",
    "itemsDonation",
    "modalCreateCommunity",
  ]),

  components: {
    TheNavbar,
    NewHeaderComponent,
    CommunityFormCreate,
    TeamFormCreate,
    IntersectionObserver,
    LiveStreamVideoComponent,
    TheBanner,
    FireWorksComponent,
    DonationFormCreate,
    ModalSupporter,
    ModalDonationCompleted,
    ModalDonationDuplicated,
    ModalDonationError,
    TheBannerMobile,
    Footer,
    Timers,
    TimersSupporterPage,
    Info,
  },

  metaInfo() {
    return {
      title: `${this.name} - Charity Live`,

      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: `Campaign ${this.name} - Charity.Live`,
        },
        {
          vmid: "description",
          name: "description",
          content: this.description,
        },

        {
          name: "description",
          content: this.description,
        },

        {
          vmid: "description",
          property: "description",
          content: this.description,
        },
      ],
    };
  },

  data() {
    return {
      isIntersectingElement: true,
      fireworks: false,
      name: "",
      description: "",
      campaign: `
        --charity-primary:  #cdcdcd;
        --charity-secondary:  #fafafa;
        --charity-third:  #000000;
        --charity-color-text:  #e3e3e3;
        --charity-color-alternative:  #ccc;
        --charity-color-title:  #ccc;
      `,
    };
  },
  watch: {
    info() {
      if (this.info) {
        const style = JSON.parse(this.info.style);
        this.name = this.info.name;
        this.description = this.info.excerpt;
        if (style.pickerPrimary) {
          this.campaign = `--charity-primary:  ${style.pickerPrimary.hexa};
                        --charity-secondary:  ${style.pickerSecondary.hexa};
                        --charity-third:  ${style.pickerTertiary.hexa};
                        --charity-color-text:  ${style.pickerPrimary.hexa};
                        --charity-color-alternative:  ${style.pickerSecondary.hexa};
                        --charity-color-title:  ${style.pickerPrimary.hexa};
                        `;
        }

        let endDate = this.info.end;
        const now = new Date();
        const endFireworks = new Date(this.info.end);
        endFireworks.setDate(endFireworks.getDate() + 2);
        endDate = new Date(endDate);
        if (endDate < now && endFireworks > now) {
          if (this.info.donations_amount >= this.info.goal) {
            this.fireworks = true;
          }
          // selected date is in the past
        }
      }
    },
  },
  created() {
    const params = window.location.pathname.slice(1).split("/");

    this.$store.dispatch("FETCH_CAMPAIGN_INFO", params[0]);
    this.$store.dispatch("FETCH_CAMPAIGN_MEDIA", params[0]);
    this.$store.dispatch("UPDATE_SEARCH", params);
  },
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    onIntersectionElement(value) {
      this.isIntersectingElement = value;
    },
  },
};
</script>
<style>
@font-face {
  font-family: Nunito-Black;
  src: url("/fonts/Nunito-Black.ttf");
}

@media (max-width: 600px) {
  .information {
    margin-top: 30px;
  }
}

.body-base {
  background-color: white !important;
  max-width: 1100px;
}

.banner {
  padding-top: 125px;
}

.npinfo {
  padding-top: 340px;
  height: 70vh;
}

@media (min-width: 960px) and (max-width: 1020px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (max-width: 600px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

.ch-text-primary {
  color: var(--charity-primary) !important;
}

.ch-text-secondary {
  color: var(--charity-secondary) !important;
}

.ch-text-third {
  color: var(--charity-third) !important;
}

.ch-primary {
  background-color: var(--charity-primary) !important;
}

.ch-secondary {
  background-color: var(--charity-secondary) !important;
}

.ch-third {
  background-color: var(--charity-third) !important;
}

.ch-light {
  background-color: var(--background) !important;
}

.ch-text-color {
  color: var(--charity-color-text) !important;
}

.ch-title-color {
  color: var(--charity-color-title) !important;
}

.ch-font-primary {
  font-family: var(--charity-font-primary) !important;
}

.ch-font-secondary {
  font-family: var(--charity-font-secondary) !important;
}

.ch-font-third {
  font-family: var(--charity-font-title) !important;
}

.ch-font-title {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.ch-color-gradient {
  background: var(--charity-primary);
  background: linear-gradient(
    90deg,
    var(--charity-primary) 0%,
    var(--charity-third) 100%
  );
}

.ch-color-gradient-inverse {
  background: var(--charity-third);
  background: linear-gradient(
    90deg,
    var(--charity-third) 0%,
    var(--charity-primary) 100%
  );
}

.ch-font-btn {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}
</style>
