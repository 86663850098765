<template>
  <div class="contentCharity">
    <div>
      <div class="title-campaign">
        <div>
          {{ $t("Join_the") }}
        </div>
        <div style="font-size: 1.2em" class="red--text">
          {{ $i18n.locale == "en" ? info.name : info.name_he }}
        </div>
      </div>
    </div>
    <div class="mx-auto">
      <div class="">
        <div class="text-center title">{{ $t("Join_the_Learning") }}</div>
        <v-row class="text-center">
          <v-col cols="12" md="12" class="mx-auto"> </v-col>

          <v-col cols="12" md="12" class="mx-auto">
            <v-btn
              class="btn-text rounded-lg font-weight-medium white--text"
              color="error "
              style="font-size: 35px"
              elevation="0"
              height="60px"
              width="340px"
              @click="toggleSupportModal()"
            >
              {{ $t("SIGN_UP_HERE") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" class="mt-1 pr-2 text-right">
            <v-btn
              class="
                ml-
                mr-6
                ch-border-secondary
                rounded-lg
                font-weight-medium
                ch-text-secondary
                btn-title
              "
              color=""
              elevation="0"
              height="44px"
              outlined
              width="200px"
              @click="createMyCommunity()"
              >{{ $t("create_my_community") }}
            </v-btn>
          </v-col>
        </v-row>
        <DataSupporters />
      </div>
    </div>
    <div class="clocks"></div>
  </div>
</template>

<script>
import RaisedSupporters from "./RaisedSupportersComponent.vue";
import DataSupporters from "./DataSupportersComponent.vue";
import CountDown from "./CountDown.vue";
import ClockSupporters from "./ClockSupporters.vue";

export default {
  name: "TimersSupporterPage",
  components: {
    DataSupporters,
    RaisedSupporters,
    CountDown,
    ClockSupporters,
  },
  data() {
    return {
      item: {
        amount: 0,
        currency: "",
        name: "",
        surname: "",
        display_name: "",
        anonymus: false,
        email: "",
        message: "",
        billing_name: "",
        private_message: "",
        item: null,
        installment: 1,
        phone: "",
        country_code: "",
        team: [],
        team_full: null,
        community: "",
        wallet: "",
        async_payment_message: null,
        teffilah_first: "",
        teffilah_second: "",
        gift_aid: null,
        terms: false,
        url: 5,
        cid: "",
        zip_code: 0,
        house_number: "",
        city: "",
        currency_exchange: 1,
        card_number: "",
        exp_date: "",
        form_id: "2.2.4",
        pay_status: [],
        gpay_pm: "",
        token: null,
        title: null,
        surname: "",
      },
    };
  },
  methods: {
    toggleSupportModal() {
      this.$store.commit("TOGGLE_MODAL_SUPPORTER");
    },
    makeDonation() {
      this.$store.commit("UPDATE_AMOUNT_TO_DONATE", this.item);
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    createMyCommunity() {
      this.$store.commit("TOGGLE_MODAL_CREATE_COMMUNITY");
    },
  },
  watch: {},
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  async created() {
    const params = window.location.pathname.slice(1).split("/");

    const data = await axios.get(`/api/v1/campaign/${params[0]}/item`);
    this.items = data.data.map((x) => {
      // Assign new properties and return
      return Object.assign(x, {
        active: false,
      });
    });
    this.$store.commit("UPDATE_ITEMS_DONATION", data.data);

    setTimeout(() => {
      fetch("https://icanhazip.com")
        .then((response) => response.text())
        .then((data) => {
          const ip = data;
          fetch(`https://freegeoip.app/json/${ip}`)
            .then((response) => response.json())
            .then((data) => {
              this.geoIP = data.country_code;
              if (
                data.country_code == "AR" ||
                data.country_code == "UK" ||
                data.country_code == "GB"
              ) {
                if (this.currencies) {
                  const index = this.currencies.findIndex(
                    (element) => element.id == 2
                  );

                  if (index != -1) {
                    this.item.currency = 2; // GEOIP
                  }
                }
              }
            });
        });
    }, 2000);
  },
};
</script>

<style scoped>
.clocks {
  position: relative;
  margin: 0 auto;
}

.hidden {
  display: none;
}

.title-campaign {
  overflow: visible;
  text-align: center;
  font-family: "Nunito-Black", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 33px;
  margin: 0 auto;
  color: #616060;
}
.w-45 {
  width: 45%;
}
.w-10 {
  width: 10%;
}

.or-with {
  width: 90vw !important;
  text-align: center;
  margin: 2px auto !important;
  padding-bottom: 0px !important;
}
@media (max-width: 426px) {
  .contentCharity {
    margin-top: 40px;
  }
  .or-with {
    width: 90vw !important;
    text-align: center;
    margin: 10px auto !important;
    padding-bottom: 0px !important;
  }
}
</style>
