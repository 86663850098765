<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :color="color"
      :timeout="15000"
      right
      style="z-index: 10001"
      top
    >
      <span>{{ text }} </span>

      <div class="d-flex justify-space-between">
        <v-btn dark elevation="0" icon small @click="snackbar = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog
        v-model="modalSupporter"
        :fullscreen="fullscreen"
        :retain-focus="false"
        class="description-area"
        max-width="900px"
        persistent
        style="z-index: 99999"
      >
        <v-card
          :class="{ 'text-direction': lang_init }"
          :disabled="loading_form"
          :loading="loading_form"
          :style="styles"
          class="relative description-area"
        >
          <div
            :style="styles"
            class="
              px-6
              py-3
              ch-font-title
              size-title
              text-white
              ch-primary
              font-weight-medium
              d-flex
              align-center
              justify-space-between
              sticky-toolbar
            "
          >
            <div>
              <!-- {{ $t("title_supporter_form") }} -->
              {{ $t("Join_the") }}
              <span v-if="info && info.name">
                {{ $i18n.locale == "en" ? info.name : info.name_he }}
              </span>
            </div>
            <div>
              <v-btn class="m-neg" icon small @click="toggleSupportModal()">
                <v-icon class="font-weight-bold" color="white" small>
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="mx-6 pt-8 px-0 form-donation">
            <v-row>
              <v-col class="py-0" cols="6" md="6">
                <v-text-field
                  v-model="$v.item.name.$model"
                  :error-messages="nameErrors"
                  :label="$t('First_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.name.$touch()"
                  @input="$v.item.name.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="6" md="6">
                <v-text-field
                  v-model="$v.item.surname.$model"
                  :error-messages="surnameErrors"
                  :label="$t('Last_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.surname.$touch()"
                  @input="$v.item.surname.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-combobox
                  v-model="$v.item.name.$model"
                  :error-messages="nameErrors"
                  :label="$t('First_Name')"
                  background-color="#F1F1F1"
                  color="#323232"
                  chips
                  dense
                  hide-no-data
                  outlined
                  multiple
                  deletable-chips
                  @blur="$v.item.name.$touch()"
                  @input="$v.item.name.$touch()"
                >
                </v-combobox>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <!-- <p v-if="item.team_id">
                      {{item.team_id}}
                  </p> -->
                <v-autocomplete
                  v-model="item.community_id"
                  :items="communities"
                  :label="$t('select_community')"
                  :menu-props="{
                    maxHeight: 304,
                    closeOnContentClick: true,
                  }"
                  background-color="#F1F1F1"
                  class=""
                  color="#FEF0F0"
                  clearable
                  dense
                  item-text="public_name"
                  item-value="id"
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-btn
                            class="float-right"
                            color="red"
                            outlined
                            x-small
                          >
                            Close
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>
                      <i style="font-size: 0.9em">({{ item.id }})</i>
                      <strong>{{ item.name }}</strong>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" md="6">
                <v-text-field
                  v-model.trim="$v.item.email.$model"
                  :error-messages="emailErrors"
                  :label="$t('email')"
                  :rules="[]"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  type="text"
                  @blur="$v.item.email.$touch()"
                  @input="$v.item.email.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="6">
                <div class="d-flex">
                  <div>
                    <vue-country-code
                      :preferredCountries="['gb', 'us', 'ca']"
                      style="
                        height: 40px;
                        border-color: #aaa;
                        background-color: #f1f1f1;
                      "
                      @onSelect="onSelect"
                    >
                    </vue-country-code>
                  </div>
                  <div class="pl-2" style="width: 100%">
                    <v-text-field
                      v-model="$v.item.phone.$model"
                      :label="$t('phone_optional')"
                      background-color="#F1F1F1"
                      color="#323232"
                      dense
                      outlined
                      type="number"
                      @blur="$v.item.phone.$touch()"
                      @input="$v.item.phone.$touch()"
                      :error-messages="phoneErrors"
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="6" md="4">
                <v-text-field
                  v-model="$v.item.city.$model"
                  :label="$t('city')"
                  :rules="[]"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  @blur="$v.item.city.$touch()"
                  @input="$v.item.city.$touch()"
                  outlined
                  type="text"
                >
                  <!-- :error-messages="cityErrors" -->
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="6" md="4">
                <v-text-field
                  v-model="$v.item.state.$model"
                  :label="$t('state')"
                  :rules="[]"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  @blur="$v.item.state.$touch()"
                  @input="$v.item.state.$touch()"
                  outlined
                  type="text"
                >
                  <!-- :error-messages="stateErrors" -->
                </v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" md="4">
                <v-text-field
                  v-model="$v.item.country.$model"
                  :label="$t('country')"
                  :rules="[]"
                  background-color="#F1F1F1"
                  color="#323232"
                  dense
                  outlined
                  @blur="$v.item.country.$touch()"
                  @input="$v.item.country.$touch()"
                  type="text"
                >
                  <!-- :error-messages="countryErrors" -->
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <v-textarea
                  v-model="$v.item.message.$model"
                  :error-messages="messageErrors"
                  :label="$t('Leave_a_message_of_support_(optional)')"
                  background-color="#F1F1F1"
                  color="#323232"
                  counter="500"
                  dense
                  maxlength="500"
                  outlined
                  rows="3"
                  @blur="$v.item.message.$touch()"
                  @input="$v.item.message.$touch()"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <div class="subtitle-2">
                  {{
                    $t(
                      "I_will_bli_neder_join_this_1_hour_global_initiative_on_the_first_night_of_Purim_"
                    )
                  }}
                </div>
                <v-radio-group
                  v-model="item.option"
                  class="d-flex my-0 justify-center mb-1"
                  hide-details
                >
                  <v-radio
                    class="mt-0 mx-2 subtitle-2"
                    hide-details
                    :label="$t('For_Men_1_Hour_of_Torah_learning')"
                    value="For Men:	1 Hour of Torah learning"
                  ></v-radio>
                  <v-radio
                    class="mb-0 mx-2 subtitle-2"
                    :label="$t('For_Women_20_Minute_of_Tehillim')"
                    value="For Women:	20 Minute of Tehillim"
                    hide-details
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <div>
            <div class="w-full px-0 py-1 mt-1">
              <div class="d-flex justify-center align-center">
                <v-checkbox
                  v-model="$v.item.terms.$model"
                  class="mt-0 pt-0 mr-0"
                  color="#323232"
                  dense
                  :error-messages="termsErrors"
                  hide-details
                  @blur="$v.item.terms.$touch()"
                  @input="$v.item.terms.$touch()"
                >
                </v-checkbox>
                <div>
                  {{ $t("I_agree_with") }}
                  <span class="blue--text pointer" @click="modalTerms = true"
                    >{{ $t("terms_and_conditions") }}
                  </span>
                </div>
              </div>
              <div
                class="red--text text-center"
                v-for="t in termsErrors"
                :key="t"
              >
                {{ t }}
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  :loading="loading"
                  :style="styles"
                  class="
                    my-6
                    ch-color-gradient
                    btn-text
                    rounded-lg
                    font-weight-medium
                    white--text
                  "
                  elevation="0"
                  height="45px"
                  width="300px"
                  @click="sendForm()"
                >
                  {{ $t("sign_up") }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalCongratulations" max-width="700" persistent>
        <v-card
          :class="$i18n.locale == 'he' ? 'text-direction text-right' : null"
        >
          <div class="float-right">
            <v-btn icon @click="closeCongratulations()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="p-4 teal--text darken-4 headline font-weight-bold">
            {{ $t("Thank_you_for_Joining") }}
          </div>
          <!-- <div style="font-size: 1.3em" class="px-4">
            <p>
              {{ $t("To_add_a_Donation") }}

              <a href="/harnofkollel"> {{ $t("click_here") }}</a>
              <br />
              {{ $t("Please_note_This_donation_supports_the_Har_Nof_Kollel") }}
            </p>

            <p>
              {{ $t("To_donate_to_the_Ukraine_emergency_fund") }}

              <a href="https://www.charityextra.com/moshefhima" target="_blank">
                {{ $t("click_here") }}</a
              >
            </p>
          </div> -->
          <div style="font-size: 1.6em" class="mx-4 px-4 py-3">
            <div>
              <div class="my-1">{{ $t("share") }}</div>
              <div>
                <a
                  :href="mail_share"
                  class="mx-1"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-icon large color="info"> mdi-email-outline</v-icon>
                </a>
                <a
                  :href="wp_share"
                  class="mx-1"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-icon large color="success"> mdi-whatsapp</v-icon>
                </a>
                <a
                  :href="linkedin_share"
                  class="mx-1"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <v-icon large color="info"> mdi-linkedin</v-icon>
                </a>
              </div>
            </div>
            <div class="d-flex justify-end items-end">
              <v-btn
                class="button"
                color="primary lighten-2"
                dark
                outlined
                @click="createMyTeam"
                >{{ $t("create_my_team") }}
              </v-btn>

              <v-btn color="error" text @click="closeCongratulations()"
                >{{ $t("close") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalTerms" max-width="90%" scrollable>
        <v-card>
          <v-card-title class="headline font-weight-bold">
            Terms of Service &nbsp;
            <strong>(“Terms”)</strong>
            <v-spacer></v-spacer>
            <v-btn icon @click="modalTerms = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="custom-scrollbar">
            <TermsOfService></TermsOfService>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="openPrivacy()"
              >SEE PRIVACY
            </v-btn>
            <v-btn color="green darken-1" text @click="modalTerms = false"
              >CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="modalPrivacy" max-width="90%" scrollable>
        <v-card>
          <v-card-title class="headline font-weight-bold">
            Charity Live Privacy Policy
            <v-spacer></v-spacer>
            <v-btn icon @click="modalPrivacy = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="custom-scrollbar">
            <TermsOfPrivacy></TermsOfPrivacy>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="openTerms()"
              >SEE TERMS
            </v-btn>

            <v-btn color="green darken-1" text @click="modalPrivacy = false"
              >CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import {
  maxLength,
  required,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import TermsOfService from "./TermsOfService.vue";
import TermsOfPrivacy from "./TermsOfPrivacy";

export default {
  components: {
    TermsOfService,
    TermsOfPrivacy,
  },
  props: {
    styles: {
      default: null,
    },
  },
  data() {
    return {
      rules: {
        email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      },
      lang_init: false,
      dir_lang: "ltr",
      publishableKey: "",
      sessionId: "session-id", // session id from backend
      token: null,
      carouselItem: 0,
      modalCongratulations: false,
      modalTerms: false,
      minimum_donation_error: "",
      modalPrivacy: false,
      modalCondition: false,
      teams: [],
      item: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        city: "",
        state: "",
        country: "",
        option: [],
        country_code: "",
        message: "",
        terms: false,
        team: false,
        team_id: null,
        community_id: null,
      },
      url_share: null,
      fb_share: null,
      tw_share: null,
      wp_share: null,
      linkedin_share: null,
      mail_share: null,
      messageAmountText: null,
      idItemSelected: 0,
      image: [],
      // currencies: [],
      snackbar: false,
      loading: false,
      installment: 1,
      information: "",
      campaign: "",
      extra_data_payment: 0,
      extra_data_1_payment: 0,
      min: 0,
      max: 999999,
      element: "",
      card: "",
      stripe_var: "",
      fullscreen: true,
      repeatMessageActive: false,
      distributeInTeamsActive: true,
      urlImage: "",
      error_donation: "",
      color: "",
      text: "",
      congratulationsTitle: "",
      congratulationsText: "",
      exchange_shekel: [],
      communities: [],
      // TODO: MATCHERS
      matchers: [],
      match_message: "",
      amount_after_match: 0,
      base_match: 0,
      secret_stripe: "",
      message_donation: "",
      gp_stripe: null,
      gp_paymentRequest: null,
      loading_form: false,
      website: false,
      campaign_name: false,
    };
  },
  async created() {
    this.loading_form = false;

    if (window.addEventListener) {
      window.addEventListener("message", this.readPostResponse, false);
    } else {
      window.attachEvent("onmessage", this.readPostResponse);
    }
    this.url_share = window.location.href; // ${this.url_share}
    // this.fb_share = `https://www.facebook.com/sharer/sharer.php?u=${this.url_share}&quote=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!`;
    this.wp_share = `https://wa.me/?text=Hi%20there,%20I%20just%joined%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.url_share}`;
    // this.tw_share = `https://twitter.com/intent/tweet?text=Hi%20there,%20I%20just%20donated%20to%20to%20this%20important%20cause%20and%20invite%20you%20to%20join%20me!%0A${this.url_share}`;
    this.mail_share = `mailto:?subject=I Donated!&body=Hi there, I just joined to to this important cause and invite you to join me!%0A${this.url_share}`;
    this.linkedin_share = `https://linkedin.com/shareArticle?url=${this.url_share}`;

    const params = window.location.pathname.slice(1).split("/");
    if (params[0] == "website") {
      this.campaign = params[1];
      this.website = true;
    } else this.campaign = params[0];

    axios
      .get(`/api/v1/campaign/${this.campaign}/community/list`)
      .then((res) => {
        this.communities = res.data;
        // this.selectIdCommunityByStore();
      });

    // get teams
    axios.get(`/api/v1/campaign/${this.campaign}/team/list`).then((res) => {
      this.teams = res.data;
    });

    if (window.innerWidth > 900) this.fullscreen = false;
    // hrdcoding GBP
  },
  mounted() {
    // this.selectIdTeamByStore();
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.item.name.$dirty) return errors;
      !this.$v.item.name.required && errors.push("Required.");
      !this.$v.item.name.maxLength && errors.push("Max. 20 characters.");

      return errors;
    },
    info() {
      return this.$store.state.info;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.item.surname.$dirty) return errors;
      !this.$v.item.surname.required && errors.push("Required.");
      !this.$v.item.surname.maxLength && errors.push("Max. 20 characters.");

      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.item.phone.$dirty) return errors;
      !this.$v.item.phone.maxLength && errors.push("Max. 15 characters.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.item.message.$dirty) return errors;
      !this.$v.item.message.maxLength && errors.push("Max. 2500 characters.");
      return errors;
    },
    termsErrors() {
      const errors = [];
      if (!this.$v.item.terms.$dirty) return errors;
      !this.$v.item.terms.required &&
        errors.push("Please, accept terms and conditions.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.item.city.$dirty) return errors;
      !this.$v.item.city.maxLength && errors.push("Max. 40 characters.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.item.state.$dirty) return errors;
      !this.$v.item.state.maxLength && errors.push("Max. 40 characters.");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.item.country.$dirty) return errors;
      !this.$v.item.country.maxLength && errors.push("Max. 20 characters.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.item.email.$dirty) return errors;
      !this.$v.item.email.required && errors.push("Required.");
      !this.$v.item.email.email && errors.push("Invalid format.");
      return errors;
    },

    modalSupporter() {
      return this.$store.state.modalSupporter;
    },
  },
  watch: {
    info() {
      this.min = this.info.minimum_donation;
      this.item.message = this.info.default_message;
      this.campaign_name = this.info.name;
    },
    modalSupporter() {
      if (window.innerWidth > 900) this.fullscreen = false;
      this.langDirection();
    },
  },
  methods: {
    cancelForm() {
      this.item.name = "";
      this.item.surname = "";
      this.item.email = "";
      this.item.phone = "";
      this.item.city = "";
      this.item.state = "";
      this.item.country = "";
      this.item.country_code = "";
      this.item.message = "";
      this.item.terms = false;
      this.item.option = [];
    },
    onSelect({ name, iso2, dialCode }) {
      this.item.country_code = dialCode;
    },
    createMyTeam() {
      this.$store.commit("TOGGLE_MODAL_CREATE_TEAM");
      this.closeCongratulations();
    },

    toggleSupportModal() {
      this.$store.commit("TOGGLE_MODAL_SUPPORTER");
    },
    langDirection() {
      const lang_init = localStorage.getItem("charity-locale");
      if (!lang_init || lang_init == "en") {
        this.lang_init = false;
        this.dir_lang = "ltr";
      } else if (lang_init == "he") {
        this.lang_init = true;
        this.dir_lang = "rtl";
      }
    },

    openPrivacy() {
      this.modalTerms = false;
      this.modalPrivacy = true;
    },
    openTerms() {
      this.modalTerms = true;
      this.modalPrivacy = false;
    },

    closeCongratulations() {
      this.modalCongratulations = false;
      this.$store.commit("TOGGLE_MODAL_SUPPORTER");
    },

    makeDonation() {
      this.modalCongratulations = false;

      this.$store.commit("TOGGLE_MODAL_SUPPORTER");
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    sendForm() {
      this.$v.item.$touch();
      if (this.$v.item.$pending || this.$v.item.$error) {
        this.loading = false;
        return;
      }
      this.$v.item.$reset();
      this.loading = true;

      axios
        .post(`/api/v2/campaign/${this.campaign}/supporter`, this.item)
        .then((res) => {
          this.modalCongratulations = true;
          this.cancelForm();
          this.$store.dispatch("UPDATE_CAMPAIGN_DONATED_INFO", this.campaign);
        })
        .catch((error) => {
          this.color = "error";
          this.text = `There was an error: ${error.response.data.message}`;
          this.error_donation = "";
        })
        .finally(() => {
          this.loading = false;
          this.$store.commit("UPDATE_MONITOR_DONATIONS");
          setTimeout(() => {
            this.error_donation = "";
          }, 10000);
        });
    },
    // validateSelectedTeam(value) {
    //   this.item.team = value.filter((item) => typeof item !== "string");
    // },
  },
  validations: {
    item: {
      name: {
        required,
        maxLength: maxLength(20),
      },
      message: {
        maxLength: maxLength(2500),
      },
      surname: {
        required,
        maxLength: maxLength(20),
      },
      email: {
        email,
        required,
      },
      phone: {
        maxLength: maxLength(15),
      },
      city: {
        maxLength: maxLength(40),
      },
      state: {
        maxLength: maxLength(40),
      },
      country: {
        maxLength: maxLength(20),
      },
      terms: {
        required: (el) => {
          return el == true;
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.size-title > div {
  height: 26px;
}

.active {
  cursor: pointer;
  background-color: #f1f1f1;
  color: var(--charity-primary);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.match {
  padding: 5px;
  width: 67px;
  height: 67px;
  border: 3px solid var(--charity-color-title);
  background-color: #f1f1f1;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 3px !important;
}

::-webkit-scrollbar:vertical {
  display: block;
}

.btn-text {
  font-size: 20px !important;
}

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

.StripeElement {
  border: 1px solid #a4a4a4 !important;
  background-color: var(--background) !important;
}

.item-card {
  width: 220px;
  min-height: 120px;
  border-radius: 8px;
}

.goal_item {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(58, 58, 58, 1);
  margin: 0 auto;
}

.border-ch-primary {
  border: 1px solid var(--charity-primary);
}

.imageOfItem {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  border-style: solid;
  border-color: var(--charity-primary);
  margin: 0 auto;
  margin-top: 10px;
  display: block;
}

.items-coarrousel-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;

  .img-container {
    width: 50px;
    height: auto;
    margin-bottom: 32px;
  }

  h4 {
    font-weight: bold;
  }
}

.pink-text {
  color: #be2c83;
}

.black-text {
  color: #000;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.item-card {
  width: 220px;
  border-radius: 8px;
}

.font-title-item {
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  min-height: 35px;
}

.font-amount-item {
  font-size: 32px;
  font-weight: bold;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

.icon-carousel {
  background-color: rgba(0, 0, 0, 0.35) !important;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  visibility: visible;
}

.font-xs {
  font-size: 0.8rem;
}

.poster-influ {
  width: 230px;
  height: 122px;
  border: 1px solid #cdcdcd;
}

@-moz-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}

@keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}

@-moz-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}

@keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}

.icon-next {
  right: 5px;
}

.justifyCenter {
  justify-content: center;
}

.influencer-area {
  position: relative;
}

.icon-prev {
  left: 5px;
}

.nedarimFrame {
  width: 100%;
  box-sizing: border-box;
  height: 350px;
  border: 4px solid indianred;
  padding: 15px;
  margin-top: 10px;
}

.m-neg {
  margin-top: -10px;
  margin-left: -10px;
}

.text-direction {
  direction: rtl !important;
}

.new-line {
  white-space: pre;
}
</style>

