var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.info &&
    _vm.info.donations_amount >= _vm.info.goal &&
    _vm.campaignFinished == true &&
    _vm.info.is_countdown_trophy_image_hidden == 0
  )?_c('div',[_c('v-img',{staticClass:"mx-auto d-block",attrs:{"lazy-src":"/img/trofeo-min.png","width":"227px","src":"/img/trofeo-min.png"}})],1):(
    _vm.info &&
    _vm.info.donations_amount < _vm.info.goal &&
    _vm.campaignFinished == true &&
    _vm.info.is_countdown_welldone_image_hidden == 0
  )?_c('div',[_c('img',{staticClass:"mx-auto d-block",attrs:{"width":"227px","loading":"lazy","src":"/img/welldone.png"}})]):(_vm.startCampiagnPassed == false)?_c('div',{staticClass:"mx-auto justify-center d-inline",staticStyle:{"height":"290px"}},[(!_vm.hide_clock && _vm.info && _vm.info.is_clock_hidden == 0)?_c('div',{staticClass:"d-flex justify-center"},[_c(_vm.component,{tag:"vue-ellipse-progress",attrs:{"dot":{
        size: 20,
        backgroundColor: 'black',
        width: '2px',
      },"id":"timer-example","progress":_vm.progress,"determinate":_vm.determinate,"line":"butt","color":_vm.colorOfTimer,"empty-color":"#b4b4b4","emptyColorFill":_vm.emptyColorFill,"thickness":"9","emptyThickness":"8","size":210,"dash":"strict 12 0.95","line-mode":"in 7","legend":false,"legendClass":"legend-custom-style","fontSize":"1.5rem","font-color":"white","animation":"reverse 1000","loading":_vm.loading,"no-data":_vm.noData}},[_c('span',{staticClass:"d-flex",staticStyle:{"margin-top":"-20px"},attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_c('div',{staticClass:"d-inline",staticStyle:{"line-height":"1.6"}},[_c('div',[_c('p',{staticClass:"text-center time_left"},[_vm._v("TIME LEFT")])]),_vm._v(" "),(_vm.moreThanFourTeenHours)?_c('v-row',{staticClass:"justify-center daysTimer textBig pulse"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.daysForCampaign)+" ")]),_vm._v(_vm._s(_vm.dayText)+"\n          ")]):_vm._e(),_vm._v(" "),(!_vm.moreThanFourTeenHours)?_c('v-row',{staticClass:"justify-center",class:{
              textSmall: _vm.moreThanFourTeenHours,
              textBig: !_vm.moreThanFourTeenHours,
            }},[_c('div',[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse",attrs:{"id":"days-clock"}},[_vm._v(_vm._s(_vm.hours))])])]),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse",attrs:{"id":"hours-clock"}},[_vm._v(_vm._s(_vm.minutes))])])]),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse",attrs:{"id":"minutes-clock"}},[_vm._v(_vm._s(_vm.seconds))])])])]):_vm._e()],1)])])],1):_vm._e()]):_c('div',{staticClass:"mx-auto justify-center d-inline",staticStyle:{"height":"290px"}},[(!_vm.hide_clock && _vm.info && _vm.info.is_clock_hidden == 0)?_c('div',{staticClass:"d-flex justify-center"},[_c(_vm.component,{tag:"vue-ellipse-progress",attrs:{"id":"timer-example","progress":_vm.progress,"determinate":_vm.determinate,"color":_vm.colorOfTimer,"empty-color":"#324c7e","emptyColorFill":_vm.emptyColorFill,"thickness":"8","emptyThickness":"3","size":210,"dash":"strict 60 0.8","line-mode":"out ","legend":false,"fontSize":"1.5rem","font-color":"white","animation":"bounce  1000 100","loading":_vm.loading,"no-data":_vm.noData}},[_c('span',{staticClass:"d-flex",attrs:{"slot":"legend-caption"},slot:"legend-caption"},[_c('div',{staticClass:"d-inline",staticStyle:{"line-height":"1.6"}},[_c('div',[_c('p',{staticClass:"text-center time_left"},[_vm._v("LAUNCHING IN")])]),_vm._v(" "),(_vm.moreThanFourTeenHours)?_c('v-row',{staticClass:"justify-center daysTimer textBig pulse"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.daysForCampaign)+" ")]),_vm._v(_vm._s(_vm.dayText)+"\n          ")]):_vm._e(),_vm._v(" "),(!_vm.moreThanFourTeenHours)?_c('v-row',{staticClass:"justify-center",class:{
              textSmall: _vm.moreThanFourTeenHours,
              textBig: !_vm.moreThanFourTeenHours,
            }},[_c('div',[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse"},[_vm._v(_vm._s(_vm.hours))])])]),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse"},[_vm._v(_vm._s(_vm.minutes))])])]),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"countdown-numbers pulse"},[_vm._v(_vm._s(_vm.seconds))])])])]):_vm._e()],1)])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }