<template>
  <div>
    <div v-if="loading === true">
      <Loading :loading="loading"></Loading>
    </div>
    <div
      v-if="items == ''"
      class="d-flex flex-column align-center justify-center m-0 p-0"
      style="height: 300px"
    >
      <div>
        <v-img
          contain
          height="120px"
          src="img/NoDonations.svg"
          width="120px"
        ></v-img>
      </div>
      <div class="ch-font-title ch-title-color">No supporters yet!</div>
    </div>

    <v-row v-if="items">
      <v-col v-for="(item, index) in items" :key="index" cols="12" sm="4">
        <div class="rounded-lg border-gray ch-shadow card-donation pt-4">
          <div class="mx-6 d-flex align-center">
            <div class="pr-2">
              <v-avatar
                :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                class="white--text"
                dark
                style="font-family: 'Roboto'; color: #292929"
              >
                <template v-if="item.path">
                  <img :src="item.path" alt="" />
                </template>
                <template v-else>
                  {{ item.name | initWord }}
                </template>
              </v-avatar>
            </div>
            <div class="ml-2 text-line-brack">
              <div class="general-title font-weight-bold">
                {{ item.name }}
                {{ item.surname }}
              </div>
              <div class="mt- grey--text dateOfDonation">
                <template v-if="isShowDate(item.updated_at)">
                  <div class="mt- grey--text dateOfDonation">
                    {{ hummanDate(item.updated_at) }}
                  </div>
                </template>
                <!-- <span v-show="item.team_name"> with</span> -->
              </div>
            </div>
          </div>
          <div class="messages">
            <div class="mx-8 my-2 text-small description-area grey--text">
              {{ item.message }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="d-flex justify-center my-4">
      <v-btn
        v-if="next"
        :loading="loading"
        class="button btn-large rounded-lg ch-text-primary"
        dark
        elevation="0"
        large
        text
        @click="getSupportPaginate"
      >
        {{ $t("see_more") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "../LoadingComponent.vue";
import hummanDate from "../../../utils/human-date.util";
import moment from "moment-timezone";

export default {
  props: {
    columns: {
      type: Number,
      default: 4,
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      styles: {
        pickerRespondTeam: {
          hexa: "",
        },
      },
      campaign: "",
      value: 30,
      bufferValue: 120,
      interval: 0,
      items: null,
      next: null,
      loading: false,
      lastSupporter: 0,
      validateS: 0,
    };
  },
  computed: mapState(["search", "orderByFinder", "info", "monitorDonations"]),
  watch: {
    info() {
      this.connect();
    },
    monitorDonations() {
      this.syncSupporters();
    },
    search() {
      this.getSupportByQuery();
    },
    orderByFinder() {
      this.getSupportByQuery();
    },
  },
  created() {
    const params = window.location.pathname.slice(1).split("/");
    this.campaign = params[0];

    setInterval(() => {
      this.syncSupporters();
    }, 50000);
    this.connect();
  },
  methods: {
    getSupporters() {
      const params = window.location.pathname.slice(1).split("/");
      axios
        .get(
          `/api/v2/campaign/${params[0]}/supporter?order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
          this.loading = false;

          if (this.orderByFinder == "Latest")
            this.lastSupporter = this.items[0].updated_at;
        });
    },
    connect() {
      this.getSupporters();
      if (this.info && this.info.id) {
        window.Echo.channel("donations." + this.info.id).listen(
          ".donations",
          (e) => {
            this.syncSupporters();
          }
        );
      }
    },
    isShowDate(from) {
      const now = moment().tz(moment.tz.guess());
      const fromDate = moment.utc(from).tz(moment.tz.guess());
      const diff = now.diff(fromDate, "days");
      return diff < 15 ? true : false;
    },
    hummanDate(from) {
      return hummanDate(from);
    },
    syncSupporters() {
      this.$store.dispatch("UPDATE_CAMPAIGN_DONATED_INFO", this.campaign);

      if (this.orderByFinder == "Latest") this.validateS++;
      const variable = this.validateS;
      axios
        .get(
          `/api/v2/campaign/${this.info.slug}/getLastSupport?latest=` +
            this.lastSupporter
        )
        .then((res) => {
          if (variable == this.validateS) {
            this.lastSupporter = this.items[0].updated_at;
            this.updateCards(res.data.data);
          }
        });
    },
    updateCards(data) {
      data.forEach((el) => {
        const index = this.items.findIndex((element) => element.id === el.id);

        if (index == -1) {
          this.items.unshift(el);
        } else {
          this.items.splice(index, 1);
          this.items.unshift(el);
        }
      });
      this.items.sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    },
    getSupportPaginate() {
      this.loading = true;
      axios
        .get(`${this.next}&query=${this.search}&order=${this.orderByFinder}`)
        .then((res) => {
          if (res.data) {
            res.data.data.forEach((el) => {
              const index = this.items.findIndex(
                (element) => element.id === el.id
              );
              //   if (index == -1) {
              //     this.items.unshift(el);
              //   } else {
              //     this.items.splice(index, 1);
              this.items.unshift(el);
              //   }
            });
            if (this.orderByFinder == "Latest") {
              this.items.sort(
                (a, b) =>
                  new Date(b.updated_at).getTime() -
                  new Date(a.updated_at).getTime()
              );
            }
          }
          this.next = res.data.next_page_url;
          this.loading = false;
        });
    },
    getSupportByQuery() {
      const params = window.location.pathname.slice(1).split("/");

      axios
        .get(
          `/api/v2/campaign/${this.campaign}/supporter?query=${this.search}&order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.next = res.data.next_page_url;
          if (this.orderByFinder == "Latest")
            this.lastSupporter = this.items[0].updated_at;
        });
    },
  },
};
</script>

<style scoped>
.abs-bottom {
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
}
</style>
