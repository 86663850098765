var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.startIsBiggerThanNow)?_c('div',[(_vm.info)?_c('div',{staticStyle:{"line-height":"1.4"}},[(_vm.info && _vm.info.donations_amount)?_c('div',{staticClass:"text-uppercase",attrs:{"id":"raised"}},[_vm._v("\n      Raised "),_c('br'),_vm._v(_vm._s(_vm.info.principal_currency.symbol)+"\n      "+_vm._s(_vm._f("dollar")(_vm.info.donations_amount))+"\n    ")]):_vm._e(),_vm._v(" "),(
        _vm.info &&
        _vm.info.donations_amount > _vm.info.goal &&
        _vm.info.extra_goal &&
        _vm.info.extra_goal > _vm.info.goal
      )?_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"goalstrikethrough-text text-through"},[_vm._v("\n        "+_vm._s(_vm._f("uppercase")(_vm.$t("goal")))+"\n        "+_vm._s(_vm.info.principal_currency.symbol)+" "+_vm._s(_vm._f("dollar")(_vm.info.goal))+"\n      ")])]):_vm._e(),_vm._v(" "),(_vm.info && _vm.info.goal)?_c('div',{attrs:{"id":"objectGoal"}},[(
          _vm.info &&
          _vm.info.donations_amount > _vm.info.goal &&
          _vm.info.extra_goal &&
          _vm.info.extra_goal < _vm.info.goal &&
          _vm.info.third_goal &&
          _vm.info.third_goal > _vm.info.goal
        )?_c('span',[_c('span',{staticClass:"goalstrikethrough-text text-through"},[_vm._v("\n          "+_vm._s(_vm._f("uppercase")(_vm.$t("_bonus")))+"\n          "+_vm._s(_vm._f("uppercase")(_vm.$t("goal")))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"goalstrikethrough-text text-through",staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v("\n          "+_vm._s(_vm._f("uppercase")(_vm.$t("goal")))+"\n          "+_vm._s(_vm.info.principal_currency.symbol)+"\n          "+_vm._s(_vm._f("dollar")(_vm.info.extra_goal)))]),_c('br'),_vm._v(" "),_c('span',{staticClass:"goalstrikethrough-text",staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v("THIRD GOAL")]),_vm._v(" "),_c('span',{staticClass:"goalstrikethrough-text",staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v("\n          "+_vm._s(_vm.info.principal_currency.symbol)+"\n          "+_vm._s(_vm._f("dollar")(_vm.info.third_goal))+"\n        ")])]):(
          _vm.info &&
          _vm.info.donations_amount > _vm.info.goal &&
          _vm.info.extra_goal &&
          _vm.info.extra_goal > _vm.info.goal
        )?_c('span',[_c('span',{staticClass:"goalstrikethrough-text"},[_vm._v("\n          "+_vm._s(_vm._f("uppercase")(_vm.$t("_bonus")))+"\n          "+_vm._s(_vm._f("uppercase")(_vm.$t("goal")))+"\n          "),_c('span',{staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v("\n            "+_vm._s(_vm.info.principal_currency.symbol)+"\n            "+_vm._s(_vm._f("dollar")(_vm.info.extra_goal))+"\n          ")])])]):_c('span',{staticClass:"goalstrikethrough-text",staticStyle:{"font-style":"normal","font-weight":"bold"}},[_vm._v("\n        "+_vm._s(_vm._f("uppercase")(_vm.$t("goal")))+"\n        "+_vm._s(_vm.info.principal_currency.symbol)+" "+_vm._s(_vm._f("dollar")(_vm.info.goal))+"\n      ")])]):_vm._e()]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }