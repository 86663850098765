<template>
  <div v-if="items.length > 0" class="mt-0 pt-0">
    <div class="d-flex justify-center overflow-scroll-y">
      <div
        v-if="items"
        id="carousel-matchers-items"
        class="
          pb-2
          px-1
          d-flex
          carousel-matchers-items
          flex-wrap
          overflow-scroll-y
          justifyCenter
        "
        :class="{ justifyCenter: items.length < 4 }"
      >
        <div v-for="(item, i) in items" :key="i" class="mx-4 mb-8">
          <div
            class="
              matcher-item
              ch-shadow
              border-gray
              card-item
              px-2
              py-4
              pointer
            "
          >
            <!-- <div class="matcher-avatar-container">
              <div class="matcher-avatar">{{ item.name  | initsName }}</div>
            </div> -->
            <v-avatar
              :color="i % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
              size="75"
              dark
              class="white--text"
              style="font-family: 'Roboto'; color: #292929"
            >
              <v-img :src="item.media.path" width="75px" v-if="item.media">
              </v-img>
              <span v-else>{{ item.name | initsName }}</span>
            </v-avatar>
            <div class="matcher-info">
              <div class="mt-2 general-title font-weight-bold">
                {{ item.name }}
              </div>
              <!-- <div class="word-break"></div> -->
              <!-- <b v-if="item.value " class="pink-text ">Match X{{ item.value }}</b> -->
              <div class="word-break description-area" style="width: 210px">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      items: [],
    };
  },

  mounted() {
    this.getItems();
  },
  computed: mapState(["info"]),
  methods: {
    prev() {
      let a = document.getElementById("carousel-matchers-items").scrollLeft;
      a -= 362;
      document
        .getElementById("carousel-matchers-items")
        .scroll({ left: a, behavior: "smooth" });
    },

    next() {
      let a = document.getElementById("carousel-matchers-items").scrollLeft;
      a += 362;
      document
        .getElementById("carousel-matchers-items")
        .scroll({ left: a, behavior: "smooth" });
    },

    getItems() {
      let campaign = window.location.pathname.slice(1).split("/");

      axios.get(`/api/v1/campaign/${campaign[0]}/matcher`).then((res) => {
        this.$store.commit("UPDATE_MATCHERS", res.data);
        this.items = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// Estilos para el carrusel (tomados de ItemsCarrouselComponent)
// Hay que definir si se va a usar una hoja de estilos global para casos como este
// por ahora y por tiempo estoy duplicando los estilos con el fin de usar el modificar
// scoped y evitar que los mismos se sobrescriban entre ellos.

.icon-carousel {
  background-color: rgba(0, 0, 0, 0.35) !important;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  bottom: 100px;
  visibility: hidden;
}

.influencer-area:hover .icon-prev {
  -moz-animation-name: left-to-right;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: left-to-right;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
.influencer-area:hover .icon-next {
  -moz-animation-name: right-to-left;
  -moz-animation-timing-function: ease-out;
  -moz-animation-duration: 0.4s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-name: right-to-left;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  visibility: visible;
}
@-moz-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}
@-webkit-keyframes left-to-right {
  0% {
    left: -30px;
  }
  100% {
    left: 10px;
  }
}
@-moz-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}
@-webkit-keyframes right-to-left {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}
.icon-next {
  right: 5px;
}

.justifyCenter {
  justify-content: center;
}

// Estilos para el titulo e imagen del carrusel de matchers
.items-coarrousel-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;

  .img-container {
    width: 50px;
    height: auto;
    margin-bottom: 32px;
  }

  h4 {
    font-weight: bold;
  }
}

.pink-text {
  color: #be2c83;
}

.black-text {
  color: #000;
}

.grey-text {
  color: rgba(0, 0, 0, 0.5);
}

// Estilos para el card de matchers
.matcher-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  // width: 100%;
  width: 332px;
  min-height: 140px;
  .matcher-avatar-container {
    border-radius: 100%;
    border: 1px solid #000;
    flex: 1 1 auto;
    .matcher-avatar {
      border-radius: 100%;
      border: 4px solid #fff;
      background-color: #e5b167;
      color: #fff;
      height: 100px;
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .matcher-info {
    display: inline;
    text-align: left;
    margin-left: 12px;
    .word-break {
      margin: 6px 0px;
    }
    p {
      line-height: 16px;
    }
  }
}
</style>
