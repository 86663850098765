<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="modalDonationDuplicated" max-width="900">
        <v-card>
          <div class="float-right">
            <v-btn icon @click="closemodalDonationDuplicated()"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
          </div>
          <v-card-title class="orange--text darken-4 headline font-weight-bold">
            Duplicate donation detected!
          </v-card-title>
          <v-card-text class="pt-4 subtitle-1">
            <p>
              Donatio ID {{ donation_duplicated_id }} has already been
              successfully processed. Please conatct
              <a href="mailto:support@charity.live"> support@charity.live</a>
              for any queries
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="closemodalDonationDuplicated()"
              >CLOSE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: mapState(["modalDonationDuplicated", "donation_duplicated_id"]),
  methods: {
    closemodalDonationDuplicated() {
      let params = window.location.pathname.slice(1).split("/");
      let a = window.location;
      window.location.href = `http://${a.host}/${params[0]}`;
      this.$store.commit("TOGGLE_MODAL_DONATION_DUPLICATED", false);
    },
  },
};
</script>

<style>
</style>
