<template>
  <div class="scroll-bar" :style="`${campaign}`">
    <FireWorksComponent v-if="fireworks"></FireWorksComponent>

    <div id="theNav" class="">
      <TheNavbar
        :teampage="true"
        :isIntersectingElement="isIntersectingElement"
      />
    </div>
    <div class="npinfo ch-text-primary h1 text-center" v-if="message">
      {{ message }}
    </div>
    <div v-else class="body-base mx-auto">
      <IntersectionObserver
        sentinal-name="sentinal-name"
        @on-intersection-element="onIntersectionElement"
      >
        <TheBanner />
        <div class="d-flex flex-wrap justify-space-between align-center">
          <div
            style="margin: 0 auto; width: 300px"
            v-if="info && info.is_goal_hidden == 0"
          >
            <div
              style="
                max-width: 350px;
                min-width: 250px;
                position: relative;
                margin: 0 auto;
              "
            >
              <Goal></Goal>
            </div>
          </div>
          <div id="goalTeamCard">
            <GoalTeam></GoalTeam>
          </div>
          <div style="margin: 0 auto; width: 300px">
            <CountDown />
          </div>
        </div>
      </IntersectionObserver>

    <!-- LIVE STREAM VIDEO COMPONENT -->
    <div id="LiveStreamVideoComponent">
        <LiveStreamVideoComponent :fromPage="`team`"></LiveStreamVideoComponent>
    </div>
    <!-- /LIVE STREAM VIDEO COMPONENT -->

      <div style="margin-top: 30px">
        <Info></Info>
      </div>
      <div id="filterSection" class="w-full">
        <div class="">
          <NewHeaderComponent :teampage="true"></NewHeaderComponent>
        </div>
      </div>
    </div>
    <div>
      <DonationFormCreate :styles="`${campaign}`" />
      <ModalDonationCompleted />
      <div v-show="modalCreateTeam">
        <TeamFormCreate :styles="`${campaign}`" />
      </div>
    </div>

    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import TheNavbar from "../../components/frontend/TheNavbarComponent.vue";
import TheBanner from "../../components/frontend/TheBannerComponent.vue";
import GoalTeam from "../../components/frontend/GoalTeamComponent.vue";
import Footer from "../../components/frontend/FooterComponent.vue";
import TheBannerMobile from "../../components/frontend/TheBannerMobileComponent.vue";
import ModalDonationCompleted from "../../components/frontend/ModalDonationCompletedComponent.vue";
import DonationFormCreate from "../../components/frontend/Donation/FormCreateComponent.vue";
import TeamFormCreate from "../../components/frontend/Team/FormCreateComponent.vue";
import IntersectionObserver from "../../components/frontend/IntersectionObserverComponent.vue";
import { mapState } from "vuex";
import Goal from "../../components/frontv2/Goal.vue";
import NewHeaderComponent from "../../components/frontv2/NewHeaderComponent";
import LiveStreamVideoComponent from "../../components/frontv2/LiveStreamVideoComponent.vue";
import Info from "../../components/frontv2/Info";
import CountDown from "../../components/frontv2/CountDown.vue";
import FireWorksComponent from "../../components/frontend/FireWorksComponent";

export default {
  computed: mapState([
    "modalCreateTeam",
    "message",
    "info",
    "video",
    "team",
    "is_goal_hidden",
  ]),
  components: {
    TheNavbar,
    TeamFormCreate,
    IntersectionObserver,
    TheBanner,
    GoalTeam,
    DonationFormCreate,
    ModalDonationCompleted,
    TheBannerMobile,
    LiveStreamVideoComponent,
    NewHeaderComponent,
    Footer,
    FireWorksComponent,
    Goal,
    CountDown,
    Info,
  },
  data() {
    return {
      isIntersectingElement: true,
      fireworks: false,
      campaign: `
        --charity-primary:  #cdcdcd;
        --charity-secondary:  #fafafa;
        --charity-third:  #000000;
        --charity-color-text:  #e3e3e3;
        --charity-color-alternative:  #ccc;
        --charity-color-title:  #ccc;
      `,
    };
  },
  watch: {
    team() {},
    info() {
      let style = JSON.parse(this.info.style);
      this.campaign = `--charity-primary:  ${style.pickerPrimary.hexa};
        --charity-secondary:  ${style.pickerSecondary.hexa};
        --charity-third:  ${style.pickerTertiary.hexa};
        --charity-color-text:  ${style.pickerPrimary.hexa};
        --charity-color-alternative:  ${style.pickerSecondary.hexa};
        --charity-color-title:  ${style.pickerPrimary.hexa};
        `;

      let endDate = this.info.end;
      var now = new Date();
      endDate = new Date(endDate);
      var endFireworks = new Date(this.info.end);
      endFireworks.setDate(endFireworks.getDate() + 2);
      endDate = new Date(endDate);
      if (endDate < now && endFireworks > now) {
        if (this.info.donations_amount >= this.info.goal) {
          this.fireworks = true;
        }
        // selected date is in the past
      }
    },
  },
  created() {
    let params = window.location.pathname.slice(1).split("/");

    this.$store.dispatch("FETCH_CAMPAIGN_INFO", params[0]);
    this.$store.dispatch("FETCH_CAMPAIGN_MEDIA", params[0]);

    this.$store.dispatch("UPDATE_SEARCH", params);
  },
  methods: {
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    onIntersectionElement(value) {
      this.isIntersectingElement = value;
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 500px) {
  #goalTeamCard {
    min-width: 480px;
    max-width: 480px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
  }
}

@media (max-width: 500px) {
  #goalTeamCard {
    width: 100vw;
    margin-top: 30px;
  }
}

.body-base {
  background-color: white !important;
  max-width: 1100px;
}

.banner {
  padding-top: 125px;
}

.npinfo {
  padding-top: 340px;
  height: 70vh;
}

#welcome {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  margin-top: 40px;
  color: rgba(159, 159, 159, 1);
}

@media (min-width: 960px) and (max-width: 1020px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .mt-mobile-nav {
    padding-top: 100px;
  }
}

@media (max-width: 600px) {
  .mt-mobile-nav {
    padding-top: 30px;
  }
}

.ch-text-primary {
  color: var(--charity-primary) !important;
}

.ch-text-secondary {
  color: var(--charity-secondary) !important;
}

.ch-text-third {
  color: var(--charity-third) !important;
}

.ch-primary {
  background-color: var(--charity-primary) !important;
}

.ch-secondary {
  background-color: var(--charity-secondary) !important;
}

.ch-third {
  background-color: var(--charity-third) !important;
}

.ch-light {
  background-color: var(--background) !important;
}

.ch-text-color {
  color: var(--charity-color-text) !important;
}

.ch-title-color {
  color: var(--charity-color-title) !important;
}

.ch-font-primary {
  font-family: var(--charity-font-primary) !important;
}

.ch-font-secondary {
  font-family: var(--charity-font-secondary) !important;
}

.ch-font-third {
  font-family: var(--charity-font-title) !important;
}

.ch-font-title {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.ch-color-gradient {
  background: var(--charity-primary);
  background: linear-gradient(
    90deg,
    var(--charity-primary) 0%,
    var(--charity-third) 100%
  );
}

.ch-color-gradient-inverse {
  background: var(--charity-third);
  background: linear-gradient(
    90deg,
    var(--charity-third) 0%,
    var(--charity-primary) 100%
  );
}

.ch-font-btn {
  font-family: var(--charity-font-title) !important;
  font-weight: 600 !important;
  font-size: 22px !important;
}

.progress-bar {
  width: 100%;
  height: 16px;
  border-radius: 7px;
  position: relative;
}

.progress-bar-complete {
  height: 16px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
}

.progressBarcomplete {
  background-color: var(--charity-primary);
}
</style>
