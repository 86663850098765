<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="5000" color="success" right top>
      <div class="d-flex justify-space-between">
        <div>{{ $t("copied") }}</div>
        <div class="">
          <v-btn
            color="white"
            dark
            elevation="0"
            icon
            x-small
            @click="snackbar = false"
          >
            <v-icon x-small>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </v-snackbar>

    <div v-if="loading === true">
      <Loading :loading="loading"></Loading>
    </div>

    <div
      v-if="items == ''"
      class="d-flex flex-column align-center justify-center m-0 p-0"
      style="height: 300px"
    >
      <div>
        <v-img
          contain
          height="120px"
          src="img/NoTeams.svg"
          width="120px"
        ></v-img>
      </div>
      <div class="ch-font-title ch-title-color">No teams detected</div>
    </div>

    <!-- <div v-if="teamPage">
              <v-col cols="12">
                <TeamPage />
              </v-col>
            </div> -->

    <div v-else>
      <v-row v-if="items">
        <v-col v-for="(item, index) in items" :key="item.key" cols="12" md="4">
          <div
            class="
              card-donation
              ch-shadow-light
              rounded-lg
              border-gray
              py-4
              px-4
              d-flex
              flex-column
              align-center
            "
            :class="item && item.goal > 0 ? 'justify-space-around' : null"
          >
            <div
              class="
                mx-6
                d-flex
                justify-space-between
                align-center
                containerWidth
              "
            >
              <div class="">
                <a
                  v-if="item && info && info.type_campaign == 0"
                  :href="'/' + campaign + '/' + item.slug"
                >
                  <v-avatar
                    :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                    class="pointer white--text mx-auto"
                    dark
                    size="50"
                    @click="seeTeam(item)"
                  >
                    <template v-if="item.path">
                      <v-img
                        :src="item.path"
                        alt="Team Image"
                        class="bg-white rounded-circle"
                        cover
                        height="50px"
                        width="50px"
                      >
                      </v-img>
                    </template>
                    <template v-else>
                      {{ item.name | idNameTeam }}
                    </template>
                  </v-avatar>
                </a>
                <v-avatar
                  v-else-if="item"
                  :color="index % 2 == 0 ? 'ch-primary' : 'ch-secondary'"
                  class="white--text mx-auto"
                  dark
                  size="50"
                >
                  <template v-if="item.path">
                    <v-img
                      :src="item.path"
                      alt="Team Image"
                      class="bg-white rounded-circle"
                      cover
                      height="50px"
                      width="50px"
                    >
                    </v-img>
                  </template>
                  <template v-else>
                    {{ item.name | idNameTeam }}
                  </template>
                </v-avatar>
              </div>
              <a
                v-if="item && info && info.type_campaign == 0"
                :href="'/' + campaign + '/' + item.slug"
              >
                <div
                  class="
                    ml-3
                    general-title
                    font-weight-bold
                    pointer
                    text-truncate text-team
                  "
                  @click="seeTeam(item)"
                >
                  {{ item.name }}
                  <!-- <div class="general-subtitle">Committee</div> -->
                </div>
              </a>
              <div
                v-else-if="item"
                class="
                  ml-3
                  general-title
                  font-weight-bold
                  text-truncate text-team
                "
              >
                {{ item.name }}
                <!-- <div class="general-subtitle">Committee</div> -->
              </div>
              <div class="ml-auto">
                <a
                  v-if="item && info && info.type_campaign == 0"
                  :href="'/' + campaign + '/' + item.slug"
                >
                  <div class="buttonView" @click="seeTeam(item)">
                    <div class="text-center ch-text-secondary viewText">
                      VIEW
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="mx-6 my-4 description-area text-small containerWidth">
              {{ item.description | truncate_280 }}
            </div>
            <div
              v-if="info && info.type_campaign == 0 && item.goal > 0"
              class="mx-8 text-small containerWidth"
            >
              <div class="grey lighten-2 progress-bar my-4">
                <template v-if="item.percent >= 80">
                  <div
                    :style="{ width: item.percent + '%' }"
                    class="progress-bar-complete"
                  ></div>
                </template>
                <template v-if="item.percent < 80 && item.percent >= 40">
                  <div
                    :style="{ width: item.percent + '%' }"
                    class="progress-bar-intermediate"
                  ></div>
                </template>
                <template v-if="item.percent < 40">
                  <div
                    :style="{ width: item.percent + '%' }"
                    class="progress-bar-start"
                  ></div>
                </template>
              </div>
              <p class="percenteGoal">{{ item.percent.toFixed() }}%</p>
            </div>
            <div v-else></div>

            <div
              class="
                mx-8
                d-flex
                justify-space-between
                align-center
                containerWidth
              "
              :style="item.goal == 0 ? 'padding-top: 56px' : null"
              v-if="info && info.type_campaign == 0"
            >
              <v-row class="footerCardTeam raisedTeam">
                <v-col class="text-center">
                  <p class="pa-0 ma-0">
                    {{ info.principal_currency.symbol }}
                    {{ item.raised | dollar }}
                  </p>
                  <span class="raisedText"> Raised </span>
                </v-col>
                <v-col class="text-center">
                  <p class="pa-0 ma-0">{{ item.donors }}</p>
                  <span class="raisedText"> Donors </span>
                </v-col>
                <v-col class="text-center" v-if="item.goal > 0">
                  <p class="pa-0 ma-0 goalTeam">
                    {{ info.principal_currency.symbol }}
                    {{ item.goal | dollar }}
                  </p>
                  <span class="raisedText"> Goal </span>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>

      <div class="d-flex justify-center my-4">
        <v-btn
          v-if="next"
          class="button btn-large rounded-lg ch-text-primary"
          dark
          elevation="0"
          large
          text
          @click="getTeamsPaginate"
        >
          {{ $t("see_more") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TeamPage from "./PageComponent.vue";
import Loading from "../LoadingComponent.vue";

export default {
  components: {
    Loading,
    TeamPage,
  },
  data() {
    return {
      value: 30,
      bufferValue: 120,
      interval: 0,
      items: null,
      next: null,
      loading: true,
      snackbar: false,
      campaign: "",
    };
  },
  created() {
    const params = window.location.pathname.slice(1).split("/");
    this.campaign = params[0];
    this.getTeams();
  },
  computed: mapState(["search", "teamPage", "orderByFinder", "info"]),
  watch: {
    search() {
      this.getTeamsByQuery();
    },
    orderByFinder() {
      this.getTeamsByQuery();
    },
  },
  methods: {
    orderByDonor() {
      this.items.sort(function (a, b) {
        if (a.donors < b.donors) {
          return 1;
        }
        if (a.donors > b.donors) {
          return -1;
        }
        return 0;
      });
    },
    orderByLatest() {
      this.items.sort(function (a, b) {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
    },
    orderByHighest() {
      this.items.sort(function (a, b) {
        if (a.raised < b.raised) {
          return 1;
        }
        if (a.raised > b.raised) {
          return -1;
        }
        return 0;
      });
    },
    makeDonation() {
      this.$store.commit("TOGGLE_MODAL_DONATION");
    },
    getTeams() {
      this.loading = true;
      axios
        .get(
          `/api/v1/campaign/${this.campaign}/team/all?order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data;
          switch (this.orderByFinder) {
            case "Latest":
              this.orderByLatest();
              break;
            case "Donnors":
              this.orderByDonor();
              break;
            case "Highest":
              this.orderByHighest();
              break;
            default:
              break;
          }
          //         this.next = res.data.next_page_url;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamsPaginate() {
      this.loading = true;
      axios
        .get(`${this.next}&query=${this.search}&order=${this.orderByFinder}`)
        .then((res) => {
          res.data.data.forEach((element) => {
            this.items.push(element);
          });
          this.next = res.data.next_page_url;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamsByQuery() {
      this.loading = true;
      axios
        .get(
          `/api/v1/campaign/${this.campaign}/team/all?query=${this.search}&order=${this.orderByFinder}`
        )
        .then((res) => {
          this.items = res.data;
          switch (this.orderByFinder) {
            case "Latest":
              this.orderByLatest();
              break;
            case "Donnors":
              this.orderByDonor();
              break;
            case "Highest":
              this.orderByHighest();
              break;
            default:
              break;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    seeTeam(item) {
      // this.$store.commit("UPDATE_TEAM", item);
      // this.views = true;
      // document
      //   .getElementById("filterSection")
      //   .scrollIntoView({ behavior: "smooth" });
      window.location.href = `/${this.campaign}/${item.slug}`;
    },
    copyTeamPage(slug) {
      const url = window.location.origin;
      const el = document.createElement("textarea");
      document.body.appendChild(el);
      el.value = `${url}/${slug}`;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.containerWidth {
  width: 97%;
}

.percenteGoal {
  line-height: 0;
  text-align: end;
  margin-top: -6px;
  font-weight: bold;
  margin-bottom: 18px;
  color: var(--charity-secondary);
}

.boxView {
  padding-top: 4px;
}

.goalTeam {
  color: #9b9b9b;
  font-size: 19px;
}

.footerCardTeam {
  line-height: 1;
}

.raisedText {
  font-weight: normal;
  color: #a4a4a4;
  font-size: 14px;
  font-family: "Roboto";
}

.raisedTeam {
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;
  font-weight: bold;
  color: #2c2c2c;
}

.viewText {
  margin-right: 3px;
  margin-left: 3px;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
}

.buttonView {
  border: 1px solid var(--charity-secondary) !important;
  font-size: 15px;
  cursor: pointer;
  padding: 3px;
  align-items: center;
  margin-left: 5px;
}

.buttonView:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.description-area {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  text-align: justify;
  overflow: scroll;
  overflow-y: hidden;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

::-webkit-scrollbar:vertical {
  display: block;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 13px !important;
}

.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 7px;
  position: relative;
}

.progress-bar-complete {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #00bb3d 33%,
    #00b343 87%,
    #00ae4f 100%
  );
}

.progress-bar-intermediate {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f28800 33%,
    #ea6f00 87%,
    #e75900 100%
  );
}

.progress-bar-start {
  height: 8px;
  border-radius: 7px;
  position: absolute;
  left: 0x;
  top: 0px;
  background-image: linear-gradient(
    85deg,
    #f0000b 33%,
    #e50017 87%,
    #df0023 100%
  );
}

.text-team {
  white-space: inherit !important;
  line-height: 1.2;
  color: #2c2c2c;
  text-decoration: none;
}

.text-truncate {
  /* width: 60%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; */
}
</style>
